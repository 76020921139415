import { useRef, useEffect, useState } from 'react';

import { GLOBAL_STYLES } from '../../../utils/styles/global-styles';

import {
    ArrowUpTrayIcon,
    PaperAirplaneIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import { muted_border } from '../../../utils/styles/tailwind-styles';
import { DocumentChartBarIcon } from '@heroicons/react/24/solid';
import { discoverHomeService } from '../../../store/discover/home/discover-home.services';

const TargetTemplateUploadModal = ({ metric, className, onFinish }) => {
    const ref = useRef();
    const dropArea = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [draggedOver, setDraggedOver] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && ref.current === e.target) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            <label
                className={`btn btn-outline btn-primary btn-xs flex justify-center items-center tooltip normal-case ${className}`}
                htmlFor={`target-template-upload-modal-${metric}`}
                onClick={() => {}}
                data-tip={'Upload Template'}
            >
                <ArrowUpTrayIcon className="w-4 h-4" />
            </label>

            <input
                type="checkbox"
                id={`target-template-upload-modal-${metric}`}
                className="modal-toggle"
            />

            <div className="modal">
                {loading ? (
                    <div
                        className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl flex items-center justify-center`}
                        style={{
                            maxHeight: '80vh',
                            minHeight: '50vh',
                        }}
                    >
                        <LoadingComponent
                            type={LOADING_COMPONENT_TYPES.TAILSPIN}
                        />
                    </div>
                ) : (
                    <div
                        className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl`}
                        style={{
                            maxHeight: '80vh',
                        }}
                    >
                        <span className="w-full flex flex-col items-center justify-start mt-2">
                            <div className="flex justify-between items-center w-full gap-2">
                                <h3 className="font-bold w-fit">
                                    Target Template Upload
                                </h3>

                                <div className="flex flex-wrap grow gap-1 justify-start items-center">
                                    <span className="badge badge-primary font-bold capitalize">
                                        {metric.replace('_', ' ')}
                                    </span>
                                </div>

                                <div className="flex items-center gap-1">
                                    <label
                                        htmlFor={`target-template-upload-modal-${metric}`}
                                        className="btn btn-ghost btn-circle btn-sm"
                                        onClick={() => onFinish()}
                                    >
                                        <XMarkIcon
                                            className="w-4 h-4"
                                            onClick={toggleIsOpen}
                                        />
                                    </label>
                                </div>
                            </div>
                        </span>

                        <div className="divider my-2" />

                        <div
                            className={`${
                                draggedOver
                                    ? `border-2 border-primary border-opacity-80 shadow-inner`
                                    : `border border-base-content border-opacity-20 shadow-inner`
                            } ${
                                selectedFile
                                    ? 'bg-primary text-primary-content'
                                    : 'text-base-content'
                            }`}
                            ref={dropArea}
                            onDragEnter={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (!draggedOver) setDraggedOver(true);
                            }}
                            onDragOver={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (!draggedOver) setDraggedOver(true);
                            }}
                            onDragLeave={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                if (draggedOver) setDraggedOver(false);
                            }}
                            onDrop={(event) => {
                                event.preventDefault();
                                event.stopPropagation();

                                let files = event.dataTransfer.files;
                                if (files && files.length >= 1)
                                    setSelectedFile(files[0]);
                                setDraggedOver(false);
                            }}
                        >
                            <form className="flex flex-col items-start justify-start w-full min-h-16 p-8">
                                <input
                                    type="file"
                                    className={'w-0 h-0 hidden'}
                                    id={'fileInput'}
                                    // multiple
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={(event) => {
                                        let file = event.target.file;
                                        setSelectedFile(file);
                                    }}
                                />
                                <label
                                    className="text-opacity-80"
                                    htmlFor="fileInput"
                                >
                                    <strong>Drag</strong> your template and drop
                                    anywhere within this box.
                                </label>

                                <div className="divider my-1" />

                                {selectedFile ? (
                                    <div className="flex items-center w-full justify-between bg-base-100 bg-opacity-20 rounded">
                                        <div className="flex items-center justify-start gap-2">
                                            <span className="w-8 bg-emerald-500 text-white rounded p-1 flex justify-center">
                                                <DocumentChartBarIcon className="w-4 h=4" />
                                            </span>
                                            <p className="text-xs font-bold text-opacity-60">
                                                {selectedFile.name.length > 52
                                                    ? selectedFile.name.slice(
                                                          0,
                                                          52
                                                      ) + '..'
                                                    : selectedFile.name}
                                            </p>

                                            <p className="text-xs text-opacity-60">
                                                {(
                                                    selectedFile.size /
                                                    1000 /
                                                    10 /
                                                    100
                                                ).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                                mb
                                            </p>

                                            <div className="divider divider-horizontal mx-0" />
                                        </div>

                                        <span
                                            className={`btn btn-xs tooltip ${
                                                selectedFile
                                                    ? 'btn-error'
                                                    : 'btn-ghost'
                                            }`}
                                            data-tip={'Clear'}
                                            onClick={() => {
                                                setSelectedFile(null);
                                            }}
                                        >
                                            <XMarkIcon className={`w-4 h-4`} />
                                        </span>
                                    </div>
                                ) : (
                                    <input
                                        type="file"
                                        className={
                                            'file-input file-input-xs file-input-ghost w-full'
                                        }
                                        id={'fileInput'}
                                        // multiple
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();

                                            let files = event.target.files;
                                            if (files && files.length >= 1)
                                                setSelectedFile(files[0]);
                                        }}
                                    />
                                )}
                            </form>
                        </div>

                        <p className="text-sm m-4">
                            <span className="badge-primary badge-xs mr-2">
                                Note
                            </span>
                            Uploading a template file will overwrite all
                            existing targets.
                        </p>

                        <div className="divider mt-2 mb-4" />

                        <div className="flex w-full justify-end items-start">
                            <label
                                className={`btn btn-xs h-full gap-1 ${
                                    selectedFile
                                        ? 'btn-primary'
                                        : 'btn-disabled disabled'
                                }`}
                                htmlFor={`target-template-upload-modal-${metric}`}
                                data-tip={'Upload Targets'}
                                onClick={async () => {
                                    setLoading(true);
                                    try {
                                        const response =
                                            await discoverHomeService.submitTargetTemplateUploadRequest(
                                                selectedFile,
                                                metric
                                            );
                                        setLoading(false);
                                        setSelectedFile(null);
                                    } catch (Exception) {
                                        setLoading(false);
                                        setSelectedFile(null);
                                    }
                                }}
                            >
                                <PaperAirplaneIcon className={'w-4 h-4'} />
                                Submit Template
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default TargetTemplateUploadModal;