import { NAVIGATION_ACTION_TYPES } from './navigation.types';

const INITIAL_STATE = {
    themeMenuIsOpen: false,
    sidebarMode: false,
    sidebarIsOpen: false,
};

export const navigationReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case NAVIGATION_ACTION_TYPES.SET_SIDEBAR_MODE:
            return { ...state, sidebarMode: payload };
        case NAVIGATION_ACTION_TYPES.SET_SIDEBAR_ACTIVE:
            return { ...state, sidebarIsOpen: payload };
        case NAVIGATION_ACTION_TYPES.SET_THEME_MENU_ACTIVE:
            return { ...state, themeMenuIsOpen: payload };
        default:
            return state;
    }
};
