import { useSelector } from 'react-redux';
import {
    selectUser,
    selectUserToken,
} from '../../../store/auth/authentication.selectors';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

import { useEffect, useState } from 'react';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { engineService } from '../../../utils/engine/engine.service';
import { apiService } from '../../../utils/engine/api.service';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';
import { selectProductType } from '../../../store/product/product.selectors';
import {
    generateTemporaryPassword,
    isCAdmin,
    isCOwner,
    isM2Admin,
    isM2Support,
    USER_ROLES,
} from '../../../utils/auth.util';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid';

const UserAddComponent = ({ onSuccess }) => {
    const user = useSelector(selectUser);

    const product = useSelector(selectProductType);

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(USER_ROLES.USER);

    const [password, setPassword] = useState(generateTemporaryPassword(8));
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [roleOptions, setRoleOptions] = useState([]);

    useEffect(() => {
        if (isM2Admin(user)) setRoleOptions(Object.values(USER_ROLES));

        if (isM2Support(user))
            setRoleOptions(
                Object.values(USER_ROLES).filter(
                    (roleOption) => roleOption !== USER_ROLES.M2_ADMIN
                )
            );

        if (isCOwner(user) || isCAdmin(user)) {
            setRoleOptions(
                Object.values(USER_ROLES).filter(
                    (roleOption) =>
                        ![
                            USER_ROLES.M2_ADMIN,
                            USER_ROLES.M2_SUPPORT,
                            USER_ROLES.C_OWNER,
                        ].includes(roleOption)
                )
            );
        }
    }, [user]);

    const onCreateUser = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const response = await apiService.addUser(
                name,
                email,
                password,
                role
            );

            /*
            We now need to add the masterdata entry
             */
            if (response.status >= 200 && response.status < 400) {
                engineService
                    .invoke(WEB_PROCESS_FLOWS.MASTERDATA, {
                        action: 'addUserProduct',
                        payload: {
                            email: email,
                            product: product,
                        },
                    })
                    .then((payload) => {
                        createNotification(
                            `Added [${email}] as a [${product}] user.`,
                            NOTIFICATION_TYPES.SUCCESS
                        );
                        return payload;
                    })
                    .catch((error) => {
                        createNotification(
                            'Something went wrong, please try refresh',
                            NOTIFICATION_TYPES.ERROR
                        );
                        return Promise.reject(error.statusText);
                    });
            }

            setName('');
            setEmail('');
            setRole(USER_ROLES.USER);

            setPassword(generateTemporaryPassword(8));
            setPasswordVisible(false);

            onSuccess();
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <input
                type="checkbox"
                id="add-user-modal"
                className="modal-toggle"
            />

            <div className="modal">
                <div className="modal-box relative py-3">
                    {isLoading ? (
                        <span className="w-full flex justify-center items-center min-h-16 py-28">
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.TAILCHASE}
                            />
                        </span>
                    ) : (
                        <>
                            <span className="w-full flex justify-between items-center gap-2">
                                <h3 className="text-lg font-bold">Add User</h3>
                                <span className="badge badge-lg badge-primary text-center justify-center font-bold">
                                    {user?.custaccountName}
                                </span>
                            </span>

                            <div className="divider my-1" />

                            <form
                                className="w-full h-full m-0 px-2"
                                autoComplete={'off'}
                            >
                                <div className="w-full h-full grid grid-cols-2 place-items-center gap-2">
                                    <div className="col-span-2 w-full">
                                        <label
                                            className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                            htmlFor="name"
                                        >
                                            Name
                                        </label>

                                        <input
                                            className="input input-bordered input-sm w-full"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={(event) =>
                                                setName(
                                                    event.currentTarget.value
                                                )
                                            }
                                            required={true}
                                            autoComplete={'false'}
                                        />
                                    </div>

                                    <div className="col-span-2 w-full mb-2">
                                        <label
                                            className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                            htmlFor="email"
                                        >
                                            Email
                                        </label>
                                        <input
                                            className="input input-bordered input-sm w-full"
                                            name="user-email"
                                            id="user-email"
                                            type="email"
                                            value={email}
                                            onChange={(event) =>
                                                setEmail(
                                                    event.currentTarget.value
                                                )
                                            }
                                            required={true}
                                            autoComplete={'new-email'}
                                        />
                                    </div>
                                </div>

                                <div className="w-full h-full grid grid-cols-2 place-items-center gap-2">
                                    <div className="col-span-2 md:col-span-1 w-full">
                                        <span className="w-full h-full flex justify-between items-center">
                                            <label
                                                className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                                htmlFor="user-password"
                                            >
                                                Password
                                            </label>

                                            <div className="flex items-center gap-1">
                                                <span
                                                    className="btn btn-primary btn-xs btn-circle tooltip tooltip-left flex items-center justify-center"
                                                    data-tip={
                                                        'Re-generate Password'
                                                    }
                                                    onClick={() => {
                                                        setPassword(
                                                            generateTemporaryPassword(
                                                                8
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <ArrowPathRoundedSquareIcon className="w-4 h-4" />
                                                </span>

                                                {passwordVisible ? (
                                                    <span
                                                        className="btn btn-ghost btn-xs btn-circle"
                                                        onClick={() =>
                                                            setPasswordVisible(
                                                                !passwordVisible
                                                            )
                                                        }
                                                    >
                                                        <EyeSlashIcon className="w-4 h-4" />
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="btn btn-ghost btn-xs btn-circle"
                                                        onClick={() =>
                                                            setPasswordVisible(
                                                                !passwordVisible
                                                            )
                                                        }
                                                    >
                                                        <EyeIcon className="w-4 h-4" />
                                                    </span>
                                                )}
                                            </div>
                                        </span>

                                        <input
                                            className="input input-bordered input-sm w-full"
                                            name="user-password"
                                            id="user-password"
                                            type={
                                                passwordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            required={true}
                                            onChange={(event) =>
                                                setPassword(
                                                    event.currentTarget.value
                                                )
                                            }
                                            value={password}
                                            autoComplete={'new-password'}
                                        />
                                    </div>

                                    <div className="col-span-2 md:col-span-1 w-full">
                                        <span className="w-full h-full flex justify-between items-center">
                                            <label
                                                className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                                htmlFor="role"
                                            >
                                                Role
                                            </label>
                                        </span>

                                        <select
                                            onChange={(e) =>
                                                setRole(e.target.value)
                                            }
                                            value={role}
                                            id={'role'}
                                            className="select select-bordered select-sm min-w-full"
                                        >
                                            {roleOptions.map((roleOption) => {
                                                return (
                                                    <option key={roleOption}>
                                                        {roleOption}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="divider" />

                                <div className="w-full flex justify-end gap-2 items-center mt-5">
                                    <label
                                        className="btn btn-outline btn-sm normal-case"
                                        htmlFor={'add-user-modal'}
                                    >
                                        Close
                                    </label>

                                    <label
                                        className={`btn btn-primary btn-outline btn-sm normal-case`}
                                        htmlFor={'add-user-modal'}
                                        onClick={onCreateUser}
                                    >
                                        Create
                                    </label>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default UserAddComponent;