import { BASE_FILTERS_ACTION_TYPES } from './base-filters.types';

const now = new Date();
let from = new Date();
from.setMonth(from.getMonth() - 3);

const INITIAL_STATE = {
    selected: {
        fromDate: from,
        toDate: now,
    },
};

export const baseFiltersReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case BASE_FILTERS_ACTION_TYPES.SET_FILTERS_SELECTED:
            return {
                ...state,
                selected: {
                    ...state['selected'],
                    [payload.id]: payload.selectedFilters,
                },
            };

        case BASE_FILTERS_ACTION_TYPES.CLEAR_FILTER_SELECTED:
            const selectedRemoved = { ...state['selected'] };
            delete selectedRemoved[payload];
            return { ...state, selected: selectedRemoved };

        case BASE_FILTERS_ACTION_TYPES.CLEAR_FILTERS_SELECTED:
            const original = { ...state['selected'] };
            payload.forEach((id) => {
                delete original[payload];
            });
            return { ...state, selected: original };

        case BASE_FILTERS_ACTION_TYPES.RESET_FILTERS_SELECTED:
            return { ...INITIAL_STATE };

        default:
            return state;
    }
};
