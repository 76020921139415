import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';
import { engineService } from '../../../utils/engine/engine.service';
import {
    addOpacity,
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
    graphColourSelector,
} from '../../../utils/styles/global-styles';

export const dashboardService = {
    fetchDashboardDataStatus,
    fetchDashboardTopSkus,
    fetchDashboardSalesPerformance,
    fetchDashboardTopStores,

    convertTopSkuDataToPlottable,
    convertDataStatusToPlottable,
    convertSalesPerformanceToPlottable,
};

async function fetchDashboardDataStatus(filters) {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DASHBOARD_DATA_STATUS,
        {
            selectedFilters: filters,
        }
    );
    return response;
}

async function fetchDashboardTopSkus(filters) {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DASHBOARD_TOP_SKUS,
        {
            selectedFilters: filters,
        }
    );
    return response;
}

async function fetchDashboardSalesPerformance(filters) {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DASHBOARD_SALES_PERFORMANCE,
        {
            selectedFilters: filters,
        }
    );
    return response;
}

async function fetchDashboardTopStores(filters) {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DASHBOARD_TOP_STORES,
        {
            selectedFilters: filters,
        }
    );
    return response;
}

/*
DATA FORMATTING HELPER FUNCTIONS
 */
function convertTopSkuDataToPlottable(topSkuData, daisyTheme) {
    const byValueGraphData =
        'by_value' in topSkuData
            ? convertTopSkuDataToPlottablePerCategory(
                  topSkuData['by_value'],
                  daisyTheme
              )
            : fetchStubGraphData('By Value');
    const byVolumeGraphData =
        'by_volume' in topSkuData
            ? convertTopSkuDataToPlottablePerCategory(
                  topSkuData['by_volume'],
                  daisyTheme
              )
            : fetchStubGraphData('By Volume');
    const biggestDropsGraphData =
        'biggest_drops' in topSkuData
            ? convertTopSkuDataToPlottablePerCategory(
                  topSkuData['biggest_drops'],
                  daisyTheme
              )
            : fetchStubGraphData('Biggest Drops');
    const highestGainsGraphData =
        'highest_gains' in topSkuData
            ? convertTopSkuDataToPlottablePerCategory(
                  topSkuData['highest_gains'],
                  daisyTheme
              )
            : fetchStubGraphData('Highest Gains');
    const mostProfitGraphData =
        'most_profit' in topSkuData
            ? convertTopSkuDataToPlottablePerCategory(
                  topSkuData['most_profit'],
                  daisyTheme
              )
            : fetchStubGraphData('Most Profit');

    return {
        byValueGraphData,
        byVolumeGraphData,
        biggestDropsGraphData,
        highestGainsGraphData,
        mostProfitGraphData,
    };
}

function convertTopSkuDataToPlottablePerCategory(categoryData, daisyTheme) {
    const unformattedDatasets = categoryData['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = categoryData['rows'].map((row) => row.name);

    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: dataset,
                xAxisID: dataset === 'avg price' ? `x2` : `x`,
                data: categoryData['rows'].map((row) => {
                    return row[dataset];
                }),
                borderColor,
                borderRadius: 8,
                backgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}

function convertDataStatusToPlottable(dataStatusData, daisyTheme) {
    const salesPerDistValue = convertDataStatusToPlottablePerCategory(
        dataStatusData['sales_perdist_value'],
        daisyTheme
    );
    const salesPerDistStoreCount = convertDataStatusToPlottablePerCategory(
        dataStatusData['sales_perdist_storecount'],
        daisyTheme
    );
    const invPerDistVolume = convertDataStatusToPlottablePerCategory(
        dataStatusData['inv_perdist_volume'],
        daisyTheme
    );
    const invPerDistStoreCount = convertDataStatusToPlottablePerCategory(
        dataStatusData['inv_perdist_storecount'],
        daisyTheme
    );

    const alertsPublishedPerType =
        dataStatusData['alerts_published_pertype_records'];
    const alertsPublishedResponded =
        dataStatusData['alerts_published_responded_records'];

    return {
        salesPerDistValue,
        salesPerDistStoreCount,
        invPerDistVolume,
        invPerDistStoreCount,
    };
}

function convertDataStatusToPlottablePerCategory(categoryData, daisyTheme) {
    if (categoryData['channels'] === undefined) {
        return fetchStubGraphData();
    }
    const unformattedDatasets = categoryData['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = categoryData['rows'].map((row) => row.name);

    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: dataset,
                data: categoryData['rows'].map((row) => row[dataset]),
                fill: false,
                borderColor,
                tension: 0.125,

                pointRadius: 5,
                pointHoverRadius: 10,
                pointBorderWidth: 2,
                pointHoverBorderWidth: 1,
                pointBorderColor: borderColor,
                pointBackgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}

function convertDashboardProgressToPlottable(categoryData, daisyTheme) {
    const unformattedDatasets = categoryData['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = categoryData['rows'].map((row) => row.name);
    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index !== 0 ? index - 1 : index + 7,
                daisyTheme
            );
            return {
                label: dataset,
                // xAxisID: dataset === 'full year' ? `x2` : `x`,
                xAxisID: `x`,
                hidden: dataset === 'full year',
                data: categoryData['rows'].map((row) => {
                    return row[dataset];
                }),
                borderColor,
                borderRadius: 8,
                backgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}

function convertSalesPerformanceToPlottable(salesPerformanceData, daisyTheme) {
    const progress = convertDashboardProgressToPlottable(
        salesPerformanceData['progress'],
        daisyTheme
    );

    const monthlyByVolume = convertSalesPerformanceToPlottablePerCategory(
        salesPerformanceData['monthly_by_volume'],
        daisyTheme
    );
    const monthlyByValue = convertSalesPerformanceToPlottablePerCategory(
        salesPerformanceData['monthly_by_value'],
        daisyTheme
    );

    return {
        progress,
        monthlyByValue,
        monthlyByVolume,
    };
}

function convertSalesPerformanceToPlottablePerCategory(
    categoryData,
    daisyTheme
) {
    const labels = categoryData['channels'].map((dataset) => dataset.dataKey);
    const data = categoryData['rows'].map((data) => data['diff']);

    let neutralBorderColor = DAISY_THEME_MAP[daisyTheme]['primary'];

    let positiveBackgroundColor = addOpacity(
        GLOBAL_COLOURS['accents'][`emerald-medium`],
        0.5
    );
    let negativeBackgroundColor = addOpacity(
        GLOBAL_COLOURS['accents'][`rose-medium`],
        0.5
    );
    let neutralBackgroundColor = addOpacity(
        DAISY_THEME_MAP[daisyTheme]['primary'],
        0.5
    );

    return {
        labels,
        datasets: [
            {
                label: 'Difference',
                data: data,
                fill: {
                    target: 'origin',
                    above: positiveBackgroundColor, // Area will be red above the origin
                    below: negativeBackgroundColor, // And blue below the origin
                },
                borderColor: neutralBorderColor,
                borderWidth: 0.5,
                backgroundColor: neutralBackgroundColor,
                tension: 0.25,

                pointRadius: 5,
                pointHoverRadius: 10,
                pointBorderWidth: 0.5,
                pointHoverBorderWidth: 0.5,
            },
        ],
    };
}

function fetchStubGraphData(type) {
    return {
        labels: [''],
        datasets: [
            {
                label: '',
                data: null,
            },
        ],
    };
}
