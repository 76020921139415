import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/24/solid';

import { selectDaisyTheme } from '../../store/themes/themes.selectors';

import Select from 'react-select';

import { getWindowSize } from '../base/filters/trend-filters/trend-filters.component';

import { generateSelectStylesDaisy } from '../../utils/styles/global-styles';
import { muted_border } from '../../utils/styles/tailwind-styles';

const metricOptions = [
    { value: 'days', label: 'Days' },
    { value: 'weeks', label: 'Weeks' },
    { value: 'months', label: 'Months' },
    { value: 'years', label: 'Years' },
];

const valueOptions = {
    days: Array.from(Array(7), (_, i) => i + 1).map((i) => {
        return { value: i, label: i };
    }),
    weeks: Array.from(Array(52), (_, i) => i + 1).map((i) => {
        return { value: i, label: i };
    }),
    months: Array.from(Array(12), (_, i) => i + 1).map((i) => {
        return { value: i, label: i };
    }),
    years: Array.from(Array(5), (_, i) => i + 1).map((i) => {
        return { value: i, label: i };
    }),
};

const buildRangeObject = (timeMetric, rangeValue) => {
    return {
        fromDate: `-${rangeValue['value']} ${timeMetric['value']}`,
        toDate: 'now',
    };
};

const convertFromToMetric = (initialFrom) => {
    if (!initialFrom) return metricOptions[0];
    let temp = initialFrom.split(/(\s+)/).filter((e) => e.trim().length > 0);
    for (let i = 0; i < metricOptions.length; i++) {
        let option = metricOptions[i];
        if (option['value'] === temp[temp.length - 1]) {
            return option;
        }
    }
    return metricOptions[0];
};

const convertFromToRange = (initialFrom) => {
    if (!initialFrom) return valueOptions['days'][0];

    let temp = initialFrom.split(/(\s+)/).filter((e) => e.trim().length > 0);
    let value = Math.abs(temp[0]);
    return {
        value: value,
        label: value,
    };
};

const RelativeDateRangeComponent = ({ onChange, initialFrom }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    const [timeMetric, setTimeMetric] = useState(
        convertFromToMetric(initialFrom)
    );
    const [rangeValue, setRangeValue] = useState(
        convertFromToRange(initialFrom)
    );

    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const customStyles = generateSelectStylesDaisy(daisyTheme, windowSize);

    const changeTimeMetric = (metric) => {
        setTimeMetric(metric);
        onChange(buildRangeObject(metric, rangeValue));
    };

    const changeRangeValue = (value) => {
        setRangeValue(value);
        onChange(buildRangeObject(timeMetric, value));
    };

    return (
        <div
            className={`flex w-full max-w-lg justify-between items-center bg-base-300 rounded px-6 py-2 h-16 ${muted_border}`}
        >
            <CalendarIcon className="h-5 w-5 mr-2" />
            <p className="text-xs">Data from</p>
            <Select
                styles={customStyles}
                options={valueOptions[timeMetric['value']]}
                value={rangeValue}
                placeholder=""
                onChange={changeRangeValue}
                isSearchable={false}
                isClearable={false}
                isMulti={false}
            />
            <Select
                styles={customStyles}
                options={metricOptions}
                value={timeMetric}
                placeholder=""
                onChange={changeTimeMetric}
                isSearchable={false}
                isClearable={false}
                isMulti={false}
            />
            <p className="text-xs lowercase">before</p>
        </div>
    );
};

export default RelativeDateRangeComponent;