import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { XMarkIcon } from '@heroicons/react/24/solid';

import Select from 'react-select';

import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { selectFiltersSelected } from '../../../store/base/filters/base-filters.selectors';
import { selectTrendFilters } from '../../../store/base/filters/trend/trend.selectors';
import { trendService } from '../../../store/base/filters/trend/trend.services';

import DateRangeComponent from '../filters/date-filters/date-range.component';
import FiltersContainer from '../filters/filters-container.component';
import SchedulerComponent from '../../scheduler-component/scheduler.component';

import { getWindowSize } from '../filters/trend-filters/trend-filters.component';

import {
    generateSelectStylesDaisy,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';
import '../share-view-component/share-view.styles.css';

import RelativeDateRangeComponent from '../../relative-date-range/relative-date-range.component';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';

const frequencyOptions = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'sum', label: 'Sum of Range' },
];

const GenerateReportComponent = ({
    frequency,
    dateRange,
    includeSoh,
    onGenerate,
}) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    const selectedFilters = useSelector(selectFiltersSelected);
    const trendFilters = useSelector(selectTrendFilters);

    const ref = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [selectedFrequency, setSelectedFrequency] = useState(
        trendService.mapTrendFilterValueToSelectionObject(
            trendFilters.frequency
        )
    );
    const [soh, setSoh] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [additionalMessage, setAdditionalMessage] = useState('');

    const [scheduleReport, setScheduleReport] = useState(false);
    const [scheduleCronTab, setScheduleCronTab] = useState(null);

    const [relativeDateRange, setRelativeDateRange] = useState(null);

    const handleFrequencyChange = (event) => {
        setSelectedFrequency(event);
    };
    const handleScheduleToggler = (event) => {
        setScheduleReport(event);
        if (!event) {
            setScheduleCronTab(null);
            setRelativeDateRange(null);
        }
    };

    const handleRelativeDateRangeChange = (event) => {
        setRelativeDateRange(event);
    };

    const handleSchedulerChange = (cron) => {
        setScheduleCronTab(cron);
    };

    const customStylesMulti = generateSelectStylesDaisy(daisyTheme, windowSize);

    useEffect(() => {
        handleScheduleToggler(false);

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && ref.current === e.target) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        setSoh(false);
        setScheduleReport(false);
    }, [isOpen]);

    const onGenerateHandler = () => {
        const reportParams = {};
        if (includeSoh) reportParams['includeSoh'] = soh;
        if (frequency) reportParams['frequency'] = selectedFrequency.value;
        if (additionalMessage !== '') reportParams['note'] = additionalMessage;
        if (dateRange) {
            reportParams['fromDate'] = selectedFilters.fromDate;
            reportParams['toDate'] = selectedFilters.toDate;
        }

        reportParams['sendViaEmail'] = true;

        if (scheduleCronTab && scheduleReport) {
            reportParams['cronText'] = scheduleCronTab;
            if (relativeDateRange) {
                reportParams['fromDate'] = relativeDateRange['fromDate'];
                reportParams['toDate'] = relativeDateRange['toDate'];
            } else {
                createNotification(
                    'Relative date range not adjusted, defaulting to use data from 7 days before',
                    NOTIFICATION_TYPES.WARNING
                );
                reportParams['fromDate'] = '-7 days';
                reportParams['toDate'] = 'now';
            }
        }
        onGenerate(reportParams);
        setIsOpen(false);
    };

    return (
        <>
            <label
                className="btn btn-sm btn-circle btn-ghost flex justify-center items-center tooltip tooltip-bottom normal-case"
                htmlFor="generate-report-modal"
                data-tip={'Generate Report'}
            >
                <DocumentPlusIcon className="w-5 h-5" />
            </label>

            <input
                type="checkbox"
                id="generate-report-modal"
                className="modal-toggle"
            />
            <div className="modal lg:top-20 top-0" style={{ zIndex: 1001 }}>
                <div
                    className="modal-box bg-base-200 relative w-full h-auto max-w-7xl z-1000"
                    style={{
                        maxHeight: '80vh',
                    }}
                >
                    <span className="w-full flex flex-col items-center justify-start mt-2">
                        <div className="flex justify-between items-center px-12 w-full">
                            <h3 className="text-base">Generate Report</h3>
                            <label
                                htmlFor="generate-report-modal"
                                className="btn btn-sm btn-ghost btn-circle"
                            >
                                <XMarkIcon
                                    className="w-5 h-5"
                                    onClick={toggleIsOpen}
                                />
                            </label>
                        </div>

                        <div className="divider my-2" />
                        <p className="text-center my-2 text-sm">
                            Default file format is <strong>.xlsx</strong>,
                            larger extracts will be exported as{' '}
                            <strong>csv</strong>
                        </p>

                        <FiltersContainer compactOnly={true} />
                    </span>

                    <div className="divider my-2" />

                    <div className="grid grid-cols-4 gap-2 mb-2">
                        {dateRange && (
                            <div className="desktop:laptop:tablet:col-span-2 col-span-4">
                                <span className="w-full flex flex-col items-center justify-center lg:px-12 px-0">
                                    <p className="text-center m-2 text-xs">
                                        Data Date Range
                                    </p>

                                    {scheduleReport ? (
                                        <RelativeDateRangeComponent
                                            onChange={
                                                handleRelativeDateRangeChange
                                            }
                                        />
                                    ) : (
                                        <DateRangeComponent />
                                    )}
                                </span>
                            </div>
                        )}

                        {frequency && (
                            <div className="desktop:laptop:tablet:col-span-2 col-span-4">
                                <span className="w-full flex flex-col items-stretch justify-start desktop:laptop:tablet:px-12 px-0">
                                    <p className="tracking-wide text-center m-2 text-xs">
                                        Data Frequency
                                    </p>
                                    <div className="w-full flex justify-center items-center text-center m-2">
                                        <Select
                                            styles={customStylesMulti}
                                            options={frequencyOptions}
                                            className="w-56"
                                            value={selectedFrequency}
                                            placeholder=""
                                            onChange={handleFrequencyChange}
                                            isSearchable={false}
                                            isClearable={false}
                                            isMulti={false}
                                        />
                                    </div>
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="divider my-2" />
                    <span className="w-full flex flex-col items-start justify-start desktop:laptop:px-12 tablet:px-8 px-0 mb-2">
                        <p className="text-center m-2 text-sm">
                            Additional comments
                        </p>

                        <textarea
                            className="textarea textarea-primary w-full"
                            name="comments"
                            id="comments"
                            rows="5"
                            cols="33"
                        />
                    </span>

                    <div className="divider my-2" />

                    {includeSoh && (
                        <span className="w-full flex items-center justify-end lg:px-12 px-0">
                            <p className="text-center m-2 text-xs">
                                Include SOH
                            </p>

                            <div className="form-control">
                                <input
                                    type="checkbox"
                                    className="toggle toggle-primary"
                                    checked={soh}
                                    onChange={(event) => {
                                        setSoh(event.currentTarget.checked);
                                    }}
                                />
                            </div>
                        </span>
                    )}

                    <span className="w-full flex flex-col items-start justify-start lg:px-12 px-0 my-2">
                        <span className="w-full flex items-center justify-end mb-2">
                            <p className="text-center m-2 text-xs">
                                Schedule report?
                            </p>
                            <div className="form-control">
                                <input
                                    type="checkbox"
                                    className="toggle toggle-primary"
                                    checked={scheduleReport}
                                    onChange={(event) => {
                                        setScheduleReport(
                                            event.currentTarget.checked
                                        );
                                    }}
                                />
                            </div>
                        </span>

                        {scheduleReport ? (
                            <SchedulerComponent
                                onChange={handleSchedulerChange}
                            />
                        ) : (
                            <></>
                        )}
                    </span>

                    <div className="divider my-2" />

                    <span className="w-full flex flex-col items-end justify-start px-24 mb-2 mt-2">
                        <label
                            className="btn btn-primary normal-case"
                            htmlFor="generate-report-modal"
                            onClick={onGenerateHandler}
                        >
                            Generate
                        </label>
                    </span>
                </div>
            </div>
        </>
    );
};

export default GenerateReportComponent;