import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { authenticationService } from '../../../store/auth/authentication.services';
import FooterComponent from '../../../components/footer-component/footer.component';

import { selectUser } from '../../../store/auth/authentication.selectors';
import { clearProductType } from '../../../store/product/product.actions';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../components/loading-component/loading-component';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';
import { clearDiscoverSearchData } from '../../../store/discover/search/discover-search.actions';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid';

const LoginPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(selectUser);

    const onLoginHandler = async (event) => {
        event.preventDefault();
        const target = event.currentTarget;

        setIsLoading(true);
        try {
            const userAction = await authenticationService.login(
                target.email.value,
                target.password.value
            );

            if (userAction) {
                dispatch(userAction);
                createNotification(
                    `Logged in as [${userAction.payload['name']} - ${userAction.payload['custaccountName']}]`,
                    NOTIFICATION_TYPES.SUCCESS
                );
                navigate('../product-select', { replace: true });
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user !== null) {
            dispatch(clearProductType());
            dispatch(clearDiscoverSearchData());
            dispatch(authenticationService.logout());
        }
    }, []);

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
                <div
                    className={`card flex flex-col py-8 px-12 shadow-lg bg-base-300 bg-opacity-95 w-full max-w-xl justify-center items-center min-h-64 z-10`}
                >
                    <form
                        id="loginForm"
                        className="w-full h-full m-0 p-0"
                        onSubmit={onLoginHandler}
                    >
                        <div className="w-full h-full flex justify-evenly items-start flex-col">
                            <label
                                className="text-sm text-start p-2"
                                htmlFor="email"
                            >
                                Email
                            </label>
                            <input
                                className="input input-bordered w-full"
                                name="email"
                                id="email"
                                type="email"
                                required={true}
                            />
                        </div>

                        <div className="w-full h-full flex justify-evenly items-start flex-col">
                            <span className="w-full h-full flex justify-between items-center">
                                <label
                                    className="text-sm text-start p-2"
                                    htmlFor="password"
                                >
                                    Password
                                </label>
                                {passwordVisible ? (
                                    <span
                                        className="btn btn-ghost btn-sm btn-circle"
                                        onClick={() =>
                                            setPasswordVisible(!passwordVisible)
                                        }
                                    >
                                        <EyeSlashIcon className="w-4 h-4" />
                                    </span>
                                ) : (
                                    <span
                                        className="btn btn-ghost btn-sm btn-circle"
                                        onClick={() =>
                                            setPasswordVisible(!passwordVisible)
                                        }
                                    >
                                        <EyeIcon className="w-4 h-4" />
                                    </span>
                                )}
                            </span>

                            <input
                                className="input input-bordered w-full"
                                name="password"
                                id="password"
                                type={passwordVisible ? 'text' : 'password'}
                                required={true}
                            />
                        </div>

                        <div className="divider" />

                        <div className="w-full flex justify-between items-center mt-5">
                            <Link
                                to="../password-reset-request"
                                className="cursor-pointer text-sm underline"
                            >
                                Forgot Password?
                            </Link>

                            <button
                                type="submit"
                                className={`btn btn-outline ${
                                    isLoading
                                        ? 'disabled btn-disabled gap-1.5'
                                        : 'gap-2'
                                }`}
                            >
                                {isLoading ? (
                                    <LoadingComponent
                                        type={LOADING_COMPONENT_TYPES.TAILSPIN}
                                        stroke={2}
                                        size={14}
                                    />
                                ) : (
                                    <ArrowRightStartOnRectangleIcon className="w-4 h-4" />
                                )}
                                Login
                            </button>
                        </div>
                    </form>
                </div>

                <PublicBackdrop />
            </div>

            <FooterComponent useDaisy={true} />
        </>
    );
};

export default LoginPage;