import http from '../../utils/request.util';

export const engineService = {
    invoke,
};

async function invoke(pfSystemCode, param) {
    try {
        const response = await http.post(
            '/api/prweb/engine',
            JSON.stringify({
                pfSystemCode,
                param,
            })
        );
        return response.data;
    } catch (error) {
        console.log(error);
    }
}

export const removeDateRangeFromFilters = (filtersObject) => {
    const filters = [filtersObject];
    return filters.map((filter) => {
        const { fromDate, toDate, ...filterWithoutDates } = filter;
        return filterWithoutDates;
    })[0];
};

export const handleError = (response) => {
    console.log(response);
};