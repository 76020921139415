import { DAISY_THEMES } from '../../store/themes/themes.types';

export const DAISY_THEME_MAP = {
    corporate: {
        primary: '#4A6D9B',
        'primary-content': '#FCFBF8',

        secondary: '#1e293b',
        'secondary-content': '#FCFBF8',

        accent: '#59677D',
        'accent-content': '#FCFBF8',

        neutral: '#f7f8fa',
        'neutral-content': '#020617',

        'base-100': '#F0F5F4',
        'base-200': '#f2f8f7',
        'base-300': '#F7FCFA',
        'base-content': '#020617',

        success: '#16a34a',
        'success-content': '#F7FCFA',
        warning: '#ca8a04',
        'warning-content': '#F7FCFA',
        error: '#dc2626',
        'error-content': '#F7FCFA',

        radius: '0.1rem',
        'logo-filter':
            'invert(51%) sepia(10%) saturate(5529%) hue-rotate(179deg) brightness(84%) contrast(82%)',
    },
    business: {
        primary: '#6693CB',
        'primary-content': '#F3F3F7',

        secondary: '#818188',
        'secondary-content': '#F3F3F7',

        accent: '#F3F3F7',
        'accent-content': '#10101B',

        neutral: '#27272a',
        'neutral-content': '#F3F3F7',

        'base-100': '#10101B',
        'base-200': '#141622',
        'base-300': '#191C2A',
        'base-content': '#F3F3F7',

        success: '#4ade80',
        'success-content': '#10101B',

        warning: '#facc15',
        'warning-content': '#10101B',

        error: '#f87171',
        'error-content': '#10101B',

        radius: '0.1rem',
        'logo-filter':
            'invert(55%) sepia(81%) saturate(1310%) hue-rotate(193deg) brightness(95%) contrast(100%)',
    },
    light: {
        primary: '#287556',
        'primary-content': '#e7e5e4',

        secondary: '#44403c',
        'secondary-content': '#f5f5f4',

        accent: '#BD9A6C',
        'accent-content': '#0c0a09',

        neutral: '#f5f5f4',
        'neutral-content': '#0c0a09',

        'base-100': '#ECEBEB',
        'base-200': '#F1F0F0',
        'base-300': '#F6F5F5',
        'base-content': '#0c0a09',

        success: '#16a34a',
        'success-content': '#f5f5f4',
        warning: '#ca8a04',
        'warning-content': '#f5f5f4',
        error: '#dc2626',
        'error-content': '#f5f5f4',

        radius: '0.1rem',
        'logo-filter':
            'invert(11%) sepia(98%) saturate(2569%) hue-rotate(138deg) brightness(74%) contrast(97%)',
    },
    dark: {
        primary: '#B0A0D5',
        'primary-content': '#F8FAFE',

        secondary: '#55628B',
        'secondary-content': '#F8FAFE',

        accent: '#E5EAF0',
        'accent-content': '#0C111C',

        neutral: '#1F2937',
        'neutral-content': '#F8FAFE',

        'base-100': '#110C1C',
        'base-200': '#151020',
        'base-300': '#1a1626',
        'base-content': '#F8FAFE',

        success: '#4ade80',
        'success-content': '#0C111C',
        warning: '#facc15',
        'warning-content': '#0C111C',
        error: '#f87171',
        'error-content': '#0C111C',

        radius: '0.1rem',
        'logo-filter':
            'invert(48%) sepia(68%) saturate(453%) hue-rotate(210deg) brightness(103%) contrast(90%)',
    },
    coffee: {
        primary: '#bba287',
        'primary-content': '#0c0a09',

        secondary: '#F8F4F2',
        'secondary-content': '#0c0a09',

        accent: '#7E6363',
        'accent-content': '#0c0a09',

        neutral: '#362b2b',
        'neutral-content': '#F8F4F2',

        'base-100': '#221B1B',
        'base-200': '#251e1e',
        'base-300': '#282020',
        'base-content': '#F8F4F2',

        success: '#4ade80',
        'success-content': '#221B1B',
        warning: '#facc15',
        'warning-content': '#221B1B',
        error: '#f87171',
        'error-content': '#221B1B',

        radius: '0.1rem',
        'logo-filter':
            'invert(16%) sepia(10%) saturate(1942%) hue-rotate(340deg) brightness(94%) contrast(88%)',
    },
};

export const GLOBAL_COLOURS = {
    dark: {
        'bg-page': '#0f172a',
        'bg-component-muted': '#172033',
        'bg-component': '#1e293b',
        'bg-component-hover': '#334155',

        text: '#f8fafc',
        'text-hover': '#e2e8f0',
        'text-muted': '#B3B9C2',
        'text-medium': '#848993',

        'border-color': '#f8fafc',

        'logo-filter':
            'invert(99%) sepia(41%) saturate(154%) hue-rotate(217deg) brightness(111%) contrast(96%)',
    },
    light: {
        'bg-page': '#d5dcea',

        'bg-component': '#f0f3fa',
        'bg-component-hover': '#e8eaf4',

        text: '#02040c',
        'text-hover': '#2c3941',
        'text-muted': '#71737a',
        'text-medium': '#848993',

        'border-color': '#02040c',

        'logo-filter':
            'invert(95%) sepia(0%) saturate(1000%) hue-rotate(152deg) brightness(0%) contrast(30%)',
    },
    accents: {
        'rose-dark': '#be123c',
        'rose-medium': '#f43f5e',
        'rose-medium-light': '#F97287',
        'rose-light': '#fda4af',
        'rose-logo-filter-dark':
            'invert(73%) sepia(61%) saturate(4410%) hue-rotate(310deg) brightness(104%) contrast(95%)',
        'rose-logo-filter-light':
            'invert(9%) sepia(77%) saturate(7399%) hue-rotate(340deg) brightness(91%) contrast(88%)',

        'fuchsia-dark': '#86198f',
        'fuchsia-medium': '#d946ef',
        'fuchsia-medium-light': '#E579F6',
        'fuchsia-light': '#f0abfc',
        'fuchsia-logo-filter-dark':
            'invert(80%) sepia(42%) saturate(7492%) hue-rotate(236deg) brightness(102%) contrast(93%)',
        'fuchsia-logo-filter-light':
            'invert(19%) sepia(48%) saturate(3457%) hue-rotate(278deg) brightness(94%) contrast(107%)',

        'indigo-dark': '#4338ca',
        'indigo-medium': '#6366f1',
        'indigo-medium-light': '#848DF7',
        'indigo-light': '#a5b4fc',
        'indigo-logo-filter-dark':
            'invert(47%) sepia(87%) saturate(591%) hue-rotate(203deg) brightness(105%) contrast(94%)',
        'indigo-logo-filter-light':
            'invert(20%) sepia(94%) saturate(4716%) hue-rotate(245deg) brightness(82%) contrast(91%)',

        'cyan-dark': '#14709b',
        'cyan-medium': '#06aec6',
        'cyan-medium-light': '#32b9d7',
        'cyan-light': '#67d7f9',
        'cyan-logo-filter-dark':
            'invert(86%) sepia(17%) saturate(6101%) hue-rotate(150deg) brightness(95%) contrast(90%)',
        'cyan-logo-filter-light':
            'invert(24%) sepia(99%) saturate(1784%) hue-rotate(171deg) brightness(90%) contrast(89%)',

        'emerald-dark': '#047857',
        'emerald-medium': '#10b981',
        'emerald-medium-light': '#3FD09C',
        'emerald-light': '#6ee7b7',
        'emerald-logo-filter-dark':
            'invert(72%) sepia(77%) saturate(351%) hue-rotate(100deg) brightness(85%) contrast(94%)',
        'emerald-logo-filter-light':
            'invert(17%) sepia(65%) saturate(4716%) hue-rotate(160deg) brightness(99%) contrast(97%)',

        'lime-dark': '#4d7c0f',
        'lime-medium': '#84cc16',
        'lime-medium-light': '#A1DF3D',
        'lime-light': '#bef264',
        'lime-logo-filter-dark':
            'invert(91%) sepia(25%) saturate(1151%) hue-rotate(25deg) brightness(90%) contrast(94%)',
        'lime-logo-filter-light':
            'invert(38%) sepia(100%) saturate(391%) hue-rotate(44deg) brightness(89%) contrast(92%)',

        'amber-dark': '#c47c08',
        'amber-medium': '#eba80b',
        'amber-medium-light': '#F4BE2C',
        'amber-light': '#fcd34d',
        'amber-logo-filter-dark':
            'invert(91%) sepia(27%) saturate(5053%) hue-rotate(339deg) brightness(108%) contrast(91%)',
        'amber-logo-filter-light':
            'invert(53%) sepia(81%) saturate(2964%) hue-rotate(14deg) brightness(93%) contrast(94%)',

        'slate-dark': '#a8543b',
        'slate-medium': '#bf725b',
        'slate-medium-light': '#DA8A72',
        'slate-light': '#f4a188',
        'slate-logo-filter-dark':
            'invert(70%) sepia(30%) saturate(860%) hue-rotate(321deg) brightness(88%) contrast(92%)',
        'slate-logo-filter-light':
            'invert(38%) sepia(57%) saturate(608%) hue-rotate(327deg) brightness(87%) contrast(86%)',
    },
};
export const GLOBAL_STYLES = {
    'radius-x-small': '4px',
    'radius-small': '8px',
    'radius-larger': '24px',
    'radius-pill': '32px',
    'radius-circle': '50%',
    'navbar-height': '3.25rem',
    'default-margin': '1rem',
    'default-margin-double': '2rem',
    'default-margin-half': '0.75rem',
    'default-padding': '0rem',
    'default-padding-double': '2rem',
    'default-padding-half': '0.75rem',
    'default-padding-half-y': '0.75rem',
    'default-padding-quarter-y': '0.5rem',

    'default-padding-x': '1rem',
    'default-padding-half-x': '0.75rem',

    'enlarged-scroll': 100,

    'hero-icon-stroke-width': '1.25px',
    'hero-icon-stroke-width-thick': '1.5px',

    // screen breakpoints
    'screen-xs': '524px',
    'screen-sm': '640px',
    'screen-md': '768px',
    'screen-lg': '1024px',
    'screen-xl': '1280px',
    'screen-2xl': '1536px',

    'text-xs': '0.6rem',
    'text-sm': '0.7rem',
    'text-md': '0.8rem',
    'text-lg': '0.9rem',
    'text-xl': '1.0rem',
};

export const GRAPH_OPACITY = 0.6;
export const GRAPH_OPACITY_SUBDUED = 0.4;

export function addOpacity(hexColor, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return hexColor + _opacity.toString(16).toUpperCase();
}

export function graphColourSelector(
    index,
    daisyTheme = DAISY_THEMES.CORPORATE
) {
    if (index === 0) {
        return {
            borderColor: DAISY_THEME_MAP[daisyTheme].primary,
            backgroundColor: DAISY_THEME_MAP[daisyTheme].primary,
        };
    } else if (index === 1) {
        return {
            borderColor: DAISY_THEME_MAP[daisyTheme].secondary,
            backgroundColor: DAISY_THEME_MAP[daisyTheme].secondary,
        };
    } else if (index === 12) {
        return {
            borderColor: DAISY_THEME_MAP[daisyTheme].accent,
            backgroundColor: DAISY_THEME_MAP[daisyTheme].accent,
        };
    } else if (index >= 2 && index < 10) {
        let graphColours = [
            {
                borderColor: GLOBAL_COLOURS['accents']['emerald-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['emerald-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['amber-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['amber-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['fuchsia-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['fuchsia-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['cyan-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['cyan-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['lime-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['lime-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['slate-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['slate-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['indigo-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['indigo-medium'],
            },
            {
                borderColor: GLOBAL_COLOURS['accents']['rose-medium'],
                backgroundColor: GLOBAL_COLOURS['accents']['rose-medium'],
            },
        ];

        return graphColours[index - 2];
    }

    let randomColor = '';
    while (randomColor.length < 7) {
        randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
    return {
        borderColor: randomColor,
        backgroundColor: randomColor,
    };
}

export function generateSelectStylesMulti(themeMode, themeColor, windowSize) {
    return {
        control: (base, state) => ({
            ...base,
            fontSize: windowSize.innerWidth > 1200 ? '0.8rem' : '0.7rem',
            letterSpacing:
                windowSize.innerWidth > 1200 ? '0.065rem' : '0.05rem',

            color: state.isDisabled
                ? GLOBAL_COLOURS[themeMode]['text-medium']
                : GLOBAL_COLOURS[themeMode]['text'],

            border: state.isFocused
                ? `0px solid ${
                      GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`]
                  }`
                : `0px solid ${GLOBAL_COLOURS[themeMode]['text-medium']}`,
            backgroundColor: GLOBAL_COLOURS[themeMode]['bg-page'],
            borderRadius: GLOBAL_STYLES['radius-larger'],

            boxShadow: state.isFocused
                ? `0px 0px 2px 1px ${
                      GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`]
                  }`
                : '',
            '&:hover': {
                // border: `1px solid ${GLOBAL_COLOURS[themeMode]['text-medium']}`,
                boxShadow: `0px 0px 2px 1px ${GLOBAL_COLOURS[themeMode]['text-medium']}`,
                cursor: 'pointer',
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: windowSize.innerWidth > 1200 ? '0.8rem' : '0.7rem',
            letterSpacing:
                windowSize.innerWidth > 1200 ? '0.065rem' : '0.05rem',

            backgroundColor: GLOBAL_COLOURS[themeMode]['bg-page'],
            color: GLOBAL_COLOURS[themeMode]['text'],
            border: `1px solid ${GLOBAL_COLOURS[themeMode]['bg-page']}`,
            borderRadius: GLOBAL_STYLES['radius-larger'],
            boxShadow: state.isFocused
                ? `0px 0px 2px 1px ${
                      GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`]
                  }`
                : '',
            '&:hover': {
                border: `1px solid ${
                    GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`]
                }`,
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            // backgroundColor: GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`],
            color: GLOBAL_COLOURS['accents'][
                `${themeColor}-${themeMode === 'dark' ? 'light' : 'dark'}`
            ],
            width: 'auto',
            maxWidth: '12rem',
            padding: '0.25rem',
            minHeight: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',

            border: 0,
            borderRadius: GLOBAL_STYLES['radius-larger'],
            boxShadow: 0,
        }),
        singleValueLabel: (provided, state) => ({
            ...provided,
            color: GLOBAL_COLOURS[themeMode]['text'],
            border: 0,
            boxShadow: 0,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor:
                GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`],
            color: GLOBAL_COLOURS[themeMode]['text'],
            maxWidth: '16rem',
            padding: '0.1rem',
            width: 'auto',
            minHeight: '2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            border: 0,
            borderRadius: GLOBAL_STYLES['radius-larger'],
            boxShadow: 0,
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: GLOBAL_COLOURS[themeMode]['text'],
            border: 0,
            boxShadow: 0,
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: GLOBAL_COLOURS[themeMode]['text'],
            border: 0,
            borderRadius: GLOBAL_STYLES['radius-larger'],
            boxShadow: 0,
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            backgroundColor:
                GLOBAL_COLOURS['accents'][`${themeColor}-${themeMode}`],
            color: GLOBAL_COLOURS[themeMode]['text'],
            border: 0,
            borderRadius: GLOBAL_STYLES['radius-larger'],
            boxShadow: 0,
            '&:hover': {
                backgroundColor:
                    GLOBAL_COLOURS['accents'][`${themeColor}-medium`],
                color: GLOBAL_COLOURS[themeMode]['text'],
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            color: GLOBAL_COLOURS[themeMode]['text-medium'],
            border: 0,
            boxShadow: 0,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: state.isDisabled
                ? GLOBAL_COLOURS[themeMode]['text-medium']
                : GLOBAL_COLOURS[themeMode]['text'],

            border: 0,
            boxShadow: 0,
            minHeight: '2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',

            '&:hover': {
                border: 0,
            },
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: state.isDisabled
                ? GLOBAL_COLOURS[themeMode]['text-medium']
                : GLOBAL_COLOURS[themeMode]['text'],
            border: 0,
            borderRadius: GLOBAL_STYLES['radius-larger'],
            boxShadow: 0,
            '&:hover': {
                color: GLOBAL_COLOURS[themeMode]['text-medium'],
                backgroundColor: GLOBAL_COLOURS[themeMode]['bg-component'],
                cursor: 'pointer',
            },
        }),
        indicatorSeparator: (styles) => ({ display: 'none' }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? GLOBAL_COLOURS[themeMode]['bg-component']
                : GLOBAL_COLOURS[themeMode]['bg-page'],
            color: GLOBAL_COLOURS[themeMode]['text'],
            borderRadius: GLOBAL_STYLES['radius-larger'],
            cursor: 'pointer',
            border: 0,
            boxShadow: 0,
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
            color: GLOBAL_COLOURS['accents'][`${themeColor}-medium-light`],
        }),
        input: (provided, state) => ({
            ...provided,
            color: GLOBAL_COLOURS[themeMode]['text'],
            border: 0,
            boxShadow: 0,
            minHeight: '1.8rem',
        }),
    };
}

export function generateSelectStylesDaisy(daisyTheme, windowSize) {
    return {
        control: (base, state) => ({
            ...base,
            fontSize: windowSize.innerWidth > 1024 ? '0.75rem' : '0.7rem',
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],

            border: !state.isFocused
                ? `1px solid ${DAISY_THEME_MAP[daisyTheme]['base-200']}`
                : `1px solid ${DAISY_THEME_MAP[daisyTheme]['primary']}`,
            backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-200'],
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],

            boxShadow: state.isFocused
                ? `0px 0px 0px 0px ${DAISY_THEME_MAP[daisyTheme]['bg-base-100']}`
                : `0px 0px 0px 0px ${DAISY_THEME_MAP[daisyTheme]['bg-base-100']}`,

            '&:hover': {
                cursor: 'pointer',
                border: `1px solid ${DAISY_THEME_MAP[daisyTheme]['primary']}`,
                boxShadow: `0 0 0 0 ${DAISY_THEME_MAP[daisyTheme]['bg-base-100']}`,
            },
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: windowSize.innerWidth > 1024 ? '0.75rem' : '0.7rem',
            backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-200'],
            opacity: 1,
            color: DAISY_THEME_MAP[daisyTheme]['base-200'],
            border: `1px solid ${DAISY_THEME_MAP[daisyTheme]['base-200']}`,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            dropShadow: `0 0 1px 2px ${DAISY_THEME_MAP[daisyTheme]['base-100']}`,
            zIndex: 1000,
            // '&:hover': {
            //     border: `1px solid ${DAISY_THEME_MAP[daisyTheme][`primary`]}`,
            // },
            padding: '0.5rem',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['primary'],
            width: 'auto',
            maxWidth: '12rem',
            padding: '0.25rem',
            minHeight: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',

            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
        }),
        singleValueLabel: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme][`primary`],
            color: DAISY_THEME_MAP[daisyTheme]['primary-content'],
            maxWidth: '16rem',
            padding: '0.1rem',
            width: 'auto',
            minHeight: '2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['primary-content'],
            border: 0,
            boxShadow: 0,
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['primary-content'],
            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme][`primary`],
            color: DAISY_THEME_MAP[daisyTheme]['primary-content'],
            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
            '&:hover': {
                backgroundColor: DAISY_THEME_MAP[daisyTheme][`primary`],
                color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0,
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],

            border: 0,
            boxShadow: 0,
            minHeight: '2rem',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',

            '&:hover': {
                border: 0,
            },
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            boxShadow: 0,
            '&:hover': {
                color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-200'],
                cursor: 'pointer',
            },
        }),
        indicatorSeparator: (styles) => ({ display: 'none' }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-200'],
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            borderRadius: DAISY_THEME_MAP[daisyTheme]['radius'],
            cursor: 'pointer',
            border: 0,
            boxShadow: 0,
            '&:hover': {
                backgroundColor: DAISY_THEME_MAP[daisyTheme]['base-200'],
                cursor: 'pointer',
            },
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme][`primary`],
        }),
        input: (provided, state) => ({
            ...provided,
            color: DAISY_THEME_MAP[daisyTheme]['base-content'],
            border: 0,
            boxShadow: 0,
            minHeight: '1.8rem',
        }),
    };
}

export const lineGraphStyles = {
    borderWidth: 0.5,
    tension: 0.2,
    pointRadius: 1.5,
    pointHoverRadius: 3,
    pointBorderWidth: 0.25,
    pointHoverBorderWidth: 0.25,
};

export function checkLightTheme(daisyTheme) {
    return [DAISY_THEMES.LIGHT, DAISY_THEMES.CORPORATE].includes(daisyTheme);
}