import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import DiscoverTabPage from './discover-page/discover.page';
import CustomerPage from './customer-page/customer-page';
import ProductPage from './product-page/product-page';
import RepPage from './rep-page/rep-page';
import FooterComponent from '../../../components/footer-component/footer.component';
import DiscoverDashboardPage from './dashboard-page/dashboard.page';
import MarketingPage from './marketing-page/marketing.page';
import PageNotFound from '../../public/errors/page-not-found';
import WatchlistPage from './watchlist-page/watchlist.page';
import { useTour } from '@reactour/tour';
import ExtractsPage from './extracts-page/extracts.page';
import ExclusionsPage from './exclusions-page/exclusions.page';
import TeamPage from '../team-page/team.page';
import BillingPage from '../billing-page/billing.page';
import CategoryPage from './category-page/category-page';
import { RoleProtectedRoute } from '../../../utils/role-protected-route';
import { USER_ROLES } from '../../../utils/auth.util';
import { PermissionProtectedRoute } from '../../../utils/permission-protected-route';

const DiscoverPage = () => {
    const { setIsOpen } = useTour();

    useEffect(() => {
        if (
            !localStorage.getItem('tutorialCompleted') ||
            localStorage.getItem('tutorialCompleted') === 'false'
        ) {
            localStorage.setItem('tutorialCompleted', 'true');
            setIsOpen(true);
        }

        if (!localStorage.getItem('selectedDate')) {
            localStorage.setItem('selectedDate', 'MTD');
        }
    }, []);

    return (
        <>
            <Routes>
                <Route
                    index={true}
                    path="dashboard"
                    element={
                        <PermissionProtectedRoute>
                            <DiscoverDashboardPage />
                        </PermissionProtectedRoute>
                    }
                />

                <Route
                    path="home"
                    element={
                        <PermissionProtectedRoute>
                            <DiscoverDashboardPage />
                        </PermissionProtectedRoute>
                    }
                />

                <Route path="discover" element={<DiscoverTabPage />} />
                <Route path="marketing" element={<MarketingPage />} />
                <Route path="watchlist" element={<WatchlistPage />} />

                <Route path="extracts" element={<ExtractsPage />} />

                <Route
                    path="exclusions"
                    element={
                        <RoleProtectedRoute
                            roles={[
                                USER_ROLES.M2_ADMIN,
                                USER_ROLES.M2_SUPPORT,
                                USER_ROLES.C_OWNER,
                                USER_ROLES.C_ADMIN,
                            ]}
                        >
                            <ExclusionsPage />
                        </RoleProtectedRoute>
                    }
                />

                <Route
                    path="team"
                    element={
                        <RoleProtectedRoute
                            roles={[
                                USER_ROLES.M2_ADMIN,
                                USER_ROLES.M2_SUPPORT,
                                USER_ROLES.C_OWNER,
                                USER_ROLES.C_ADMIN,
                            ]}
                        >
                            <TeamPage />
                        </RoleProtectedRoute>
                    }
                />

                <Route
                    path="billing"
                    element={
                        <RoleProtectedRoute
                            roles={[
                                USER_ROLES.M2_ADMIN,
                                USER_ROLES.M2_SUPPORT,
                                USER_ROLES.C_OWNER,
                                USER_ROLES.C_ADMIN,
                            ]}
                        >
                            <BillingPage />
                        </RoleProtectedRoute>
                    }
                />

                <Route path="customer/:id" element={<CustomerPage />} />
                <Route path="product/:id" element={<ProductPage />} />
                <Route path="category/:id" element={<CategoryPage />} />
                <Route path="sku/:id" element={<ProductPage />} />

                <Route
                    path="rep/:id"
                    element={
                        <PermissionProtectedRoute>
                            <RepPage />
                        </PermissionProtectedRoute>
                    }
                />

                <Route path="*" exact={true} element={<PageNotFound />} />
            </Routes>

            <FooterComponent className="mt-2" />
        </>
    );
};

export default DiscoverPage;