import { BanknotesIcon, ScaleIcon } from '@heroicons/react/24/solid';
import { Fragment, useState } from 'react';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';
import NoData from '../../no-data/no-data.component';
import { navigationService } from '../../../utils/navigation.util';
import { DISCOVER_WIDGET_AGGREGATION_METRICS } from '../../../store/discover/widgets/widgets.types';
import { ArrowsUpDownIcon } from '@heroicons/react/16/solid';

const ProfitRanking = ({
    rawData,
    selectedDate,
    onDateChange,
    aggregationMetric,
}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <>
            {!rawData[selectedDate] ? (
                <NoData />
            ) : (
                <div className="col-span-5">
                    <div className="grid grid-cols-10 gap-x-0 gap-y-2 py-2 px-0 place-items-center">
                        {/*Need to allow them to sort by value and not by margin percentage*/}

                        {rawData[selectedDate].map((unit, index) => {
                            return (
                                <Fragment key={index}>
                                    <span className="col-span-5 flex items-center justify-self-start">
                                        <span className="font-mono text-xs font-bold text-base-content text-opacity-80">
                                            {index + 1}.
                                        </span>
                                        <a
                                            className="btn btn-ghost btn-sm normal-case hover:underline tooltip"
                                            target={'_blank'}
                                            href={navigationService.buildUrlByAggMetric(
                                                aggregationMetric,
                                                unit
                                            )}
                                            data-tip={unit.descr}
                                        >
                                            {unit.descr
                                                .replace('-', ' ')
                                                .slice(0, 24)}
                                            {unit.descr.length > 24 && '..'}
                                        </a>
                                    </span>

                                    <span
                                        className={`font-mono w-full tooltip col-span-2 justify-self-end text-sm cursor-pointer hover:scale-105`}
                                        data-tip={'Mean Margin %'}
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        <ScaleIcon className={'w-4 h-4 mx-1'} />
                                        {unit.margin.toFixed(2)}%
                                    </span>

                                    <span
                                        className="text-primary text-sm font-mono w-full tooltip col-span-3 justify-self-end text-end cursor-pointer hover:scale-105"
                                        data-tip={'Total Profit Value'}
                                        onClick={() => setExpanded(!expanded)}
                                    >
                                        <BanknotesIcon
                                            className={'w-4 h-4 mx-1'}
                                        />
                                        R
                                        {unit.profitValue.toLocaleString(
                                            undefined,
                                            {
                                                minimumFractionDigits: 2,
                                            }
                                        )}
                                    </span>

                                    <span
                                        className={`col-span-10 grid grid-cols-2 justify-items-start items-start gap-x-4 gap-y-2 transition-all ease-in-out duration-150 text-xs font-light ${
                                            expanded
                                                ? 'opacity-100 p-4 bg-base-200 text-base-content w-full h-full shadow-inner '
                                                : 'opacity-0 h-0 w-0'
                                        }`}
                                    >
                                        {aggregationMetric ===
                                            DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS && (
                                            <>
                                                <span className="flex items-center gap-1">
                                                    <span className="font-bold w-44">
                                                        Mean Selling Price
                                                    </span>
                                                    <span className="font-mono w-full">
                                                        R
                                                        {unit.avgPriceSold.toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </span>
                                                </span>

                                                <span className="flex items-center gap-1">
                                                    <span className="font-bold w-44">
                                                        Cost Price
                                                    </span>
                                                    <span className="font-mono w-full">
                                                        R
                                                        {unit.costPrice.toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </span>
                                                </span>

                                                <div className="divider col-span-2 my-0 w-full" />
                                            </>
                                        )}

                                        <span className="flex items-center gap-1">
                                            <span className="font-bold w-44">
                                                Quantity Sold
                                            </span>
                                            <span className="font-mono w-full">
                                                {unit.soldQty.toLocaleString(
                                                    undefined,
                                                    {
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                            </span>
                                        </span>

                                        <span className="flex items-center gap-1">
                                            <span className="font-bold w-44">
                                                Mean Profit
                                            </span>
                                            <span className="font-mono w-full">
                                                R
                                                {unit.meanProfit.toLocaleString(
                                                    undefined,
                                                    {
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                            </span>
                                        </span>
                                    </span>
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
            )}

            <div className="flex flex-col justify-start items-center w-full h-full col-span-5">
                {rawData && rawData['subAggregates'] && (
                    <div className="col-span-full">
                        <div className="divider my-1" />

                        <WidgetSubAggregates
                            subAggregates={rawData['subAggregates']}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfitRanking;
