import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import {
    TrashIcon,
    CheckCircleIcon,
    PencilSquareIcon,
    XCircleIcon,
    XMarkIcon,
    BookmarkSquareIcon,
} from '@heroicons/react/24/solid';

import { selectUserToken } from '../../../store/auth/authentication.selectors';

import { apiService } from '../../../utils/engine/api.service';

import SchedulerComponent, {
    weekOptions,
} from '../../scheduler-component/scheduler.component';
import FiltersContainer from '../filters/filters-container.component';
import RelativeDateRangeComponent from '../../relative-date-range/relative-date-range.component';

import { handleError } from '../../../utils/engine/engine.service';
import LoadingComponent from '../../loading-component/loading-component';
import {
    glowing_border,
    muted_border,
    muted_border_primary,
} from '../../../utils/styles/tailwind-styles';

function convertCronToHumanReadable(cronText) {
    let array = cronText.split(' ');

    let minute = array[0];
    let hour = array[1];
    let dayOfMonth = array[2];
    let month = array[3];
    let dayOfWeek = array[4];

    let time =
        String(hour).padStart(2, '0') + ':' + String(minute).padStart(2, '0');

    if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
        return (
            <p className="w-fit flex justify-center items-center col-span-2">
                <strong className="mr-2">Daily</strong> at{' '}
                <strong className="ml-2">{time}</strong>
            </p>
        );
    }
    if (dayOfMonth === '*' && month === '*') {
        return (
            <p className="w-fit flex justify-center items-center col-span-2">
                <strong className="mr-2">Weekly</strong> on a{' '}
                <strong className="ml-2 mr-2">
                    {weekOptions[dayOfWeek - 1]['label']}
                </strong>{' '}
                at <strong className="ml-2">{time}</strong>
            </p>
        );
    }
    if (dayOfWeek === '*' && month === '*' && dayOfMonth !== '*') {
        return (
            <p className="w-fit flex justify-center items-center col-span-2">
                <strong className="mr-2">Monthly</strong> on day{' '}
                <strong className="ml-2 mr-2">{dayOfMonth}</strong> at{' '}
                <strong className="ml-2">{time}</strong>
            </p>
        );
    }
    return <></>;
}

const ScheduledProcessComponent = ({
    processFlowScheduleView,
    onDelete,
    className,
}) => {
    const [processFlowScheduleControl, setProcessFlowScheduleControl] =
        useState(null);
    const [processFlowScheduleCronText, setProcessFlowScheduleCronText] =
        useState(null);
    const [
        processFlowScheduleJobParamJson,
        setProcessFlowScheduleJobParamJson,
    ] = useState(null);
    const [processFlowModel, setProcessFlowModel] = useState(null);

    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onScheduleChange = (event) => {
        setProcessFlowScheduleJobParamJson({
            ...processFlowScheduleJobParamJson,
            uiRequest: {
                ...processFlowScheduleJobParamJson['uiRequest'],
                reportParams: {
                    ...processFlowScheduleJobParamJson['uiRequest'][
                        'reportParams'
                    ],
                    cronText: event,
                },
            },
        });
        setProcessFlowScheduleCronText(event);
    };

    const onDateRangeChange = (event) => {
        setProcessFlowScheduleJobParamJson({
            ...processFlowScheduleJobParamJson,
            uiRequest: {
                ...processFlowScheduleJobParamJson['uiRequest'],
                reportParams: {
                    ...processFlowScheduleJobParamJson['uiRequest'][
                        'reportParams'
                    ],
                    fromDate: event.fromDate,
                    toDate: event.toDate,
                },
            },
        });
    };

    const onCancelChanges = () => {
        if (Object.keys(processFlowScheduleView).length > 1) {
            setProcessFlowScheduleControl(processFlowScheduleView.control);
            setProcessFlowScheduleCronText(processFlowScheduleView.cronText);
            setProcessFlowScheduleJobParamJson(
                JSON.parse(processFlowScheduleView.jobParamJson)
            );
            setProcessFlowModel(processFlowScheduleView.processFlowModel);
        }
        setEditMode(!editMode);
    };

    const onConfirmProcessChange = async () => {
        try {
            setEditMode(!editMode);
            setIsLoading(true);

            await apiService.updateProcessFlowSchedule(
                processFlowScheduleControl,
                processFlowScheduleCronText,
                processFlowScheduleJobParamJson
            );

            createNotification(
                'Scheduled report saved',
                NOTIFICATION_TYPES.SUCCESS
            );

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (Object.keys(processFlowScheduleView).length > 1) {
            setProcessFlowScheduleControl(processFlowScheduleView.control);
            setProcessFlowScheduleCronText(processFlowScheduleView.cronText);
            setProcessFlowScheduleJobParamJson(
                JSON.parse(processFlowScheduleView.jobParamJson)
            );
            setProcessFlowModel(processFlowScheduleView.processFlowModel);
        }
    }, [processFlowScheduleView]);

    return !isLoading &&
        processFlowScheduleJobParamJson &&
        processFlowScheduleCronText &&
        processFlowModel &&
        processFlowScheduleControl ? (
        <div
            className={`bg-base-200 w-full text-sm font-normal normal-case p-4 ${className} ${
                editMode
                    ? glowing_border + ' shadow-primary ' + muted_border_primary
                    : muted_border
            }`}
        >
            <div
                className={`${className} flex justify-between w-full items-center gap-2`}
            >
                <p className="w-24 text-sm text-base-content font-bold text-opacity-80 mb-2">
                    {processFlowModel['descr']}
                </p>

                <div className="w-full flex justify-start items-center max-w-xl">
                    <p className="text-sm text-base-content font-bold text-opacity-80 mb-2">
                        Process Filters
                    </p>

                    <FiltersContainer
                        compactOnly={true}
                        overrideSelected={
                            processFlowScheduleJobParamJson['uiRequest'][
                                'state'
                            ]['selectedFilters']
                        }
                    />
                </div>

                {convertCronToHumanReadable(processFlowScheduleCronText)}

                <span className="flex justify-end items-center gap-1">
                    {editMode ? (
                        <button
                            className="btn btn-sm btn-outline btn-circle btn-success tooltip tooltip-left"
                            onClick={onConfirmProcessChange}
                            data-tip={'Save changes'}
                        >
                            <BookmarkSquareIcon className="w-4 h-4" />
                        </button>
                    ) : (
                        <button
                            className="btn btn-sm btn-circle btn-primary btn-outline tooltip tooltip-left"
                            onClick={() => setEditMode(true)}
                            data-tip={'Edit schedule'}
                        >
                            <PencilSquareIcon className="w-4 h-4" />
                        </button>
                    )}

                    {editMode ? (
                        <button
                            className="btn btn-sm btn-circle btn-error btn-outline tooltip tooltip-bottom"
                            onClick={onCancelChanges}
                            data-tip={'Discard changes'}
                        >
                            <XMarkIcon className="w-4 h-4" />
                        </button>
                    ) : (
                        <button
                            className="btn btn-sm btn-circle btn-error btn-outline tooltip tooltip-bottom"
                            onClick={() => onDelete(processFlowScheduleControl)}
                            data-tip={'Remove schedule'}
                        >
                            <TrashIcon className="w-4 h-4" />
                        </button>
                    )}
                </span>
            </div>

            {editMode && (
                <div className="w-full grid grid-cols-2">
                    <div className="divider my-2 col-span-2" />
                    <div className="w-full flex flex-col justify-start items-center">
                        <p className="text-sm text-base-content font-bold text-opacity-80 mb-2">
                            Process Date Range
                        </p>

                        <span
                            className={`mb-2 flex justify-center w-full items-center ${
                                !editMode ? 'mt-2' : ''
                            }`}
                        >
                            <RelativeDateRangeComponent
                                onChange={onDateRangeChange}
                                initialFrom={
                                    processFlowScheduleJobParamJson[
                                        'uiRequest'
                                    ]['reportParams']['fromDate']
                                }
                            />
                        </span>
                    </div>

                    <div className="w-full flex flex-col justify-start items-center">
                        <p className="text-sm text-base-content font-bold text-opacity-80 mb-2">
                            Process Schedule
                        </p>
                        <SchedulerComponent
                            onChange={onScheduleChange}
                            initialValues={processFlowScheduleCronText}
                        />
                    </div>
                </div>
            )}
        </div>
    ) : (
        <LoadingComponent />
    );
};

export default ScheduledProcessComponent;
