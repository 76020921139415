import { useEffect } from 'react';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import MiniLineChartComponent from '../../charts/mini-line/mini-line-chart.component';
import LimitedDateRangeSelectorComponent, {
    limitedDateTextMap,
} from '../../limited-date-range-selector/limited-date-range-selector.component';
import { CreditCardIcon } from '@heroicons/react/24/solid';
import LoadingComponent from '../../loading-component/loading-component';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';
import MiniBarChartComponent from '../../charts/mini-bar/mini-bar-chart.component';
import {
    widget_graph_box,
    widget_text_box,
} from '../../../utils/styles/tailwind-styles';
import { widgetService } from '../../../store/discover/widgets/widgets.services';
import NoData from '../../no-data/no-data.component';
import { objectService } from '../../../utils/object.util';

const UnusedCreditTrend = ({ plottableData, rawData, selectedDate }) => {
    useEffect(() => {}, [plottableData, selectedDate]);
    return (
        <>
            <div className={widget_text_box}>
                <div className="flex flex-col justify-evenly items-start gap-0 p-4">
                    <div className="text-lg text-base-content text-opacity-80">
                        Unused Credit
                    </div>
                    {!objectService.checkObjectPopulated(rawData) ? (
                        <LoadingComponent />
                    ) : widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'totalCreditUsed'
                      ) &&
                      widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'intervalCreditLimit'
                      ) ? (
                        <>
                            <div className="font-bold text-2xl font-mono text-wrap">
                                R
                                {rawData[selectedDate][
                                    'totalCreditUsed'
                                ].toLocaleString()}
                                <span
                                    className={
                                        'font-light text-base-content text-opacity-80 text-xs break-all ml-2'
                                    }
                                >
                                    [remaining credit]
                                </span>
                            </div>

                            <div className="font-bold text-lg font-mono text-wrap text-accent text-opacity-80">
                                R
                                {rawData[selectedDate][
                                    'intervalCreditLimit'
                                ].toLocaleString()}
                                <span
                                    className={
                                        'font-light text-base-content text-opacity-80 text-xs break-all ml-2'
                                    }
                                >
                                    [interval credit limit]
                                </span>
                            </div>
                        </>
                    ) : (
                        <NoData />
                    )}
                    <div className="text-sm text-base-content text-opacity-80">
                        {objectService.checkObjectPopulated(rawData) ? (
                            <span className="flex justify-start items-center">
                                <span className="mx-2">
                                    <CreditCardIcon className="w-5 h-5 text-primary" />
                                </span>

                                <span className="flex flex-wrap gap-1 justify-start items-center w-full">
                                    <p>
                                        <span className="font-bold font-mono text-primary mx-1">
                                            {
                                                rawData[selectedDate][
                                                    'percCreditUsed'
                                                ]
                                            }
                                            %
                                        </span>
                                        of the allocated credit limit has been
                                        used over
                                        <strong className={'mx-1'}>
                                            {limitedDateTextMap(selectedDate)}
                                        </strong>
                                        , with a net total sales value of
                                        <span className="font-bold font-mono mx-1">
                                            R
                                            {rawData[selectedDate][
                                                'totalSales'
                                            ].toLocaleString()}
                                        </span>
                                        .
                                    </p>
                                </span>
                            </span>
                        ) : (
                            <LoadingComponent />
                        )}
                    </div>
                </div>
            </div>

            <div className={widget_graph_box}>
                {!objectService.checkObjectPopulated(plottableData) ? (
                    <PageLoadingComponent />
                ) : selectedDate === '1D' ? (
                    <MiniBarChartComponent
                        data={plottableData[selectedDate]}
                        className="max-h-52"
                    />
                ) : (
                    <MiniLineChartComponent
                        data={plottableData[selectedDate]}
                        className="max-h-52"
                    />
                )}

                {rawData && rawData['subAggregates'] && (
                    <div className="col-span-full">
                        <div className="divider my-1" />

                        <WidgetSubAggregates
                            subAggregates={rawData['subAggregates']}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default UnusedCreditTrend;
