import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import Select from 'react-select';

import { trendService } from '../../../../store/base/filters/trend/trend.services';
import {
    selectTrendFilterCompare,
    selectTrendFilterFrequency,
} from '../../../../store/base/filters/trend/trend.selectors';
import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import {
    setTrendFilterCompare,
    setTrendFilterFrequency,
} from '../../../../store/base/filters/trend/trend.actions';
import { TREND_FILTER_TYPES } from '../../../../store/base/filters/trend/trend.types';
import { ALERT_FILTER_TYPES } from '../../../../store/base/filters/alerts/alerts.types';

import DateRangeComponent from '../date-filters/date-range.component';
import CompactFilterGroup from '../compact-filter-container/compact-filter-group.component';

import { generateSelectStylesDaisy } from '../../../../utils/styles/global-styles';

export function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const TrendFilters = ({
    onViewChange,
    viewState,
    alerts,
    visible,
    toggleOpen,
}) => {
    const dispatch = useDispatch();

    const [selectedFrequency, setSelectedFrequency] = useState(
        trendService.mapTrendFilterValueToSelectionObject(
            useSelector(selectTrendFilterFrequency),
            alerts
        )
    );
    const [selectedCompare, setSelectedCompare] = useState(
        trendService.mapTrendFilterValueToSelectionObject(
            useSelector(selectTrendFilterCompare),
            alerts
        )
    );
    const [selectedGraphType, setSelectedGraphType] = useState(viewState);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [dropdownMode, setDropdownMode] = useState(
        windowSize['innerWidth'] <= 1200
    );
    const [dropdownActive, setDropdownActive] = useState(false);

    const daisyTheme = useSelector(selectDaisyTheme);

    const handleFrequencyChange = (event) => {
        setSelectedFrequency(event);
        dispatch(setTrendFilterFrequency(event.value));
    };
    const handleCompareChange = (event) => {
        setSelectedCompare(event);
        dispatch(setTrendFilterCompare(event.value));
    };

    useEffect(() => {
        handleCompareChange(selectedCompare);
        handleFrequencyChange(selectedFrequency);

        function handleWindowResize() {
            setWindowSize(getWindowSize());

            if (getWindowSize()['innerWidth'] < 1536) {
                setDropdownMode(true);
            } else {
                setDropdownMode(false);
            }
        }

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        setSelectedGraphType(viewState);
    }, [viewState]);

    const customStyles = generateSelectStylesDaisy(daisyTheme, windowSize);

    const toggleDropdownActive = () => {
        setDropdownActive(!dropdownActive);
    };

    return (
        <>
            {!visible && !dropdownMode ? (
                <div
                    className="flex justify-evenly items-center hover:cursor-pointer gap-2"
                    onClick={toggleOpen}
                >
                    <CompactFilterGroup
                        title="Frequency"
                        activeFilters={[selectedFrequency]}
                    />
                    <CompactFilterGroup
                        title="Compare"
                        activeFilters={[selectedCompare]}
                    />
                    <CompactFilterGroup
                        title="Graph"
                        activeFilters={[selectedGraphType]}
                    />

                    <DateRangeComponent compact={true} />

                    <span className="btn btn-circle btn-ghost btn-sm">
                        <ChevronDownIcon
                            className="w-5 h-5"
                            onClick={toggleOpen}
                        />
                    </span>
                </div>
            ) : (
                <>
                    <div
                        className={
                            !dropdownMode
                                ? 'flex desktop:flex-row flex-col justify-evenly z-50'
                                : dropdownActive
                                ? 'bg-base-200 drop-shadow absolute top-full right-0 min-h-screen w-80 flex flex-col justify-start items-end p-3 transition-all visible z-50'
                                : 'bg-base-200 drop-shadow absolute top-full right-0 min-h-screen w-80 flex flex-col justify-start items-end p-3 transition-all invisible z-50'
                        }
                    >
                        <div className="flex flex-col justify-center items-center col-span-1 w-full desktop:mx-2 my-2 text-xs">
                            <span className="mb-2 tracking-wider">
                                {TREND_FILTER_TYPES.frequency.heading}
                            </span>
                            <Select
                                styles={customStyles}
                                options={
                                    alerts
                                        ? ALERT_FILTER_TYPES.frequency.options
                                        : TREND_FILTER_TYPES.frequency.options
                                }
                                className="w-40"
                                value={selectedFrequency}
                                onChange={handleFrequencyChange}
                                isSearchable={false}
                                isClearable={false}
                                isMulti={false}
                            />
                        </div>

                        <div className="text-sm flex flex-col justify-center items-center w-full desktop:mx-2 my-2 text-xs">
                            <span className="mb-2 tracking-wider">
                                {alerts
                                    ? ALERT_FILTER_TYPES.compare.heading
                                    : TREND_FILTER_TYPES.compare.heading}
                            </span>
                            <Select
                                styles={customStyles}
                                options={
                                    alerts
                                        ? ALERT_FILTER_TYPES.compare.options
                                        : TREND_FILTER_TYPES.compare.options
                                }
                                value={selectedCompare}
                                className="w-40"
                                onChange={handleCompareChange}
                                isSearchable={false}
                                isClearable={false}
                                isMulti={false}
                            />
                        </div>

                        <div className="flex flex-col justify-center items-center w-full desktop:mx-2 my-2">
                            <span className="mb-2 tracking-wider text-xs">
                                {TREND_FILTER_TYPES.view.heading}
                            </span>
                            <Select
                                styles={customStyles}
                                className="w-40"
                                options={TREND_FILTER_TYPES.view.options}
                                value={selectedGraphType}
                                onChange={onViewChange}
                                isSearchable={false}
                                isClearable={false}
                                isMulti={false}
                            />
                        </div>

                        <div className="flex flex-col w-full justify-center items-center z-0 desktop:mx-2 my-4 text-xs">
                            <span className="mb-2 text-xs">Trend Timeline</span>
                            <DateRangeComponent />
                        </div>
                    </div>
                    {dropdownMode ? (
                        dropdownActive ? (
                            <div
                                className="btn btn-ghost text-xs font-normal normal-case"
                                onClick={toggleDropdownActive}
                            >
                                <p>Trend Filters</p>

                                <ChevronUpIcon className="w-5 h-5" />
                            </div>
                        ) : (
                            <div
                                className="btn btn-ghost text-xs font-normal normal-case"
                                onClick={toggleDropdownActive}
                            >
                                <p>Trend Filters</p>

                                <ChevronDownIcon className="w-5 h-5" />
                            </div>
                        )
                    ) : (
                        <span className="btn btn-circle btn-ghost btn-sm">
                            <ChevronUpIcon
                                className="w-5 h-5"
                                onClick={toggleOpen}
                            />
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export default TrendFilters;