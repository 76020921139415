import React from 'react';
import { formula_box } from '../../../utils/styles/tailwind-styles';

const OpportunityHelp = () => {
    return (
        <>
            <div className="flex flex-col gap-2 text-sm">
                <span className="badge badge-lg badge-primary">Ranking</span>
                <p className="px-2">
                    The <strong>Opportunity Ranking</strong> widget provides a
                    ranked view of the calculated difference in localized sales
                    for a product (within a perspective), and the global sales
                    for that same product.
                    <br />
                    <br />
                    Assuming we're with customer <strong>C</strong>, for product{' '}
                    <strong>P</strong>, and time period <strong>T</strong>, the
                    calculation of opportunity <strong>O</strong> will take
                    place as follows
                </p>

                <div className={formula_box}>
                    <p className="font-bold p-2">Sales from C's perspective</p>
                    {'\\(sales_T^C = \\sum_t^T{sales_t}\\)'}
                    <br />
                    {'\\(sales_{T-1}^C = \\sum_t^{T-1}{sales_t}\\)'}
                    <br />
                    {'\\(\\Delta local_{T}^C = sales_T^C - sales_{T-1}^C \\)'}

                    <p className="font-bold p-2">
                        Sales from Global perspective
                    </p>
                    {'\\(sales_T^G = \\sum_t^T{sales_t}\\)'}
                    <br />
                    {'\\(sales_{T-1}^G = \\sum_t^{T-1}{sales_t}\\)'}
                    <br />
                    {'\\(\\Delta global_{T}^G = sales_T^G - sales_{T-1}^G \\)'}

                    <p className="font-bold p-2">% Difference</p>
                    {
                        '\\(\\Delta sales_T^G = \\frac{sales_T^G - sales_{T-1}^G}{sales_{T-1}^G}\\)'
                    }
                    <br />
                    {
                        '\\(\\Delta sales_T^C = \\frac{sales_T^C - sales_{T-1}^C}{sales_{T-1}^C}\\)'
                    }
                    <br />
                    {'\\(difference^C = sales_T^G - sales_T^C \\)'}

                    <p className="font-bold p-2">Opportunity</p>
                    <span className="px-4 text-xs">
                        Now we can calculate opportunity by utilising
                        <ol className={'list-decimal px-6'}>
                            <li>
                                {'\\(sales_T^C\\)'} or the sales we know occured
                                for the current time period for customer C.
                            </li>
                            <li>
                                {'\\(sales_{T-1}^C\\)'} or the sales we know
                                occured for the previous time period for
                                customer C.
                            </li>
                            <li>
                                {'\\(difference^C\\)'} or difference in % change
                                between the local sales and the global sales,
                                between the current and the previous time
                                period.
                            </li>
                        </ol>
                    </span>
                    <br />
                    {
                        '\\(O^C = (sales_{T-1}^C * (1+\\frac{difference^C}{100})) - sales_T^C \\)'
                    }
                </div>
            </div>
        </>
    );
};

export default OpportunityHelp;