import {
    badge_overflow,
    glowing_border,
} from '../../../utils/styles/tailwind-styles';
import {
    ArrowsUpDownIcon,
    EyeIcon,
    EyeSlashIcon,
    InformationCircleIcon,
    StarIcon as StarIconFilled,
} from '@heroicons/react/20/solid';

import { StarIcon } from '@heroicons/react/24/outline';
import { REACT_TOUR_CLASS_NAMES } from '../../../utils/react-tour.util';
import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
} from '../../../store/discover/widgets/widgets.types';

const WidgetHeader = ({
    metric,
    visualization,
    sortDirection,

    perspective,
    aggregationMetric,

    favourite,
    onFavourite,

    hidden,
    onToggleHidden,

    className,

    onLimitChange,
    limit,
    toggleShowHelp,

    // Need to generalize this somehow
    sortType = null,
    onChangeSortType = null,

    admin = false,
}) => {
    return (
        <div className={`${className} ${hidden ? 'py-0' : ''}`}>
            <div className="flex w-full justify-between items-center">
                <div
                    className={`flex w-full min-h-full items-center justify-start gap-1`}
                >
                    <div
                        className={`flex flex-col gap-0.5 items-start justify-start min-h-full ${REACT_TOUR_CLASS_NAMES.WIDGET_2}`}
                    >
                        <div
                            className={
                                'flex items-center gap-1 text-base text-start text-primary capitalize font-bold'
                            }
                        >
                            {metric && metric.includes('_')
                                ? metric.replace('_', ' ')
                                : metric}{' '}
                            {visualization}{' '}
                            <a
                                className={`tooltip tooltip-right btn btn-ghost btn-circle btn-xs capitalize cursor-help`}
                                onClick={() => toggleShowHelp()}
                                data-tip={`More Details`}
                            >
                                <InformationCircleIcon
                                    className={'w-4 h-4 text-primary'}
                                />
                            </a>
                        </div>

                        {/*<div className="divider my-0 " />*/}

                        <div className="flex laptop:flex-row flex-col w-full gap-1">
                            {aggregationMetric && (
                                <div
                                    className={`tooltip tooltip-top bg-opacity-95 bg-primary shadow min-h-full text-primary-content ${badge_overflow} ${REACT_TOUR_CLASS_NAMES.WIDGET_4} text-start justify-start w-fit`}
                                    data-tip={`Aggregated by ${aggregationMetric.replace(
                                        '-',
                                        ' '
                                    )}`}
                                >
                                    <span className={'font-bold'}>
                                        {aggregationMetric.replace('-', ' ')}
                                    </span>
                                </div>
                            )}

                            {perspective && perspective.type && (
                                <div
                                    className={`tooltip bg-secondary bg-opacity-95 shadow min-h-full text-secondary-content ${badge_overflow} w-fit ${REACT_TOUR_CLASS_NAMES.WIDGET_3} text-start justify-start gap-1`}
                                    data-tip={
                                        perspective.descr &&
                                        perspective.descr.replace('-', ' ')
                                    }
                                >
                                    {perspective.type === 'global' && (
                                        <span className="font-bold">
                                            {perspective.type}
                                        </span>
                                    )}
                                    {Object.keys(perspective).includes(
                                        'descr'
                                    ) &&
                                        perspective.descr && (
                                            <span
                                                className={'text-sm font-bold'}
                                            >
                                                {perspective.descr
                                                    .replace('-', ' ')
                                                    .slice(0, 16)}
                                                {perspective.descr.length >
                                                    16 && '..'}
                                            </span>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>

                    {!admin ? (
                        <>
                            {/*<div className="divider divider-horizontal mx-0 my-0" />*/}
                        </>
                    ) : (
                        <span className={'text-primary capitalize font-bold'}>
                            {perspective.type ===
                            DISCOVER_WIDGET_AGGREGATION_METRICS.SKU
                                ? 'Product'
                                : perspective.type}{' '}
                            View
                        </span>
                    )}
                </div>

                {sortType && (
                    <div
                        className={
                            'btn btn-circle btn-secondary btn-xs  mr-2 tooltip'
                        }
                        onClick={onChangeSortType}
                        data-tip={`Sorted by ${
                            sortType[0].toUpperCase() + sortType.slice(1)
                        }`}
                    >
                        <ArrowsUpDownIcon className={'w-4 h-4'} />
                    </div>
                )}

                {!admin && (
                    <>
                        {
                            <>
                                <div
                                    className={`flex items-center justify-start gap-2 pl-2 bg-secondary bg-opacity-95 rounded`}
                                >
                                    {sortDirection && (
                                        <span
                                            className={`min-h-full capitalize text-secondary-content text-sm font-bold`}
                                        >
                                            {sortDirection}
                                        </span>
                                    )}
                                    {visualization ===
                                        DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING && (
                                        <select
                                            onChange={(e) => {
                                                onLimitChange(e.target.value);
                                            }}
                                            value={limit}
                                            className="select bg-secondary text-secondary-content select-xs text-sm font-mono"
                                        >
                                            {[3, 5, 10, 20, 50].map(
                                                (results) => {
                                                    return (
                                                        <option key={results}>
                                                            {results}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    )}
                                </div>
                            </>
                        }

                        <div className="divider divider-horizontal mx-2" />

                        <div className={`flex items-center justify-end gap-2`}>
                            {!hidden && (
                                <>
                                    {favourite ? (
                                        <button
                                            className={`btn btn-ghost btn-circle btn-xs tooltip tooltip-left flex justify-center items-center normal-case font-light text-xs border border-primary ${REACT_TOUR_CLASS_NAMES.WATCHLIST}`}
                                            data-tip={'Remove from Watchlist'}
                                            onClick={onFavourite}
                                        >
                                            <StarIconFilled
                                                className={
                                                    'w-4 h-4 text-primary animate-pulse-gentle'
                                                }
                                            />
                                        </button>
                                    ) : (
                                        <button
                                            className={`btn btn-ghost btn-circle btn-xs tooltip tooltip-left flex justify-center items-center normal-case font-bold text-xs ${REACT_TOUR_CLASS_NAMES.WATCHLIST}`}
                                            data-tip={'Add to Watchlist'}
                                            onClick={onFavourite}
                                        >
                                            <StarIcon
                                                className={
                                                    'w-4 h-4 text-primary'
                                                }
                                            />
                                        </button>
                                    )}
                                </>
                            )}

                            {hidden ? (
                                <button
                                    className={`btn btn-ghost btn-circle btn-xs tooltip tooltip-bottom flex justify-center items-center normal-case font-light text-xs ${REACT_TOUR_CLASS_NAMES.HIDE}`}
                                    data-tip={'Expand Widget'}
                                    onClick={onToggleHidden}
                                >
                                    <EyeIcon
                                        className={'w-4 h-4 text-secondary'}
                                    />
                                </button>
                            ) : (
                                <button
                                    className={`btn btn-ghost btn-circle btn-xs tooltip tooltip-left flex justify-center items-center normal-case font-light text-xs ${REACT_TOUR_CLASS_NAMES.HIDE}`}
                                    data-tip={'Collapse Widget'}
                                    onClick={onToggleHidden}
                                >
                                    <EyeSlashIcon
                                        className={'w-4 h-4 text-secondary'}
                                    />
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default WidgetHeader;
