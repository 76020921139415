import { RANGING_ACTION_TYPES, RANGING_TYPES } from './ranging.types';

const INITIAL_STATE = {
    rangingStrategy: RANGING_TYPES.rangingStrategy.default.value,
    identifyOption: RANGING_TYPES.identifyOption.default.value,
};

export const rangingReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case RANGING_ACTION_TYPES.SET_RANGING_FILTER_STRATEGY:
            return { ...state, rangingStrategy: payload };
        case RANGING_ACTION_TYPES.SET_RANGING_FILTER_SORT:
            return { ...state, identifyOption: payload };

        case RANGING_ACTION_TYPES.RESET_RANGING_FILTER:
            return { ...INITIAL_STATE };

        default:
            return state;
    }
};
