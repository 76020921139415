import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { selectUser } from '../../../store/auth/authentication.selectors';

import { authenticationService } from '../../../store/auth/authentication.services';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { apiService } from '../../../utils/engine/api.service';
import {
    resetBaseFiltersSelected,
    setBaseFiltersSelected,
} from '../../../store/base/filters/base-filters.actions';
import { setProductType } from '../../../store/product/product.actions';
import { PRODUCT_TYPES } from '../../../store/product/product.types';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import FooterComponent from '../../../components/footer-component/footer.component';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../components/loading-component/loading-component';
import { objectService } from '../../../utils/object.util';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/20/solid';

const UrlRequest = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const user = useSelector(selectUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLoginHandler = async (event) => {
        event.preventDefault();
        const target = event.currentTarget;
        setIsLoading(true);

        try {
            const userAction = await authenticationService.login(
                target.email.value,
                target.password.value
            );

            if (userAction) {
                dispatch(userAction);
                createNotification(
                    `${userAction.payload['name']} - ${userAction.payload['custaccountName']}`,
                    NOTIFICATION_TYPES.SUCCESS
                );
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (objectService.checkObjectPopulated(user) && 'products' in user) {
            goToRedirect();
        }
    }, [user]);

    const goToRedirect = async () => {
        const redirect = searchParams.get('redirect');
        const alias = searchParams.get('filters');
        const state = searchParams.get('state');

        dispatch(resetBaseFiltersSelected());
        dispatch(setProductType(PRODUCT_TYPES.PROFIT_ROCKET_BASE));

        setIsLoading(true);
        let selectedFilters = await apiService.getFiltersFromAlias(alias);
        console.log(selectedFilters);

        setIsLoading(false);

        if (typeof selectedFilters === 'string') {
            selectedFilters = JSON.parse(selectedFilters);
        }

        if (objectService.checkObjectPopulated(selectedFilters)) {
            Object.keys(selectedFilters).map((id) => {
                dispatch(setBaseFiltersSelected(id, selectedFilters[id]));
            });
        }

        navigate(`../${redirect}`, { replace: true });
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar">
                {!user && (
                    <div
                        className="card flex bg-base-200 flex-col py-8 px-12 shadow-lg w-full max-w-xl justify-center items-center"
                        style={{
                            minHeight: '14rem',
                        }}
                    >
                        <form
                            id="loginForm"
                            className="w-full h-full m-0 p-0"
                            onSubmit={onLoginHandler}
                        >
                            <p className="w-full text-sm font-light">
                                <strong className="mr-4 font-bold">Note</strong>
                                Before we can show you the shared view, you'll
                                need to login.
                            </p>
                            <div className="divider my-2" />

                            <div className="w-full h-full flex justify-evenly items-start flex-col">
                                <label
                                    className="text-sm text-start p-2"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    className="input input-bordered w-full"
                                    name="email"
                                    id="email"
                                    type="email"
                                    required={true}
                                />
                            </div>

                            <div className="w-full h-full flex justify-evenly items-start flex-col">
                                <span className="w-full h-full flex justify-between items-center">
                                    <label
                                        className="text-sm text-start p-2"
                                        htmlFor="password"
                                    >
                                        Password
                                    </label>
                                    {passwordVisible ? (
                                        <span
                                            className="btn btn-ghost btn-circle btn-sm"
                                            onClick={() =>
                                                setPasswordVisible(
                                                    !passwordVisible
                                                )
                                            }
                                        >
                                            <EyeSlashIcon className="w-4 h-4" />
                                        </span>
                                    ) : (
                                        <span
                                            className="btn btn-ghost btn-circle btn-sm"
                                            onClick={() =>
                                                setPasswordVisible(
                                                    !passwordVisible
                                                )
                                            }
                                        >
                                            <EyeIcon className="w-4 h-4" />
                                        </span>
                                    )}
                                </span>

                                <input
                                    className="input input-bordered w-full"
                                    name="password"
                                    id="password"
                                    type={passwordVisible ? 'text' : 'password'}
                                    required={true}
                                />
                            </div>

                            <div className="divider" />

                            <div className="w-full flex justify-between items-center mt-5">
                                <Link
                                    to="../password-reset-request"
                                    className="cursor-pointer text-sm underline"
                                >
                                    Forgot Password?
                                </Link>

                                <button
                                    type="submit"
                                    className={`btn btn-outline ${
                                        isLoading
                                            ? 'disabled btn-disabled gap-1.5'
                                            : 'gap-2'
                                    }`}
                                >
                                    {isLoading ? (
                                        <LoadingComponent
                                            type={
                                                LOADING_COMPONENT_TYPES.TAILSPIN
                                            }
                                            stroke={2}
                                            size={14}
                                        />
                                    ) : (
                                        <ArrowRightStartOnRectangleIcon className="w-4 h-4" />
                                    )}
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <FooterComponent useDaisy={true} />
        </>
    );
};

export default UrlRequest;