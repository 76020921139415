import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiscoverSearch } from '../../../../store/discover/search/discover-search.selectors';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';

import DiscoverSubNavigation from '../../../../components/discover/discover-sub-navigation/discover-sub-navigation.component';
import {
    discover_grid,
    discover_page,
    muted_border,
    widget_full,
    widget_half,
} from '../../../../utils/styles/tailwind-styles';

import Widget from '../../../../components/discover/widget/widget.component';

import Admin from '../../../../components/discover/admin/admin.component';
import AddWidget from '../../../../components/discover/widget/add-widget.component';
import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
} from '../../../../store/discover/widgets/widgets.types';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';
import {
    setCustomerPageWidgets,
    setSkuPageWidgets,
} from '../../../../store/discover/widgets/widgets.actions';
import { selectDiscoverCustomerPageWidgets } from '../../../../store/discover/widgets/widgets.selectors';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';
import NotFound from '../../../../components/not-found/not-found.component';
import { widgetService } from '../../../../store/discover/widgets/widgets.services';
import { objectService } from '../../../../utils/object.util';

const CustomerPage = () => {
    const params = useParams();
    const daisyTheme = useSelector(selectDaisyTheme);
    const discoverSearchMaster = useSelector(selectDiscoverSearch);
    const customerPageWidgets = useSelector(selectDiscoverCustomerPageWidgets);
    const dispatch = useDispatch();

    const [refresher, setRefresher] = useState(0);

    const [customerInfo, setCustomerInfo] = useState({});
    const [perspective, setPerspective] = useState({});
    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('selectedDate') ?? 'MTD'
    );

    const handleDateChange = (date) => {
        localStorage.setItem('selectedDate', date);
        setSelectedDate(date);
    };

    const handleAddWidget = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric,
        size
    ) => {
        const widgetData = {
            metric,
            visualization,
            sortDirection:
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND
                    ? null
                    : sortDirection,
            aggregationMetric,
            size,
        };

        const widgetId = widgetService.createWidgetRuleset(
            widgetData.metric,
            widgetData.visualization,
            widgetData.sortDirection,
            perspective,
            widgetData.aggregationMetric
        );

        let validWidget = true;

        if (Object.keys(customerPageWidgets).includes(widgetId)) {
            createNotification(
                'A widget exists on this page which matches those parameters.',
                NOTIFICATION_TYPES.ERROR
            );
            document
                .getElementById(widgetId)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });

            validWidget = false;
        }

        if (validWidget) {
            let newCustomerPageWidgets = {
                ...customerPageWidgets,
            };
            newCustomerPageWidgets[widgetId] = widgetData;
            dispatch(setCustomerPageWidgets(newCustomerPageWidgets));
        }
    };

    const handleRemoveWidget = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric
    ) => {
        const widgetId = widgetService.createWidgetRuleset(
            metric,
            visualization,
            sortDirection,
            perspective,
            aggregationMetric
        );
        let newCustomerPageWidgets = { ...customerPageWidgets };
        if (Object.keys(customerPageWidgets).includes(widgetId)) {
            delete newCustomerPageWidgets[widgetId];
            createNotification('Widget removed', NOTIFICATION_TYPES.SUCCESS);
        }

        dispatch(setCustomerPageWidgets(newCustomerPageWidgets));
    };

    const handleMoveWidgetUp = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric
    ) => {
        const widgetId = widgetService.createWidgetRuleset(
            metric,
            visualization,
            sortDirection,
            perspective,
            aggregationMetric
        );

        let newCustomerPageWidgets = objectService.moveObjectItemUp(
            widgetId,
            customerPageWidgets
        );

        if (
            !objectService.checkObjectKeysEqual(
                newCustomerPageWidgets,
                customerPageWidgets
            )
        ) {
            dispatch(setCustomerPageWidgets(newCustomerPageWidgets));
        }
    };

    useEffect(() => {
        if (Object.keys(discoverSearchMaster['customer']).includes(params.id))
            setCustomerInfo(discoverSearchMaster['customer'][params.id]);
        else
            createNotification(
                `We have no record of [${params.id}] in our masterdata.`,
                NOTIFICATION_TYPES.WARNING
            );
    }, [discoverSearchMaster, params.id]);

    useEffect(() => {
        if (Object.keys(customerInfo).includes('code')) {
            setPerspective({
                type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
                code: customerInfo['code'],
                descr: customerInfo['descr'],
            });
        }
    }, [customerInfo]);

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <DiscoverSubNavigation
                    title="Customer View"
                    className={`laptop:col-span-2 col-span-12 row-span-max sticky ${muted_border} discover-dashboard-sub-nav-tour`}
                    onDateChange={handleDateChange}
                    selectedDate={selectedDate}
                    widgets={customerPageWidgets}
                />

                {objectService.checkObjectPopulated(customerInfo) ? (
                    objectService.checkObjectPopulated(perspective) && (
                        <>
                            <Admin
                                data={customerInfo}
                                type={
                                    DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER
                                }
                                className={widget_full}
                                handleRefresh={() =>
                                    setRefresher(refresher + 1)
                                }
                            />

                            {objectService.checkObjectPopulated(
                                customerPageWidgets
                            ) &&
                                Object.keys(customerPageWidgets).map(
                                    (widgetId, index) => {
                                        const widget =
                                            customerPageWidgets[widgetId];

                                        return (
                                            <Widget
                                                id={widgetService.createWidgetRuleset(
                                                    widget.metric,
                                                    widget.visualization,
                                                    widget.sortDirection,
                                                    perspective,
                                                    widget.aggregationMetric
                                                )}
                                                key={widgetService.createWidgetRuleset(
                                                    widget.metric,
                                                    widget.visualization,
                                                    widget.sortDirection,
                                                    perspective,
                                                    widget.aggregationMetric
                                                )}
                                                perspective={perspective}
                                                metric={widget.metric}
                                                visualization={
                                                    widget.visualization
                                                }
                                                sortDirection={
                                                    widget.sortDirection
                                                }
                                                aggregationMetric={
                                                    widget.aggregationMetric
                                                }
                                                selectedDate={selectedDate}
                                                onDateChange={handleDateChange}
                                                size={widget.size}
                                                className={`${
                                                    widget.size === 'full'
                                                        ? widget_full
                                                        : widget_half
                                                } ${
                                                    index === 0 &&
                                                    REACT_TOUR_CLASS_NAMES.WIDGET_1
                                                }`}
                                                onRemoveWidget={
                                                    handleRemoveWidget
                                                }
                                                onMoveUp={handleMoveWidgetUp}
                                                daisyTheme={daisyTheme}
                                                refresher={refresher}
                                            />
                                        );
                                    }
                                )}
                        </>
                    )
                ) : (
                    <NotFound type={'Customer'} descr={params.id} />
                )}

                <AddWidget
                    perspective={perspective}
                    onAddWidget={handleAddWidget}
                />
            </div>
        </div>
    );
};

export default CustomerPage;