import { engineService } from '../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';
import { useEffect, useState } from 'react';
import { objectService } from '../../../utils/object.util';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import { useSelector } from 'react-redux';
import { selectDiscoverSearch } from '../../../store/discover/search/discover-search.selectors';
import { XMarkIcon } from '@heroicons/react/20/solid';
import UserRepAddComponent from './user-rep-add.component';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';

const UserReps = ({ user }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reps, setReps] = useState([]);

    const discoverSearchRepMaster = useSelector(selectDiscoverSearch).rep;

    const fetchRepLinks = async () => {
        try {
            setIsLoading(true);
            const response = await engineService.invoke(
                WEB_PROCESS_FLOWS.MASTERDATA,
                {
                    action: 'retrieveUserMasterdata',
                    payload: {
                        email: user.email,
                    },
                }
            );

            setIsLoading(false);

            if (response?.reps) {
                setReps(response.reps);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleRemoveLink = async (repCode) => {
        try {
            setIsLoading(true);
            const response = await engineService.invoke(
                WEB_PROCESS_FLOWS.MASTERDATA,
                {
                    action: 'removeUserRep',
                    payload: {
                        email: user.email,
                        code: repCode,
                    },
                }
            );
            if (response?.reps) {
                createNotification(
                    `Rep [${repCode}] link to [${user.email}] removed successfully`,
                    NOTIFICATION_TYPES.SUCCESS
                );
                setReps(response.reps);
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRepLinks();
    }, [user]);

    return (
        <>
            {!isLoading ? (
                reps.length > 0 ? (
                    reps.map((repCode) => {
                        return (
                            <div
                                key={repCode}
                                className="px-2 py-1 bg-primary text-primary-content w-full rounded text-sm flex gap-1 items-center justify-between"
                            >
                                <p>
                                    {objectService.checkObjectPopulated(
                                        discoverSearchRepMaster
                                    ) && repCode in discoverSearchRepMaster
                                        ? discoverSearchRepMaster[repCode].name
                                        : repCode}
                                </p>
                                <button
                                    className="btn btn-xs btn-ghost btn-circle tooltip tooltip-left"
                                    data-tip={'Remove Link'}
                                    onClick={() => handleRemoveLink(repCode)}
                                >
                                    <XMarkIcon className={'w-4 h-4'} />
                                </button>
                            </div>
                        );
                    })
                ) : (
                    <span className="badge badge-secondary badge-outline font-bold w-full">
                        -
                    </span>
                )
            ) : (
                <LoadingComponent
                    type={LOADING_COMPONENT_TYPES.TAILCHASE}
                    size={20}
                    speed={1.2}
                />
            )}

            <div className="divider my-1" />

            <UserRepAddComponent
                user={user}
                reps={reps}
                onFinishAdd={(reps) => setReps(reps)}
            />
        </>
    );
};

export default UserReps;