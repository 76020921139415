import { applyMiddleware, compose, createStore } from 'redux';

import { rootReducer } from './root-reducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const middlewares = [
    // logger
];
const composedEnhancers = compose(applyMiddleware(...middlewares));

const persistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [
        // Values of reducers you don't want to persist
        'baseDashboard',
        'baseFilterOptions',
        'viewShare',
        'trend',
        // 'baseFilters'
        // 'authentication',
        // 'product',
        // 'discoverSearch',
    ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    undefined,
    composedEnhancers
);
export const persistor = persistStore(store);