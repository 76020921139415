import { createAction } from '../../utils/reducer.utils';
import { DASHBOARD_ACTION_TYPES } from './dashboard.types';

export const setDashboardActiveComponent = (activeComponent) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_ACTIVE_COMPONENT,
        activeComponent
    );
};

export const setDashboardSidebarActive = (sidebarActive) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SIDEBAR_ACTIVE,
        sidebarActive
    );
};

export const setDashboardSidebarMode = (sidebarMode) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SIDEBAR_MODE,
        sidebarMode
    );
};

export const setDashboardTopSkus = (topSkus) => {
    return createAction(DASHBOARD_ACTION_TYPES.SET_DASHBOARD_TOP_SKUS, topSkus);
};

export const setDashboardTopStores = (topStores) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_TOP_STORES,
        topStores
    );
};

export const setDashboardSalesPerformance = (salesPerformance) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SALES_PERFORMANCE,
        salesPerformance
    );
};

export const setDashboardActivity = (activity) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_ACTIVITY,
        activity
    );
};

export const setDashboardDataStatus = (dataStatus) => {
    return createAction(
        DASHBOARD_ACTION_TYPES.SET_DASHBOARD_DATA_STATUS,
        dataStatus
    );
};

export const clearDashboardData = () => {
    return createAction(DASHBOARD_ACTION_TYPES.CLEAR_DASHBOARD_DATA);
};
