import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../components/loading-component/loading-component';
import {
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
} from './widgets.types';
import SalesTrend from '../../../components/discover/sales/sales-trend.component';
import SalesRank from '../../../components/discover/sales/sales-rank.component';
import ReturnsTrend from '../../../components/discover/returns/returns-trend.component';
import DiscountsTrend from '../../../components/discover/discounts/discounts-trend.component';
import ProfitRanking from '../../../components/discover/profit/profit-rank.component';
import UnusedCreditTrend from '../../../components/discover/unused-credit/unused-credit-trend.component';
import ChaosRank from '../../../components/discover/chaos/chaos-rank.component';
import OpportunityRank from '../../../components/discover/opportunity/opportunity-rank.component';

import ChaosHelp from '../../../components/discover/chaos/chaos-help.component';
import SalesHelp from '../../../components/discover/sales/sales-help.component';
import ReturnsHelp from '../../../components/discover/returns/returns-help.component';
import DiscountsHelp from '../../../components/discover/discounts/discounts-help.component';
import ProfitHelp from '../../../components/discover/profit/profit-help.component';
import OpportunityHelp from '../../../components/discover/opportunity/opportunity-help.component';
import UnusedCreditHelp from '../../../components/discover/unused-credit/unused-credit-help.component';
import NoData from '../../../components/no-data/no-data.component';
import ReturnsRank from '../../../components/discover/returns/returns-rank.component';
import ProfitTrend from '../../../components/discover/profit/profit-trend.component';
import WidgetSkeleton from '../../../components/discover/widget/widget-skeleton.component';
import { discoverGraphService } from '../graph/discover-graph.services';
import { objectService } from '../../../utils/object.util';

export const widgetService = {
    createWidgetRuleset,
    createWatchlistWidgetRuleset,
    getComponentByMetricAndVisualization,
    getHelpByMetricAndVisualiztion,

    checkWidgetHasData,
};

function createWidgetRuleset(
    metric,
    visualization,
    sortDirection,

    perspective,
    aggregationMetric
) {
    return `${aggregationMetric ? aggregationMetric + '-' : ''}${
        metric.includes('_') ? metric.replace('_', '-') : metric
    }-${sortDirection ? sortDirection + '-' : ''}${visualization}-[${
        perspective['type']
    }]`;
}

function createWatchlistWidgetRuleset(
    metric,
    visualization,
    sortDirection,

    perspective,
    aggregationMetric
) {
    return `${aggregationMetric ? aggregationMetric + '-' : ''}${metric}-${
        sortDirection ? sortDirection + '-' : ''
    }${visualization}-[${perspective['type']}${
        perspective['code'] ? '-' + perspective['code'] : ''
    }]`;
}

function getComponentByMetricAndVisualization(
    metric,
    visualization,
    sortDirection,

    perspective,
    aggregationMetric,

    data,

    selectedDate,
    onDateChange,

    daisyTheme,

    onTargetChange
) {
    // Check if data is undefined, return no response from backend
    if (data === undefined)
        return (
            <NoData
                message={
                    'Our servers are experiencing high volumes. Please reload in a few minutes.'
                }
            />
        );

    // Check if data is blank object, return loading
    if (!objectService.checkObjectPopulated(data))
        return <WidgetSkeleton className={'col-span-full'} rows={5} />;

    // API Endpoint returned action, something went wrong on retrieval
    if ('action' in data) return <NoData />;

    switch (metric) {
        case DISCOVER_WIDGET_DATA_METRICS.SALES:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
                const salesPlottable =
                    discoverGraphService.convertSalesTrendDataToLineCharts(
                        data,
                        daisyTheme
                    );

                const salesQtyPlottable =
                    discoverGraphService.convertSalesQtyDataToLineCharts(
                        data,
                        daisyTheme
                    );

                return (
                    <SalesTrend
                        rawData={data}
                        plottableData={salesPlottable}
                        plottableQtyData={salesQtyPlottable}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        perspective={perspective}
                        onTargetChange={onTargetChange}
                    />
                );
            } else if (
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
            ) {
                const plottable =
                    discoverGraphService.convertSalesPerformersToPlottable(
                        data,
                        sortDirection,
                        daisyTheme
                    );

                return (
                    <SalesRank
                        rawData={data}
                        plottableData={plottable}
                        aggregationMetric={aggregationMetric}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                    />
                );
            }

            break;
        case DISCOVER_WIDGET_DATA_METRICS.RETURNS:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
                const returnsPlottable =
                    discoverGraphService.convertReturnsTrendDataToLineCharts(
                        data,
                        daisyTheme
                    );

                return (
                    <ReturnsTrend
                        rawData={data}
                        plottableData={returnsPlottable}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        perspective={perspective}
                    />
                );
            } else if (
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
            ) {
                const plottable =
                    discoverGraphService.convertSalesPerformersToPlottable(
                        data,
                        sortDirection,
                        daisyTheme,
                        true
                    );

                return (
                    <ReturnsRank
                        rawData={data}
                        plottableData={plottable}
                        aggregationMetric={aggregationMetric}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                    />
                );
            }
            break;
        case DISCOVER_WIDGET_DATA_METRICS.DISCOUNT:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
                const discountsPlottable =
                    discoverGraphService.convertDiscountsTrendDataToLineCharts(
                        data,
                        daisyTheme
                    );

                return (
                    <DiscountsTrend
                        rawData={data}
                        plottableData={discountsPlottable}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        perspective={perspective}
                    />
                );
            }
            break;
        case DISCOVER_WIDGET_DATA_METRICS.PROFIT:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
                const profitPlottable =
                    discoverGraphService.convertProfitTrendDataToLineCharts(
                        data,
                        daisyTheme
                    );

                return (
                    <ProfitTrend
                        rawData={data}
                        plottableData={profitPlottable}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                    />
                );
            } else if (
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
            ) {
                return (
                    <ProfitRanking
                        rawData={data}
                        aggregationMetric={aggregationMetric}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                    />
                );
            }
            break;
        case DISCOVER_WIDGET_DATA_METRICS.UNUSED_CREDIT:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
                const unusedCreditPlottable =
                    discoverGraphService.convertUnusedCreditTrendDatatoLineCharts(
                        data,
                        daisyTheme
                    );
                return (
                    <UnusedCreditTrend
                        rawData={data}
                        plottableData={unusedCreditPlottable}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                        perspective={perspective}
                    />
                );
            }
            break;

        case DISCOVER_WIDGET_DATA_METRICS.CHAOS:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING) {
                return (
                    <ChaosRank
                        rankData={data}
                        aggregationMetric={aggregationMetric}
                    />
                );
            }
            break;

        case DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY:
            if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING) {
                return (
                    <OpportunityRank
                        rawData={data}
                        aggregationMetric={aggregationMetric}
                        perspective={perspective}
                        selectedDate={selectedDate}
                        onDateChange={onDateChange}
                    />
                );
            }
            break;

        default:
            return <LoadingComponent />;
    }
}

function getHelpByMetricAndVisualiztion(metric, visualization) {
    switch (metric) {
        case DISCOVER_WIDGET_DATA_METRICS.SALES:
            return <SalesHelp />;

        case DISCOVER_WIDGET_DATA_METRICS.RETURNS:
            return <ReturnsHelp />;

        case DISCOVER_WIDGET_DATA_METRICS.DISCOUNT:
            return <DiscountsHelp />;

        case DISCOVER_WIDGET_DATA_METRICS.PROFIT:
            return <ProfitHelp />;

        case DISCOVER_WIDGET_DATA_METRICS.UNUSED_CREDIT:
            return <UnusedCreditHelp />;

        case DISCOVER_WIDGET_DATA_METRICS.CHAOS:
            return <ChaosHelp />;

        case DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY:
            return <OpportunityHelp />;

        default:
            return <LoadingComponent />;
    }
}

function checkWidgetHasData(data, selectedDate, key) {
    if (!data) return false;
    if (!data[selectedDate]) return false;
    return key in data[selectedDate];
}