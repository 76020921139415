import { combineReducers } from 'redux';

import { authenticationReducer } from './auth/authentication.reducer';
import { themesReducer } from './themes/themes.reducer';
import { navigationReducer } from './navigation/navigation.reducer';
import { productReducer } from './product/product.reducer';
import { dashboardReducer } from './base/dashboard/dashboard.reducer';
import { baseFiltersReducer } from './base/filters/base-filters.reducer';
import { baseFilterOptionsReducer } from './base/filters/options/base-filter-options.reducer';
import { trendReducer } from './base/filters/trend/trend.reducer';
import { rangingReducer } from './base/filters/ranging/ranging.reducer';
import { viewShareReducer } from './base/view-share/view-share.reducer';

import { discoverSearchReducer } from './discover/search/discover-search.reducer';
import { discoverWidgetsReducer } from './discover/widgets/widgets.reducer';
import { trendTooltipReducer } from './trend/trend-tooltip.reducer';

export const rootReducer = combineReducers({
    /*
    User specific state
     */
    authentication: authenticationReducer,
    theme: themesReducer,
    product: productReducer,

    /*
    Application specific state
     */
    navigation: navigationReducer,

    /*
    Base specific state
     */
    baseDashboard: dashboardReducer,
    baseFilters: baseFiltersReducer,
    baseFilterOptions: baseFilterOptionsReducer,
    baseRangingFilters: rangingReducer,
    baseTrendFilters: trendReducer,

    viewShare: viewShareReducer,

    /*
    Discover specific state
     */
    discoverWidgets: discoverWidgetsReducer,
    discoverSearch: discoverSearchReducer,

    trendTooltip: trendTooltipReducer,
});