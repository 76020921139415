import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectThemeMode } from '../../../../store/themes/themes.selectors';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';

const ForecastingPage = () => {
    const themeMode = useSelector(selectThemeMode);

    useEffect(() => {
        createNotification(
            'This feature is coming soon!',
            NOTIFICATION_TYPES.INFO
        );
    }, []);

    return (
        <>
            {/*<HeaderContainer*/}
            {/*    background={GLOBAL_COLOURS[themeMode]['bg-component']}*/}
            {/*    foreground={GLOBAL_COLOURS[themeMode]['text']}*/}
            {/*>*/}
            {/*    <HeaderTitle>Forecasting</HeaderTitle>*/}
            {/*</HeaderContainer>*/}

            {/*<PageBodyContainer*/}
            {/*    background={GLOBAL_COLOURS[themeMode]['bg-page']}*/}
            {/*    foreground={GLOBAL_COLOURS[themeMode]['text']}*/}
            {/*>*/}
            {/*    <FiltersContainer />*/}
            {/*</PageBodyContainer>*/}
        </>
    );
};

export default ForecastingPage;
