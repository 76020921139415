import { engineService } from '../../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';
import {
    addOpacity,
    graphColourSelector,
} from '../../../../utils/styles/global-styles';

export const trendService = {
    loadStockTrendData,
    loadSalesTrendData,

    convertTrendDataToPlottable,
    mapTrendFilterValueToSelectionObject,
};

async function loadStockTrendData(selectedFilters, trendFilters, reportParams) {
    const fetchParam = {
        selectedFilters: selectedFilters,
        compare: trendFilters['compare'],
        frequency: trendFilters['frequency'],
    };

    let param;
    if (reportParams) {
        param = {
            uiRequest: {
                action: 'generateReport',
                state: fetchParam,
                reportParams,
            },
        };
    } else {
        param = fetchParam;
    }

    const response = await engineService.invoke(WEB_PROCESS_FLOWS.INV, param);
    return response;
}

async function loadSalesTrendData(selectedFilters, trendFilters, reportParams) {
    const fetchParam = {
        selectedFilters: selectedFilters,
        compare: trendFilters['compare'],
        frequency: trendFilters['frequency'],
    };

    let param;
    if (reportParams) {
        param = {
            uiRequest: {
                action: 'generateReport',
                state: fetchParam,
                reportParams,
            },
        };
    } else {
        param = fetchParam;
    }

    const response = await engineService.invoke(WEB_PROCESS_FLOWS.SALES, param);
    return response;
}

function convertTrendDataToPlottable(trendData, daisyTheme) {
    const unformattedDatasets = trendData['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = trendData['rows'].map((row) => row.name);

    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: dataset,
                data: trendData['rows'].map((row) => row[dataset]),
                fill: false,
                borderColor,
                backgroundColor: addOpacity(backgroundColor, 0.5),
                tension: 0.125,

                pointRadius: 4,
                pointHoverRadius: 8,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 0.5,
                pointBorderColor: borderColor,
                pointBackgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}

function mapTrendFilterValueToSelectionObject(value, alerts) {
    if (alerts) {
        switch (value) {
            case 'daily':
                return { value: 'daily', label: 'Daily' };
            case 'weekly':
                return { value: 'weekly', label: 'Weekly' };
            case 'monthly':
                return { value: 'monthly', label: 'Monthly' };

            case 'retailers':
                return { value: 'retailers', label: 'Retailers' };
            case 'storetypes':
                return { value: 'storetypes', label: 'Store Types' };
            case 'storeregions':
                return { value: 'storeregions', label: 'Store Regions' };
            case 'stores':
                return { value: 'stores', label: 'Stores' };
            case 'brands':
                return { value: 'brands', label: 'Brands' };
            case 'skus':
                return { value: 'skus', label: "SKU's" };
            case 'alerttype':
                return { value: 'alerttype', label: 'Alert Types' };
            case 'lines':
                return { value: 'lines', label: 'Lines' };
            case 'stacked':
                return { value: 'stacked', label: 'Stacked Areas' };

            default:
                return { value: 'retailers', label: 'Retailers' };
        }
    } else {
        switch (value) {
            case 'daily':
                return { value: 'daily', label: 'Daily' };
            case 'weekly':
                return { value: 'weekly', label: 'Weekly' };
            case 'monthly':
                return { value: 'monthly', label: 'Monthly' };

            case 'retailers':
                return { value: 'retailers', label: 'Retailers' };
            case 'storetypes':
                return { value: 'storetypes', label: 'Store Types' };
            case 'storeregions':
                return { value: 'storeregions', label: 'Store Regions' };
            case 'stores':
                return { value: 'stores', label: 'Stores' };
            case 'categories':
                return { value: 'categories', label: 'Categories' };
            case 'subcategories':
                return { value: 'subcategories', label: 'Sub-Categories' };
            case 'brands':
                return { value: 'brands', label: 'Brands' };
            case 'skus':
                return { value: 'skus', label: "SKU's" };

            case 'lines':
                return { value: 'lines', label: 'Lines' };
            case 'stacked':
                return { value: 'stacked', label: 'Stacked Areas' };

            default:
                return { value: 'retailers', label: 'Retailers' };
        }
    }
}
