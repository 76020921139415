import AdminSkeleton from './admin-skeleton.component';
import { objectService } from '../../../utils/object.util';
import { navigationService } from '../../../utils/navigation.util';
import { detail_sub_heading } from '../../../utils/styles/tailwind-styles';

class Rep {
    code;
    name;

    email;
    phone;

    active;

    constructor(detail) {
        if (objectService.checkObjectPopulated(detail)) {
            this.code = detail.code;
            this.name = detail.name;

            if ('email' in detail) this.email = detail.email;
            if ('phone' in detail) this.telephone = detail.phone;
            if ('active' in detail) this.active = detail.active;
        }
    }
}

const RepDetail = ({ detail }) => {
    const rep = new Rep(detail);

    return (
        <>
            {objectService.checkObjectPopulated(detail) ? (
                <div
                    className={`grid grid-cols-2 gap-x-2 gap-y-6 text-sm items-start`}
                >
                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>Phone</span>

                        <span
                            className="text-center text-xs cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(rep.phone)
                            }
                        >
                            {rep.phone ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>Email</span>

                        <span
                            className="text-center text-xs cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(rep.email)
                            }
                        >
                            {rep.email ?? '-'}
                        </span>
                    </div>
                </div>
            ) : (
                <AdminSkeleton rows={1} />
            )}
        </>
    );
};

export default RepDetail;
