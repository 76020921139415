import { useEffect, useState } from 'react';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import MiniLineChartComponent from '../../charts/mini-line/mini-line-chart.component';
import LimitedDateRangeSelectorComponent, {
    limitedDateTextMap,
} from '../../limited-date-range-selector/limited-date-range-selector.component';

import LoadingComponent from '../../loading-component/loading-component';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';
import MiniBarChartComponent from '../../charts/mini-bar/mini-bar-chart.component';
import SalesTarget from './sales-target.component';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
    widget_graph_box,
    widget_text_box,
} from '../../../utils/styles/tailwind-styles';
import { widgetService } from '../../../store/discover/widgets/widgets.services';
import NoData from '../../no-data/no-data.component';
import { objectService } from '../../../utils/object.util';

const SalesTrend = ({
    rawData,
    plottableData,
    plottableQtyData,
    selectedDate,
    perspective,
    onTargetChange,
}) => {
    const [showQty, setShowQty] = useState(false);

    useEffect(() => {}, [rawData, plottableData, selectedDate]);

    return (
        <>
            <div className={widget_text_box}>
                <div className="flex flex-col justify-evenly items-start gap-4 p-8">
                    <div className="text-lg text-base-content text-opacity-80">
                        Sales Value
                        <strong className="font-normal text-base-content text-opacity-60 mx-1 text-xs">
                            [Net]
                        </strong>
                    </div>
                    {!objectService.checkObjectPopulated(rawData) ? (
                        <LoadingComponent />
                    ) : widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'total'
                      ) ? (
                        <div className="stat-value text-2xl font-mono">
                            R{rawData[selectedDate]['total'].toLocaleString()}
                            <span
                                className={
                                    'font-light text-base-content text-opacity-80 text-xs ml-2'
                                }
                            >
                                [{limitedDateTextMap(selectedDate)}]
                            </span>
                        </div>
                    ) : (
                        <NoData />
                    )}

                    <SalesTarget
                        rawData={rawData}
                        selectedDate={selectedDate}
                        perspective={perspective}
                        onTargetChange={onTargetChange}
                    />
                </div>
            </div>

            <div className={`relative ${widget_graph_box}`}>
                <span
                    className="tooltip absolute bottom-2 right-2 btn btn-secondary btn-circle btn-xs bg-opacity-40 z-20"
                    data-tip={`${showQty ? 'Hide' : 'Show'} Quantity`}
                    onClick={() => setShowQty(!showQty)}
                >
                    {!showQty ? (
                        <PlusIcon className="w-4 h-4" />
                    ) : (
                        <MinusIcon className="w-4 h-4" />
                    )}
                </span>

                {!objectService.checkObjectPopulated(plottableData) ? (
                    <PageLoadingComponent />
                ) : selectedDate === '1D' ? (
                    <>
                        <MiniBarChartComponent
                            data={plottableData[selectedDate]}
                            className="max-h-52"
                        />

                        {showQty && (
                            <MiniBarChartComponent
                                data={plottableQtyData[selectedDate]}
                                className="max-h-24"
                            />
                        )}
                    </>
                ) : (
                    <>
                        <MiniLineChartComponent
                            data={plottableData[selectedDate]}
                            className="max-h-60"
                        />

                        {showQty && (
                            <MiniLineChartComponent
                                data={plottableQtyData[selectedDate]}
                                className="max-h-24"
                            />
                        )}
                    </>
                )}

                {rawData &&
                    'subAggregates' in rawData &&
                    rawData['subAggregates'] && (
                        <div className="col-span-full">
                            <div className="divider my-1" />

                            <WidgetSubAggregates
                                subAggregates={rawData['subAggregates']}
                            />
                        </div>
                    )}
            </div>
        </>
    );
};

export default SalesTrend;
