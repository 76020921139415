import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { ShareIcon, XMarkIcon } from '@heroicons/react/24/solid';

import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../store/auth/authentication.selectors';

import { apiService } from '../../../utils/engine/api.service';
import { getWindowSize } from '../filters/trend-filters/trend-filters.component';

import Select from 'react-select';
import FiltersContainer from '../filters/filters-container.component';

import {
    generateSelectStylesDaisy,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';
import './share-view.styles.css';

export const onShareViewClick = async (
    userToken,
    selectedFilters,
    trendFilters,
    recipientsSelected,
    additionalMessage
) => {
    const selectedFiltersAlias = await apiService.createFiltersAlias(
        selectedFilters
    );
    const link =
        window.location.origin +
        '/urlrequest?redirect=' +
        window.location.pathname.replace('/', '') +
        '&filters=' +
        selectedFiltersAlias +
        '&state=' +
        btoa(JSON.stringify(trendFilters));

    let body = {
        link: link,
        recipients: recipientsSelected.map((recipient) => {
            return {
                name: recipient['label'],
                email: recipient['value'],
            };
        }),
        note: additionalMessage,
    };

    if (!body.link) {
        createNotification(
            'Failed to create link for this view',
            NOTIFICATION_TYPES.ERROR
        );
        return;
    }

    if (!body.recipients || body.recipients.length === 0) {
        createNotification(
            'Please select one or more recipients',
            NOTIFICATION_TYPES.WARNING
        );
        return;
    }

    if (!body.note || body.note.length < 5) {
        createNotification(
            'Please add a note with at least 5 characters.',
            NOTIFICATION_TYPES.WARNING
        );
        return;
    }

    await apiService.publishShareViewLink(body);
};

const ShareViewComponent = ({ onClick }) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const [recipientOptions, setRecipientOptions] = useState(null);
    const [recipientsSelected, setRecipientsSelected] = useState(null);
    const [additionalMessage, setAdditionalMessage] = useState(null);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    const daisyTheme = useSelector(selectDaisyTheme);
    const customStyles = generateSelectStylesDaisy(daisyTheme, windowSize);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && ref.current === e.target) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        const getRecipients = async () => {
            const recipients = await apiService.fetchTeam();
            const newRecipients = recipients.map((recipient) => {
                if (recipient['name'] === 'Myself') {
                    setRecipientsSelected([
                        {
                            value: recipient['email'],
                            label: recipient['name'],
                        },
                    ]);
                }
                return {
                    value: recipient['email'],
                    label: recipient['name'],
                };
            });
            setRecipientOptions(newRecipients);
        };
        getRecipients();

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    const handleSelectRecipient = (event) => {
        setRecipientsSelected(event);
    };

    const handleMessageChange = (event) => {
        setAdditionalMessage(event.currentTarget.value);
    };

    const onShareClick = () => {
        onClick(recipientsSelected, additionalMessage);
        toggleIsOpen();
    };

    return (
        <>
            <label
                className="btn btn-sm btn-circle btn-ghost flex justify-center items-center tooltip tooltip-bottom normal-case "
                data-tip={'Share View'}
                htmlFor="share-view-modal"
            >
                <ShareIcon className="w-5 h-5" />
            </label>

            <input
                type="checkbox"
                id="share-view-modal"
                className="modal-toggle"
            />
            <div className="modal lg:top-20 top-0" style={{ zIndex: 1001 }}>
                <div
                    className="modal-box bg-base-200 relative w-full h-auto max-w-7xl "
                    style={{
                        maxHeight: '80vh',
                    }}
                >
                    <span className="w-full flex flex-col items-center justify-start mt-2">
                        <div className="flex justify-between items-center px-12 w-full">
                            <h3 className="text-base">Share View</h3>
                            <label
                                htmlFor="share-view-modal"
                                className="btn btn-sm btn-ghost btn-circle"
                            >
                                <XMarkIcon className="w-5 h-5" />
                            </label>
                        </div>

                        <div className="divider my-2" />
                        <p className="text-center my-2 text-sm">
                            We'll email you as well as your recipients a link to
                            the view you're looking at.
                        </p>

                        <FiltersContainer compactOnly={true} />
                    </span>

                    <div className="divider my-2" />

                    <span className="w-full flex flex-col items-start justify-start desktop:px-24 tablet:px-12 px-4">
                        <p className="tracking-wide text-center m-2 text-xs">
                            Recipients
                        </p>

                        <Select
                            styles={customStyles}
                            className="w-full"
                            options={recipientOptions}
                            value={recipientsSelected}
                            placeholder="Select recipients..."
                            onChange={handleSelectRecipient}
                            closeMenuOnSelect={false}
                            isSearchable={true}
                            isClearable={true}
                            isMulti={true}
                        />
                    </span>

                    <span className="w-full flex flex-col items-start justify-start desktop:px-24 tablet:px-12 px-4 mt-2 mb-2">
                        <p className="tracking-wide text-center m-2 text-xs">
                            Additional comments
                        </p>

                        <textarea
                            onChange={handleMessageChange}
                            className="textarea textarea-primary w-full"
                            name="comments"
                            id="comments"
                            rows="5"
                            cols="33"
                        />
                    </span>

                    <div className="divider my-2" />
                    <span className="w-full flex flex-col items-end justify-start px-24 mt-4">
                        <label
                            className="btn btn-primary normal-case"
                            htmlFor="share-view-modal"
                            onClick={onShareClick}
                        >
                            Share
                        </label>
                    </span>
                </div>
            </div>
        </>
    );
};

export default ShareViewComponent;