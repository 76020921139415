import { Fragment, useEffect, useState } from 'react';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import MiniLineChartComponent from '../../charts/mini-line/mini-line-chart.component';
import LimitedDateRangeSelectorComponent, {
    limitedDateTextMap,
} from '../../limited-date-range-selector/limited-date-range-selector.component';

import LoadingComponent from '../../loading-component/loading-component';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';
import { ScaleIcon } from '@heroicons/react/24/solid';
import MiniBarChartComponent from '../../charts/mini-bar/mini-bar-chart.component';
import {
    widget_graph_box,
    widget_text_box,
} from '../../../utils/styles/tailwind-styles';
import { widgetService } from '../../../store/discover/widgets/widgets.services';
import NoData from '../../no-data/no-data.component';
import { objectService } from '../../../utils/object.util';

const ProfitTrend = ({
    rawData,
    plottableData,
    selectedDate,
    onDateChange,
}) => {
    useEffect(() => {}, [plottableData, selectedDate]);

    return (
        <>
            <div className={widget_text_box}>
                <div className="flex flex-col justify-evenly items-start gap-4 p-8">
                    <div className="text-lg text-base-content text-opacity-80">
                        Profit Value
                    </div>

                    {!objectService.checkObjectPopulated(rawData) ? (
                        <LoadingComponent />
                    ) : widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'profitTotal'
                      ) &&
                      widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'soldTotal'
                      ) &&
                      widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'profitPerc'
                      ) ? (
                        <>
                            <div className="stat-value text-2xl font-mono">
                                R
                                {rawData[selectedDate][
                                    'profitTotal'
                                ].toLocaleString()}
                                <span
                                    className={
                                        'font-light text-base-content text-opacity-80 text-xs ml-2'
                                    }
                                >
                                    [{limitedDateTextMap(selectedDate)}]
                                </span>
                            </div>

                            <div className="text-sm text-base-content text-opacity-80">
                                <span className="flex justify-start items-center">
                                    <span className="mx-2">
                                        <ScaleIcon className="w-5 h-5 text-primary" />
                                    </span>

                                    <span className="flex flex-wrap whitespace-pre-wrap justify-start items-center w-100">
                                        The profit % for the past
                                        <p className="font-bold mx-1">
                                            {limitedDateTextMap(selectedDate)}
                                        </p>
                                        is
                                        <p className="font-bold mx-1 font-mono text-success">
                                            {rawData[selectedDate][
                                                'profitPerc'
                                            ].toLocaleString()}
                                            %
                                        </p>
                                        with a{' '}
                                        <span className={'font-bold mx-1'}>
                                            profit value
                                        </span>{' '}
                                        of
                                        <p className="font-bold mx-1 font-mono text-primary">
                                            R
                                            {rawData[selectedDate][
                                                'profitTotal'
                                            ].toLocaleString()}
                                        </p>
                                        and a{' '}
                                        <span className={'font-bold mx-1'}>
                                            net sales value
                                        </span>{' '}
                                        of{' '}
                                        <p className="font-bold mx-1 font-mono text-primary">
                                            R
                                            {rawData[selectedDate][
                                                'soldTotal'
                                            ].toLocaleString()}
                                        </p>
                                    </span>
                                </span>
                            </div>
                        </>
                    ) : (
                        <NoData />
                    )}
                </div>
            </div>

            <div className={widget_graph_box}>
                {!objectService.checkObjectPopulated(plottableData) ? (
                    <PageLoadingComponent />
                ) : selectedDate === '1D' ? (
                    <MiniBarChartComponent
                        data={plottableData[selectedDate]}
                        className="max-h-52"
                    />
                ) : (
                    <MiniLineChartComponent
                        data={plottableData[selectedDate]}
                        className="max-h-52"
                    />
                )}

                {rawData && rawData['subAggregates'] && (
                    <div className="col-span-full">
                        <div className="divider my-1" />

                        <WidgetSubAggregates
                            subAggregates={rawData['subAggregates']}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ProfitTrend;
