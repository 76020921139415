export const BASE_FILTERS_ACTION_TYPES = {
    SET_FILTERS_SELECTED: 'base/filters/SET_FILTERS_SELECTED',

    CLEAR_FILTER_SELECTED: 'base/filters/CLEAR_FILTER_SELECTED',
    CLEAR_FILTERS_SELECTED: 'base/filters/CLEAR_FILTERS_SELECTED',

    RESET_FILTERS_SELECTED: 'base/filters/RESET_FILTERS_SELECTED',
};

export const BASE_FILTER_TYPES = {
    skulists: 'skulists',
    supplcategories: 'supplcategories',
    supplsubcategories: 'supplsubcategories',
    supplbrands: 'supplbrands',
    supplskus: 'supplskus',

    dists: 'dists',
    storelists: 'storelists',
    storetypes: 'storetypes',
    distregions: 'distregions',
    distpoints: 'distpoints',

    todate: 'todate',
    fromdate: 'fromdate',
};

export const BASE_FILTER_PLACEHOLDER_MAP = {
    skulists: 'Sku Lists',
    supplcategories: 'Category',
    supplsubcategories: 'Sub-Category',
    supplbrands: 'Brand',
    supplskus: 'Sku Code',

    dists: 'Retailer',
    storelists: 'Store Lists',
    storetypes: 'Store Type',
    distregions: 'Region',
    distpoints: 'Store',
};
