import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { muted_border, widget_full } from '../../utils/styles/tailwind-styles';
import {
    LOADING_COMPONENT_TYPES,
    LoadingComponent,
} from '../loading-component/loading-component';
import { navigationService } from '../../utils/navigation.util';

export const NotFound = ({ type, descr, className }) => {
    return (
        <div
            className={`${className} ${widget_full} ${muted_border} gap-x-6 w-full`}
        >
            <div className="col-span-2 flex flex-col justify-center items-center">
                <span
                    data-tip={`${type} Code`}
                    className="badge badge-error badge-lg tooltip cursor-copy font-bold"
                    onClick={() => navigationService.copyToClipboard(descr)}
                >
                    {<ExclamationTriangleIcon className="w-4 h-4 mr-1" />}
                    {descr}
                </span>
                <div className="divider my-1" />
                <p className={'text-base-content text-opacity-80 text-sm'}>
                    Not found in our masterdata records.
                </p>
            </div>

            <div className="col-span-3 flex justify-center items-center">
                <LoadingComponent
                    type={LOADING_COMPONENT_TYPES.PING}
                    size={150}
                    speed={2.2}
                />
            </div>
        </div>
    );
};

export default NotFound;