import { useDispatch, useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
} from '../../../utils/styles/global-styles';
import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import '../mini-chart.styles.css';
import { selectTooltipXPos } from '../../../store/trend/trend-tooltip.selectors';
import { useEffect, useRef } from 'react';
import {
    clearTooltipXPos,
    setTooltipXPos,
} from '../../../store/trend/trend-tooltip.actions';
import {
    GRAPH_FONT_FAMILY,
    GRAPH_FONT_SIZE,
} from '../mini-bar/mini-bar-chart.component';

ChartJS.register(CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

const miniLineChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
        tooltip: {
            enabled: true,
        },
    },
    interaction: {
        intersect: false,
        axis: 'x',
        mode: 'index',
    },
    scales: {
        // to remove the labels
        x: {
            ticks: {
                display: true,
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
            grid: {
                display: true,
                drawBorder: false,
            },
        },
        // to remove the y-axis labels
        y1: {
            type: 'linear',
            display: false,
            position: 'left',
        },
        y2: {
            type: 'linear',
            display: false,
            position: 'right',
            grid: {
                drawOnChartArea: false,
            },
            max: 0,
        },
        y3: {
            type: 'linear',
            display: false,
            position: 'right',
            grid: {
                drawOnChartArea: false,
            },
            max: 100,
            min: 0,
        },
        y4: {
            type: 'linear',
            display: false,
            position: 'right',
            grid: {
                drawOnChartArea: false,
            },
            min: 0,
        },
        y5: {
            type: 'linear',
            display: false,
            position: 'left',
            grid: {
                drawOnChartArea: true,
            },
            max: 100.5,
            min: -0.5,
        },
        y6: {
            type: 'linear',
            display: false,
            position: 'right',
        },
    },
};

const MiniLineChartComponent = ({
    data,
    showXAxis,
    className,
    showLegend = false,
}) => {
    const dispatch = useDispatch();

    const daisyTheme = useSelector(selectDaisyTheme);
    const tooltipXPos = useSelector(selectTooltipXPos);

    const chartRef = useRef(null);
    useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            const handleHover = (e) => {
                const point = chart.getElementsAtEventForMode(
                    e,
                    'index',
                    {
                        intersect: false,
                    },
                    true
                );

                if (point[0]) {
                    dispatch(setTooltipXPos(point[0].index));
                }
            };

            const handleLeave = (e) => {
                dispatch(clearTooltipXPos());
            };

            chart.canvas.onmousemove = handleHover;
            chart.canvas.onmouseout = handleLeave;
        }
    }, []);

    useEffect(() => {
        const chart = chartRef.current;
        if (chart) {
            const xTicks = chart.scales.x.ticks;

            if (tooltipXPos !== null && xTicks && xTicks.length > tooltipXPos) {
                chart.tooltip.setActiveElements([
                    {
                        datasetIndex: 0,
                        index: tooltipXPos,
                    },
                ]);
            } else {
                chart.tooltip.setActiveElements([]);
            }

            chart.update();
        }
    }, [tooltipXPos]);

    let options;
    if (!showXAxis) {
        options = {
            ...miniLineChartOptions,
            scales: {
                ...miniLineChartOptions['scales'],
                x: { ticks: { display: false } },
            },
        };
    } else {
        options = { ...miniLineChartOptions };
    }

    options = {
        ...options,
        scales: {
            ...options['scales'],
            y1: {
                ...options['scales']['y1'],
                grid: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
            },
            y2: {
                ...options['scales']['y2'],
                grid: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
            },
            y3: {
                ...options['scales']['y3'],
                grid: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
            },
            // RETURNS CUSTOM
            y8: {
                ...options['scales']['y1'],
                grid: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
            },
            y9: {
                ...options['scales']['y3'],
                grid: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
            },
            x: {
                ...options['scales']['x'],
                grid: {
                    display: true,
                    drawBorder: false,
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
            },
        },
    };

    if (showLegend) {
        options = {
            ...options,
            plugins: {
                legend: {
                    display: true,
                },
            },
        };
    }

    // console.log(data);

    return (
        data && (
            <Line
                ref={chartRef}
                className={className}
                id="chart"
                data={data}
                options={options}
            />
        )
    );
};

export default MiniLineChartComponent;
