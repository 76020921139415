import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import {
    STOCK_COVER_OPTIONS,
    STOCK_COVER_TYPES,
} from '../../../../store/base/filters/stock-cover/stock-cover.types';

import CompactFilterGroup from '../compact-filter-container/compact-filter-group.component';

import { generateSelectStylesDaisy } from '../../../../utils/styles/global-styles';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const StockCoverFilters = ({ onChange, visible, toggleOpen }) => {
    const [selected, setSelected] = useState(
        STOCK_COVER_TYPES.HIGHEST_DAYS_COVER
    );

    const daisyTheme = useSelector(selectDaisyTheme);

    const handleSelectChange = (event) => {
        setSelected(event);
        onChange(event);
    };

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const customStyles = generateSelectStylesDaisy(daisyTheme, windowSize);

    if (visible) {
        return (
            <div className="flex flex-col items-center my-2">
                <span className="mb-2 font-light text-sm">Sort By</span>
                <div>
                    <Select
                        styles={customStyles}
                        options={STOCK_COVER_OPTIONS}
                        className="w-56"
                        value={selected}
                        placeholder=""
                        onChange={handleSelectChange}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="flex justify-evenly items-center hover:cursor-pointer"
                onClick={toggleOpen}
            >
                <CompactFilterGroup
                    title="Sort By"
                    activeFilters={[selected]}
                />
            </div>
        );
    }
};

export default StockCoverFilters;
