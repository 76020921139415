import { glowing_border } from '../../../utils/styles/tailwind-styles';
import { apiService } from '../../../utils/engine/api.service';
import { Fragment, useEffect, useState } from 'react';
import {
    LOADING_COMPONENT_TYPES,
    LoadingComponent,
} from '../../../components/loading-component/loading-component';

const BillingPage = () => {
    const [sessionUrl, setSessionUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleBilling = async () => {
        setIsLoading(true);

        try {
            const response = await apiService.createStripeBillingSession();

            setSessionUrl(response);
            window.open(response, '_self');
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!sessionUrl) {
            handleBilling();
        }
    }, []);

    return (
        <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
            <div
                className={`card flex flex-col p-4 shadow-lg bg-base-300 bg-opacity-95 w-full max-w-xl justify-center items-center min-h-64 z-10 ${glowing_border}`}
            >
                {isLoading ? (
                    <LoadingComponent
                        type={LOADING_COMPONENT_TYPES.HELIX}
                        size={200}
                    />
                ) : (
                    <>
                        <div className="flex flex-wrap gap-1">
                            <span className="text-base-content text-opacity-80">
                                Please <strong>allow pop-ups</strong> in order
                                to use this feature. <br />
                                <div className="divider my-0" />
                                Alternatively, you may navigate to your
                                <a
                                    href={sessionUrl}
                                    className="btn btn-sm btn-primary font-bold text-sm py-0 mx-2 px-2"
                                    target={'_self'}
                                >
                                    billing portal
                                </a>
                                manually.
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BillingPage;
