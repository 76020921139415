import { engineService } from '../../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';
import { useEffect, useState } from 'react';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';

import {
    discover_grid,
    discover_page,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';

import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../../components/loading-component/loading-component';
import { PlusIcon } from '@heroicons/react/16/solid';

const ExclusionsPage = () => {
    const [exclusionsByType, setExclusionsByType] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setIsLoading(true);

        engineService
            .invoke(WEB_PROCESS_FLOWS.MASTERDATA, {
                action: 'getExclusions',
                payload: {},
            })
            .then((payload) => {
                if (payload && Object.keys(payload).length !== 0) {
                    createNotification(
                        `Retrieved Exclusions.`,
                        NOTIFICATION_TYPES.SUCCESS
                    );
                    setExclusionsByType(payload);
                    setSelectedTab(Object.keys(payload)[0] || '');
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    const includeItem = (index, code, type) => {
        setIsLoading(true);
        engineService
            .invoke(WEB_PROCESS_FLOWS.MASTERDATA, {
                action: 'removeExclusion',
                payload: {
                    perspective_type: type,
                    perspective_code: code,
                },
            })
            .then((response) => {
                if (response.message === 'Exclusion removed.') {
                    const updatedData = { ...exclusionsByType };
                    updatedData[type] = updatedData[type].filter(
                        (item) => item.perspective_code !== code
                    );
                    setExclusionsByType(updatedData);
                    setIsLoading(false);
                    createNotification(
                        `Re-included ${code}.`,
                        NOTIFICATION_TYPES.SUCCESS
                    );
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    const tabs = Object.keys(exclusionsByType);

    const Tabs = ({ tabs, selectedTab, onTabSelect }) => (
        <div className="tabs mb-2">
            {tabs.map((tab) => (
                <button
                    key={tab}
                    className={`btn btn-primary btn-sm shadow-inner text-xs btn-ghost normal-case text-base-content text-opacity-80 transition-all ease-in-out duration-300 ${
                        tab === selectedTab
                            ? 'font-bold bg-base-100 border border-primary border-opacity-10'
                            : 'text-opacity-50'
                    }`}
                    onClick={() => onTabSelect(tab)}
                >
                    {tab.toUpperCase()}
                </button>
            ))}
        </div>
    );

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <div
                    className={`card gap-2 w-full p-6 h-full col-span-full ${muted_border} bg-base-300`}
                >
                    <div className="flex justify-between items-center">
                        <span className="text-sm text-base-content text-opacity-80 font-bold">
                            Exclusion List
                        </span>
                    </div>
                    <div className="divider my-0" />
                    {isLoading ? (
                        <div className="flex w-full justify-center items-center">
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.TABLE_SKELETON}
                            />
                        </div>
                    ) : (
                        <>
                            <Tabs
                                tabs={tabs}
                                selectedTab={selectedTab}
                                onTabSelect={setSelectedTab}
                            />
                            <table className="table table-zebra w-full rounded-box text-sm tracking-normal">
                                <thead>
                                    <tr>
                                        <th className="w-1/4 normal-case tracking-normal font-normal">
                                            Type
                                        </th>
                                        <th className="w-1/4 normal-case tracking-normal font-normal">
                                            Code
                                        </th>
                                        <th className="w-1/4 normal-case tracking-normal font-normal">
                                            {selectedTab === 'rep'
                                                ? 'Name'
                                                : 'Description'}
                                        </th>
                                        <th className="w-1/4 normal-case tracking-normal font-normal">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {exclusionsByType[selectedTab]?.map(
                                        (item, index) => (
                                            <tr
                                                key={`${item.perspective_code}-${index}`}
                                            >
                                                <td className="">
                                                    <p className="font-bold w-full">
                                                        {item.perspective_type}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="font-bold w-full">
                                                        {item.perspective_code}
                                                    </p>
                                                </td>
                                                <td>
                                                    <p className="font-bold w-full">
                                                        {selectedTab === 'rep'
                                                            ? item.name
                                                            : item.description}
                                                    </p>
                                                </td>
                                                <td>
                                                    <span
                                                        className="btn btn-xs btn-outline btn-primary gap-1 tooltip tooltip-left font-bold w-32"
                                                        onClick={() =>
                                                            includeItem(
                                                                index,
                                                                item.perspective_code,
                                                                item.perspective_type
                                                            )
                                                        }
                                                        data-tip={`Re-include ${item.perspective_type}?`}
                                                    >
                                                        <PlusIcon
                                                            className={
                                                                'w-4 h-4'
                                                            }
                                                        />
                                                        Re-include
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                            {exclusionsByType[selectedTab]?.length === 0 && (
                                <div className="flex w-full flex-1 justify-center m-5 text-base-content text-opacity-60 text-sm">
                                    <span className="text-base-content text-opacity-80 text-sm">
                                        No exclusions found.
                                    </span>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExclusionsPage;