import styled from 'styled-components';
import {
    GLOBAL_COLOURS,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';

export const TableContainer = styled.div`
    width: 100%;
    border-radius: ${GLOBAL_STYLES['radius-larger']};
    padding: 1rem;
    background-color: ${(props) => props.background};

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 10rem;
`;

export const TableContainer2 = styled.div`
    width: 100%;
    border-radius: ${GLOBAL_STYLES['radius-larger']};
    padding: 1rem;
    background-color: ${(props) => props.background};

    display: flex;
    justify-content: start;
    align-items: center;

    min-height: 10rem;
    height: fit-content;
`;

export const NarrowTableContainer = styled.div`
    width: 100%;
    max-width: 75vw;

    @media (max-width: 1366px) {
        max-width: 90vw;
    }

    @media (max-width: 1200px) {
        max-width: 100vw;
    }

    border: 1px solid ${GLOBAL_COLOURS['dark']['text-medium']};
    padding: 1rem;
    background-color: ${GLOBAL_COLOURS['dark']['bg-page']};

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 10rem;
`;

export const SkuTableStyle = styled.table`
    width: 100%;
    border-collapse: collapse;

    overflow-y: scroll;
    overflow-x: scroll;
`;

export const TableDataRow = styled.tr`
    width: 100%;
    height: 100%;

    font-size: 0.85rem;
    //border: 1px solid #00000000;

    &:hover td {
        background-color: ${(props) => props.backgroundhover};
        // border: 1px solid ${(props) => props.backgroundhover};
        cursor: ${(props) => (props.nocursor ? 'default' : 'pointer')};
    }
`;

export const TableHeadingRow = styled.th`
    padding: 1rem;
    font-size: 1.1rem;

    letter-spacing: 0.05rem;

    font-weight: lighter;

    @media (max-width: 1366px) {
        font-size: 0.9rem;
    }

    @media (max-width: 768px) {
        font-size: 0.7rem;
    }

    @media (max-width: 568px) {
        font-size: 0.5rem;
    }
`;

export const TableDataCell = styled.td`
    padding: 1rem;

    @media (max-width: 768px) {
        padding: 0.55rem;
        font-size: 0.65rem;
    }

    @media (max-width: 568px) {
        padding: 0.45rem;
        font-size: 0.45rem;
    }
`;

export const MiniTableDataRow = styled.tr`
    width: 100%;
    height: 100%;

    border-radius: ${GLOBAL_STYLES['radius-small']};
    font-size: 0.85rem;

    &:hover td {
        background-color: ${(props) => props.backgroundhover};
        // border: 1px solid ${(props) => props.backgroundhover};
        cursor: ${(props) => (props.nocursor ? 'default' : 'pointer')};
    }
`;

export const MiniTableHeadingRow = styled.th`
    padding: 0.25rem;
    font-size: 1rem;

    letter-spacing: 0.05rem;

    font-weight: lighter;

    @media (max-width: 1366px) {
        font-size: 0.9rem;
    }

    @media (max-width: 768px) {
        font-size: 0.7rem;
    }

    @media (max-width: 568px) {
        font-size: 0.5rem;
    }
`;

export const MiniTableDataCell = styled.td`
    padding: 0.25rem;
    font-size: 0.75rem;

    @media (max-width: 768px) {
        padding: 0.25rem;
        font-size: 0.7rem;
    }

    @media (max-width: 568px) {
        padding: 0.25rem;
        font-size: 0.65rem;
    }
`;

export const FocusSkuContainer = styled.div`
    width: 100%;

    background-color: ${(props) => props.background};
    border-radius: ${GLOBAL_STYLES['radius-larger']};
    color: ${(props) => props.foreground};
    padding: 1rem 2rem 2rem 2rem;

    text-align: end;

    display: grid;
    grid-template-columns: repeat(1fr, 6);

    justify-content: space-evenly;
    align-items: center;
`;

export const FocusSkuDetail = styled.div`
    display: flex;

    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;

    font-size: 0.85rem;
`;
