export const dateOptions = [
    '1D',

    // '7D',
    // '1M',
    // '6M',

    'MTD',

    // '12W',

    'YTD',

    // '1Y',
];

export function limitedDateTextMap(dateKey) {
    let map = {
        '1D': 'one day',
        '7D': 'seven days',
        '2W': 'two weeks',
        MTD: 'this month',
        '1M': 'month',
        '6M': 'six months',
        '12W': 'twelve weeks',
        YTD: 'this year',
        '1Y': 'year',
        '2Y': 'two years',
    };
    return map[dateKey];
}

const LimitedDateRangeSelectorComponent = ({
    onChange,
    selectedDate,
    className,
    compact,
}) => {
    const handleItemClick = (item) => {
        onChange(item);
    };

    return (
        <div
            className={`gap-0 w-full max-w-full  ${className} ${
                compact
                    ? 'grid grid-cols-3 gap-0 place-items-center justify-items-center'
                    : 'flex items-center flex-wrap overflow-x-clip '
            }`}
        >
            {dateOptions.map((key) => {
                return (
                    <div
                        className={`${
                            compact && 'w-full'
                        } btn btn-primary btn-sm grow shadow-inner text-xs btn-ghost normal-case text-base-content text-opacity-80 transition-all ease-in-out duration-300 ${
                            key === selectedDate
                                ? 'font-bold bg-base-100 border border-primary border-opacity-10'
                                : 'text-opacity-50'
                        }`}
                        key={key}
                        onClick={() => handleItemClick(key)}
                    >
                        {key}
                    </div>
                );
            })}
        </div>
    );
};

export default LimitedDateRangeSelectorComponent;