import { convertChaosScoresToPlottable } from '../../../utils/dev-data/dev-data';
import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { useEffect, useState } from 'react';
import MiniRadarChartComponent from '../../charts/mini-radar/mini-radar-chart.component';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import ChaosElement from './chaos.element.component';
import ChaosHelp from './chaos-help.component';
import { navigationService } from '../../../utils/navigation.util';

const ChaosRank = ({ rankData, aggregationMetric }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    useEffect(() => {}, [daisyTheme]);

    return (
        <div className="col-span-full w-full h-full grid grid-cols-2 place-items-center justify-items-center gap-2">
            {rankData.map((chaos, index) => {
                const radarScores = convertChaosScoresToPlottable(
                    chaos['chaos_pillars'],
                    daisyTheme
                );

                return (
                    <span
                        key={index}
                        className={`col-span-2 flex flex-col items-start justify-center w-full rounded-box shadow-sm p-2 gap-2`}
                    >
                        <a
                            className="btn btn-ghost text-base normal-case hover:underline tooltip flex items-center justify-center text-center"
                            target={'_blank'}
                            href={navigationService.buildUrlByAggMetric(
                                aggregationMetric,
                                chaos
                            )}
                            data-tip={`Go to ${chaos.descr}..`}
                        >
                            <span className="font-bold mr-1">{index + 1}.</span>
                            {chaos.descr &&
                                chaos.descr.replace('-', ' ').slice(0, 48)}
                            {chaos.descr && chaos.descr.length > 48 && '..'}
                        </a>

                        <div className="flex w-full justify-evenly items-center">
                            <div className="w-full flex flex-col items-center justify-center gap-4">
                                <MiniRadarChartComponent
                                    className={'max-h-40'}
                                    data={radarScores}
                                />
                            </div>

                            <div className="w-full flex flex-col items-center justify-center gap-2">
                                <ChaosElement
                                    value={
                                        Math.round(
                                            (100 *
                                                Object.keys(
                                                    chaos['chaos_pillars']
                                                )
                                                    .map(
                                                        (el) =>
                                                            chaos[
                                                                'chaos_pillars'
                                                            ][el]
                                                    )
                                                    .reduce(
                                                        (a, b) => a + b,
                                                        0
                                                    )) /
                                                Object.keys(
                                                    chaos['chaos_pillars']
                                                ).length
                                        ) / 100
                                    }
                                    element={'Overall'}
                                    overall={true}
                                />

                                <div className="divider my-0" />

                                {Object.keys(chaos['chaos_pillars']).map(
                                    (pillar) => {
                                        return (
                                            <ChaosElement
                                                key={pillar}
                                                value={
                                                    chaos['chaos_pillars'][
                                                        pillar
                                                    ]
                                                }
                                                element={pillar}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </span>
                );
            })}
        </div>
    );
};

export default ChaosRank;