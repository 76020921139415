import React, { useEffect, useState } from 'react';
import { useSubscription } from 'react-stomp-hooks';
import { useSelector } from 'react-redux';
import { selectUserToken } from '../store/auth/authentication.selectors';

export const SocketSubscriptionComponent = (props) => {
    const [lastMessage, setLastMessage] = useState('No message received yet');

    //Subscribe to /topic/test, and use handler for all received messages
    //Note that all subscriptions made through the library are automatically removed when their owning component gets unmounted.
    //If the STOMP connection itself is lost they are however restored on reconnect.
    //You can also supply an array as the first parameter, which will subscribe to all destinations in the array
    useSubscription(
        `/${props.path}/${props.topic}`,
        (message) => {
            if (props.callback) {
                props.callback(message.body);
            } else {
                console.log('-------');
                console.log(props.topic);
                console.log(message);
                console.log(message.body);
                setLastMessage(message);
                console.log('-------');
            }
        },
        {
            user: props.token,
            token: props.token,
        }
    );

    useEffect(() => {}, []);

    return <></>;
};