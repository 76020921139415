import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogoutMenu from '../../logout-menu-component/logout-menu.component';
import DiscoverSearch from '../discover-search/discover-search.component';

import {
    selectThemeColor,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';
import {
    selectSidebarActive,
    selectSidebarMode,
} from '../../../store/navigation/navigation.selectors';
import { clearProductType } from '../../../store/product/product.actions';

import { authenticationService } from '../../../store/auth/authentication.services';

import '../../navigation-bar-component/navigation-bar.styles.css';
import { Link, useLocation } from 'react-router-dom';
import {
    sidebar_active,
    sidebar_inactive,
} from '../../../utils/styles/tailwind-styles';

const PRDiscoverNavigation = () => {
    const sidebarMode = useSelector(selectSidebarMode);
    const sidebarActive = useSelector(selectSidebarActive);
    const location = useLocation();

    useEffect(() => {}, [sidebarMode]);

    return (
        <div
            className={
                !sidebarMode
                    ? 'flex justify-evenly items-center text-center'
                    : sidebarActive
                    ? sidebar_active
                    : sidebar_inactive
            }
        >
            <DiscoverSearch
                className={`${sidebarMode ? 'my-2 w-full' : 'mr-4 '} `}
            />

            <Link
                className={`btn btn-ghost normal-case hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/discover/home' ||
                              location.pathname === '/discover/dashboard'
                                  ? 'font-bold'
                                  : 'font-normal'
                          }`
                        : `flex justify-between items-center px-4 ${
                              location.pathname === '/discover/home' ||
                              location.pathname === '/discover/dashboard'
                                  ? 'font-bold'
                                  : 'font-normal'
                          }`
                }`}
                to="/discover/home"
                id={'discover-nav-home-link'}
            >
                Home
            </Link>

            {/*TODO: Version 1.1*/}
            {/*<Link*/}
            {/*    className={`btn btn-ghost normal-case hover:text-primary ${*/}
            {/*        sidebarMode*/}
            {/*            ? `flex items-center justify-start py-3 w-full ${*/}
            {/*                  location.pathname === '/discover/discover'*/}
            {/*                      ? 'font-bold'*/}
            {/*                      : 'font-normal'*/}
            {/*              }`*/}
            {/*            : `flex justify-between items-center px-4 ${*/}
            {/*                  location.pathname === '/discover/discover'*/}
            {/*                      ? 'font-bold'*/}
            {/*                      : 'font-normal'*/}
            {/*              }`*/}
            {/*    }`}*/}
            {/*    to="/discover/discover"*/}
            {/*    id={'discover-nav-discover-link'}*/}
            {/*>*/}
            {/*    Discover*/}
            {/*</Link>*/}

            {/*TODO: Version 1.1*/}
            {/*<Link*/}
            {/*    className={`btn btn-ghost normal-case hover:text-primary ${*/}
            {/*        sidebarMode*/}
            {/*            ? `flex items-center justify-start py-3 w-full ${*/}
            {/*                  location.pathname === '/discover/marketing'*/}
            {/*                      ? 'font-bold'*/}
            {/*                      : 'font-normal'*/}
            {/*              }`*/}
            {/*            : `flex justify-between items-center px-4 ${*/}
            {/*                  location.pathname === '/discover/marketing'*/}
            {/*                      ? 'font-bold'*/}
            {/*                      : 'font-normal'*/}
            {/*              }`*/}
            {/*    }`}*/}
            {/*    to="/discover/marketing"*/}
            {/*    id={'discover-nav-marketing-link'}*/}
            {/*>*/}
            {/*    Marketing*/}
            {/*</Link>*/}

            <Link
                className={`btn btn-ghost normal-case hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/discover/watchlist'
                                  ? 'font-bold'
                                  : 'font-normal'
                          }`
                        : `flex justify-between items-center px-4 ${
                              location.pathname === '/discover/watchlist'
                                  ? 'font-bold'
                                  : 'font-normal'
                          }`
                }`}
                to="/discover/watchlist"
                id={'discover-nav-watchlist-link'}
            >
                Watchlist
            </Link>

            <LogoutMenu />
        </div>
    );
};

export default PRDiscoverNavigation;