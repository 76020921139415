import { dateOptions } from '../../components/limited-date-range-selector/limited-date-range-selector.component';
import { faker } from '@faker-js/faker';
import {
    addOpacity,
    DAISY_THEME_MAP,
    GRAPH_OPACITY,
    graphColourSelector,
} from '../styles/global-styles';
import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_METRICS,
} from '../../store/discover/widgets/widgets.types';

export const generateFakeTopChaosScores = (min, max) => {
    const fakeChaos = {};

    dateOptions.forEach((option) => {
        if (!fakeChaos[option]) fakeChaos[option] = {};

        Object.keys(stubTopChaos).forEach((aggregationMetric) => {
            const candidates = stubTopChaos[aggregationMetric];

            if (!fakeChaos[option][aggregationMetric])
                fakeChaos[option][aggregationMetric] = [];

            candidates.forEach((candidate) => {
                fakeChaos[option][aggregationMetric].push({
                    ...candidate,
                    chaos: {
                        // 'Unused Credit': faker.datatype.number({
                        //     min,
                        //     max,
                        // }),
                        Discount: faker.datatype.number({
                            min,
                            max,
                        }),
                        Returns: faker.datatype.number({
                            min,
                            max,
                        }),
                        Profit: faker.datatype.number({
                            min,
                            max,
                        }),
                        Sales: faker.datatype.number({
                            min,
                            max,
                        }),
                    },
                });
            });
        });
    });

    return fakeChaos;
};

export const generateFakeWorstChaosScores = (min, max) => {
    const fakeChaos = {};

    dateOptions.forEach((option) => {
        if (!fakeChaos[option]) fakeChaos[option] = {};

        Object.keys(stubBottomChaos).forEach((aggregationMetric) => {
            const candidates = stubBottomChaos[aggregationMetric];

            if (!fakeChaos[option][aggregationMetric])
                fakeChaos[option][aggregationMetric] = [];

            candidates.forEach((candidate) => {
                fakeChaos[option][aggregationMetric].push({
                    ...candidate,
                    chaos: {
                        // 'Unused Credit': faker.datatype.number({
                        //     min,
                        //     max,
                        // }),
                        Discount: faker.datatype.number({
                            min,
                            max,
                        }),
                        Returns: faker.datatype.number({
                            min,
                            max,
                        }),
                        Profit: faker.datatype.number({
                            min,
                            max,
                        }),
                        Sales: faker.datatype.number({
                            min,
                            max,
                        }),
                    },
                });
            });
        });
    });

    return fakeChaos;
};

export const generateStubLineChartData = (daisyTheme) => {
    const lineStubLabels = [
        '-7 days',
        '-6 days',
        '-5 days',
        '-4 days',
        '-3 days',
        '-2 days',
        '-1 days',
        '-0 days',
    ];

    const color1 = graphColourSelector(0, daisyTheme);
    const color2 = graphColourSelector(1, daisyTheme);

    return {
        labels: lineStubLabels,
        datasets: [
            {
                label: 'Dataset 1',
                data: lineStubLabels.map(() =>
                    faker.datatype.number({ min: 0, max: 2000 })
                ),
                fill: {
                    target: 'origin',
                    above: addOpacity(color1.backgroundColor, GRAPH_OPACITY),
                },

                borderColor: color1.borderColor,
                borderWidth: 0.5,
                backgroundColor: addOpacity(
                    color1.backgroundColor,
                    GRAPH_OPACITY
                ),
                tension: 0.2,

                pointRadius: 1,
                pointHoverRadius: 2,
                pointBorderWidth: 0.5,
                pointHoverBorderWidth: 0.5,
                yAxisID: 'y1',
            },
            {
                label: 'Dataset 2',
                data: lineStubLabels.map(() =>
                    faker.datatype.number({ min: 0, max: 1000 })
                ),
                fill: {
                    target: 'origin',
                    above: addOpacity(color2.backgroundColor, GRAPH_OPACITY),
                },

                borderColor: color2.borderColor,
                borderWidth: 0.5,
                backgroundColor: addOpacity(
                    color2.backgroundColor,
                    GRAPH_OPACITY
                ),
                tension: 0.2,

                pointRadius: 1,
                pointHoverRadius: 2,
                pointBorderWidth: 0.5,
                pointHoverBorderWidth: 0.5,
                yAxisID: 'y1',
            },
        ],
    };
};

export const generateStubBarChartData = (daisyTheme) => {
    const lineStubLabels = [
        '-7 days',
        '-6 days',
        '-5 days',
        '-4 days',
        '-3 days',
        '-2 days',
        '-1 days',
        '-0 days',
    ];

    return {
        labels: lineStubLabels,
        datasets: [
            {
                label: 'Dataset 1',
                data: lineStubLabels.map(() =>
                    faker.datatype.number({ min: 0, max: 2000 })
                ),
                borderColor: DAISY_THEME_MAP[daisyTheme]['primary'],
                backgroundColor: addOpacity(
                    DAISY_THEME_MAP[daisyTheme]['primary'],
                    GRAPH_OPACITY
                ),
                xAxisID: 'x3',
                yAxisID: 'y2',
            },
            {
                label: 'Dataset 2',
                data: lineStubLabels.map(() =>
                    faker.datatype.number({ min: 0, max: 2000 })
                ),
                borderColor: DAISY_THEME_MAP[daisyTheme]['secondary'],
                backgroundColor: addOpacity(
                    DAISY_THEME_MAP[daisyTheme]['secondary'],
                    GRAPH_OPACITY
                ),
                xAxisID: 'x3',
                yAxisID: 'y2',
            },
        ],
    };
};

export const convertChaosScoresToPlottable = (
    chaosScores,
    daisyTheme,
    top = true
) => {
    let backgroundColor;
    let borderColor;

    backgroundColor = top
        ? addOpacity(DAISY_THEME_MAP[daisyTheme]['primary'], GRAPH_OPACITY)
        : addOpacity(DAISY_THEME_MAP[daisyTheme]['error'], GRAPH_OPACITY);
    borderColor = top
        ? DAISY_THEME_MAP[daisyTheme]['primary']
        : DAISY_THEME_MAP[daisyTheme]['error'];

    return {
        labels: Object.keys(chaosScores).map((el) =>
            el === DISCOVER_WIDGET_DATA_METRICS.RETURNS ? 'non-returns' : el
        ),
        datasets: [
            {
                label: 'Bucket',
                // label: 'Decile',
                // label: 'Percentile',

                data: Object.keys(chaosScores).map((el) => chaosScores[el]),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 0.5,
                pointRadius: 5,
                hoverRadius: 10,
            },
        ],
    };
};

const stubTopChaos = {
    customers: [
        {
            code: '72020',
            descr: 'Matril Technology (Gauteng)',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
        },
        {
            code: 'WEIKIN',
            descr: 'Super Electronics Benoni',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
        },
        {
            code: 'JUMBEL',
            descr: 'Super Electronics Wholesale',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
        },
    ],
    skus: [
        {
            code: '999997GHN',
            descr: 'Megacorp Pro Gaming Chair',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
        },
        {
            code: '999999MNB',
            descr: 'Megacorp Pro Gaming Desk',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
        },
        {
            code: '999996ZAQ',
            descr: '22" FHD Monitor',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
        },
    ],
    reps: [
        {
            code: '4',
            descr: 'Morgan Johnston',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
        },
        {
            code: '5',
            descr: 'Alphonso Baldwin',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
        },
        {
            code: '7',
            descr: 'Hunter McKenzie',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
        },
    ],
};

const stubBottomChaos = {
    customers: [
        {
            code: '72020',
            descr: 'Matril Technology (Gauteng)',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
        },
        {
            code: 'WEIKIN',
            descr: 'Super Electronics Benoni',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
        },
        {
            code: 'JUMBEL',
            descr: 'Super Electronics Wholesale',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
        },
    ],
    skus: [
        {
            code: '999990VFD',
            descr: '92xa Pro Cellphone Cover w/ Screen Protector',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
        },
        {
            code: 'ACS077NHY',
            descr: 'Earbud Rubber Inserts 4pc',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
        },
        {
            code: 'ACT001CDE',
            descr: 'Standard USB C Charging Cable',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
        },
    ],
    reps: [
        {
            code: '8',
            descr: 'Siphuzo Ngomozulu',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
        },
        {
            code: '6',
            descr: 'Jeremy Evans',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
        },
        {
            code: '1',
            descr: 'Johnny Harris',
            type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
        },
    ],
};

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
