import { discover_page } from '../../../../utils/styles/tailwind-styles';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';

const MarketingPage = () => {
    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div
                className={`w-full h-full max-w-7xl grid grid-cols-12 items-start justify-start gap-2`}
            ></div>
        </div>
    );
};

export default MarketingPage;