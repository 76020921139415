import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';

import { getWindowSize } from '../../base/filters/trend-filters/trend-filters.component';
import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';

import {
    backdrop,
    enlarged,
    enlarged_chart,
    regular_chart,
} from '../../../utils/styles/tailwind-styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const stackedChartOptions = {
    indexAxis: 'x',
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 30,
                pointStyle: 'rect',
                usePointStyle: true,
            },
        },
        title: {
            display: false,
        },
    },
    interaction: {
        intersect: false,
        axis: 'x',
        mode: 'index',
    },
    scales: {
        x: {
            stacked: true,
            grid: {
                display: true,
                drawBorder: false,
                color: GLOBAL_COLOURS['dark']['bg-page'],
            },
        },
        y: {
            stacked: true,
            grid: {
                display: true,
                drawBorder: false,
                color: GLOBAL_COLOURS['dark']['bg-page'],
            },
        },
    },
};

const StackedChartComponent = ({
    data,
    title,
    interactionMode,
    onClickHideOthers,
    type,
}) => {
    const [isEnlarged, setIsEnlarged] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const daisyTheme = useSelector(selectDaisyTheme);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    let options;
    if (!interactionMode) options = { ...stackedChartOptions };
    else
        options = {
            ...stackedChartOptions,
            interaction: {
                ...stackedChartOptions['interaction'],
                mode: interactionMode,
            },
        };

    const newClickHandler = function (e, legendItem) {
        let datasetIndex = legendItem.datasetIndex;
        let ci = this.chart,
            metaSets = [];

        for (let i = 0; i < ci.data.datasets.length; i++) {
            metaSets.push(ci.getDatasetMeta(i));
        }

        let reset = false;
        metaSets.forEach(function (meta) {
            let checkAgainst;
            if (meta.index > 0) {
                checkAgainst = metaSets[meta.index - 1];
            } else {
                checkAgainst = metaSets[meta.index + 1];
            }

            if (checkAgainst.hidden) {
                if (meta.index === datasetIndex && meta.hidden === false) {
                    reset = true;
                }
            }
        });

        if (reset) {
            metaSets.forEach(function (meta) {
                meta.hidden = false;
            });
        } else {
            metaSets.forEach(function (meta) {
                if (meta.hidden) {
                    meta.hidden = false;
                }
                meta.hidden = meta.index !== datasetIndex;
            });
        }
        ci.update();
    };

    const defaultClickHandler = function (e, legendItem) {
        let datasetIndex = legendItem.datasetIndex;
        let ci = this.chart,
            metaSets = [];

        for (let i = 0; i < ci.data.datasets.length; i++) {
            metaSets.push(ci.getDatasetMeta(i));
        }

        metaSets.forEach(function (meta) {
            meta.hidden =
                meta.index === datasetIndex ? !meta.hidden : meta.hidden;
        });

        ci.update();
    };

    if (onClickHideOthers) {
        options['plugins']['legend']['onClick'] = newClickHandler;
    } else {
        options['plugins']['legend']['onClick'] = defaultClickHandler;
    }

    options = {
        ...options,
        plugins: {
            ...options['plugins'],
            legend: {
                ...options['plugins']['legend'],
                labels: {
                    ...options['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['text'],
                },
            },
        },
        scales: {
            ...options['scales'],
            x: {
                stacked: true,
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y: {
                stacked: true,
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
    };

    const toggleEnlarged = () => {
        setIsEnlarged(!isEnlarged);
        if (!isEnlarged) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            {isEnlarged ? (
                <div className={backdrop}>
                    <div className={enlarged_chart} id={'enlarged-chart'}>
                        <div className="flex justify-between items-center w-full px-2">
                            <div className="flex items-center">
                                <h3 className="text-primary font-bold text-base">
                                    {title}
                                </h3>

                                {type && (
                                    <>
                                        <div className="divider divider-horizontal mx-0" />
                                        <span className="badge badge-primary font-bold">
                                            {type}
                                        </span>
                                    </>
                                )}
                            </div>

                            <span className="btn btn-sm btn-ghost btn-circle">
                                <ArrowsPointingInIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </span>
                        </div>

                        <div className="divider my-3" />

                        <Bar
                            style={{
                                maxHeight: '85%',
                            }}
                            id="chart"
                            data={data}
                            options={options}
                        />
                    </div>
                </div>
            ) : (
                <div className={regular_chart}>
                    <div className="flex justify-between items-center w-full px-2">
                        <div className="flex items-center">
                            <h3 className="text-primary font-bold text-base">
                                {title}
                            </h3>

                            {type && (
                                <>
                                    <div className="divider divider-horizontal mx-0" />
                                    <span className="badge badge-primary font-bold">
                                        {type}
                                    </span>
                                </>
                            )}
                        </div>

                        {windowSize['innerWidth'] > 1366 ? (
                            <a
                                className="btn btn-sm btn-ghost btn-circle"
                                href={'#enlarged-chart'}
                            >
                                <ArrowsPointingOutIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </a>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="divider my-3" />
                    <Bar id="chart" data={data} options={options} />
                </div>
            )}
        </>
    );
};

export default StackedChartComponent;