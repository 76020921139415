import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSidebarActive,
    selectSidebarMode,
} from '../../../store/navigation/navigation.selectors';

import '../navigation-bar.styles.css';
import ThemeComponent from '../../theme-component/theme.component';
import { useLocation } from 'react-router-dom';

const DefaultNavigation = () => {
    const sidebarMode = useSelector(selectSidebarMode);
    const sidebarActive = useSelector(selectSidebarActive);

    const location = useLocation();

    useEffect(() => {}, [sidebarMode]);

    return (
        <div
            className={
                !sidebarMode
                    ? 'flex justify-evenly items-center text-center'
                    : sidebarActive
                    ? 'bg-base-300 drop-shadow absolute top-full right-0 min-h-screen w-64 flex flex-col justify-start items-end p-3 transition-all visible'
                    : 'bg-base-300 drop-shadow absolute top-full right-0 min-h-screen w-64 flex flex-col justify-start items-end p-3 transition-all invisible'
            }
        >
            {location.pathname !== '/subscribe' && (
                <ThemeComponent
                    className={`${sidebarMode ? 'w-full' : 'w-40'}`}
                />
            )}
        </div>
    );
};

export default DefaultNavigation;