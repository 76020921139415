import { XMarkIcon } from '@heroicons/react/24/solid';
import { formula_box, help_box } from '../../../utils/styles/tailwind-styles';

import React, { Fragment } from 'react';

const ProfitHelp = ({ onClose }) => {
    return (
        <>
            <div className="flex flex-col gap-2  text-sm">
                <span className="badge badge-lg badge-secondary mt-2">
                    Calculation
                </span>

                <p className="px-2">
                    We calculate an estimate of profit margins by utilizing
                    product cost data which lies within your product masterdata.
                    First, we use the average selling price of a product,
                    calculated using invoice data from within the selected time
                    period, and subtract the item cost from that to find an
                    estimated profit value.
                </p>

                <span className={formula_box}>
                    {'\\(profit_k = MSP_k - CP_k \\)'}
                </span>

                <p className="px-2">
                    where <strong>MSP</strong> is mean selling price, and{' '}
                    <strong>CP</strong> is cost price.
                </p>

                <span className="badge badge-lg badge-accent mt-2">
                    Ranking
                </span>
                <p className="px-2">
                    The <strong>Profit Ranking</strong> widget provides a ranked
                    view of profit for the current period in terms of mean
                    profit margin
                    <span className="badge-md badge-secondary mx-2 bg-opacity-60" />{' '}
                    and average profit value
                    <span className="badge-md badge-primary mx-2 bg-opacity-60" />
                </p>
            </div>
        </>
    );
};

export default ProfitHelp;