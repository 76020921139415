import CompactSingleFilterContainer from './compact-single-filter.component';

const CompactFilterGroup = ({ title, activeFilters, className }) => {
    return (
        <div
            className={`flex justify-start text-start text-xs items-center ${className}`}
        >
            <h4 className="font-bold w-14 mr-2 text-start"> {title} </h4>
            <div className="flex flex-wrap items-center justify-start w-full max-w-full">
                {Array.isArray(activeFilters) ? (
                    activeFilters.map((activeFilter) => (
                        <CompactSingleFilterContainer
                            key={activeFilter.value}
                            label={activeFilter.label}
                        />
                    ))
                ) : (
                    <CompactSingleFilterContainer
                        key={activeFilters.value}
                        label={activeFilters.label}
                    />
                )}
            </div>
        </div>
    );
};

export default CompactFilterGroup;
