import { FaceFrownIcon } from '@heroicons/react/24/solid';

const PageNotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar">
            <div className="flex justify-center items-center py-3">
                <h1 className="text-7xl">4</h1>
                <FaceFrownIcon className="w-16   h-16 animate-bounce" />
                <h1 className="text-7xl">4</h1>
            </div>

            <p className="text-base">
                Sorry, looks like that page doesn't exist.
            </p>
        </div>
    );
};

export default PageNotFound;