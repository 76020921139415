import { engineService } from '../../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';

import {
    addOpacity,
    GLOBAL_COLOURS,
    graphColourSelector,
} from '../../../../utils/styles/global-styles';

export const stockCoverService = {
    loadStockCoverData,
    convertCoverDataToPlottable,
    convertFocusDataToPlottable,
};

async function loadStockCoverData(selectedFilters, identifyOption, focusSku) {
    const newSelectedFilters = { ...selectedFilters };
    delete newSelectedFilters['fromDate'];
    delete newSelectedFilters['toDate'];

    const response = await engineService.invoke(WEB_PROCESS_FLOWS.STOCK_COVER, {
        selectedFilters: newSelectedFilters,
        identifyOption: identifyOption,
        focusSku: focusSku,
    });
    return response;
}

function convertCoverDataToPlottable(coverData, daisyTheme) {
    let dataLabel;
    let data = [];
    let dataKeys = [];
    for (const key in coverData['rows'][0]) {
        if (key === 'name') dataLabel = key;
        else {
            dataKeys.push(key);
            data.push(coverData['rows'][0][key]);
        }
    }

    const map = {
        '#28a745': GLOBAL_COLOURS['accents']['emerald-dark'],
        '#ffc107': GLOBAL_COLOURS['accents']['amber-dark'],
        '#ff7f07': GLOBAL_COLOURS['accents']['slate-dark'],
        '#dc3545': GLOBAL_COLOURS['accents']['rose-dark'],
    };

    const borderColors = coverData['channels'].map(
        (item) => map[item['color']]
    );
    const backgroundColors = coverData['channels'].map((item) =>
        addOpacity(map[item['color']], 0.75)
    );
    const hoverBackgroundColors = coverData['channels'].map((item) =>
        addOpacity(map[item['color']], 0.75)
    );

    return {
        labels: dataKeys,
        datasets: [
            {
                label: dataLabel,
                data: data,
                borderWidth: 3,
                hoverBorderWidth: 6,

                backgroundColor: backgroundColors,
                borderColor: borderColors,

                hoverBackgroundColor: hoverBackgroundColors,
            },
        ],
    };
}

function convertFocusDataToPlottable(focusData, daisyTheme) {
    const unformattedDatasets = focusData['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = focusData['rows'].map((row) => row.name);

    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: dataset,
                data: focusData['rows'].map((row) => row[dataset]),
                borderColor,
                backgroundColor: addOpacity(backgroundColor, 0.75),

                pointRadius: 4,
                pointHoverRadius: 8,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 0.5,
                pointBorderColor: borderColor,
                pointBackgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}
