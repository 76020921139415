import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { selectTrendFilters } from '../../../../store/base/filters/trend/trend.selectors';
import { selectUserToken } from '../../../../store/auth/authentication.selectors';
import { ALERT_FILTER_TYPES } from '../../../../store/base/filters/alerts/alerts.types';
import { alertsService } from '../../../../store/base/filters/alerts/alerts.services';

import TrendFiltersComponent from '../../../../components/base/filters/trend-filters/trend-filters.component';
import FiltersContainer from '../../../../components/base/filters/filters-container.component';
import ShareViewComponent, {
    onShareViewClick,
} from '../../../../components/base/share-view-component/share-view.component';

import GenerateReportComponent from '../../../../components/base/generate-report-component/generate-report.component';
import LineChartComponent from '../../../../components/charts/line-chart-component/line-chart.component';
import StackedChartComponent from '../../../../components/charts/stacked-chart-component/stacked-chart.component';
import NoData from '../../../../components/no-data/no-data.component';

import { apiService } from '../../../../utils/engine/api.service';

import PageLoadingComponent from '../../../../components/loading-component/page-loading-component';
import {
    base_grid,
    base_page,
    base_subnavigation_bar,
    base_widget_full,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';

const AlertsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [viewType, setViewType] = useState(ALERT_FILTER_TYPES.view.default);

    const [alertGraphData, setAlertGraphData] = useState(null);
    const [alertGraphDataPlottable, setAlertGraphDataPlottable] =
        useState(null);

    const [tableData, setTableData] = useState(null);
    const [trendFiltersVisible, setTrendFiltersVisible] = useState(false);

    const daisyTheme = useSelector(selectDaisyTheme);

    const selectedFilters = useSelector(selectFiltersSelected);
    const trendFilters = useSelector(selectTrendFilters);
    const userToken = useSelector(selectUserToken);

    const handleViewTypeChange = (event) => {
        setViewType(event);
    };

    const onShare = async (recipientsSelected, additionalMessage) => {
        setIsLoading(true);
        try {
            await onShareViewClick(
                userToken,
                selectedFilters,
                trendFilters,
                recipientsSelected,
                additionalMessage
            );
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchAlertsDataHelper = async () => {
        try {
            setIsLoading(true);
            const alertData = await alertsService.loadAlertsData(
                selectedFilters,
                trendFilters
            );
            if (alertData['freqTrendFutureValue']['channels'].length === 0) {
                createNotification(
                    'No alert data available',
                    NOTIFICATION_TYPES.WARNING
                );
            } else if (
                alertData['freqTrendFutureValue']['channels'][0]['dataKey'] !==
                'Value'
            ) {
                setAlertGraphData(alertData);
                setAlertGraphDataPlottable(
                    alertsService.convertAlertDataToTrendGraph(
                        alertData,
                        daisyTheme
                    )
                );

                if ('resultHeadings' in alertData && 'results' in alertData) {
                    setTableData({
                        tableHeadings: alertData['resultHeadings'],
                        tableData: alertData['results'],
                        tableDatestamp: alertData['resultsDate'],
                    });
                } else {
                    setTableData(null);
                }
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const onGenerateReportClick = async (reportParams) => {
        try {
            setIsLoading(true);
            const alertData = await alertsService.loadAlertsData(
                selectedFilters,
                selectedFilters,
                reportParams
            );
            if (alertData.message && alertData.message === 'Processing Request')
                createNotification(
                    'Report request sent',
                    NOTIFICATION_TYPES.SUCCESS
                );
            if (reportParams['cronText'] != null) {
                await apiService.scheduleAlertsProcess(
                    selectedFilters,
                    trendFilters,
                    reportParams,
                    reportParams['cronText']
                );
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAlertsDataHelper();
    }, [selectedFilters, trendFilters]);

    useEffect(() => {
        if (alertGraphData !== null) {
            setAlertGraphDataPlottable(
                alertsService.convertAlertDataToTrendGraph(
                    alertGraphData,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    return (
        <>
            <div className={base_subnavigation_bar}>
                <div className="flex w-full h-full justify-between items-center max-w-8xl">
                    <span className={'flex items-center gap-1'}>
                        <a
                            className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                            href={'/base/alerts'}
                        >
                            Alerts
                        </a>

                        <ShareViewComponent onClick={onShare} />
                        <GenerateReportComponent
                            onGenerate={onGenerateReportClick}
                            dateRange={false}
                            includeSoh={false}
                            frequency={false}
                        />
                    </span>

                    <span className="flex items-center justify-between">
                        <TrendFiltersComponent
                            className="z-50"
                            onViewChange={handleViewTypeChange}
                            viewState={viewType}
                            visible={trendFiltersVisible}
                            toggleOpen={() => {
                                setTrendFiltersVisible(!trendFiltersVisible);
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <FiltersContainer className={'col-span-full'} />

                    {tableData && (
                        <div className={`${base_widget_full} ${muted_border}`}>
                            <table className="table table-zebra table-compact w-full -z-0 px-4 pt-4">
                                <thead>
                                    <tr>
                                        {tableData['tableHeadings'].map(
                                            (heading, index) => {
                                                return (
                                                    <th
                                                        key={heading}
                                                        className={
                                                            'text-right normal-case text-sm'
                                                        }
                                                    >
                                                        {heading}
                                                    </th>
                                                );
                                            }
                                        )}
                                    </tr>
                                </thead>

                                <tbody>
                                    {tableData['tableData'].map(
                                        (data, index) => {
                                            return (
                                                <tr key={data[0]} className="">
                                                    {data.map((key) => {
                                                        return (
                                                            <td
                                                                className="text-sm font-mono text-end"
                                                                key={uuidv4()}
                                                            >
                                                                {key}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>

                            <div className="divider my-2 px-4" />

                            <div className="flex items-center justify-end w-full gap-2 pb-4 px-4">
                                <span className="text-base-content text-opacity-80 text-sm">
                                    Date
                                </span>
                                <span className="badge badge-primary font-bold">
                                    {tableData['tableDatestamp']}
                                </span>
                            </div>
                        </div>
                    )}

                    {alertGraphDataPlottable === null ? (
                        isLoading === true ? (
                            <PageLoadingComponent className="col-span-2" />
                        ) : (
                            <NoData className="col-span-full" />
                        )
                    ) : (
                        <div className={`${base_widget_full} ${muted_border}`}>
                            {viewType.value === 'lines' ? (
                                <LineChartComponent
                                    data={alertGraphDataPlottable}
                                    title="Lost Sales"
                                    type={'Trend'}
                                    interactionMode="nearest"
                                    onClickHideOthers={true}
                                />
                            ) : (
                                <StackedChartComponent
                                    data={alertGraphDataPlottable}
                                    title="Lost Sales"
                                    type={'Trend'}
                                    interactionMode="nearest"
                                    onClickHideOthers={true}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AlertsPage;
