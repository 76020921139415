import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    ArchiveBoxIcon,
    ChartPieIcon,
} from '@heroicons/react/24/solid';

import { selectSidebarMode } from '../../store/navigation/navigation.selectors';

import { GLOBAL_STYLES } from '../../utils/styles/global-styles';
import { Link } from 'react-router-dom';
import './dropdown.styles.css';

const Dropdown = ({ title, options, className, onClick }) => {
    const sidebarMode = useSelector(selectSidebarMode);

    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            ref={ref}
            className={`relative ${sidebarMode && 'w-full justify-start'}`}
        >
            <div className={className} onClick={toggleIsOpen}>
                {isOpen ? (
                    sidebarMode ? (
                        <ChevronRightIcon className="dropdown-icon" />
                    ) : (
                        <ChevronUpIcon className="dropdown-icon" />
                    )
                ) : sidebarMode ? (
                    <ChevronLeftIcon className="dropdown-icon" />
                ) : (
                    <ChevronDownIcon className="dropdown-icon" />
                )}
                <span>{title}</span>
            </div>

            <div
                className={`z-50 drop-shadow menu bg-base-300 rounded-box absolute w-full p-2 top-full right-0 ${
                    isOpen ? 'visible' : 'invisible'
                }`}
                style={
                    sidebarMode
                        ? {
                              top: 0,
                              right: `100%`,
                              width: '8rem',
                          }
                        : {
                              minWidth: '8rem',
                          }
                }
            >
                {options.map(({ path, name }) => {
                    return (
                        <Link
                            key={path}
                            to={path}
                            className="w-full btn btn-ghost text-xs relative flex justify-center items-center font-normal normal-case hover:text-primary"
                            onClick={() => {
                                toggleIsOpen();
                                onClick();
                            }}
                        >
                            {name === 'Trend' ? (
                                <ArchiveBoxIcon className="w-5 h-5" />
                            ) : name === 'Cover' ? (
                                <ChartPieIcon className="w-5 h-5" />
                            ) : (
                                ''
                            )}
                            <p className="ml-2">{name}</p>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default Dropdown;