import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';
import { useState } from 'react';
import { glowing_border } from '../../../utils/styles/tailwind-styles';
import StripeCheckoutForm from '../../../components/stripe/stripe-checkout-form.component';
import FooterComponent from '../../../components/footer-component/footer.component';

const SubscribePage = () => {
    const [reload, setReload] = useState(0);

    const onPointerHandler = () => {
        setReload(reload + 1);
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
                <div
                    className={`card flex flex-col p-4 shadow-lg bg-base-300 bg-opacity-95 w-full max-w-xl justify-center items-center min-h-64 z-10 ${glowing_border}`}
                    onPointerOver={onPointerHandler}
                >
                    <div className={`z-10 w-full`}>
                        <StripeCheckoutForm />
                    </div>
                </div>

                <PublicBackdrop />
            </div>
            <FooterComponent useDaisy={true} />
        </>
    );
};

export default SubscribePage;
