import { useEffect } from 'react';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import MiniLineChartComponent from '../../charts/mini-line/mini-line-chart.component';
import LimitedDateRangeSelectorComponent, {
    limitedDateTextMap,
} from '../../limited-date-range-selector/limited-date-range-selector.component';
import { ExclamationCircleIcon, ScaleIcon } from '@heroicons/react/24/solid';
import LoadingComponent from '../../loading-component/loading-component';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';
import MiniBarChartComponent from '../../charts/mini-bar/mini-bar-chart.component';
import {
    widget_graph_box,
    widget_text_box,
} from '../../../utils/styles/tailwind-styles';
import { widgetService } from '../../../store/discover/widgets/widgets.services';
import NoData from '../../no-data/no-data.component';
import { objectService } from '../../../utils/object.util';

const ReturnsTrend = ({
    rawData,
    plottableData,
    selectedDate,
    onDateChange,
}) => {
    useEffect(() => {}, [plottableData, selectedDate]);

    return (
        <>
            <div className={widget_text_box}>
                <div className="flex flex-col justify-evenly items-start gap-4 p-8">
                    <div className="text-lg text-base-content text-opacity-80">
                        Returns Value
                    </div>
                    {!objectService.checkObjectPopulated(rawData) ? (
                        <LoadingComponent />
                    ) : widgetService.checkWidgetHasData(
                          rawData,
                          selectedDate,
                          'total'
                      ) ? (
                        <div className="stat-value text-2xl flex-wrap font-mono">
                            -R
                            {rawData[selectedDate]['total'].toLocaleString()}
                            <span
                                className={
                                    'font-light text-base-content text-opacity-80 text-xs ml-2'
                                }
                            >
                                [{limitedDateTextMap(selectedDate)}]
                            </span>
                        </div>
                    ) : (
                        <NoData />
                    )}

                    <div className="text-sm text-base-content text-opacity-80">
                        {objectService.checkObjectPopulated(rawData) &&
                            widgetService.checkWidgetHasData(
                                rawData,
                                selectedDate,
                                'isSufficientHistory'
                            ) &&
                            widgetService.checkWidgetHasData(
                                rawData,
                                selectedDate,
                                'grossSales'
                            ) &&
                            widgetService.checkWidgetHasData(
                                rawData,
                                selectedDate,
                                'perc'
                            ) &&
                            (!rawData[selectedDate]['isSufficientHistory'] ? (
                                <span className="flex justify-start items-center">
                                    <ExclamationCircleIcon className="w-4 h-4 text-warning mx-2" />

                                    <span className="flex flex-wrap whitespace-pre-wrap justify-start items-center">
                                        Insufficient data to returns ratio for
                                        the current period.
                                    </span>
                                </span>
                            ) : (
                                <span className="flex justify-start items-center">
                                    <span className="mx-2">
                                        <ScaleIcon className="w-5 h-5 text-primary" />
                                    </span>

                                    <span className="flex flex-wrap gap-1 justify-start items-center w-full">
                                        <p>
                                            The % of <strong>returns</strong> vs{' '}
                                            <strong>gross sales</strong>{' '}
                                            <span className="font-bold font-mono text-primary mx-1">
                                                R
                                                {rawData[selectedDate][
                                                    'grossSales'
                                                ].toLocaleString()}
                                            </span>{' '}
                                            for
                                            <strong className={'mx-1'}>
                                                {limitedDateTextMap(
                                                    selectedDate
                                                )}
                                            </strong>
                                            is
                                            <span className="font-bold font-mono text-primary mx-1">
                                                {rawData[selectedDate][
                                                    'perc'
                                                ].toLocaleString()}
                                                %
                                            </span>
                                            .
                                        </p>
                                    </span>
                                </span>
                            ))}
                    </div>
                </div>
            </div>

            <div className={widget_graph_box}>
                {!objectService.checkObjectPopulated(plottableData) ? (
                    <PageLoadingComponent />
                ) : selectedDate === '1D' ? (
                    <MiniBarChartComponent
                        data={plottableData[selectedDate]}
                        className="max-h-52"
                    />
                ) : (
                    <MiniLineChartComponent
                        data={plottableData[selectedDate]}
                        className="max-h-52"
                    />
                )}

                {rawData && rawData['subAggregates'] && (
                    <div className="col-span-full">
                        <div className="divider my-1" />

                        <WidgetSubAggregates
                            subAggregates={rawData['subAggregates']}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ReturnsTrend;
