import { TREND_TOOLTIP_ACTION_TYPES } from './trend-tooltip.types';

const INITIAL_STATE = {
    tooltipXPos: null,
};

export const trendTooltipReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TREND_TOOLTIP_ACTION_TYPES.SET_TOOLTIP_X_POS:
            return { ...state, tooltipXPos: payload };

        case TREND_TOOLTIP_ACTION_TYPES.CLEAR_TOOLTIP_X_POS:
            return { ...INITIAL_STATE };

        default:
            return state;
    }
};