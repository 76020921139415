import { formatDate } from '../../../utils/auth.util';

const getStylesByType = (type) => {
    switch (type) {
        case 'Created':
            return 'border border-secondary text-secondary';
        case 'Updated':
            return 'border border-accent text-accent';
        default:
            return 'border border-primary text-primary';
    }
};

const UserAudit = ({ type, date, by }) => {
    return (
        <div
            className={`flex flex-wrap h-fit justify-start text-start items-center break-all gap-2 max-w-2xs text-sm overflow-x-auto px-2 py-4 shadow-inner z-10 tooltip w-full ${getStylesByType(
                type
            )}`}
            data-tip={`Date Updated`}
        >
            <p className="absolute bottom-0 right-2 text-2xs capitalize text-base-content text-opacity-80">
                {type}
            </p>
            <div className="font-bold monospace font-mono">
                {date !== null ? formatDate(date) : '-'}
            </div>
            {by !== null && by !== undefined && (
                <div className="flex items-center">
                    <span>[{by}]</span>
                </div>
            )}
        </div>
    );
};

export default UserAudit;