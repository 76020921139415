import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';
import {
    engineService,
    removeDateRangeFromFilters,
} from '../../../../utils/engine/engine.service';
import {
    addOpacity,
    graphColourSelector,
} from '../../../../utils/styles/global-styles';

export const rangingService = {
    fetchRangingData,
    mapRangingFilterValueToSelectionObject,

    convertFocusSkuGraphToPlottable,
};

async function fetchRangingData(
    filters,
    identifyOption,
    rangingStrategy,
    focusSku,
    reportParams
) {
    const fetchParam = {
        selectedFilters: removeDateRangeFromFilters(filters),
        identifyOption,
        rangingStrategy,
    };
    if (focusSku) fetchParam['focusSku'] = focusSku.supplSku;

    let param;
    if (reportParams) {
        param = {
            uiRequest: {
                action: 'generateReport',
                state: fetchParam,
                reportParams,
            },
        };
    } else {
        param = fetchParam;
    }

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.RANGING_DIST,
        param
    );
    return response;
}

function convertFocusSkuGraphToPlottable(focusSkuGraph, daisyTheme) {
    const unformattedDatasets = focusSkuGraph['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = focusSkuGraph['rows'].map((row) => row.name);

    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: dataset,
                data: focusSkuGraph['rows'].map((row) => row[dataset]),
                fill: false,
                borderColor,
                tension: 0.125,

                pointRadius: 6,
                pointHoverRadius: 9,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 0.5,
                pointBorderColor: borderColor,
                pointBackgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}

function mapRangingFilterValueToSelectionObject(value) {
    switch (value) {
        case 'all':
            return { value: 'all', label: 'All Stores' };
        case 'brand':
            return { value: 'brand', label: 'Stores that stock brand' };
        case 'skus':
            return { value: 'skus', label: 'Stores that stock skus' };

        case 'leastranged':
            return { value: 'leastranged', label: 'Least Ranged' };
        case 'rangedvsstocked':
            return {
                value: 'rangedvsstocked',
                label: 'Ranged vs Stock Disparity',
            };
        case 'stockedvsselling':
            return {
                value: 'stockedvsselling',
                label: 'Stock vs Selling Disparity',
            };

        default:
            return {};
    }
}
