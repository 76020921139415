import { TREND_FILTER_ACTION_TYPES, TREND_FILTER_TYPES } from './trend.types';

const INITIAL_STATE = {
    frequency: TREND_FILTER_TYPES.frequency.default.value,
    compare: TREND_FILTER_TYPES.compare.default.value,
};

export const trendReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TREND_FILTER_ACTION_TYPES.SET_TREND_FILTER_FREQUENCY:
            return { ...state, frequency: payload };
        case TREND_FILTER_ACTION_TYPES.SET_TREND_FILTER_COMPARE:
            return { ...state, compare: payload };

        case TREND_FILTER_ACTION_TYPES.RESET_TREND_FILTERS:
            return { ...INITIAL_STATE };

        default:
            return state;
    }
};
