import { ChartBarIcon, CubeTransparentIcon } from '@heroicons/react/24/outline';
import { glowing_border } from '../../utils/styles/tailwind-styles';

const Product = ({
    title,
    type,
    blurb,
    onClickHandler,
    onPointerHandler,
    className,
}) => {
    return (
        <div
            className={`card card-body bg-base-300 bg-opacity-90 ${glowing_border} shadow-primary flex flex-col justify-center items-center cursor-pointer hover:bg-primary hover:text-primary-content hover:scale-110 transition-all h-full w-full z-10 hover:z-20 duration-500 ${className}`}
            style={{
                minHeight: '30rem',
                maxWidth: '45rem',
            }}
            onClick={onClickHandler}
            onPointerOver={onPointerHandler}
        >
            <div className="flex w-full justify-center items-center p-2">
                {type === 'base' ? (
                    <ChartBarIcon
                        className={`
                        w-8 h-8
                            `}
                    />
                ) : (
                    <CubeTransparentIcon
                        className={`
                                w-8 h-8
                            `}
                    />
                )}

                <h2 className="normal-case md:text-xl text-lg tracking-wide text-center ml-2">
                    {title}
                </h2>
            </div>

            <div className="divider" />

            <div className="w-full flex justify-center items-center md:text-base text-opacity-80 text-center md:px-10 px-4">
                {blurb}
            </div>
        </div>
    );
};

export default Product;