import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectUser } from '../../../store/auth/authentication.selectors';

import { authenticationService } from '../../../store/auth/authentication.services';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { apiService } from '../../../utils/engine/api.service';

import {
    clearProductType,
    setProductType,
} from '../../../store/product/product.actions';
import { PRODUCT_TYPES } from '../../../store/product/product.types';

import {
    EyeIcon,
    EyeSlashIcon,
    LockClosedIcon,
    LockOpenIcon,
} from '@heroicons/react/24/solid';
import FooterComponent from '../../../components/footer-component/footer.component';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../components/loading-component/loading-component';
import { OPS_HOSTNAME } from '../../../utils/engine/socket-config.service';
import jwtDecode from 'jwt-decode';
import { selectProductType } from '../../../store/product/product.selectors';
import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';
import { clearDiscoverSearchData } from '../../../store/discover/search/discover-search.actions';

const FileShare = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const user = useSelector(selectUser);
    const productType = useSelector(selectProductType);

    const dispatch = useDispatch();

    const [fileId, setFileId] = useState(false);
    const [fileName, setFileName] = useState(false);

    const [validEmails, setValidEmails] = useState([]);

    const [userValidated, setUserValidated] = useState(false);

    useEffect(() => {
        setFileId(searchParams.get('fileId'));
    }, []);

    useEffect(() => {
        if (fileId) {
            const getFileShare = async () => {
                let fileShare = await apiService.getFileShareData(fileId);
                setValidEmails(fileShare.emails);
                setFileName(fileShare.fileName);
            };
            getFileShare();
        }
    }, [fileId]);

    useEffect(() => {
        // Check if token is expired
        if (user && user.token) {
            if (Date.now() >= jwtDecode(user.token)['exp'] * 1000) {
                createNotification(
                    'Session expired, please re-log in.',
                    NOTIFICATION_TYPES.WARNING
                );
                dispatch(clearProductType());
                dispatch(clearDiscoverSearchData());
                dispatch(authenticationService.logout());
            }
        }

        if (user && validEmails.length > 0) {
            if (validEmails.includes(user.email)) {
                /*
                Need to check if auth token is still valid
                 */

                setUserValidated(true);
            }
        }
    }, [validEmails, user]);

    const onFileClick = async () => {
        let url =
            OPS_HOSTNAME +
            `/api/prweb/shared-files/${fileId}/download?token=${user.token}`;
        await window.open(url).then((response) => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.redirected === true) {
                return Promise.reject('Failed to download file.');
            } else {
                return response.json();
            }
        });
    };

    const onLoginHandler = async (event, email) => {
        event.preventDefault();
        const target = event.currentTarget;

        setIsLoading(true);

        try {
            const userAction = await authenticationService.login(
                email,
                target.password.value
            );

            if (userAction) {
                dispatch(userAction);
                createNotification(
                    `${userAction.payload['name']} - ${userAction.payload['custaccountName']}`,
                    NOTIFICATION_TYPES.SUCCESS
                );

                if (!userAction.payload.products) {
                    createNotification(
                        'No products configured for user',
                        NOTIFICATION_TYPES.ERROR
                    );
                } else if (userAction.payload.products.length === 1)
                    dispatch(setProductType(userAction.payload.products[0]));
                else {
                    // TODO if a user has multiple products, we need to allow them to select one when they're authenticating to view a document
                    dispatch(setProductType(userAction.payload.products[0]));
                }
            } else {
                createNotification(
                    'Invalid credentials',
                    NOTIFICATION_TYPES.ERROR
                );
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
                <div className="card flex bg-base-200 flex-col py-8 px-12 shadow-lg w-full max-w-4xl justify-start items-center z-10">
                    <span className="w-full flex justify-between items-center">
                        <div className="flex items-center">
                            <span>
                                <LockClosedIcon className={'w-4 h-4 mr-2'} />
                            </span>
                            <h3 className="font-bold">Secure File Share</h3>
                        </div>
                    </span>

                    <div className="divider my-2" />

                    {userValidated ? (
                        <div className="italic flex justify-start items-center w-full mb-2">
                            {fileName ? (
                                <span
                                    className="btn btn-link text-primary flex justify-center items-center font-normal normal-case tooltip tooltip-right"
                                    data-tip={'Download'}
                                    onClick={onFileClick}
                                >
                                    {fileName}
                                    {/*<ArrowDownTrayIcon className="w-4 h-4 text-success ml-2" />*/}
                                </span>
                            ) : (
                                <LoadingComponent
                                    type={LOADING_COMPONENT_TYPES.TAILSPIN}
                                    stroke={2}
                                    size={14}
                                />
                            )}
                        </div>
                    ) : (
                        <div className="italic flex justify-start items-center w-full">
                            {fileName ? (
                                <span
                                    className="btn btn-ghost flex justify-center items-center font-normal normal-case tooltip tooltip-right"
                                    data-tip={'Permission Denied'}
                                >
                                    <LockClosedIcon className="w-4 h-4 text-error mr-2" />
                                    {fileName}
                                </span>
                            ) : (
                                <LoadingComponent
                                    type={LOADING_COMPONENT_TYPES.TAILSPIN}
                                    stroke={2}
                                    size={14}
                                />
                            )}
                        </div>
                    )}

                    {user ? (
                        userValidated ? (
                            <>
                                <p className="font-light text-sm mb-2">
                                    You are logged in as{' '}
                                    <span className="text-md font-bold px-1">
                                        {user.email}
                                    </span>{' '}
                                    and have been successfully validated to view
                                    this report. You may download it via the
                                    link above, or you may download it via the
                                    {productType ===
                                    PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER ? (
                                        <>
                                            <a
                                                href={`/discover/extracts`}
                                                className="underline cursor-pointer text-primary mx-2"
                                            >
                                                extracts
                                            </a>{' '}
                                            page.
                                        </>
                                    ) : (
                                        <>
                                            <a
                                                href={`/base/manage-reports`}
                                                className="underline cursor-pointer text-primary mx-2"
                                            >
                                                manage reports
                                            </a>{' '}
                                            page of the dashboard.
                                        </>
                                    )}
                                </p>
                            </>
                        ) : (
                            <>
                                <p className="font-light text-sm mb-2">
                                    You are logged in as{' '}
                                    <span className="text-error">
                                        {user.email}
                                    </span>{' '}
                                    and are not authorized to view this report.
                                </p>
                            </>
                        )
                    ) : (
                        <>
                            <p className="text-sm my-2">
                                You are <strong>not</strong> currently logged
                                in. You can authenticate yourself by{' '}
                                <strong>clicking on your email</strong> and
                                entering your account password.
                            </p>
                            {validEmails.length > 0 ? (
                                <>
                                    <div className="divider my-2" />
                                    {validEmails.map((email) => {
                                        if (email !== '') {
                                            return (
                                                <div
                                                    key={email}
                                                    tabIndex={0}
                                                    className="collapse border border-base-100 hover:border-primary w-full rounded"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className="peer"
                                                    />
                                                    <div className="collapse-title text-sm font-bold peer-hover:bg-base-300 hover:bg-base-300">
                                                        {email}
                                                    </div>
                                                    <div className="collapse-content">
                                                        <form
                                                            id="loginForm"
                                                            className="w-full h-full flex items-center gap-2"
                                                            onSubmit={(event) =>
                                                                onLoginHandler(
                                                                    event,
                                                                    email
                                                                )
                                                            }
                                                        >
                                                            <input
                                                                className="input input-bordered input-sm w-full mt-2 mr-2"
                                                                name="password"
                                                                id="password"
                                                                placeholder={
                                                                    'Enter your password...'
                                                                }
                                                                type={
                                                                    passwordVisible
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                required={true}
                                                            />
                                                            {passwordVisible ? (
                                                                <span
                                                                    className="btn btn-ghost btn-sm btn-circle"
                                                                    onClick={() =>
                                                                        setPasswordVisible(
                                                                            !passwordVisible
                                                                        )
                                                                    }
                                                                >
                                                                    <EyeSlashIcon className="w-4 h-4" />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="btn btn-ghost btn-sm btn-circle"
                                                                    onClick={() =>
                                                                        setPasswordVisible(
                                                                            !passwordVisible
                                                                        )
                                                                    }
                                                                >
                                                                    <EyeIcon className="w-4 h-4" />
                                                                </span>
                                                            )}

                                                            {!isLoading ? (
                                                                <button
                                                                    className="normal-case font-normal btn btn-primary btn-outline btn-sm flex justify-center items-center gap-2"
                                                                    type={
                                                                        'submit'
                                                                    }
                                                                >
                                                                    <LockOpenIcon className="w-4 h-4" />{' '}
                                                                    Authenticate
                                                                </button>
                                                            ) : (
                                                                <LoadingComponent
                                                                    type={
                                                                        LOADING_COMPONENT_TYPES.TAILSPIN
                                                                    }
                                                                    stroke={2}
                                                                    size={14}
                                                                />
                                                            )}
                                                        </form>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </>
                            ) : (
                                <LoadingComponent />
                            )}
                        </>
                    )}
                </div>

                <PublicBackdrop />
            </div>
            <FooterComponent useDaisy={true} />
        </>
    );
};

export default FileShare;
