export const DISCOVER_WIDGETS_ACTION_TYPES = {
    SET_DASHBOARD_PAGE_WIDGETS: 'discover/widgets/SET_DASHBOARD',
    SET_WATCHLIST_PAGE_WIDGETS: 'discover/widgets/SET_WATCHLIST',

    SET_SKU_PAGE_WIDGETS: 'discover/widgets/SET_SKU',
    SET_CUSTOMER_PAGE_WIDGETS: 'discover/widgets/SET_CUSTOMER',
    SET_REP_PAGE_WIDGETS: 'discover/widgets/SET_REP',
    SET_CATEGORY_PAGE_WIDGETS: 'discover/widgets/SET_CATEGORY',

    SET_HIDDEN_WIDGETS: 'discover/widgets/SET_HIDDEN',
    CLEAR_HIDDEN_WIDGETS: 'discover/widgets/CLEAR_HIDDEN',

    CLEAR: 'discover/widgets/CLEAR',
    CLEAR_DASHBOARD_WIDGETS: 'discover/widgets/CLEAR_DASHBOARD',

    CLEAR_PRODUCT_WIDGETS: 'discover/widgets/CLEAR_PRODUCT',
    CLEAR_CATEGORY_WIDGETS: 'discover/widgets/CLEAR_CATEGORY',
    CLEAR_CUSTOMER_WIDGETS: 'discover/widgets/CLEAR_CUSTOMER',
    CLEAR_REP_WIDGETS: 'discover/widgets/CLEAR_REP',
    CLEAR_WATCHLIST_WIDGETS: 'discover/widgets/CLEAR_WATCHLIST',
};

export const DISCOVER_WIDGET_DATA_METRICS = {
    SALES: 'sales',
    RETURNS: 'returns',

    DISCOUNT: 'discount',
    PROFIT: 'profit',

    UNUSED_CREDIT: 'unused_credit',

    CHAOS: 'performance',

    OPPORTUNITY: 'opportunity',
};

export const DISCOVER_WIDGET_DATA_VISUALIZATIONS = {
    TREND: 'trend',
    RANKING: 'ranking',

    SINGULAR: 'singular',
};

export const DISCOVER_WIDGET_DATA_SORTING = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

export const DISCOVER_WIDGET_AGGREGATION_METRICS = {
    GLOBAL: null,

    CUSTOMERS: 'customers',
    REPS: 'reps',
    SKUS: 'skus',

    CUSTOMER: 'customer',
    REP: 'rep',
    SKU: 'sku',

    CATEGORY: 'category',
    CATEGORIES: 'categories',
};

export const DISCOVER_WIDGET_SORT_TYPES = {
    VALUE: 'value',
    MARGIN: 'margin',
};
