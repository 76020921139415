import { formula_box } from '../../../utils/styles/tailwind-styles';

import React from 'react';

const ReturnsHelp = () => {
    return (
        <>
            <div className="flex flex-col gap-2 text-sm">
                <span className="badge badge-lg badge-primary">Trend</span>
                <p className="px-2">
                    The <strong>Returns Trend</strong> widget provides a
                    trending view of the absolute returns value
                    <span className="badge-md badge-secondary rounded-full mx-2 bg-opacity-60" />{' '}
                    and the % of returns vs sales
                    <span className="badge-md badge-error rounded-full mx-2 bg-opacity-60" />
                    for the current period.
                </p>

                <span className={formula_box}>
                    {'\\(returns = \\sum_{n=1}^{x}{returns_n}\\)'}
                </span>

                <span className={formula_box}>
                    {
                        '\\(returns_{perc} = \\frac{abs(returns_x)}{abs(returns_x) + sales_x}\\)'
                    }
                </span>

                <p className="px-2">
                    where <strong>n</strong> represents the current time
                    interval and <strong>x</strong> represents the number of
                    time intervals within the current period. <br />
                    <br />
                </p>

                <span className="badge badge-lg badge-accent mt-2">
                    Ranking
                </span>
                <p className="px-2">
                    The <strong>Returns Ranking</strong> widget provides a
                    ranked view of returns for the current period in terms of
                    absolute returns value
                    <span className="badge-md badge-error mx-2 bg-opacity-60" />{' '}
                    and a % contribution of the given aggregation metric to the
                    total sales within that perspective
                    <span className="badge-md badge-secondary mx-2 bg-opacity-60" />
                    .
                </p>

                <span className={formula_box}>
                    {
                        '\\(contribution_x = \\frac{returns_x}{\\sum_k^N{returns_k}}\\)'
                    }
                </span>
            </div>
        </>
    );
};

export default ReturnsHelp;