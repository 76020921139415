export const ALERT_TYPES = {
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'success',
    INFO: 'info',
    DEFAULT: 'primary',
};

const MessageComponent = ({ type, title, message, className }) => {
    return (
        <div
            className={`rounded border flex items-center justify-center max-w-lg gap-1 py-2 px-4 text-sm ${className} ${
                type === ALERT_TYPES.ERROR
                    ? 'border-error text-error'
                    : 'border-warning text-warning'
            }`}
        >
            <strong className="mr-4 font-extrabold">{title}</strong>
            <p>{message}</p>
        </div>
    );
};

export default MessageComponent;