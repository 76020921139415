const WidgetSubAggregates = ({ subAggregates }) => {
    return (
        <div
            className={`flex flex-wrap gap-1 items-center justify-end w-full h-fit max-h-16 overflow-y-auto overflow-x-clip no-scrollbar`}
        >
            <span className="text-xs text-base-content text-opacity-80">
                Data Pulled from Sub Aggregates
            </span>
            <div className="divider divider-horizontal mx-1" />

            {Object.keys(subAggregates).map((code) => {
                return (
                    <a
                        key={code}
                        href={`/discover/customer/${code}`}
                        className={
                            'badge badge-primary badge-outline badge-xs tooltip tooltip-left flex items-center justify-center'
                        }
                        target={'_blank'}
                        data-tip={`Go to [${subAggregates[code]['descr']}]..`}
                    >
                        {subAggregates[code]['descr']
                            ? subAggregates[code]['descr'].length > 32
                                ? subAggregates[code]['descr'].slice(0, 32) +
                                  '..'
                                : subAggregates[code]['descr']
                            : code}
                    </a>
                );
            })}
        </div>
    );
};

export default WidgetSubAggregates;
