import AdminSkeleton from './admin-skeleton.component';
import { detail_sub_heading } from '../../../utils/styles/tailwind-styles';

const CategoryDetail = ({ detail }) => {
    return (
        <>
            {detail['skus'] ? (
                <div
                    className={`flex flex-col items-center justify-start text-sm col-span-full gap-1 max-h-48 overflow-y-auto overflow-x-clip p-3 rounded-box mb-2 no-scrollbar shadow-inner bg-base-200 hover:border-secondary hover:border-opacity-80 border border-base-200`}
                >
                    <span className={`${detail_sub_heading}`}>
                        Products
                        <span className="badge badge-xs badge-secondary mx-2">
                            {detail['skus'].length}
                        </span>
                    </span>

                    <div className="flex flex-wrap items-center justify-center gap-2">
                        {detail['skus'].sort().map((product) => {
                            return (
                                <a
                                    key={product['code']}
                                    href={'/discover/sku/' + product['code']}
                                    target={'_blank'}
                                    className={
                                        'badge badge-sm badge-outline badge-secondary font-mono cursor-pointer tooltip flex items-center justify-center'
                                    }
                                    data-tip={`View Product [${product['descr']}]`}
                                >
                                    {product['descr']
                                        ? product['descr'].length > 32
                                            ? product['descr'].slice(0, 32) +
                                              '..'
                                            : product['descr']
                                        : product['code']}
                                </a>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <AdminSkeleton rows={3} />
            )}
        </>
    );
};

export default CategoryDetail;
