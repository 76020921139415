import { muted_border, widget_full } from '../../utils/styles/tailwind-styles';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../loading-component/loading-component';

export const NoData = ({
    className,
    message = 'No data for selected period.',
}) => {
    return (
        <div className={`${className} ${widget_full}`}>
            <div className="flex flex-wrap justify-center items-center py-3 col-span-full">
                <LoadingComponent
                    type={LOADING_COMPONENT_TYPES.PING}
                    size={60}
                    speed={2.2}
                />
                <div className="mx-2" />
                <p className="col-span-3 text-sm text-base-content text-opacity-70 font-bold">
                    {message}
                </p>
            </div>
        </div>
    );
};

export default NoData;