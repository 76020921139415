import { useRef, useEffect, useState } from 'react';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { GLOBAL_STYLES } from '../../../utils/styles/global-styles';
import { QueueListIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import {
    FROM_DATE_OPTIONS,
    TO_DATE_OPTIONS,
} from './target-template-download.component';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import TargetEdit from './target-edit-component.component';
import { discoverHomeService } from '../../../store/discover/home/discover-home.services';
import { DISCOVER_WIDGET_AGGREGATION_METRICS } from '../../../store/discover/widgets/widgets.types';
import LoadingComponent from '../../loading-component/loading-component';

const convertOptionLanguageToDate = (option) => {
    const date = new Date();
    /*
    Setting date to halfway through month to ensure we don't find bugs where months don't have the same number of days.
     */
    date.setDate(15);

    switch (option) {
        case FROM_DATE_OPTIONS.TWO_YEARS_AGO:
            date.setFullYear(date.getFullYear() - 2);
            break;
        case FROM_DATE_OPTIONS.ONE_YEAR_AGO:
            date.setFullYear(date.getFullYear() - 1);
            break;
        case FROM_DATE_OPTIONS.SIX_MONTHS_AGO:
            date.setMonth(date.getMonth() - 6);
            break;
        case TO_DATE_OPTIONS.SIX_MONTHS_FROM_NOW:
            date.setMonth(date.getMonth() + 6);
            break;
        case TO_DATE_OPTIONS.ONE_YEAR_FROM_NOW:
            date.setFullYear(date.getFullYear() + 1);
            break;
        case TO_DATE_OPTIONS.TWO_YEARS_FROM_NOW:
            date.setFullYear(date.getFullYear() + 2);
            break;
        default:
            break;
    }

    return date;
};

function dateRange(startDate, endDate) {
    // we use UTC methods so that timezone isn't considered
    let start = new Date(startDate.setHours(12));
    const end = new Date(endDate.setHours(12));

    const dates = [];
    while (start <= end) {
        const displayMonth = start.getMonth() + 1;

        dates.push(
            [
                start.getUTCFullYear(),
                // months are zero based, ensure leading zero
                displayMonth.toString().padStart(2, '0'),
            ].join('-')
        );

        // progress the start date by one month
        start = new Date(start.setUTCMonth(displayMonth));
    }

    return dates;
}

const TargetBulkEdit = ({ metric, perspective, className, onFinish }) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const [from, setFrom] = useState(FROM_DATE_OPTIONS.NOW);
    const [to, setTo] = useState(TO_DATE_OPTIONS.ONE_YEAR_FROM_NOW);

    const [fromDate, setFromDate] = useState(
        convertOptionLanguageToDate(FROM_DATE_OPTIONS.NOW)
    );
    const [toDate, setToDate] = useState(
        convertOptionLanguageToDate(TO_DATE_OPTIONS.ONE_YEAR_FROM_NOW)
    );
    const [targetMonths, setTargetMonths] = useState(
        dateRange(fromDate, toDate)
    );

    const [existingTargets, setExistingTargets] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchTargets = () => {
        setLoading(true);
        discoverHomeService
            .getTargets(metric, perspective)
            .then((response) => {
                if (Object.keys(response).length > 0)
                    setExistingTargets(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        setTargetMonths(dateRange(fromDate, toDate));
    }, [fromDate, toDate]);

    useEffect(() => {}, [targetMonths, existingTargets]);

    useEffect(() => {
        fetchTargets();
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && ref.current === e.target) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            <label
                className={`btn btn-circle btn-ghost btn-xs flex justify-center items-center tooltip normal-case ${className}`}
                htmlFor={`target-bulk-edit-modal-${metric}`}
                onClick={() => fetchTargets()}
                data-tip={'Bulk Edit'}
            >
                <QueueListIcon className="w-4 h-4" />
            </label>

            <input
                type="checkbox"
                id={`target-bulk-edit-modal-${metric}`}
                className="modal-toggle"
            />

            <div className="modal">
                {loading ? (
                    <div
                        className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl flex items-center justify-center`}
                        style={{
                            maxHeight: '80vh',
                            minHeight: '40vh',
                        }}
                    >
                        <LoadingComponent />
                    </div>
                ) : (
                    <div
                        className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl`}
                        style={{
                            maxHeight: '80vh',
                        }}
                    >
                        <span className="w-full flex flex-col items-center justify-start mt-2">
                            <div className="flex justify-between items-center w-full gap-2">
                                <h3 className="text-sm font-bold w-fit">
                                    Bulk Targets Edit
                                </h3>

                                <div className="flex flex-wrap grow gap-1 justify-start items-center">
                                    <span className="badge badge-primary font-bold capitalize">
                                        {metric.replace('_', ' ')}
                                    </span>
                                </div>

                                <div className="flex items-center gap-1">
                                    <label
                                        htmlFor={`target-bulk-edit-modal-${metric}`}
                                        className="btn btn-ghost btn-circle btn-sm"
                                        onClick={() => onFinish()}
                                    >
                                        <XMarkIcon
                                            className="w-4 h-4"
                                            onClick={toggleIsOpen}
                                        />
                                    </label>
                                </div>
                            </div>
                        </span>

                        <div className="divider my-2" />

                        <p className="text-sm text-base-content text-opacity-70 mb-2">
                            When would you like to define targets from/to?
                        </p>

                        <div className="w-full flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <span className="w-20 font-bold text-base-content">
                                    From
                                </span>

                                <select
                                    onChange={(e) => {
                                        if (e.target.value === to)
                                            createNotification(
                                                'You must select different dates for [from] and [to].',
                                                NOTIFICATION_TYPES.WARNING
                                            );
                                        else {
                                            setFrom(e.target.value);
                                            setFromDate(
                                                convertOptionLanguageToDate(
                                                    e.target.value
                                                )
                                            );
                                        }
                                    }}
                                    value={from}
                                    className="select select-bordered select-sm select-accent"
                                >
                                    {Object.keys(FROM_DATE_OPTIONS).map(
                                        (targetDate, index) => {
                                            return (
                                                <option
                                                    key={`${index}-${targetDate}`}
                                                >
                                                    {
                                                        FROM_DATE_OPTIONS[
                                                            targetDate
                                                        ]
                                                    }
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            </div>

                            <div className="flex items-center gap-2">
                                <span className="w-20 font-bold text-base-content">
                                    To
                                </span>

                                <select
                                    onChange={(e) => {
                                        if (e.target.value === from)
                                            createNotification(
                                                'You must select different dates for [from] and [to].',
                                                NOTIFICATION_TYPES.WARNING
                                            );
                                        else {
                                            setTo(e.target.value);
                                            setToDate(
                                                convertOptionLanguageToDate(
                                                    e.target.value
                                                )
                                            );
                                        }
                                    }}
                                    value={to}
                                    className="select select-bordered select-sm select-accent"
                                >
                                    {Object.keys(TO_DATE_OPTIONS).map(
                                        (targetDate, index) => {
                                            return (
                                                <option
                                                    key={`${targetDate}-${index}`}
                                                >
                                                    {
                                                        TO_DATE_OPTIONS[
                                                            targetDate
                                                        ]
                                                    }
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="divider my-2" />

                        <div className="flex flex-col gap-1 place-items-start justify-items-start w-full py-1 px-2">
                            <div className="flex items-center gap-1 w-full mb-1">
                                <span
                                    className={
                                        'w-32 text-sm text-primary font-bold'
                                    }
                                >
                                    Month
                                </span>
                                <span
                                    className={'text-sm text-primary font-bold'}
                                >
                                    Value
                                </span>
                            </div>

                            {targetMonths.map((month) => {
                                return (
                                    <div
                                        className="flex items-center gap-1 w-full"
                                        key={month}
                                    >
                                        <label
                                            htmlFor={month}
                                            className={
                                                'font-mono text-sm w-32 font-bold text-base-content text-opacity-80'
                                            }
                                        >
                                            {month}
                                        </label>
                                        <TargetEdit
                                            key={month}
                                            id={month}
                                            metric={metric}
                                            perspective={perspective}
                                            targetValue={
                                                month in existingTargets
                                                    ? existingTargets[month]
                                                    : 0
                                            }
                                            targetDate={month}
                                            onFinish={(newTarget) => {
                                                // optimistic updates
                                                let newTargets = {
                                                    ...existingTargets,
                                                };
                                                newTargets[month] = newTarget;
                                                setExistingTargets(newTargets);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div className="divider mt-2 mb-4" />
                    </div>
                )}
            </div>
        </>
    );
};

export default TargetBulkEdit;