import { DASHBOARD_ACTION_TYPES } from './dashboard.types';
import { DASHBOARD_NAVIGATION_COMPONENT_TABS } from '../../../components/base/dashboard/dashboard-navigation.component';

const INITIAL_STATE = {
    activeComponent: DASHBOARD_NAVIGATION_COMPONENT_TABS.SALES_PERFORMANCE,

    topSkus: null,
    topStores: null,

    salesPerformance: null,

    dataStatus: null,
    activity: null,

    dashboardSidebarMode: false,
    dashboardSidebarIsOpen: false,
};

export const dashboardReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_ACTIVE_COMPONENT:
            return { ...state, activeComponent: payload };
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SIDEBAR_ACTIVE:
            return { ...state, dashboardSidebarActive: payload };
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SIDEBAR_MODE:
            return { ...state, dashboardSidebarMode: payload };

        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_TOP_SKUS:
            return { ...state, topSkus: payload };
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_TOP_STORES:
            return { ...state, topStores: payload };
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_SALES_PERFORMANCE:
            return { ...state, salesPerformance: payload };
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_ACTIVITY:
            return { ...state, activity: payload };
        case DASHBOARD_ACTION_TYPES.SET_DASHBOARD_DATA_STATUS:
            return { ...state, dataStatus: payload };
        case DASHBOARD_ACTION_TYPES.CLEAR_DASHBOARD_DATA:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
