import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';

import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import { getWindowSize } from '../../base/filters/trend-filters/trend-filters.component';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';

import {
    backdrop,
    enlarged,
    enlarged_chart,
    regular_chart,
} from '../../../utils/styles/tailwind-styles';

ChartJS.register(CategoryScale, LinearScale, ArcElement, Tooltip, Legend);

const pieChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 30,
                pointStyle: 'circle',
                usePointStyle: true,
            },
        },
        title: {
            display: false,
        },
    },
    // interaction: {
    //     intersect: true,
    //     axis: 'y',
    //     mode: 'index',
    // }
};

const PieChartComponent = ({ data, title, type }) => {
    const [isEnlarged, setIsEnlarged] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const daisyTheme = useSelector(selectDaisyTheme);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const toggleEnlarged = () => {
        setIsEnlarged(!isEnlarged);
        if (!isEnlarged) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    let options = {
        ...pieChartOptions,
        plugins: {
            ...pieChartOptions['plugins'],
            legend: {
                ...pieChartOptions['plugins']['legend'],
                labels: {
                    ...pieChartOptions['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
    };

    return (
        <>
            {isEnlarged ? (
                <div className={backdrop}>
                    <div className={enlarged_chart} id={'enlarged-chart'}>
                        <div className="flex justify-between items-center w-full px-2">
                            <div className="flex items-center">
                                <h3 className="text-primary font-bold text-base">
                                    {title}
                                </h3>

                                {type && (
                                    <>
                                        <div className="divider divider-horizontal mx-0" />
                                        <span className="badge badge-primary font-bold">
                                            {type}
                                        </span>
                                    </>
                                )}
                            </div>

                            <span className="btn btn-sm btn-ghost btn-circle">
                                <ArrowsPointingInIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </span>
                        </div>

                        <div className="divider my-3" />
                        <Doughnut
                            style={{
                                maxHeight: '85%',
                            }}
                            id="chart"
                            data={data}
                            options={options}
                        />
                    </div>
                </div>
            ) : (
                <div className={regular_chart}>
                    <div className="flex desktop:justify-between tablet:justify-between justify-evenly items-center w-full px-2">
                        <div className="flex items-center">
                            <h3 className="text-primary font-bold text-base">
                                {title}
                            </h3>

                            {type && (
                                <>
                                    <div className="divider divider-horizontal mx-0" />
                                    <span className="badge badge-primary font-bold">
                                        {type}
                                    </span>
                                </>
                            )}
                        </div>

                        {windowSize['innerWidth'] > 1000 ? (
                            <a
                                className="btn btn-sm btn-ghost btn-circle"
                                href={'#enlarged-chart'}
                            >
                                <ArrowsPointingOutIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </a>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="divider my-3" />
                    <Doughnut
                        className="max-h-96 max-w-96"
                        id="chart"
                        data={data}
                        options={options}
                    />
                </div>
            )}
        </>
    );
};

export default PieChartComponent;