import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsLoggedIn } from '../store/auth/authentication.selectors';
import { createNotification, NOTIFICATION_TYPES } from './notification.util';

export const ProtectedRoute = ({ children }) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    if (!isLoggedIn) {
        // user is not authenticated
        createNotification(
            'You have not been authenticated',
            NOTIFICATION_TYPES.ERROR
        );
        return <Navigate to="/" replace={true} />;
    }

    return children;
};