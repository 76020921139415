import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDiscoverSearch } from '../../../store/discover/search/discover-search.selectors';

import {
    ArrowPathIcon,
    MagnifyingGlassIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';

import { DISCOVER_WIDGET_AGGREGATION_METRICS } from '../../../store/discover/widgets/widgets.types';
import {
    clearDiscoverSearchData,
    setDiscoverSearchCategories,
    setDiscoverSearchCustomers,
    setDiscoverSearchReps,
    setDiscoverSearchSkus,
} from '../../../store/discover/search/discover-search.actions';
import { discoverSearchService } from '../../../store/discover/search/discover-search.services';
import { handleError } from '../../../utils/engine/engine.service';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { REACT_TOUR_CLASS_NAMES } from '../../../utils/react-tour.util';
import { navigationService } from '../../../utils/navigation.util';
import AnimatedBorder from '../../animated-border/animated-border.component';
import { muted_border } from '../../../utils/styles/tailwind-styles';

function mapCategoryKeyToText(key) {
    let map = {
        customer: 'Customer',
        sku: 'Product',
        rep: 'Sales Rep',

        category: 'Product Category',
    };
    return map[key];
}

const DiscoverSearch = ({ className }) => {
    const discoverSearchMaster = useSelector(selectDiscoverSearch);
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState('');
    const [conglomeratedResults, setConglomeratedResults] = useState({});
    const [skuMaster, setSkuMaster] = useState([]);
    const [custMaster, setCustMaster] = useState([]);
    const [repMaster, setRepMaster] = useState([]);
    const [categoryMaster, setCategoryMaster] = useState([]);

    const [refreshing, setRefreshing] = useState(true);

    const handleChange = (event) => {
        setSearchText(event.currentTarget.value);
        if (event.currentTarget.value === '') setConglomeratedResults({});
        else {
            /*
             Search Across All Reps, Customers, Skus
            */
            const filteredSkus = skuMaster.filter((sku) => {
                return (
                    sku['descr']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase()) ||
                    sku['code']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase())
                );
            });
            filteredSkus.sort((x, y) => {
                return x[0] === event.currentTarget.value[0]
                    ? -1
                    : y[0] === event.currentTarget.value[0]
                    ? 1
                    : 0;
            });

            const filteredCusts = custMaster.filter((cust) => {
                return (
                    cust['descr']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase()) ||
                    cust['code']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase())
                );
            });
            filteredCusts.sort((x, y) => {
                return x[0] === event.currentTarget.value[0]
                    ? -1
                    : y[0] === event.currentTarget.value[0]
                    ? 1
                    : 0;
            });
            const filteredReps = repMaster.filter((rep) => {
                return (
                    rep['name']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase()) ||
                    rep['code']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase())
                );
            });
            filteredReps.sort((x, y) => {
                return x[0] === event.currentTarget.value[0]
                    ? -1
                    : y[0] === event.currentTarget.value[0]
                    ? 1
                    : 0;
            });
            const filteredCategories = categoryMaster.filter((category) => {
                return (
                    category['descr']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase()) ||
                    category['code']
                        .toString()
                        .toLowerCase()
                        .includes(event.currentTarget.value.toLowerCase())
                );
            });
            filteredCategories.sort((x, y) => {
                return x[0] === event.currentTarget.value[0]
                    ? -1
                    : y[0] === event.currentTarget.value[0]
                    ? 1
                    : 0;
            });

            const conglomeratedSearchResults = {
                customer: filteredCusts.slice(0, 10),
                sku: filteredSkus.slice(0, 10),
                rep: filteredReps.slice(0, 10),

                category: filteredCategories.slice(0, 10),
            };
            setConglomeratedResults(conglomeratedSearchResults);
        }
    };

    const handleClear = () => {
        setSearchText('');
        setConglomeratedResults({});
    };

    const handleRefresh = async () => {
        setSearchText('');
        setConglomeratedResults({});

        dispatch(clearDiscoverSearchData());

        let searchData = null;
        setRefreshing(true);

        try {
            searchData = await discoverSearchService.loadDiscoverSearchData();

            if (
                searchData &&
                Object.keys(searchData).includes('rep') &&
                searchData['rep']
            )
                dispatch(setDiscoverSearchReps(searchData['rep']));
            if (
                searchData &&
                Object.keys(searchData).includes('customer') &&
                searchData['customer']
            )
                dispatch(setDiscoverSearchCustomers(searchData['customer']));
            if (
                searchData &&
                Object.keys(searchData).includes('sku') &&
                searchData['sku']
            )
                dispatch(setDiscoverSearchSkus(searchData['sku']));
            if (
                searchData &&
                Object.keys(searchData).includes('category') &&
                searchData['category']
            )
                dispatch(setDiscoverSearchCategories(searchData['category']));

            setRefreshing(false);
        } catch (err) {
            setRefreshing(false);
        }
    };

    const handleItemClick = (category, item) => {
        setSearchText('');
        setConglomeratedResults({});

        window.open(
            navigationService.buildUrlByAggMetric(category + 's', {
                code: item['code'],
            }),
            '_blank'
        );
    };

    const fetchSearchData = async () => {
        dispatch(clearDiscoverSearchData());
        let searchData = null;

        try {
            searchData = await discoverSearchService.loadDiscoverSearchData();

            if (
                searchData &&
                Object.keys(searchData).includes('rep') &&
                searchData['rep']
            )
                dispatch(setDiscoverSearchReps(searchData['rep']));
            if (
                searchData &&
                Object.keys(searchData).includes('customer') &&
                searchData['customer']
            )
                dispatch(setDiscoverSearchCustomers(searchData['customer']));
            if (
                searchData &&
                Object.keys(searchData).includes('sku') &&
                searchData['sku']
            )
                dispatch(setDiscoverSearchSkus(searchData['sku']));
            if (
                searchData &&
                Object.keys(searchData).includes('category') &&
                searchData['category']
            )
                dispatch(setDiscoverSearchCategories(searchData['category']));
        } catch (err) {}
    };

    useEffect(() => {
        if (checkDiscoverSearchPopulated()) {
            if (
                Object.keys(discoverSearchMaster).includes('customer') &&
                discoverSearchMaster['customer']
            )
                setCustMaster(Object.values(discoverSearchMaster['customer']));

            if (
                Object.keys(discoverSearchMaster).includes('sku') &&
                discoverSearchMaster['sku']
            )
                setSkuMaster(Object.values(discoverSearchMaster['sku']));

            if (
                Object.keys(discoverSearchMaster).includes('rep') &&
                discoverSearchMaster['rep']
            )
                setRepMaster(Object.values(discoverSearchMaster['rep']));

            if (
                Object.keys(discoverSearchMaster).includes('category') &&
                discoverSearchMaster['category']
            )
                setCategoryMaster(
                    Object.values(discoverSearchMaster['category'])
                );
        }
    }, [discoverSearchMaster]);

    useEffect(() => {
        if (!checkDiscoverSearchPopulated()) {
            fetchSearchData();
        }
    }, []);

    const checkDiscoverSearchPopulated = () => {
        if (!discoverSearchMaster) return false;
        if (Object.keys(discoverSearchMaster).length === 0) return false;
        if (Object.keys(discoverSearchMaster).includes('customer')) {
            if (!discoverSearchMaster['customer']) return false;
            if (Object.keys(discoverSearchMaster['customer']).length === 0)
                return false;
        }
        return true;
    };

    return (
        <>
            {!checkDiscoverSearchPopulated() ? (
                <span
                    className={`${className} ${REACT_TOUR_CLASS_NAMES.SEARCH_1}`}
                >
                    <div className="form-control cursor-default">
                        <div className="input-group input input-bordered p-0 m-0 items-center h-9">
                            <button
                                className={`tooltip tooltip-bottom flex items-center justify-center font-normal text-xs normal-case btn laptop:btn-sm btn-xs btn-ghost bg-base-300 h-8 ${REACT_TOUR_CLASS_NAMES.SEARCH_3}`}
                            >
                                <MagnifyingGlassIcon className="laptop:w-5 laptop:h-5 w-4 h-4 text-primary" />
                            </button>

                            <input
                                className="h-full w-full input input-sm text-xs focus:shadow-none focus:outline-none focus:outline-offset-0 focus:border-none disabled"
                                disabled={true}
                                name="global-search"
                                id={REACT_TOUR_CLASS_NAMES.SEARCH_1}
                                type="text"
                                value={searchText}
                                placeholder="Loading search data..."
                                autoComplete="off"
                            />

                            <button
                                className="btn laptop:btn-sm btn-xs h-8 btn-ghost bg-base-300"
                                onClick={handleRefresh}
                            >
                                <ArrowPathIcon
                                    className={
                                        'laptop:w-5 laptop:h-5 w-4 h-4 text-primary animate-spin'
                                    }
                                />
                            </button>
                        </div>
                    </div>
                </span>
            ) : (
                <span className={`${className}`}>
                    <div
                        className={`form-control cursor-default ${REACT_TOUR_CLASS_NAMES.SEARCH_1}`}
                    >
                        <div className="input-group input input-bordered bg-base-300 p-0 m-0 items-center h-9">
                            <span
                                className={`tooltip tooltip-bottom flex items-center justify-center font-normal text-xs normal-case btn laptop:btn-sm btn-xs btn-ghost ${REACT_TOUR_CLASS_NAMES.SEARCH_3}`}
                                data-tip={`Reload data`}
                                onClick={handleRefresh}
                            >
                                <MagnifyingGlassIcon
                                    className={`laptop:w-5 laptop:h-5 w-4 h-4 text-primary`}
                                />
                            </span>

                            <input
                                className={`input input-sm input-ghost bg-base-300 text-xs focus:shadow-none focus:outline-none focus:outline-offset-0 focus:border-none w-full`}
                                onChange={handleChange}
                                name="global-search"
                                id={REACT_TOUR_CLASS_NAMES.SEARCH_1}
                                type="text"
                                value={searchText}
                                placeholder="Search..."
                                autoComplete="off"
                            />

                            <button
                                className="btn laptop:btn-sm btn-xs h-10 btn-ghost"
                                id={'btn-clear-search'}
                                onClick={handleClear}
                            >
                                <XMarkIcon className="laptop:w-5 laptop:h-5 w-4 h-4 text-error" />
                            </button>
                        </div>
                    </div>

                    <div className={`relative z-10`}>
                        {Object.keys(conglomeratedResults).length === 0 ? (
                            <div
                                id={'discover-search-results-tour-step'}
                                className={`card card-body p-0 absolute top-0 left-0 bg-base-300 shadow-lg min-w-full gap-1 ${REACT_TOUR_CLASS_NAMES.SEARCH_2}`}
                            >
                                <div
                                    className={
                                        'text-left p-0 text-xs text-primary'
                                    }
                                ></div>
                            </div>
                        ) : (
                            <div
                                className={`card card-body ${muted_border} p-4 absolute top-0 left-0 bg-base-300 shadow-lg min-w-full gap-1 ${REACT_TOUR_CLASS_NAMES.SEARCH_2} group`}
                            >
                                <AnimatedBorder withGlow={false} />

                                {Object.keys(conglomeratedResults).map(
                                    (category, index) => {
                                        return (
                                            <div key={category}>
                                                <div
                                                    key={category}
                                                    className="flex items-center gap-2 text-xs text-primary p-2"
                                                >
                                                    {mapCategoryKeyToText(
                                                        category
                                                    )}

                                                    {/*<DownloadExtract*/}
                                                    {/*    metric={`${category}_master`}*/}
                                                    {/*    perspective={null}*/}
                                                    {/*    selectedDate={null}*/}
                                                    {/*    onDateChange={null}*/}
                                                    {/*/>*/}
                                                </div>

                                                {conglomeratedResults[category]
                                                    .length !== 0 ? (
                                                    conglomeratedResults[
                                                        category
                                                    ].map((item) => {
                                                        return (
                                                            <div
                                                                className={
                                                                    'text-left p-2 hover:underline text-xs cursor-pointer'
                                                                }
                                                                onClick={() =>
                                                                    handleItemClick(
                                                                        category,
                                                                        item
                                                                    )
                                                                }
                                                                key={
                                                                    item['code']
                                                                }
                                                            >
                                                                <strong className="mr-2 font-mono">
                                                                    {
                                                                        item[
                                                                            'code'
                                                                        ]
                                                                    }
                                                                </strong>

                                                                {category ===
                                                                'rep'
                                                                    ? item[
                                                                          'name'
                                                                      ]
                                                                    : item[
                                                                          'descr'
                                                                      ]}
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div
                                                        className={
                                                            'text-xs text-base-content text-opacity-60 my-1'
                                                        }
                                                    >
                                                        No results found.
                                                    </div>
                                                )}

                                                <div className="divider my-1" />
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        )}
                    </div>
                </span>
            )}
        </>
    );
};

export default DiscoverSearch;