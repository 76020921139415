export const selectDashboardActiveComponent = (state) =>
    state.baseDashboard.activeComponent;
export const selectDashboardTopSkus = (state) =>
    state.baseDashboard.topSkus;
export const selectDashboardTopStores = (state) =>
    state.baseDashboard.topStores;
export const selectDashboardSalesPerformance = (state) =>
    state.baseDashboard.salesPerformance;
export const selectDashboardDataStatus = (state) =>
    state.baseDashboard.dataStatus;
export const selectDashboardActivity = (state) =>
    state.baseDashboard.activity;
export const selectDashboardSidebarActive = (state) =>
    state.baseDashboard.dashboardSidebarActive;
export const selectDashboardSidebarMode = (state) =>
    state.baseDashboard.dashboardSidebarMode;
