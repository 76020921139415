import { createAction } from '../../../utils/reducer.utils';
import { BASE_FILTER_OPTIONS_ACTION_TYPES } from './base-filter-options.types';

export const setBaseFilterOptions = (filterOptions) => {
    return createAction(
        BASE_FILTER_OPTIONS_ACTION_TYPES.SET_FILTERS_OPTIONS,
        filterOptions
    );
};

export const clearBaseFilterOptions = () => {
    return createAction(BASE_FILTER_OPTIONS_ACTION_TYPES.CLEAR_FILTERS_OPTIONS);
};
