import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

import {
    selectDashboardActiveComponent,
    selectDashboardSidebarActive,
    selectDashboardSidebarMode,
} from '../../../store/base/dashboard/dashboard.selectors';
import {
    setDashboardActiveComponent,
    setDashboardSidebarActive,
    setDashboardSidebarMode,
} from '../../../store/base/dashboard/dashboard.actions';

import { GLOBAL_STYLES } from '../../../utils/styles/global-styles';
import '../../navigation-bar-component/navigation-bar.styles.css';
import { base_subnavigation_bar } from '../../../utils/styles/tailwind-styles';

export const DASHBOARD_NAVIGATION_COMPONENT_TABS = {
    SALES_PERFORMANCE: 'Sales Performance',
    TOP_SKU_CODES: 'Top Sku Codes',
    TOP_STORES: 'Top Stores',
    DATA_STATUS: 'Data Status',
    // ACTIVITY: 'Activity',
};

const DashboardNavigation = () => {
    const dispatch = useDispatch();

    const activeComponent = useSelector(selectDashboardActiveComponent);

    const sidebarMode = useSelector(selectDashboardSidebarMode);
    const sidebarActive = useSelector(selectDashboardSidebarActive);

    const handleComponentClick = (value) => {
        dispatch(setDashboardActiveComponent(value));
        toggleSideMenuActive();
    };

    const toggleSideMenuActive = () => {
        dispatch(setDashboardSidebarActive(!sidebarActive));
    };

    /*
    Create a listener on initial render to identify screen width changes
     */
    useEffect(() => {
        function handleResize() {
            if (
                window.innerWidth >=
                GLOBAL_STYLES['screen-xl'].replace('px', '')
            ) {
                dispatch(setDashboardSidebarMode(false));
            } else if (
                window.innerWidth < GLOBAL_STYLES['screen-xl'].replace('px', '')
            ) {
                dispatch(setDashboardSidebarMode(true));
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`${base_subnavigation_bar} drop-shadow`}>
            <div
                className={`flex w-full h-full justify-between items-center max-w-8xl`}
            >
                <a
                    className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                    href={'/base/dashboard'}
                >
                    Dashboard
                </a>

                <div
                    className={` ${
                        !sidebarMode
                            ? 'flex flex-none justify-center items-center h-full min-h-full'
                            : sidebarActive
                            ? 'visible absolute top-full right-0 bg-base-300 flex flex-col justify-end text-end items-start p-4 drop-shadow z-50'
                            : 'invisible absolute top-full right-0 bg-base-300 flex flex-col p-4 drop-shadow'
                    }`}
                >
                    {Object.keys(DASHBOARD_NAVIGATION_COMPONENT_TABS).map(
                        (key, index) => {
                            return (
                                <div
                                    className={`flex justify-center items-center cursor-pointer h-full min-h-16 w-40 m-0 text-xs normal-case hover:text-primary ${
                                        activeComponent ===
                                        DASHBOARD_NAVIGATION_COMPONENT_TABS[key]
                                            ? 'bg-base-100'
                                            : 'bg-base-300'
                                    }`}
                                    key={
                                        DASHBOARD_NAVIGATION_COMPONENT_TABS[key]
                                    }
                                    onClick={() => {
                                        handleComponentClick(
                                            DASHBOARD_NAVIGATION_COMPONENT_TABS[
                                                key
                                            ]
                                        );
                                    }}
                                >
                                    <p>
                                        {
                                            DASHBOARD_NAVIGATION_COMPONENT_TABS[
                                                key
                                            ]
                                        }
                                    </p>
                                </div>
                            );
                        }
                    )}
                </div>

                {sidebarMode &&
                    (sidebarActive ? (
                        <div
                            className="flex p-4 justify-center rounded-btn btn-ghost normal-case font-normal"
                            onClick={toggleSideMenuActive}
                        >
                            <p className="text-xs mr-2">{activeComponent}</p>

                            <ChevronUpIcon className="w-5 h-5" />
                        </div>
                    ) : (
                        <div
                            className="flex p-4 justify-center rounded-btn btn-ghost normal-case font-normal"
                            onClick={toggleSideMenuActive}
                        >
                            <p className="text-xs mr-2">{activeComponent}</p>

                            <ChevronDownIcon className="w-5 h-5" />
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default DashboardNavigation;