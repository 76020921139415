import request from 'axios';
import axios from 'axios';
import { store } from '../store/store';
import { OPS_HOSTNAME } from './engine/socket-config.service';
import { createNotification, NOTIFICATION_TYPES } from './notification.util';

let headers = { 'Content-type': 'application/json' };

let instance = axios.create({
    baseURL: OPS_HOSTNAME,
    headers,
    timeout: 240000,
    timeoutErrorMessage: 'Request timed out',
});

instance.interceptors.request.use(function (config) {
    if (store.getState().authentication.user) {
        config.headers.Authorization = `Bearer ${
            store.getState().authentication.user.token
        }`;
    }
    return config;
});

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log(error);

        if (request.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                if (
                    window.location.pathname.includes('login') ||
                    window.location.pathname.includes('urlrequest') ||
                    window.location.pathname.includes('fileshare')
                ) {
                    createNotification(
                        'Invalid credentials',
                        NOTIFICATION_TYPES.ERROR
                    );
                } else {
                    window.location.href = '/login';
                }

                return;
            }

            try {
                if (error.response.status === 403) {
                    // expired token, point to login
                    window.location.href = '/login';
                    return;
                }

                if (error.response?.data) {
                    /*
                    Any job which has died for whatever reason, will end up here.
                     */
                    if (error.response?.data?.message) {
                        if (
                            error.response.data.message === 'No response found.'
                        ) {
                            createNotification(
                                'High volumes',
                                NOTIFICATION_TYPES.WARNING,
                                5000
                            );
                        } else {
                            createNotification(
                                error.response.data.message,
                                NOTIFICATION_TYPES.ERROR
                            );
                        }
                    }
                    return;
                }
            } catch (err) {}
        }

        return Promise.reject(error);
    }
);

export default instance;