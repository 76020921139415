import { XMarkIcon } from '@heroicons/react/24/solid';
import { formula_box, help_box } from '../../../utils/styles/tailwind-styles';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import React, { Fragment } from 'react';

const UnusedCreditHelp = ({ onClose }) => {
    return (
        <>
            <div className="flex flex-col gap-2 text-sm">
                <span className="badge badge-lg badge-primary">Trend</span>
                <p className="px-2">
                    The <strong>Unused Credit Trend</strong> widget provides a
                    trending view of what portion a customer has used of their
                    allocated credit limit in sales. The graph inidcates{' '}
                    <strong>sales value</strong>
                    <span className="badge-md badge-primary rounded-full mx-2 bg-opacity-60" />{' '}
                    and <strong>remaining credit</strong>
                    <span className="badge-md badge-secondary rounded-full mx-2 bg-opacity-60" />
                    for the current period. <br />
                    <br />
                    To calculate credit limit for given time periods, we
                    extrapolate the monthly credit limit given within a
                    customer's masterdata. E.g for a customer with a monthly
                    credit limit of{' '}
                    <span className="font-mono italic">$10000</span>, we can
                    calculate the credit limit as
                </p>

                <div className={formula_box}>
                    {
                        '\\(limit_{day} = \\frac{limit_{month}}{days in month} \\)'
                    }
                </div>
            </div>
        </>
    );
};

export default UnusedCreditHelp;