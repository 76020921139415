import { Navigate } from 'react-router-dom';
import { selectUser } from '../store/auth/authentication.selectors';
import { useSelector } from 'react-redux';

import { createNotification, NOTIFICATION_TYPES } from './notification.util';

export const RoleProtectedRoute = ({ roles, children }) => {
    const userRole = useSelector(selectUser).role;

    if (!userRole || !roles.includes(userRole)) {
        createNotification(
            'You do not have access to that feature',
            NOTIFICATION_TYPES.ERROR
        );
        return <Navigate to="/" replace={true} />;
    }

    return children;
};