import { Fragment } from 'react';
import { BanknotesIcon, ScaleIcon } from '@heroicons/react/24/solid';

import MiniBarChartComponent from '../../charts/mini-bar/mini-bar-chart.component';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';
import NoData from '../../no-data/no-data.component';
import { navigationService } from '../../../utils/navigation.util';

const SalesRank = ({
    rawData,
    plottableData,
    selectedDate,
    onDateChange,
    aggregationMetric,
}) => {
    return (
        <>
            {!plottableData[selectedDate] ? (
                <>
                    <NoData />
                </>
            ) : (
                <>
                    <div className="col-span-5">
                        <div className="grid grid-cols-10 gap-x-0 gap-y-2 py-2 px-0">
                            {rawData[selectedDate].map((unit, index) => {
                                return (
                                    <Fragment key={index}>
                                        <span className="col-span-5 flex items-center justify-self-start">
                                            <span className="font-mono text-xs font-bold text-base-content text-opacity-80">
                                                {index + 1}.
                                            </span>
                                            <a
                                                className="btn btn-ghost btn-sm normal-case hover:underline"
                                                target={'_blank'}
                                                href={navigationService.buildUrlByAggMetric(
                                                    aggregationMetric,
                                                    unit
                                                )}
                                            >
                                                {unit && unit.descr ? (
                                                    <>
                                                        {unit.descr
                                                            .replace('-', ' ')
                                                            .slice(0, 26)}
                                                        {unit.descr.length >
                                                            26 && '..'}
                                                    </>
                                                ) : (
                                                    unit.code
                                                )}
                                            </a>
                                        </span>

                                        <span
                                            className={`font-mono w-full tooltip col-span-2 justify-self-end text-sm`}
                                            data-tip={
                                                'Contribution % to Overall Sales'
                                            }
                                        >
                                            <ScaleIcon
                                                className={'w-4 h-4 mx-1'}
                                            />
                                            {unit.perc.toFixed(2)}%
                                        </span>

                                        <span
                                            className="text-primary text-sm font-mono w-full tooltip col-span-3 justify-self-end text-end"
                                            data-tip={'Net Sales Value'}
                                        >
                                            <BanknotesIcon
                                                className={'w-4 h-4 mx-1'}
                                            />
                                            R
                                            {unit.value.toLocaleString(
                                                undefined,
                                                { minimumFractionDigits: 2 }
                                            )}
                                        </span>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>

                    <div className="flex flex-col justify-start items-center w-full h-full col-span-5 p-4 rounded-box shadow">
                        <MiniBarChartComponent
                            data={plottableData[selectedDate]}
                        />

                        {rawData && rawData['subAggregates'] && (
                            <div className="col-span-full">
                                <div className="divider my-1" />

                                <WidgetSubAggregates
                                    subAggregates={rawData['subAggregates']}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default SalesRank;
