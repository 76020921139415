import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { distributionService } from '../../../../store/base/filters/distribution/distribution.services';

import FiltersContainer from '../../../../components/base/filters/filters-container.component';
import DistributionSkuTable from '../../../../components/base/disribution/distribution-sku-table.component';
import SkuDetailsComponent from '../../../../components/sku-details-component/sku-details.component';
import ScatterChartComponent from '../../../../components/charts/scatter-chart-component/scatter-chart.component';
import MultiAxisLineChartComponent from '../../../../components/charts/multi-axis-line-chart-component/multi-axis-line-chart-component';
import ShareViewComponent, {
    onShareViewClick,
} from '../../../../components/base/share-view-component/share-view.component';
import GenerateReportComponent from '../../../../components/base/generate-report-component/generate-report.component';
import NoData from '../../../../components/no-data/no-data.component';

import { apiService } from '../../../../utils/engine/api.service';
import PageLoadingComponent from '../../../../components/loading-component/page-loading-component';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';
import {
    base_grid,
    base_page,
    base_subnavigation_bar,
    base_widget_full,
    base_widget_half,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const DistributionPage = () => {
    const daisyTheme = useSelector(selectDaisyTheme);

    const userToken = useSelector(selectUserToken);
    const selectedFilters = useSelector(selectFiltersSelected);

    const [isLoading, setIsLoading] = useState(false);

    const [focusSku, setFocusSku] = useState(null);
    const [perSku, setPerSku] = useState(null);

    const [fullRangingGraphData, setFullRangingGraphData] = useState(null);
    const [focusSkuGraphData, setFocusSkuGraphData] = useState(null);
    const [focusSkuGraphDataPlottable, setFocusSkuGraphDataPlottable] =
        useState(null);

    const onShare = async (recipientsSelected, additionalMessage) => {
        setIsLoading(true);
        try {
            await onShareViewClick(
                userToken,
                selectedFilters,
                null,
                recipientsSelected,
                additionalMessage
            );
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchDistROSHelper = async () => {
        if (
            !Object.keys(selectedFilters).includes('supplskus') &&
            !Object.keys(selectedFilters).includes('supplbrands') &&
            !Object.keys(selectedFilters).includes('supplcategories')
        ) {
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            if (focusSku) {
                const distRosData =
                    await distributionService.loadDistributionROSData(
                        selectedFilters,
                        focusSku
                    );
                setPerSku(distRosData['perSku']);
                setFocusSkuGraphData(distRosData);
                setFocusSkuGraphDataPlottable(
                    distributionService.convertRangingFocusSkuGraphDataToPlottable(
                        distRosData['focusSkuGraph'],
                        daisyTheme
                    )
                );
            } else {
                const distRosData =
                    await distributionService.loadDistributionROSData(
                        selectedFilters,
                        focusSku
                    );

                if (
                    distRosData['perSku'].length === 0 ||
                    distRosData['graphData'].length === 0
                ) {
                    createNotification(
                        'No analysis data',
                        NOTIFICATION_TYPES.ERROR
                    );
                } else {
                    setPerSku(distRosData['perSku']);

                    setFullRangingGraphData(
                        distributionService.convertDistributionROSDataToPlottable(
                            distRosData
                        )
                    );
                }
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (focusSkuGraphData !== null) {
            setFocusSkuGraphDataPlottable(
                distributionService.convertRangingFocusSkuGraphDataToPlottable(
                    focusSkuGraphData['focusSkuGraph'],
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    const handleSkuClick = (event) => {
        clearFocusSku();
        setFocusSku(event);
    };
    const focusSkuByText = (skuDescription) => {
        if (skuDescription) {
            const skuObject = perSku.filter((sku) => {
                return sku.supplSkuDescr === skuDescription;
            });
            handleSkuClick(skuObject[0]);
        } else {
            clearFocusSku();
        }
    };

    const clearFocusSku = () => {
        setFocusSku(null);
    };

    const onGenerateReportClick = async (reportParams) => {
        try {
            setIsLoading(true);
            const distRosData =
                await distributionService.loadDistributionROSData(
                    selectedFilters,
                    focusSku,
                    reportParams
                );
            if (
                distRosData.message &&
                distRosData.message === 'Processing Request'
            )
                createNotification(
                    'Report request sent',
                    NOTIFICATION_TYPES.SUCCESS
                );
            if (reportParams['cronText'] != null) {
                await apiService.scheduleDistributionProcess(
                    selectedFilters,
                    reportParams,
                    reportParams['cronText']
                );
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDistROSHelper();
    }, [selectedFilters, focusSku]);

    return (
        <>
            <div className={base_subnavigation_bar}>
                <div className="flex w-full h-full justify-between items-center max-w-8xl">
                    <span className={'flex items-center gap-1'}>
                        <a
                            className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                            href={'/base/distribution'}
                        >
                            Weighted Distribution Analysis
                        </a>

                        <ShareViewComponent onClick={onShare} />
                        <GenerateReportComponent
                            onGenerate={onGenerateReportClick}
                            dateRange={false}
                            includeSoh={false}
                            frequency={false}
                        />
                    </span>
                </div>
            </div>

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <FiltersContainer className={'col-span-full'} />

                    {isLoading ? (
                        <PageLoadingComponent />
                    ) : !selectedFilters['supplbrands'] &&
                      !selectedFilters['supplcategories'] &&
                      !selectedFilters['supplskus'] ? (
                        <div className={`${base_widget_full} ${muted_border}`}>
                            <div
                                className={`w-full flex items-center justify-center gap-2 p-6`}
                            >
                                <ExclamationCircleIcon
                                    className={'w-5 h-5 text-warning'}
                                />

                                <h2 className="text-warning text-sm font-bold">
                                    Please filter by skus, brands or categories
                                    to use this tool.
                                </h2>
                            </div>
                        </div>
                    ) : (
                        <>
                            {focusSku && focusSkuGraphDataPlottable !== null ? (
                                <div
                                    className={`${
                                        focusSku
                                            ? base_widget_half
                                            : base_widget_full
                                    } ${muted_border}`}
                                >
                                    <MultiAxisLineChartComponent
                                        data={focusSkuGraphDataPlottable}
                                        title={focusSku.supplSkuDescr}
                                    />
                                </div>
                            ) : fullRangingGraphData !== null ? (
                                <div
                                    className={`${
                                        focusSku
                                            ? base_widget_half
                                            : base_widget_full
                                    } ${muted_border}`}
                                >
                                    <ScatterChartComponent
                                        data={fullRangingGraphData}
                                        title={
                                            'Rate of Sale vs Average Distribution (12mm)'
                                        }
                                        handleSkuClick={focusSkuByText}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}

                            {focusSku && (
                                <div
                                    className={`${base_widget_half} ${muted_border}`}
                                >
                                    <SkuDetailsComponent
                                        title={focusSku['supplSkuDescr']}
                                        skuDetails={focusSku}
                                        onClear={clearFocusSku}
                                        className="w-full"
                                        isDistPage={true}
                                    />
                                </div>
                            )}

                            {perSku ? (
                                <div
                                    className={`${base_widget_full} ${muted_border}`}
                                >
                                    <DistributionSkuTable
                                        skuData={perSku}
                                        focusSku={focusSku}
                                        handleSkuClick={handleSkuClick}
                                    />
                                </div>
                            ) : (
                                <NoData className="col-span-full" />
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default DistributionPage;