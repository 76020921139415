import { VIEW_SHARE_ACTION_TYPES } from './view-share.types';

const INITIAL_STATE = {
    selectedFilters: null,
    trendFilters: null,
};

export const viewShareReducer = (state=INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case VIEW_SHARE_ACTION_TYPES.SET_FILTERS_SELECTED:
            return {
                ...state,
                selectedFilters: payload
            };

        case VIEW_SHARE_ACTION_TYPES.SET_TREND_FILTER_FREQUENCY:
            return {
                ...state,
                trendFilters: {
                    ...state['trendFilters'],
                    frequency: payload
                }
            }
        case VIEW_SHARE_ACTION_TYPES.SET_TREND_FILTER_COMPARE:
            return {
                ...state,
                trendFilters: {
                    ...state['trendFilters'],
                    compare: payload
                }
            }

        case VIEW_SHARE_ACTION_TYPES.CLEAR_FILTERS:
            return { ...INITIAL_STATE }

        default:
            return state;
    }
}
