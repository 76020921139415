import { createAction } from '../utils/reducer.utils';
import { THEMES_ACTION_TYPES } from './themes.types';

export const setThemeMode = (mode) => {
    return createAction(THEMES_ACTION_TYPES.SET_THEME_MODE, mode);
};

export const setThemeColor = (color) => {
    return createAction(THEMES_ACTION_TYPES.SET_THEME_COLOR, color);
};

export const setDaisyTheme = (daisy) => {
    return createAction(THEMES_ACTION_TYPES.SET_DAISY_THEME, daisy);
};
