import {
    discover_grid,
    discover_page,
    muted_border,
} from '../../utils/styles/tailwind-styles';
import { REACT_TOUR_CLASS_NAMES } from '../../utils/react-tour.util';
import { getStubGraphBasedOnVisualisation } from '../discover/widget/add-widget.component';
import { DISCOVER_WIDGET_DATA_VISUALIZATIONS } from '../../store/discover/widgets/widgets.types';
import {
    generateStubBarChartData,
    generateStubLineChartData,
} from '../../utils/dev-data/dev-data';
import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../store/themes/themes.selectors';

const PublicBackdrop = () => {
    const daisyTheme = useSelector(selectDaisyTheme);

    return (
        <div className="z-0 absolute top-0 left-0 w-full h-full max-h-full overflow-y-clip">
            <div
                className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
            >
                <div className={`${discover_grid} relative`}>
                    <div className="grid gap-2 grid-cols-5 col-span-full blur">
                        <div
                            className={`card gap-2 w-full p-6 h-full col-span-2 ${muted_border} bg-base-300 z-0`}
                        >
                            <span className=" w-full h-full">
                                {getStubGraphBasedOnVisualisation(
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.SINGULAR,
                                    {}
                                )}
                            </span>
                        </div>

                        <div
                            className={`card gap-2 w-full p-6 h-full col-span-3 ${muted_border} bg-base-300 z-0`}
                        >
                            <span className=" w-full h-full">
                                {getStubGraphBasedOnVisualisation(
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
                                    generateStubLineChartData(daisyTheme)
                                )}
                            </span>
                        </div>

                        <div
                            className={`card gap-2 w-full p-6 h-full col-span-3 ${muted_border} bg-base-300 z-0`}
                        >
                            <span className=" w-full h-full">
                                {getStubGraphBasedOnVisualisation(
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
                                    generateStubBarChartData(daisyTheme)
                                )}
                            </span>
                        </div>

                        <div
                            className={`card gap-2 w-full p-6 h-full col-span-2 ${muted_border} bg-base-300 z-0`}
                        >
                            <span className=" w-full h-full">
                                {getStubGraphBasedOnVisualisation(
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
                                    generateStubBarChartData(daisyTheme)
                                )}
                            </span>
                        </div>

                        <div
                            className={`card gap-2 w-full p-6 h-full col-span-2 ${muted_border} bg-base-300 z-0`}
                        >
                            <span className=" w-full h-full">
                                {getStubGraphBasedOnVisualisation(
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.SINGULAR,
                                    {}
                                )}
                            </span>
                        </div>
                        <div
                            className={`card gap-2 w-full p-6 h-full col-span-3 ${muted_border} bg-base-300 z-0`}
                        >
                            <span className=" w-full h-full">
                                {getStubGraphBasedOnVisualisation(
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
                                    generateStubLineChartData(daisyTheme)
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="z-0 absolute top-0 left-0 w-full h-full max-h-full overflow-y-clip bg-base-100 bg-opacity-70" />
                </div>
            </div>
        </div>
    );
};

export default PublicBackdrop;