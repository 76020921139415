import { engineService } from '../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';

import { dateOptions } from '../../../components/limited-date-range-selector/limited-date-range-selector.component';
import {
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_SORT_TYPES,
} from '../widgets/widgets.types';

import http from '../../../utils/request.util';
import axios from 'axios';
import { OPS_HOSTNAME } from '../../../utils/engine/socket-config.service';
import { store } from '../../store';

export const DISCOVER_GRAPH_TYPES = {
    TREND: 'trend',
    RANKING: 'ranking',
    SINGULAR: 'singular',
};

export const discoverHomeService = {
    getDiscoverSalesTrend,
    getDiscoverSalesRanking,
    getDiscoverReturnsTrend,
    getDiscoverReturnsRanking,
    getDiscoverDiscountsTrend,
    getDiscoverUnusedCreditTrend,
    getDiscoverProfitRanking,
    getDiscoverProfitTrend,

    getDiscoverOpportunityRanking,
    getDiscoverChaos,
    getDiscoverChaosRanking,

    submitDataExtractRequest,
    submitTargetTemplateRequest,
    submitTargetTemplateUploadRequest,

    getDetailData,

    setTarget,
    getTargets,

    getExclusion,
    getExclusions,
    addExclusion,
    removeExclusion,
};

async function getDiscoverSalesTrend(
    type,
    perspective,
    aggregationMetric = null
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_SALES,
        fetchParam
    );
    return response;
}

async function getTargets(metric, perspective) {
    const fetchParam = {
        action: 'extract',
        metric:
            metric !== DISCOVER_WIDGET_DATA_METRICS.DISCOUNT
                ? metric
                : metric + 's',
        perspective: perspective,
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_TARGETS,
        fetchParam
    );
    return response;
}

async function setTarget(metric, perspective, targetDate, targetValue) {
    const fetchParam = {
        action: 'update',
        metric:
            metric !== DISCOVER_WIDGET_DATA_METRICS.DISCOUNT
                ? metric
                : metric + 's',
        perspective: perspective,
        target: {
            value: targetValue,
            date: targetDate,
        },
    };

    const response = engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_TARGETS,
        fetchParam
    );
    return response;
}

async function getDiscoverSalesRanking(
    type,
    perspective,
    sort,
    aggregationMetric = null,
    limit = 5
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
        sort,
        limit,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_SALES,
        fetchParam
    );
    return response;
}

async function getDiscoverReturnsTrend(
    type,
    perspective,
    aggregationMetric = null
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_RETURNS,
        fetchParam
    );
    return response;
}

async function getDiscoverReturnsRanking(
    type,
    perspective,
    sort,
    aggregationMetric = null,
    limit = 5
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
        sort,
        limit,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_RETURNS,
        fetchParam
    );
    return response;
}

async function getDiscoverDiscountsTrend(
    type,
    perspective,
    aggregationMetric = null
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_DISCOUNT,
        fetchParam
    );
    return response;
}

async function getDiscoverUnusedCreditTrend(
    type,
    perspective,
    aggregationMetric = null
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.UNUSED_CREDIT,
        fetchParam
    );
    return response;
}

async function getDiscoverProfitRanking(
    type,
    perspective,
    sort,
    aggregationMetric = null,
    limit = 5,
    sortType = DISCOVER_WIDGET_SORT_TYPES.VALUE
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
        sort,
        limit,
        sortType,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_PROFIT,
        fetchParam
    );
    return response;
}

async function getDiscoverProfitTrend(
    type,
    perspective,
    aggregationMetric = null
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_PROFIT,
        fetchParam
    );
    return response;
}

async function getDiscoverOpportunityRanking(
    type,
    perspective,
    sort,
    aggregationMetric = null,
    limit = 5
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
        sort,
        limit,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_OPPORTUNITY,
        fetchParam
    );
    return response;
}

async function getDiscoverChaos(type, perspective) {
    const fetchParam = {
        dateOptions,
        type,
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_CHAOS,
        fetchParam
    );
    return response;
}

async function getDiscoverChaosRanking(
    type,
    aggregationMetric,
    sort,
    limit = 5
) {
    const fetchParam = {
        dateOptions,
        type,
        aggregationMetric,
        sort,
        limit,
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_CHAOS,
        fetchParam
    );
    return response;
}

async function submitDataExtractRequest(
    metric,
    perspective,
    selectedDate,
    user
) {
    const fetchParam = {
        metric:
            metric !== DISCOVER_WIDGET_DATA_METRICS.DISCOUNT
                ? metric
                : metric + 's',
        userData: {
            name: user.name,
            email: user.email,
        },
    };
    if (perspective && perspective['code'] !== null)
        fetchParam['perspective'] = perspective;
    if (selectedDate) fetchParam['selectedDate'] = selectedDate;

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_EXTRACT_RAW_DATA,
        fetchParam
    );
    return response;
}

async function submitTargetTemplateRequest(
    metric,
    perspectives,
    timeFrame,
    user
) {
    const fetchParam = {
        action: 'template',
        metric:
            metric !== DISCOVER_WIDGET_DATA_METRICS.DISCOUNT
                ? metric
                : metric + 's',
        timeFrame: timeFrame,
        perspectives: perspectives,
        userData: {
            name: user.name,
            email: user.email,
        },
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_TARGETS,
        fetchParam
    );
    return response;
}

async function submitTargetTemplateUploadRequest(file, metric) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('metric', metric);

    const response = await axios.post(
        OPS_HOSTNAME + `/api/prweb/upload/targets`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${
                    store.getState().authentication.user.token
                }`,
            },
        }
    );
    return response;
}

async function getDetailData(perspective) {
    const fetchParam = {
        perspective: perspective,
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_SEARCH_DATA,
        fetchParam
    );
    return response;
}

async function getExclusion(perspective_type, perspective_code) {
    let fetchParams = {
        action: 'getExclusion',
        payload: {
            perspective_type,
            perspective_code,
        },
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.MASTERDATA,
        fetchParams
    );
    return response;
}

async function getExclusions(perspective_type, perspective_code, token) {
    let fetchParams = {
        action: 'getExclusions',
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.MASTERDATA,
        fetchParams
    );
    return response;
}

async function addExclusion(perspective_type, perspective_code, token) {
    let fetchParams = {
        action: 'addExclusion',
        payload: {
            perspective_type,
            perspective_code,
        },
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.MASTERDATA,
        fetchParams
    );
    return response;
}

async function removeExclusion(perspective_type, perspective_code) {
    let fetchParams = {
        action: 'removeExclusion',
        payload: {
            perspective_type,
            perspective_code,
        },
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.MASTERDATA,
        fetchParams
    );
    return response;
}