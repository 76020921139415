import {
    bouncy,
    tailChase,
    tailspin,
    square,
    squircle,
    ring,
    superballs,
    helix,
    ping,
} from 'ldrs';
import { DAISY_THEME_MAP } from '../../utils/styles/global-styles';
import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../store/themes/themes.selectors';

export const LOADING_COMPONENT_TYPES = {
    TAILSPIN: 'tailspin',
    HELIX: 'helix',
    TAILCHASE: 'tailchase',
    BOUNCY: 'bouncy',
    RING: 'ring',
    SQUARE: 'square',
    SQUIRCLE: 'squircle',
    SUPERBALLS: 'superballs',
    PING: 'ping',

    TABLE_SKELETON: 'table_skeleton',
};

tailspin.register();
tailChase.register();
bouncy.register();
ring.register();
square.register();
squircle.register();
superballs.register();
helix.register();
ping.register();

export const LoadingComponent = ({
    type,
    size = 40,
    speed = 1.2,
    stroke = 6,
}) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    switch (type) {
        case LOADING_COMPONENT_TYPES.TAILSPIN:
            return (
                <l-tailspin
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.TAILCHASE:
            return (
                <l-tail-chase
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.HELIX:
            return (
                <l-helix
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.BOUNCY:
            return (
                <l-bouncy
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.RING:
            return (
                <l-ring
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.SQUARE:
            return (
                <l-square
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.SQUIRCLE:
            return (
                <l-squircle
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.SUPERBALLS:
            return (
                <l-superballs
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.PING:
            return (
                <l-ping
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
        case LOADING_COMPONENT_TYPES.TABLE_SKELETON:
            return (
                <div className="w-full grid grid-cols-4 place-items-center justify-items-center gap-x-2 gap-y-4">
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-4"></span>

                    {/*HEADER*/}
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-2"></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-2"></span>

                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-2"></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-2"></span>

                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse"></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse "></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse "></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse "></span>

                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse "></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-3"></span>

                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-3"></span>
                    <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse col-span-1"></span>
                </div>
            );

        default:
            return (
                <l-superballs
                    color={DAISY_THEME_MAP[daisyTheme]['primary']}
                    speed={speed}
                    size={size}
                    stroke={stroke}
                />
            );
    }
};

export default LoadingComponent;