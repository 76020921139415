import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';

import Widget from '../../../../components/discover/widget/widget.component';
import { useEffect, useState } from 'react';
import DiscoverSubNavigation from '../../../../components/discover/discover-sub-navigation/discover-sub-navigation.component';
import {
    discover_grid,
    discover_page,
    muted_border,
    widget_full,
    widget_half,
} from '../../../../utils/styles/tailwind-styles';

import { StarIcon } from '@heroicons/react/24/outline';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';
import { selectDiscoverWatchlistPageWidgets } from '../../../../store/discover/widgets/widgets.selectors';
import { objectService } from '../../../../utils/object.util';

const WatchlistPage = () => {
    const daisyTheme = useSelector(selectDaisyTheme);

    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('selectedDate') ?? 'MTD'
    );

    const watchlistPageWidgets = useSelector(
        selectDiscoverWatchlistPageWidgets
    );

    const [sortedWidgets, setSortedWidgets] = useState(null);

    const handleDateChange = (date) => {
        localStorage.setItem('selectedDate', date);
        setSelectedDate(date);
    };

    useEffect(() => {
        // dispatch(clearWidgets());

        if (!objectService.checkObjectPopulated(watchlistPageWidgets)) {
            setSortedWidgets(null);
        } else {
            const sortedKeys = Object.keys(watchlistPageWidgets).sort(
                (a, b) => {
                    return a > b;
                }
            );
            let tempSortedWidgets = {};

            sortedKeys.forEach((key) => {
                tempSortedWidgets[key] = watchlistPageWidgets[key];
            });
            setSortedWidgets(tempSortedWidgets);
        }
    }, [watchlistPageWidgets]);

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <DiscoverSubNavigation
                    title="Watchlist"
                    onDateChange={handleDateChange}
                    selectedDate={selectedDate}
                    widgets={sortedWidgets}
                    className={`col-span-2 row-span-max sticky ${muted_border}`}
                />

                {objectService.checkObjectPopulated(sortedWidgets) ? (
                    Object.keys(sortedWidgets).map((watchlistKey, index) => {
                        const widget = watchlistPageWidgets[watchlistKey];
                        if (
                            Object.keys(watchlistPageWidgets).includes(
                                watchlistKey
                            )
                        ) {
                            return (
                                <Widget
                                    id={watchlistKey}
                                    key={watchlistKey}
                                    perspective={widget.perspective}
                                    metric={widget.metric}
                                    visualization={widget.visualization}
                                    sortDirection={widget.sortDirection}
                                    aggregationMetric={widget.aggregationMetric}
                                    selectedDate={selectedDate}
                                    onDateChange={handleDateChange}
                                    size={widget.size}
                                    className={`${
                                        widget.size === 'full'
                                            ? widget_full
                                            : widget_half
                                    } ${
                                        index === 0 &&
                                        REACT_TOUR_CLASS_NAMES.WIDGET_1
                                    }`}
                                    daisyTheme={daisyTheme}
                                />
                            );
                        }
                    })
                ) : (
                    <>
                        <div className={`${widget_full} ${muted_border}`}>
                            <span className="stat-title col-span-5">
                                You have not added any widgets to your
                                watchlist.
                            </span>
                            <span className="flex items-center justify-start gap-1 text-xs text-base-content text-opacity-80 col-span-5">
                                To add a widget to your watchlist, click the{' '}
                                <StarIcon className={'w-4 h-4'} /> on any widget
                                and it will appear on this page.
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default WatchlistPage;