export const WEB_PROCESS_FLOWS = {
    /*
    BASE
     */
    // dashboard
    DASHBOARD_DATA_STATUS: 'web_dashboard_data_status',
    DASHBOARD_TOP_SKUS: 'web_dashboard_top_skus',
    DASHBOARD_TOP_STORES: 'web_dashboard_top_stores',
    DASHBOARD_SALES_PERFORMANCE: 'web_dashboard_sales_performance',

    // ranging dist
    RANGING_DIST: 'web_ranging_dist',

    // alert
    ALERT_OVERVIEW: 'web_alert_overview',
    // rate of sale
    DIST_ROS: 'web_dist_ros',
    // filters
    DEFAULT_FILTERS: 'web_default_filters',
    // forecasting
    FORECASTING: 'web_forecasting_v3',
    // geo analysis
    GEO_ANALYSIS: 'web_geo_analysis',
    // inv
    INV: 'web_inv',
    // on shelf availability
    ON_SHELF_AVAILABILITY: 'web_onshelf_avail',

    // sales
    SALES: 'web_sales',
    // stock cover
    STOCK_COVER: 'web_stock_cover',

    /*
    DISCOVER
     */
    // search master
    DISCOVER_SEARCH_DATA: 'web_discover_retrieve_supplier_search_data',

    // sales trend and ranking
    DISCOVER_SALES: 'web_discover_sales',
    // returns trend and ranking
    DISCOVER_RETURNS: 'web_discover_returns',
    // profit ranking
    DISCOVER_PROFIT: 'web_discover_profit',
    // opportunity ranking
    DISCOVER_OPPORTUNITY: 'web_discover_opportunity',
    // discount trend and ranking
    DISCOVER_DISCOUNT: 'web_discover_discount',
    // unused credit
    UNUSED_CREDIT: 'web_discover_unused_credit',

    // chaos
    DISCOVER_CHAOS: 'web_discover_chaos',

    // data extract
    DISCOVER_EXTRACT_RAW_DATA: 'web_discover_extract_raw_data',
    // targets
    DISCOVER_TARGETS: 'web_discover_targets',

    /*
    GENERAL
     */
    // add user lookup
    ADD_USER_LOOKUP: 'web_add_user_lookup',
    // allows the dashboard to revoke access, exclude items and manage other masterdata items
    MASTERDATA: 'web_masterdata',
};
