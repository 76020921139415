import MiniRadarChartComponent from '../../charts/mini-radar/mini-radar-chart.component';

import ChaosElement from './chaos.element.component';

import { glowing_border } from '../../../utils/styles/tailwind-styles';
import {
    ArrowTrendingDownIcon,
    ArrowTrendingUpIcon,
} from '@heroicons/react/16/solid';
import { objectService } from '../../../utils/object.util';

const ChaosRadar = ({
    data,
    radar,
    className,
    trendVisible,
    onToggleTrend,
}) => {
    return (
        <>
            <div
                className={`stats bg-base-300 bg-opacity-80 p-2 rounded-box col-span-5 w-full ${className} border-l-2 border-base-content border-opacity-10`}
            >
                <div className={'grid grid-cols-3 gap-2 p-2 w-full'}>
                    <div
                        className={
                            'flex col-span-3 w-full items-center justify-between'
                        }
                    >
                        <span className="text-base-content text-opacity-80 text-lg font-bold">
                            Performance Scores
                        </span>

                        <div className="flex items-center gap-1">
                            <span
                                className="btn btn-ghost btn-sm btn-circle tooltip tooltip-left"
                                onClick={onToggleTrend}
                                data-tip={
                                    trendVisible ? 'Hide trend' : 'Show trend'
                                }
                            >
                                {trendVisible ? (
                                    <ArrowTrendingUpIcon
                                        className={'w-5 h-5'}
                                    />
                                ) : (
                                    <ArrowTrendingDownIcon
                                        className={'w-5 h-5'}
                                    />
                                )}
                            </span>
                        </div>
                    </div>

                    <div className="w-full col-span-2">
                        {objectService.checkObjectPopulated(radar) && (
                            <MiniRadarChartComponent
                                className={'max-h-72 w-full'}
                                data={radar}
                            />
                        )}
                    </div>

                    {objectService.checkObjectPopulated(data) &&
                        Object.keys(data).includes('chaos_pillars') && (
                            <div className="flex flex-col justify-center items-start col-span-1 gap-1">
                                <ChaosElement
                                    value={
                                        Math.round(
                                            (100 *
                                                Object.keys(
                                                    data['chaos_pillars']
                                                )
                                                    .map(
                                                        (el) =>
                                                            data[
                                                                'chaos_pillars'
                                                            ][el]
                                                    )
                                                    .reduce(
                                                        (a, b) => a + b,
                                                        0
                                                    )) /
                                                Object.keys(
                                                    data['chaos_pillars']
                                                ).length
                                        ) / 100
                                    }
                                    element={'Overall'}
                                    overall={true}
                                    ranking={2}
                                />
                                <div className="divider my-1" />
                                {Object.keys(data['chaos_pillars']).map(
                                    (pillar) => {
                                        return (
                                            <ChaosElement
                                                key={pillar}
                                                value={
                                                    data['chaos_pillars'][
                                                        pillar
                                                    ]
                                                }
                                                element={pillar}
                                            />
                                        );
                                    }
                                )}

                                <span className="text-xs text-base-content text-opacity-80 w-full text-end mt-1">
                                    [latest]
                                </span>
                            </div>
                        )}
                </div>
            </div>
        </>
    );
};

export default ChaosRadar;
