import { Fragment, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Bars3Icon, ViewfinderCircleIcon } from '@heroicons/react/24/solid';

import { selectIsLoggedIn } from '../../store/auth/authentication.selectors';
import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../store/themes/themes.selectors';
import { selectProductType } from '../../store/product/product.selectors';
import {
    selectSidebarActive,
    selectSidebarMode,
} from '../../store/navigation/navigation.selectors';
import {
    setSidebarIsActive,
    setSidebarMode,
} from '../../store/navigation/navigation.actions';

import DefaultNavigation from './default-navigation-component/default-navigation.component';
import PRBaseNavigation from '../base/pr-base-navigation-component/pr-base-navigation.component';
import PRDiscoverNavigation from '../discover/pr-discover-navigation-component/pr-discover-navigation.component';

import { PRODUCT_TYPES } from '../../store/product/product.types';

import {
    DAISY_THEME_MAP,
    GLOBAL_STYLES,
} from '../../utils/styles/global-styles';
import './navigation-bar.styles.css';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import { glowing_border } from '../../utils/styles/tailwind-styles';
import { DAISY_THEMES } from '../../store/themes/themes.types';

export const NavigationBar = () => {
    const dispatch = useDispatch();
    const daisyTheme = useSelector(selectDaisyTheme);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    const productType = useSelector(selectProductType);

    const sidebarMode = useSelector(selectSidebarMode);
    const sidebarActive = useSelector(selectSidebarActive);

    const toggleSideMenuActive = () => {
        dispatch(setSidebarIsActive(!sidebarActive));
    };

    function handleResize() {
        if (window.innerWidth >= GLOBAL_STYLES['screen-xl'].replace('px', '')) {
            dispatch(setSidebarMode(false));
        } else if (
            window.innerWidth < GLOBAL_STYLES['screen-xl'].replace('px', '')
        ) {
            dispatch(setSidebarMode(true));
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        handleResize();
    }, [isLoggedIn]);

    return (
        <>
            <div
                className="relative flex items-center min-h-12 justify-center bg-base-300 w-full z-50 drop-shadow z-1000"
                id={'navigation-bar'}
            >
                <div
                    className={`flex items-center w-full justify-between max-w-7xl`}
                >
                    <Link
                        className={`w-40 flex justify-evenly items-center bg-primary btn-primary duration-100 shadow`}
                        to={
                            !isLoggedIn
                                ? `/`
                                : productType ===
                                  PRODUCT_TYPES.PROFIT_ROCKET_BASE
                                ? '/base/dashboard'
                                : '/discover/dashboard'
                        }
                    >
                        <span
                            className={`w-full h-full bg-base-100 bg-opacity-10 flex items-center justify-center px-5 py-3`}
                        >
                            <img
                                src={`${
                                    [DAISY_THEMES.COFFEE].includes(daisyTheme)
                                        ? '/images/profitrocket-logo-white.png'
                                        : '/images/profitrocket-logo-white.png'
                                } `}
                                alt="image"
                                className={'w-full'}
                            />
                        </span>
                    </Link>

                    {!isLoggedIn || !productType ? (
                        <DefaultNavigation />
                    ) : productType === PRODUCT_TYPES.PROFIT_ROCKET_BASE ? (
                        <PRBaseNavigation />
                    ) : (
                        <PRDiscoverNavigation />
                    )}

                    {sidebarMode && (
                        <div className="btn btn-ghost btn-circle">
                            <Bars3Icon
                                className="w-5 h-5"
                                onClick={toggleSideMenuActive}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default NavigationBar;
