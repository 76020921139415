import { OPS_HOSTNAME } from '../../../utils/engine/socket-config.service';
import { formatDate } from '../../../utils/auth.util';
import { useSelector } from 'react-redux';
import { selectUserToken } from '../../../store/auth/authentication.selectors';

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const SharedFileComponent = ({ sharedFile, className }) => {
    const userToken = useSelector(selectUserToken);

    const onFileClick = async () => {
        let url =
            OPS_HOSTNAME +
            `/api/prweb/shared-files/${sharedFile['uuidStr']}/download?token=${userToken}`;
        await window.open(url).then((response) => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.redirected === true) {
                return Promise.reject('Failed to download file.');
            } else {
                return response.json();
            }
        });
    };

    return (
        <div
            className={`grid grid-cols-5 btn btn-ghost btn-lg w-full text-sm font-normal normal-case shadow ${className}`}
            onClick={onFileClick}
        >
            <a className="w-full flex justify-start items-center text-left underline col-span-2">
                {sharedFile['fileName']}
            </a>

            <p className="w-full flex justify-center items-center text-left">
                {sharedFile['sharedBy']}
            </p>

            <p className="w-full flex justify-end items-center text-left">
                {formatDate(sharedFile['dateCreated'])}
            </p>

            <p className="w-full flex justify-end items-center text-left">
                {formatBytes(sharedFile['fileSize'])}
            </p>
        </div>
    );
};

export default SharedFileComponent;
