export const REACT_TOUR_CLASS_NAMES = {
    WELCOME: 'discover-tour-search-step-0',

    SEARCH_1: 'discover-tour-search-step-1',
    SEARCH_2: 'discover-tour-search-step-2',
    SEARCH_3: 'discover-tour-search-step-3',

    THEME_1: 'discover-tour-theme-step-1',
    THEME_2: 'discover-tour-theme-step-2',
    THEME_3: 'discover-tour-theme-step-3',

    WIDGET_1: 'discover-tour-widget-step-1',
    WIDGET_2: 'discover-tour-widget-step-2',
    WIDGET_3: 'discover-tour-widget-step-3',
    WIDGET_4: 'discover-tour-widget-step-4',

    SUB_NAV: 'discover-tour-sub-nav-step-3',

    DATE_SELECTOR: 'discover-tour-date-selector-step-4',
    WIDGET_RESET: 'discover-tour-date-selector-step-4b',

    WATCHLIST: 'discover-tour-watchlist-step-5',
    HIDE: 'discover-tour-hide-step-6',
    DELETE: 'discover-tour-delete-step-7',

    ADD_WIDGET: 'discover-tour-add-widget-step-1',
    ADD_WIDGET_EXPANDED: 'discover-tour-add-widget-step-1a',
    ADD_WIDGET_METRIC: 'discover-tour-add-widget-step-2',
    ADD_WIDGET_VISUALIZATION: 'discover-tour-add-widget-step-3',
    ADD_WIDGET_SIZE: 'discover-tour-add-widget-step-4',
    ADD_WIDGET_SAVE: 'discover-tour-add-widget-step-5',

    DISCOVER_PAGE: 'discover-page',
};

export const reactTourSteps = [
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.SEARCH_1}`,
        content: 'Search across all Products, Customers or Sales Reps here.',
        actionAfter: () => {
            try {
                document
                    .getElementById(REACT_TOUR_CLASS_NAMES.SEARCH_1)
                    .focus();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.SEARCH_1}`,
        content:
            "Search results will be displayed here. Any of these items can be clicked to be transferred to a view from that item's perspective.",
        actionAfter: () => {
            try {
                document
                    .getElementById(REACT_TOUR_CLASS_NAMES.SEARCH_1)
                    .click();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.THEME_1}`,
        content: 'Access personal settings in this drop down menu',
        actionAfter: () => {
            try {
                document.getElementById(REACT_TOUR_CLASS_NAMES.THEME_1).click();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.THEME_2}`,
        content: 'Change your UI Theme',
        actionAfter: () => {
            try {
                document.getElementById(REACT_TOUR_CLASS_NAMES.THEME_1).focus();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.DATE_SELECTOR}`,
        content: "Change what period of time you'd like to see data from.",
        actionAfter: () => {
            try {
                document.getElementById(REACT_TOUR_CLASS_NAMES.THEME_1).click();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.WIDGET_RESET}`,
        content:
            'You can reset your widgets to the default configuration with this button.',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.SUB_NAV}`,
        content:
            'Quickly move between different widgets using these sub navigation menus.',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.WIDGET_1}`,
        content:
            'This is a widget, which serves as an isolated display of different types of data.',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.WATCHLIST}`,
        content:
            'Add widgets to your watchlist to build a view with only the data you want to see.',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.HIDE}`,
        content:
            'Collapse widgets so that they take up less space on your view.',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.DELETE}`,
        content: 'Delete widgets so that they no longer appear in your view.',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.WIDGET_2}`,
        content:
            'This text displays the metric type [Sales, Discounts, Performance etc..] as well as the graphing type [Trend, Rank etc...].',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.WIDGET_3}`,
        content:
            "This badge displays the widget's perspective. i.e. Who/what am I looking at data for?",
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.WIDGET_4}`,
        content:
            "This badge displays the widget's aggregation metric. i.e. What am I ranking?",
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.ADD_WIDGET}`,
        content: 'Create new widgets with our customizable widget builder',
        actionAfter: () => {
            try {
                document
                    .getElementById(REACT_TOUR_CLASS_NAMES.ADD_WIDGET)
                    .click();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_METRIC}`,
        content: 'Select the metric you want the widget to display',
        actionAfter: () => {
            try {
                document
                    .getElementById(
                        REACT_TOUR_CLASS_NAMES.ADD_WIDGET_VISUALIZATION
                    )
                    .click();
            } catch (err) {
                console.log(err);
            }
        },
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_VISUALIZATION}`,
        content: 'Select the visualization method for viewing the data',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_SIZE}`,
        content: 'Change the size of your widget',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_SAVE}`,
        content: 'Save your widget',
    },
    {
        selector: `.${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_EXPANDED}`,
        content: "Play around with values to see what's useful to you",
    },
    // {
    //     selector: `.${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`,
    //     content: "You're good to go!",
    // },
];

export const reactTourStyles = {
    maskWrapper: (base) => ({
        ...base,
        color: '#020617',
    }),
    highlightedArea: (base) => ({
        ...base,
        borderRadius: '25%',
    }),
    badge: (base) => ({
        ...base,
        color: '#020617',
        backgroundColor: '#F7FCFA',
    }),
    popover: (base) => ({
        ...base,
        color: '#020617',
        backgroundColor: '#F7FCFA',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontSize: '0.85rem',
        padding: '3rem 2rem',
        borderRadius: '10px 10px',
    }),
    button: (base) => ({
        ...base,
        color: '#020617',
    }),
    close: (base) => ({
        ...base,
        color: '#020617',
    }),
};
