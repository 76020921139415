const CompactSingleFilterContainer = ({ label }) => {
    return (
        <div
            className={`badge sm:badge-sm badge-xs badge-primary max-w-xs sm:px-4 px-1 py-3 m-0.5 text-xs sm:capitalize lowercase`}
        >
            {label.length > 14 ? `${label.substring(0, 14)}..` : label}
        </div>
    );
};

export default CompactSingleFilterContainer;
