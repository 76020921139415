import { useState } from 'react';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';

const WidgetSkeleton = ({ className = '' }) => {
    return (
        <div
            className={`flex items-center gap-4 w-full justify-evenly ${className}`}
        >
            <div className="grid grid-cols-2 gap-2 w-1/2">
                <span
                    className={
                        'bg-base-content p-4 bg-opacity-15 w-full animate-pulse'
                    }
                ></span>
                <span
                    className={
                        'bg-base-content p-4 bg-opacity-15 w-full animate-pulse'
                    }
                ></span>

                <span
                    className={
                        'bg-base-content p-4 bg-opacity-15 w-full animate-pulse col-span-2'
                    }
                ></span>

                <span
                    className={
                        'bg-base-content p-4 bg-opacity-15 w-full animate-pulse'
                    }
                ></span>
                <span
                    className={
                        'bg-base-content p-4 bg-opacity-15 w-full animate-pulse'
                    }
                ></span>
            </div>

            <div className="w-full flex items-center justify-center">
                <span
                    className={
                        'bg-base-content p-4 bg-opacity-15 w-full h-52 animate-pulse rounded-lg flex items-center justify-center'
                    }
                >
                    {/*<LoadingComponent*/}
                    {/*    size={40}*/}
                    {/*    type={LOADING_COMPONENT_TYPES.PING}*/}
                    {/*/>*/}
                </span>
            </div>
        </div>
    );
};

export default WidgetSkeleton;
