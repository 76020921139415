import { createAction } from '../../utils/reducer.utils';
import { BASE_FILTERS_ACTION_TYPES } from './base-filters.types';

export const setBaseFiltersSelected = (id, selectedFilters) => {
    return createAction(BASE_FILTERS_ACTION_TYPES.SET_FILTERS_SELECTED, {
        id,
        selectedFilters,
    });
};

export const clearBaseFilterSelected = (id) => {
    return createAction(BASE_FILTERS_ACTION_TYPES.CLEAR_FILTER_SELECTED, id);
};

export const clearBaseFiltersSelected = (idArray) => {
    return createAction(
        BASE_FILTERS_ACTION_TYPES.CLEAR_FILTERS_SELECTED,
        idArray
    );
};

export const resetBaseFiltersSelected = () => {
    return createAction(BASE_FILTERS_ACTION_TYPES.RESET_FILTERS_SELECTED);
};
