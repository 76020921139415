import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';

import DiscoverSubNavigation from '../../../../components/discover/discover-sub-navigation/discover-sub-navigation.component';

import {
    discover_grid,
    discover_page,
    muted_border,
    widget_full,
    widget_half,
} from '../../../../utils/styles/tailwind-styles';
import Widget from '../../../../components/discover/widget/widget.component';

import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';
import AddWidget from '../../../../components/discover/widget/add-widget.component';
import { DISCOVER_WIDGET_DATA_VISUALIZATIONS } from '../../../../store/discover/widgets/widgets.types';
import { selectDiscoverDashboardPageWidgets } from '../../../../store/discover/widgets/widgets.selectors';
import { setDashboardPageWidgets } from '../../../../store/discover/widgets/widgets.actions';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';
import { widgetService } from '../../../../store/discover/widgets/widgets.services';
import { objectService } from '../../../../utils/object.util';

const DiscoverDashboardPage = () => {
    const daisyTheme = useSelector(selectDaisyTheme);
    const dispatch = useDispatch();

    const dashboardWidgets = useSelector(selectDiscoverDashboardPageWidgets);
    const perspective = { type: 'global', code: null, descr: null };

    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('selectedDate') ?? 'MTD'
    );

    const handleDateChange = (date) => {
        localStorage.setItem('selectedDate', date);
        setSelectedDate(date);
    };

    const handleAddWidget = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric,
        size
    ) => {
        const widgetData = {
            metric,
            visualization,
            sortDirection:
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND
                    ? null
                    : sortDirection,
            aggregationMetric,
            size,
        };

        const widgetId = widgetService.createWidgetRuleset(
            widgetData.metric,
            widgetData.visualization,
            widgetData.sortDirection,
            perspective,
            widgetData.aggregationMetric
        );

        let validWidget = true;

        if (Object.keys(dashboardWidgets).includes(widgetId)) {
            createNotification(
                'A widget exists on this page which matches those parameters.',
                NOTIFICATION_TYPES.ERROR
            );
            document
                .getElementById(widgetId)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });

            validWidget = false;
        }

        if (validWidget) {
            let newDashboardWidgets = {
                ...dashboardWidgets,
            };
            newDashboardWidgets[widgetId] = widgetData;

            dispatch(setDashboardPageWidgets(newDashboardWidgets));
        }
    };

    const handleRemoveWidget = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric
    ) => {
        const widgetId = widgetService.createWidgetRuleset(
            metric,
            visualization,
            sortDirection,
            perspective,
            aggregationMetric
        );
        let newDashboardWidgets = { ...dashboardWidgets };
        if (Object.keys(dashboardWidgets).includes(widgetId)) {
            delete newDashboardWidgets[widgetId];
            createNotification('Widget removed', NOTIFICATION_TYPES.SUCCESS);
        }

        dispatch(setDashboardPageWidgets(newDashboardWidgets));
    };

    const handleMoveWidgetUp = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric
    ) => {
        const widgetId = widgetService.createWidgetRuleset(
            metric,
            visualization,
            sortDirection,
            perspective,
            aggregationMetric
        );

        let newDashboardWidgets = objectService.moveObjectItemUp(
            widgetId,
            dashboardWidgets
        );

        if (
            !objectService.checkObjectKeysEqual(
                newDashboardWidgets,
                dashboardWidgets
            )
        ) {
            dispatch(setDashboardPageWidgets(newDashboardWidgets));
        }
    };

    useEffect(() => {
        // dispatch(clearWidgets());
    }, [dashboardWidgets]);

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <DiscoverSubNavigation
                    title="Dashboard"
                    className={`col-span-2 row-span-max sticky ${muted_border}`}
                    onDateChange={handleDateChange}
                    selectedDate={selectedDate}
                    widgets={dashboardWidgets}
                />

                {objectService.checkObjectPopulated(dashboardWidgets) &&
                    Object.keys(dashboardWidgets).map((widgetId, index) => {
                        const widget = dashboardWidgets[widgetId];

                        return (
                            <Widget
                                id={widgetService.createWidgetRuleset(
                                    widget.metric,
                                    widget.visualization,
                                    widget.sortDirection,
                                    perspective,
                                    widget.aggregationMetric
                                )}
                                key={widgetService.createWidgetRuleset(
                                    widget.metric,
                                    widget.visualization,
                                    widget.sortDirection,
                                    perspective,
                                    widget.aggregationMetric
                                )}
                                perspective={perspective}
                                metric={widget.metric}
                                visualization={widget.visualization}
                                sortDirection={widget.sortDirection}
                                aggregationMetric={widget.aggregationMetric}
                                selectedDate={selectedDate}
                                onDateChange={handleDateChange}
                                size={widget.size}
                                className={`${
                                    widget.size === 'full'
                                        ? widget_full
                                        : widget_half
                                } ${
                                    index === 0 &&
                                    REACT_TOUR_CLASS_NAMES.WIDGET_1
                                }`}
                                onRemoveWidget={handleRemoveWidget}
                                onMoveUp={handleMoveWidgetUp}
                                daisyTheme={daisyTheme}
                            />
                        );
                    })}

                <AddWidget
                    perspective={perspective}
                    onAddWidget={handleAddWidget}
                />
            </div>
        </div>
    );
};

export default DiscoverDashboardPage;