import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectDaisyTheme,
    selectThemeColor,
    selectThemeMode,
} from '../../store/themes/themes.selectors';

import Select from 'react-select';

import { getWindowSize } from '../base/filters/trend-filters/trend-filters.component';

import { generateSelectStylesDaisy } from '../../utils/styles/global-styles';
import { muted_border } from '../../utils/styles/tailwind-styles';

export const weekOptions = [
    {
        value: 1,
        label: 'Monday',
    },
    {
        value: 2,
        label: 'Tuesday',
    },
    {
        value: 3,
        label: 'Wednesday',
    },
    {
        value: 4,
        label: 'Thursday',
    },
    {
        value: 5,
        label: 'Friday',
    },
    {
        value: 6,
        label: 'Saturday',
    },
    {
        value: 7,
        label: 'Sunday',
    },
];

const monthOptions = Array.from({ length: 31 }, (_, i) => i + 1).map((i) => {
    return {
        value: i,
        label: i,
    };
});

const hourOptions = Array.from({ length: 9 }, (_, i) => i + 8).map((i) => {
    return {
        value: i,
        label: i > 9 ? i + ':00' : '0' + i + ':00',
    };
});

export const buildCrontab = (frequency, dayOf, time) => {
    if (frequency.value === 'daily') {
        return `0 ${time.value} * * *`;
    } else if (frequency.value === 'weekly') {
        return `0 ${time.value} * * ${dayOf.value}`;
    } else {
        return `0 ${time.value} ${dayOf.value} * *`;
    }
};

const convertCronToInitialOptions = (cronText) => {
    let initialMap = {};

    let array = cronText.split(' ');

    let minute = array[0];
    let hour = array[1];
    let dayOfMonth = array[2];
    let month = array[3];
    let dayOfWeek = array[4];

    if (dayOfWeek !== '*') {
        initialMap['frequencyUnit'] = { value: 'weekly', label: 'Weekly' };
        initialMap['dayOf'] = weekOptions[dayOfWeek - 1];
    } else if (dayOfMonth !== '*') {
        initialMap['frequencyUnit'] = { value: 'monthly', label: 'Monthly' };
        initialMap['dayOf'] = monthOptions[dayOfMonth - 1];
    } else {
        initialMap['frequencyUnit'] = { value: 'daily', label: 'Daily' };
    }
    initialMap['reportTime'] = hourOptions[hour - 8];
    return initialMap;
};

const SchedulerComponent = ({ onChange, initialValues }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    const [frequencyUnit, setFrequencyUnit] = useState(
        initialValues
            ? convertCronToInitialOptions(initialValues)['frequencyUnit']
            : {
                  value: 'weekly',
                  label: 'Weekly',
              }
    );
    const [dayOf, setDayOf] = useState(
        initialValues
            ? convertCronToInitialOptions(initialValues)['dayOf']
            : {
                  value: 1,
                  label: 'Monday',
              }
    );
    const [reportTime, setReportTime] = useState(
        initialValues
            ? convertCronToInitialOptions(initialValues)['reportTime']
            : {
                  value: 8,
                  label: '08:00',
              }
    );

    useEffect(() => {
        onChange(buildCrontab(frequencyUnit, dayOf, reportTime));
    }, []);

    const onChangeFrequency = (event) => {
        setFrequencyUnit(event);
        let newDayOf;

        if (event.value === 'monthly') {
            newDayOf = { value: 1, label: '1' };
        } else if (event.value === 'daily') {
            newDayOf = null;
        } else {
            newDayOf = { value: 1, label: 'Monday' };
        }

        setDayOf(newDayOf);
        onChange(buildCrontab(event, newDayOf, reportTime));
    };

    const onChangeDayOf = (event) => {
        setDayOf(event);
        onChange(buildCrontab(frequencyUnit, event, reportTime));
    };

    const onChangeTime = (event) => {
        setReportTime(event);
        onChange(buildCrontab(frequencyUnit, dayOf, event));
    };

    const customStylesSingle = generateSelectStylesDaisy(
        daisyTheme,
        getWindowSize()
    );

    return (
        <div
            className={`w-full grid grid-cols-3 items-center justify-evenly pt-6 pb-3 px-3 bg-base-300 rounded ${muted_border}`}
        >
            <span className="flex h-full items-center justify-evenly mb-2 desktop:laptop:col-span-1 col-span-3">
                <p className="text-center m-2 text-xs">Report Frequency</p>
                <Select
                    styles={customStylesSingle}
                    options={[
                        {
                            value: 'daily',
                            label: 'Daily',
                        },
                        {
                            value: 'weekly',
                            label: 'Weekly',
                        },
                        {
                            value: 'monthly',
                            label: 'Monthly',
                        },
                    ]}
                    value={frequencyUnit}
                    placeholder=""
                    onChange={onChangeFrequency}
                    isSearchable={false}
                    isClearable={false}
                    isMulti={false}
                />
            </span>

            {frequencyUnit.label !== 'Daily' ? (
                <span className="flex h-full items-center justify-evenly mb-2 desktop:laptop:col-span-1 col-span-3">
                    <p className="text-center m-2 text-xs">
                        Day of
                        {frequencyUnit.label === 'Monthly' ? ' Month' : ' Week'}
                    </p>
                    <Select
                        styles={customStylesSingle}
                        options={
                            frequencyUnit.label === 'Weekly'
                                ? weekOptions
                                : monthOptions
                        }
                        value={dayOf}
                        placeholder=""
                        onChange={onChangeDayOf}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                    />
                </span>
            ) : (
                <></>
            )}

            <span className="flex h-full items-center justify-evenly mb-2 desktop:laptop:col-span-1 col-span-3">
                <p className="text-center m-2 text-xs">Time</p>
                <Select
                    styles={customStylesSingle}
                    options={hourOptions}
                    value={reportTime}
                    placeholder=""
                    onChange={onChangeTime}
                    isSearchable={false}
                    isClearable={false}
                    isMulti={false}
                />
            </span>
        </div>
    );
};

export default SchedulerComponent;