import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectDashboardSalesPerformance } from '../../../store/base/dashboard/dashboard.selectors';
import {
    selectDaisyTheme,
    selectThemeColor,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../store/base/filters/base-filters.selectors';
import { setDashboardSalesPerformance } from '../../../store/base/dashboard/dashboard.actions';
import { dashboardService } from '../../../store/base/dashboard/dashboard.services';

import BarChartComponent from '../../charts/bar-chart-component/bar-chart.component';
import LineChartComponent from '../../charts/line-chart-component/line-chart.component';
import MessageComponent, {
    ALERT_TYPES,
} from '../../message-component/message.component';

import { handleError } from '../../../utils/engine/engine.service';
import NoData from '../../no-data/no-data.component';

import PageLoadingComponent from '../../loading-component/page-loading-component';
import {
    base_widget_full,
    base_widget_half,
    muted_border,
} from '../../../utils/styles/tailwind-styles';

const DashboardSalesPerformanceComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [insufficientData, setInsufficientData] = useState(false);

    const dispatch = useDispatch();

    const daisyTheme = useSelector(selectDaisyTheme);

    const dashboardSalesPerformance = useSelector(
        selectDashboardSalesPerformance
    );
    const [
        dashboardSalesPerformancePlottable,
        setDashboardSalesPerformancePlottable,
    ] = useState(null);

    const selectedFilters = useSelector(selectFiltersSelected);

    const fetchSalesPerformanceHelper = async () => {
        try {
            setIsLoading(true);
            const salesPerformance =
                await dashboardService.fetchDashboardSalesPerformance(
                    selectedFilters
                );

            if (salesPerformance !== null && !('action' in salesPerformance)) {
                dispatch(setDashboardSalesPerformance(salesPerformance));
                setDashboardSalesPerformancePlottable(
                    dashboardService.convertSalesPerformanceToPlottable(
                        salesPerformance,
                        daisyTheme
                    )
                );
                setInsufficientData(
                    salesPerformance['insufficientComparisonData']
                );
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    /*
    Re-fetch data when user updates selected filters (and on initial component render)
     */
    useEffect(() => {
        fetchSalesPerformanceHelper();
    }, [selectedFilters]);

    /*
    Update graph colours when user alters theme color
     */
    useEffect(() => {
        if (
            dashboardSalesPerformance !== null &&
            !('action' in dashboardSalesPerformance)
        ) {
            setDashboardSalesPerformancePlottable(
                dashboardService.convertSalesPerformanceToPlottable(
                    dashboardSalesPerformance,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    return (
        <>
            {!isLoading && insufficientData && (
                <div className="col-span-full flex justify-center">
                    <MessageComponent
                        type={ALERT_TYPES.ERROR}
                        message="Insufficient Data to Compare Year on Year"
                        title="Note"
                    />
                </div>
            )}

            {isLoading ? (
                <PageLoadingComponent />
            ) : dashboardSalesPerformancePlottable === null ||
              dashboardSalesPerformancePlottable === undefined ? (
                <NoData className="col-span-2" />
            ) : (
                <>
                    {/*<div*/}
                    {/*    className={`${className} ${widget_full} ${muted_border}`}*/}
                    {/*></div>*/}

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <LineChartComponent
                            data={
                                dashboardSalesPerformancePlottable[
                                    'monthlyByValue'
                                ]
                            }
                            title={
                                insufficientData
                                    ? 'Monthly Sales'
                                    : 'Monthly Sales vs Last Year'
                            }
                            type={'Value'}
                        />
                    </div>

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <LineChartComponent
                            data={
                                dashboardSalesPerformancePlottable[
                                    'monthlyByVolume'
                                ]
                            }
                            title={
                                insufficientData
                                    ? 'Monthly Sales'
                                    : 'Monthly Sales vs Last Year'
                            }
                            type={'Volume'}
                        />
                    </div>

                    <div className={`${base_widget_full} ${muted_border}`}>
                        <BarChartComponent
                            data={
                                dashboardSalesPerformancePlottable['progress']
                            }
                            title={
                                insufficientData
                                    ? 'Yearly Sales'
                                    : 'Progress vs. Last Year'
                            }
                            type={'Value'}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default DashboardSalesPerformanceComponent;
