import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUserProducts } from '../../../store/auth/authentication.selectors';
import {
    clearProductType,
    setProductType,
} from '../../../store/product/product.actions';

import Product from '../../../components/product-component/product.component';

import { PRODUCT_TYPES } from '../../../store/product/product.types';

import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';

import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';

const ProductSelectPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userProducts = useSelector(selectUserProducts);

    const [reload, setReload] = useState(0);

    useEffect(() => {
        if (userProducts.length === 1) {
            if (userProducts[0] === PRODUCT_TYPES.PROFIT_ROCKET_BASE) {
                goToBase();
            } else {
                goToDiscover();
            }
        } else if (!userProducts || userProducts.length === 0) {
            // Need to do a check on whether custaccount has a stripe account or not
            createNotification(
                'Your subscription requires payment details in order to be fulfilled.',
                NOTIFICATION_TYPES.WARNING
            );

            dispatch(clearProductType());
            navigate('../subscribe', { replace: true });
        }
    }, []);

    const goToRetail = () => {
        dispatch(setProductType(PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER));
        navigate('../contact-us', { replace: true });
    };

    const goToBase = () => {
        dispatch(setProductType(PRODUCT_TYPES.PROFIT_ROCKET_BASE));
        // navigate(-2);
        navigate('../base/dashboard', { replace: true });
    };

    const goToDiscover = () => {
        dispatch(setProductType(PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER));
        navigate('../discover/dashboard', { replace: true });
    };

    const onPointerHandler = () => {
        setReload(reload + 1);
    };

    return (
        <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
            <div className="grid grid-cols-2 gap-4">
                {userProducts ? (
                    userProducts.map((productType, index) => {
                        let productName;
                        let handler;
                        let type;
                        let blurb;

                        switch (productType) {
                            case PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER:
                                productName = 'Profit Rocket Discover';
                                handler = goToDiscover;
                                type = 'discover';
                                blurb =
                                    'Not all of your customers will collect detailed sales data, and some may not be able or willing to share this with you. Profit Rocket Discover leverages your invoice data with your sales strategy to identify cross-sell and up-sell opportunities, and points out defecting customers before they leave.';
                                break;
                            case PRODUCT_TYPES.PROFIT_ROCKET_BASE:
                                productName = 'Profit Rocket Base';
                                handler = goToBase;
                                type = 'base';
                                blurb =
                                    'Juggling production costs, logistics and stock holding across a multitude of retailers costs you time and money, and is a messy initiative. Profit Rocket Pro balances sales data with your business goals to push near real time alerts to your merchandising team on the ground, optimising ranging, distribution cost and stock availability.';
                                break;
                            case PRODUCT_TYPES.PROFIT_ROCKET_RETAIL:
                                productName = 'Profit Rocket Retail';
                                handler = goToRetail;
                                type = 'retail';
                                blurb = 'Coming soon';
                                break;
                            default:
                                createNotification(
                                    `Could not retrieve product ${productType}`,
                                    NOTIFICATION_TYPES.ERROR
                                );
                        }

                        return (
                            <Product
                                key={productName}
                                title={productName}
                                type={type}
                                blurb={blurb}
                                onClickHandler={handler}
                                onPointerHandler={onPointerHandler}
                            />
                        );
                    })
                ) : (
                    <h1 className={'z-10'}>No products found for user.</h1>
                )}
            </div>

            <PublicBackdrop />
        </div>
    );
};

export default ProductSelectPage;