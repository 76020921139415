import {
    ArrowUpTrayIcon,
    LinkIcon,
    ArrowsUpDownIcon,
    PaperAirplaneIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDiscoverSearch } from '../../../store/discover/search/discover-search.selectors';
import Select from 'react-select';
import { generateSelectStylesDaisy } from '../../../utils/styles/global-styles';
import { getWindowSize } from '../../base/filters/trend-filters/trend-filters.component';
import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { muted_border } from '../../../utils/styles/tailwind-styles';
import { engineService } from '../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';

const UserRepAdd = ({ user, reps, onFinishAdd }) => {
    const [isLoading, setIsLoading] = useState(false);
    const discoverSearchMasterReps = useSelector(selectDiscoverSearch).rep;
    const daisyTheme = useSelector(selectDaisyTheme);

    const [localReps, setLocalReps] = useState(
        reps.map((repCode) => {
            return {
                label:
                    repCode in discoverSearchMasterReps
                        ? discoverSearchMasterReps[repCode].name
                        : repCode,
                value: repCode,
            };
        })
    );

    const customStylesSingle = generateSelectStylesDaisy(
        daisyTheme,
        getWindowSize()
    );

    useEffect(() => {
        setLocalReps(
            reps.map((repCode) => {
                return {
                    label:
                        repCode in discoverSearchMasterReps
                            ? discoverSearchMasterReps[repCode].name
                            : repCode,
                    value: repCode,
                };
            })
        );
    }, [reps]);

    const handleAddLinks = async () => {
        try {
            setIsLoading(true);
            const response = await engineService.invoke(
                WEB_PROCESS_FLOWS.MASTERDATA,
                {
                    action: 'addUserReps',
                    payload: {
                        email: user.email,
                        codes: localReps.map((localRep) => localRep.value),
                    },
                }
            );

            if (response?.reps) {
                // On finish function
                onFinishAdd(response?.reps);
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <label
                htmlFor={`user-add-rep-modal-${user.email}`}
                className="btn btn-primary btn-outline btn-xs gap-1"
            >
                <LinkIcon className={'w-4 h-4'} />
                Add Link
            </label>

            <input
                type="checkbox"
                id={`user-add-rep-modal-${user.email}`}
                className="modal-toggle"
            />

            <div className="modal">
                {isLoading ? (
                    <div
                        className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl flex items-center justify-center`}
                        style={{
                            maxHeight: '80vh',
                            minHeight: '50vh',
                        }}
                    >
                        <LoadingComponent
                            type={LOADING_COMPONENT_TYPES.TAILSPIN}
                        />
                    </div>
                ) : (
                    <div
                        className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl`}
                        style={{
                            maxHeight: '80vh',
                            minHeight: '50vh',
                        }}
                    >
                        <div className="flex flex-col w-full items-center justify-center gap-2 font-bold text-base">
                            <div className="flex flex-wrap break-words max-w-full items-center justify-center text-base-content text-opacity-80 gap-0.5 font-normal text-sm">
                                <span className="badge badge-xs badge-secondary mr-1">
                                    Note
                                </span>
                                Linking a user to one <span /> or more reps
                                means the <span />
                                user can only view data <span />
                                for the reps to which <span />
                                they are linked.
                            </div>
                            <div className="divider my-2" />
                            <span className="badge badge-primary text-base">
                                {user.email}
                            </span>

                            <span className="text-base-content text-opacity-80 flex items-center justify-center my-2">
                                <ArrowsUpDownIcon className="w-6 h-6" />
                            </span>

                            <Select
                                styles={customStylesSingle}
                                className={`w-full max-w-sm max-h-52 ${muted_border}`}
                                options={Object.values(
                                    discoverSearchMasterReps
                                ).map((repObject) => {
                                    return {
                                        value: repObject.code,
                                        label: repObject.name,
                                    };
                                })}
                                value={localReps}
                                placeholder="Select rep/s..."
                                closeMenuOnSelect={false}
                                onChange={(event) => setLocalReps(event)}
                                isMulti
                                isClearable={false}
                            />
                        </div>

                        <div className="divider mt-4 mb-2" />

                        <div className="flex items-center justify-end gap-2">
                            <label
                                className="btn btn-secondary btn-outline btn-xs"
                                htmlFor={`user-add-rep-modal-${user.email}`}
                            >
                                Close
                            </label>

                            <label
                                className="btn btn-primary btn-outline btn-xs"
                                htmlFor={`user-add-rep-modal-${user.email}`}
                                onClick={handleAddLinks}
                            >
                                Link
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default UserRepAdd;