export const M2NORTH_DETAILS = {
    url: 'https://m2north.com/',
    phone: '011 856 2000',
    email: '',
    addressList: [
        // '14 Crescent Road, Kingston Upon Thames, Surrey KT2 7RG, UK',
        'Unit 4 M2north (2nd Floor), 8 Osborne Lane, Bedfordview 2008, Gauteng, Johannesburg, South Africa',
        // 'Highland Trail, Los Angeles, CA 90046'
    ],
};
