import { createAction } from '../../utils/reducer.utils';
import { DISCOVER_SEARCH_ACTION_TYPES } from './discover-search.types';

export const setDiscoverSearchCustomers = (customers) => {
    return createAction(DISCOVER_SEARCH_ACTION_TYPES.SET_CUSTOMERS, customers);
};

export const setDiscoverSearchSkus = (skus) => {
    return createAction(DISCOVER_SEARCH_ACTION_TYPES.SET_SKUS, skus);
};

export const setDiscoverSearchReps = (reps) => {
    return createAction(DISCOVER_SEARCH_ACTION_TYPES.SET_REPS, reps);
};

export const setDiscoverSearchCategories = (categories) => {
    return createAction(
        DISCOVER_SEARCH_ACTION_TYPES.SET_CATEGORIES,
        categories
    );
};

export const clearDiscoverSearchData = () => {
    return createAction(DISCOVER_SEARCH_ACTION_TYPES.CLEAR);
};
