export const STOCK_COVER_OPTIONS = [
    { value: 'dayssincesaledesc', label: 'Most Days Since Sale' },
    { value: 'dayscoverdesc', label: 'Highest Days Cover' },
    { value: 'sohdesc', label: 'Highest Stock on Hand' },
    { value: 'sohasc', label: 'Lowest Stock on Hand' },
];

export const STOCK_COVER_TYPES = {
    DAYS_SINCE_SALE: STOCK_COVER_OPTIONS[0],
    HIGHEST_DAYS_COVER: STOCK_COVER_OPTIONS[1],
    HIGHEST_SOH: STOCK_COVER_OPTIONS[2],
    LOWEST_SOH: STOCK_COVER_OPTIONS[3],
};
