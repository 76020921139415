import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';

import { CSVLink } from 'react-csv';

import { selectUser } from '../../../store/auth/authentication.selectors';

import { apiService } from '../../../utils/engine/api.service';
import PageSelector from '../../../components/page-selector/page-selector.component';

import {
    MagnifyingGlassIcon,
    TrashIcon,
    UserPlusIcon,
} from '@heroicons/react/20/solid';

import UserAddComponent from '../../../components/base/user/user-add.component';
import {
    discover_grid,
    discover_page,
    muted_border,
} from '../../../utils/styles/tailwind-styles';
import { REACT_TOUR_CLASS_NAMES } from '../../../utils/react-tour.util';
import { ArrowDownTrayIcon, PencilIcon } from '@heroicons/react/16/solid';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../components/loading-component/loading-component';
import {
    formatDate,
    isM2Admin,
    isM2Support,
    isCAdmin,
} from '../../../utils/auth.util';

import UserReps from '../../../components/discover/user/user-reps.component';
import UserEditComponent from '../../../components/discover/user/user-edit.component';
import UserAudit from '../../../components/discover/user/user-audit.component';
import { selectProductType } from '../../../store/product/product.selectors';
import { PRODUCT_TYPES } from '../../../store/product/product.types';
import { store } from '../../../store/store';
import { createAction } from '../../../store/utils/reducer.utils';
import { AUTHENTICATION_ACTION_TYPES } from '../../../store/auth/authentication.types';
import { objectService } from '../../../utils/object.util';

const LIMIT = 10;

const TeamPage = () => {
    const dispatch = useDispatch();

    const user = useSelector(selectUser);
    const product = useSelector(selectProductType);

    const [isLoading, setIsLoading] = useState(true);
    const [downloadData, setDownloadData] = useState([]);

    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState(3);

    const [selectedPage, setSelectedPage] = useState(0);

    const [searchText, setSearchText] = useState('');
    const [searchInputValue, setSearchInputValue] = useState('');

    const [reload, setReload] = useState(0);

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            const usersObject = await apiService.fetchUsers(
                selectedPage,
                LIMIT,
                searchText
            );

            setUsersCount(usersObject['totalElements']);
            setUsers(usersObject['content']);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handleDelete = async (userControl) => {
        try {
            if (window.confirm('Are you sure you want to delete this user?')) {
                setIsLoading(true);
                await apiService.deleteUser(userControl);
                createNotification('Removed user', NOTIFICATION_TYPES.SUCCESS);

                setSelectedPage(0);
                await fetchUsers();
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const checkMe = (currentUser) => {
        return currentUser['email'] === user['email'] || isM2Admin(currentUser);
    };

    const exportUserCSV = async () => {
        try {
            setIsLoading(true);
            const usersObject = await apiService.fetchUsers(0, 1000, '');
            setIsLoading(false);
            const newObject = usersObject['content'].map((user) => {
                return {
                    name: user.name,
                    email: user.email,
                    role: user.role,

                    dateCreated: user.dateCreated,
                    dateUpdated: user.dateUpdated,

                    createdBy: user.createdBy,
                    updatedBy: user.updatedBy,

                    lastLoginDate: user.lastLoginDate,
                };
            });
            setDownloadData(newObject);
            return newObject;
        } catch (err) {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setSelectedPage(page);
    };

    useEffect(() => {
        fetchUsers();
    }, [selectedPage, searchText]);

    useEffect(() => {
        fetchUsers();
        exportUserCSV();
    }, [reload]);

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <div
                    className={`card gap-2 w-full p-6 h-full col-span-full ${muted_border} bg-base-300`}
                >
                    <div className="flex justify-between items-center">
                        <span className="text-sm text-base-content text-opacity-80 font-bold">
                            Manage Users
                        </span>

                        <div className="flex items-center gap-2">
                            <div className="form-control">
                                <div className="input-group">
                                    <input
                                        onChange={(e) =>
                                            setSearchInputValue(e.target.value)
                                        }
                                        type="search"
                                        placeholder="search…"
                                        className="input input-bordered input-sm text-xs w-60"
                                    />
                                    <button
                                        onClick={() =>
                                            setSearchText(searchInputValue)
                                        }
                                        className="btn btn-sm btn-ghost"
                                    >
                                        <MagnifyingGlassIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            </div>

                            <span className="flex justify-between items-center">
                                <label
                                    className="btn text-primary btn-outline btn-primary btn-sm normal-case tooltip tooltip-bottom flex items-center justify-center gap-2"
                                    data-tip={'Add User'}
                                    htmlFor="add-user-modal"
                                >
                                    <UserPlusIcon className="w-5 h-5" />
                                    Add User
                                </label>
                            </span>

                            <CSVLink
                                data={downloadData}
                                asyncOnClick={true}
                                filename={`pr-users-${
                                    new Date().toISOString().split('T')[0]
                                }.csv`}
                                onClick={(event, done) => {
                                    if (
                                        !objectService.checkObjectPopulated(
                                            downloadData
                                        )
                                    ) {
                                        exportUserCSV().then(() => {
                                            createNotification(
                                                'Download complete',
                                                NOTIFICATION_TYPES.SUCCESS
                                            );
                                            done();
                                        });
                                    } else {
                                        done();
                                    }
                                }}
                            >
                                <span
                                    className="btn btn-sm btn-outline btn-accent flex justify-center items-center tooltip tooltip-bottom normal-case gap-2"
                                    data-tip={'Download CSV'}
                                >
                                    <ArrowDownTrayIcon className={'w-5 h-5'} />
                                    Download
                                </span>
                            </CSVLink>
                        </div>
                    </div>

                    <div className="divider my-0" />

                    {isLoading ? (
                        <div className="flex w-full justify-center items-center">
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.TABLE_SKELETON}
                            />
                        </div>
                    ) : (
                        <>
                            <table
                                className={`table table-zebra w-full rounded-box text-sm tracking-normal`}
                            >
                                <thead>
                                    <tr>
                                        <th className="normal-case tracking-normal font-normal"></th>
                                        <th className="normal-case tracking-normal font-normal">
                                            Name
                                        </th>
                                        <th className="normal-case tracking-normal font-normal">
                                            Email
                                        </th>
                                        <th className="normal-case tracking-normal font-normal">
                                            Role
                                        </th>
                                        {product ===
                                            PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER && (
                                            <th className="normal-case tracking-normal font-normal">
                                                Links
                                            </th>
                                        )}

                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users &&
                                        users.map((currentUser) => (
                                            <tr key={currentUser.uuid}>
                                                <td>
                                                    <div className="flex flex-col items-start justify-center w-full gap-3">
                                                        <UserAudit
                                                            by={null}
                                                            date={
                                                                currentUser[
                                                                    'lastLoginDate'
                                                                ]
                                                            }
                                                            type={'Last Login'}
                                                        />

                                                        <UserAudit
                                                            type={'Updated'}
                                                            date={
                                                                currentUser[
                                                                    'dateUpdated'
                                                                ]
                                                            }
                                                            by={
                                                                currentUser[
                                                                    'updatedBy'
                                                                ]
                                                            }
                                                        />

                                                        <UserAudit
                                                            type={'Created'}
                                                            date={
                                                                currentUser[
                                                                    'dateCreated'
                                                                ]
                                                            }
                                                            by={
                                                                currentUser[
                                                                    'createdBy'
                                                                ]
                                                            }
                                                        />
                                                    </div>
                                                </td>

                                                <td>
                                                    <p className="font-bold w-full">
                                                        {currentUser.name}
                                                    </p>
                                                </td>
                                                <td>
                                                    <div
                                                        className={
                                                            'font-bold text-base-content text-opacity-80 w-full'
                                                        }
                                                    >
                                                        {currentUser.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        className={`badge badge-outline font-bold ${
                                                            isM2Support(
                                                                currentUser
                                                            )
                                                                ? 'badge-accent'
                                                                : isCAdmin(
                                                                      currentUser
                                                                  )
                                                                ? 'badge-secondary'
                                                                : 'badge-primary'
                                                        }`}
                                                    >
                                                        {currentUser.role}
                                                    </div>
                                                </td>

                                                {product ===
                                                    PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER && (
                                                    <td>
                                                        <div className="flex w-full flex-col items-center justify-center gap-1">
                                                            <UserReps
                                                                user={
                                                                    currentUser
                                                                }
                                                                key={
                                                                    currentUser.email
                                                                }
                                                            />
                                                        </div>
                                                    </td>
                                                )}

                                                <td>
                                                    <div className="w-full flex flex-col items-center gap-1">
                                                        {checkMe(
                                                            currentUser
                                                        ) ? (
                                                            <>
                                                                <UserEditComponent
                                                                    initialUser={
                                                                        currentUser
                                                                    }
                                                                    onSuccess={(
                                                                        updatedUser
                                                                    ) => {
                                                                        setReload(
                                                                            reload +
                                                                                1
                                                                        );

                                                                        const userObject =
                                                                            {
                                                                                ...user,
                                                                                role: updatedUser.role,
                                                                                name: updatedUser.name,
                                                                            };

                                                                        dispatch(
                                                                            createAction(
                                                                                AUTHENTICATION_ACTION_TYPES.SET_CURRENT_USER,
                                                                                userObject
                                                                            )
                                                                        );
                                                                    }}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <UserEditComponent
                                                                    initialUser={
                                                                        currentUser
                                                                    }
                                                                    onSuccess={(
                                                                        updatedUser
                                                                    ) => {
                                                                        setReload(
                                                                            reload +
                                                                                1
                                                                        );
                                                                    }}
                                                                />

                                                                <button
                                                                    className={
                                                                        'btn btn-error btn-outline btn-xs tooltip gap-1'
                                                                    }
                                                                    data-tip={
                                                                        'Remove User'
                                                                    }
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            currentUser[
                                                                                'control'
                                                                            ]
                                                                        )
                                                                    }
                                                                >
                                                                    <TrashIcon
                                                                        className={
                                                                            'w-4 h-4'
                                                                        }
                                                                    />
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {users && users.length === 0 && (
                                <div className="flex w-full flex-1 justify-center m-5 text-base-content text-opacity-60 text-sm ">
                                    <span className=" text-base-content text-opacity-80 text-sm ">
                                        No results found.
                                    </span>
                                </div>
                            )}

                            <div className="w-full flex justify-between items-center px-2 mt-2 text-sm col-span-full">
                                <span className="flex justify-start items-center w-32">
                                    <strong className="mr-3">Page Size:</strong>
                                    <p>{users.length}</p>
                                </span>

                                <PageSelector
                                    pageSize={LIMIT}
                                    totalResults={usersCount}
                                    selectedIndex={selectedPage}
                                    onPageChange={handlePageChange}
                                />

                                <span className="flex justify-end items-center w-32">
                                    <strong className="mr-3">Total:</strong>
                                    <p>{usersCount}</p>
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <UserAddComponent onSuccess={() => setReload(reload + 1)} />
        </div>
    );
};

export default TeamPage;