import { createAction } from '../utils/reducer.utils';
import { PRODUCT_ACTION_TYPES } from './product.types';

export const setProductType = (type) => {
    return createAction(PRODUCT_ACTION_TYPES.SET_PRODUCT, type);
};

export const clearProductType = () => {
    return createAction(PRODUCT_ACTION_TYPES.SET_PRODUCT, null);
};
