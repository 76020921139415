import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectDashboardTopSkus } from '../../../store/base/dashboard/dashboard.selectors';
import {
    selectDaisyTheme,
    selectThemeColor,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../store/base/filters/base-filters.selectors';
import {
    clearDashboardData,
    setDashboardTopSkus,
} from '../../../store/base/dashboard/dashboard.actions';
import { dashboardService } from '../../../store/base/dashboard/dashboard.services';

import BarChartComponent from '../../../components/charts/bar-chart-component/bar-chart.component';
import MessageComponent, {
    ALERT_TYPES,
} from '../../message-component/message.component';

import { handleError } from '../../../utils/engine/engine.service';
import NoData from '../../no-data/no-data.component';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import {
    base_widget_full,
    base_widget_half,
    muted_border,
} from '../../../utils/styles/tailwind-styles';

const DashboardTopSkusComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [insufficientData, setInsufficientData] = useState(false);

    const dispatch = useDispatch();
    const daisyTheme = useSelector(selectDaisyTheme);

    const dashboardTopSkus = useSelector(selectDashboardTopSkus);
    const [dashboardTopSkusPlottable, setDashboardTopSkusPlottable] =
        useState(null);

    const selectedFilters = useSelector(selectFiltersSelected);

    const fetchTopSkusHelper = async () => {
        try {
            setIsLoading(true);
            const topSkusData = await dashboardService.fetchDashboardTopSkus(
                selectedFilters
            );
            if (topSkusData !== null && !('action' in topSkusData)) {
                dispatch(setDashboardTopSkus(topSkusData));
                setDashboardTopSkusPlottable(
                    dashboardService.convertTopSkuDataToPlottable(
                        topSkusData,
                        daisyTheme
                    )
                );
                setInsufficientData(topSkusData['insufficientComparisonData']);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    /*
    Re-fetch data when user updates selected filters (and on initial component render)
     */
    useEffect(() => {
        fetchTopSkusHelper();
    }, [selectedFilters]);

    /*
    Update graph colours when user alters theme color
     */
    useEffect(() => {
        if (dashboardTopSkus !== null && !('action' in dashboardTopSkus)) {
            setDashboardTopSkusPlottable(
                dashboardService.convertTopSkuDataToPlottable(
                    dashboardTopSkus,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    return (
        <>
            {!isLoading && insufficientData && (
                <div className="col-span-full flex justify-center">
                    <MessageComponent
                        type={ALERT_TYPES.ERROR}
                        message="Insufficient Data to Compare Year on Year"
                        title="Note"
                    />
                </div>
            )}

            {isLoading ? (
                <PageLoadingComponent />
            ) : dashboardTopSkusPlottable === null ||
              dashboardTopSkusPlottable === undefined ? (
                <NoData className="col-span-2" />
            ) : (
                <>
                    <div className={`${base_widget_half} ${muted_border}`}>
                        <BarChartComponent
                            data={dashboardTopSkusPlottable['byValueGraphData']}
                            title="Top Skus"
                            type={'By Value'}
                        />
                    </div>

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <BarChartComponent
                            data={
                                dashboardTopSkusPlottable['byVolumeGraphData']
                            }
                            title="Top Skus"
                            type={'By Volume'}
                        />
                    </div>

                    {!insufficientData ? (
                        <>
                            <div
                                className={`${base_widget_half} ${muted_border}`}
                            >
                                <BarChartComponent
                                    data={
                                        dashboardTopSkusPlottable[
                                            'biggestDropsGraphData'
                                        ]
                                    }
                                    title="Biggest Drops"
                                    type={'vs Previous Year'}
                                />
                            </div>

                            <div
                                className={`${base_widget_half} ${muted_border}`}
                            >
                                <BarChartComponent
                                    data={
                                        dashboardTopSkusPlottable[
                                            'highestGainsGraphData'
                                        ]
                                    }
                                    title="Highest Gains"
                                    type={'vs Previous Year'}
                                />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <div className={`${base_widget_full} ${muted_border}`}>
                        <BarChartComponent
                            data={
                                dashboardTopSkusPlottable['mostProfitGraphData']
                            }
                            title="Top Skus"
                            type={'Most Profit'}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default DashboardTopSkusComponent;
