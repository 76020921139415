export const regular_chart =
    'flex flex-col w-full h-full justify-center items-center p-4 rounded-box bg-base-300 shadow text-sm col-span-full';

export const muted_border = 'border border-base-content border-opacity-10';
export const muted_border_primary = 'border border-primary border-opacity-40';

export const glowing_border = 'shadow-[0px_0px_3px_0.1px_rgba(0,0,0,1)]';

export const badge_overflow =
    'flex items-center px-1.5 py-0.5 capitalize rounded-box text-sm min-h-6';

export const discover_page =
    'relative bg-base-100 min-h-screen laptop:px-4 px-2 py-2 flex flex-col items-center justify-start';

export const discover_grid =
    'w-full h-full max-w-7xl grid grid-cols-12 items-start justify-start gap-2';

export const base_page =
    'relative z-0 bg-base-100 min-h-screen laptop:px-8 px-2 py-2 flex flex-col items-center justify-start';

export const base_grid =
    'grid grid-cols-2 w-full h-full max-w-8xl items-center justify-start gap-2';

export const widget_full =
    'grid grid-cols-5 laptop:col-span-10 col-span-12 card gap-2 w-full tablet:p-4 p-2 bg-base-300 content-between h-full';

export const widget_half =
    'grid grid-cols-5 laptop:col-span-5 tablet:col-span-6 col-span-full card gap-2 w-full tablet:p-4 p-2 bg-base-300 content-between h-full';

export const base_widget_half =
    'gap-2 w-full p-0 bg-base-300 h-full col-span-2 tablet:col-span-1';

export const base_widget_full =
    'col-span-2 gap-2 w-full p-0 bg-base-300 h-full';

export const base_subnavigation_bar =
    'flex w-full min-h-16 justify-center items-center bg-base-300 relative z-40';

export const backdrop =
    'absolute top-0 left-0 bottom-0 right-0 p-8 bg-base-100 bg-opacity-10 z-1000';

export const enlarged_chart =
    'relative rounded-box flex flex-col items-center justify-center top-0 left-0 z-50 p-4 w-full min-h-screen bg-base-300 shadow-lg ' +
    muted_border;

export const help_box = `absolute top-0 left-0 w-full h-full card px-8 py-4 shadow shadow-lg no-scrollbar border border-base-300 bg-base-300 bg-opacity-95 overflow-y-auto z-20 ${muted_border}`;

export const formula_box = `bg-base-100 ${muted_border} p-4 font-mono w-full m-2`;

export const widget_text_box = `stats lg:col-span-2 col-span-full bg-base-300`;

export const widget_graph_box = `w-full h-full lg:col-span-3 col-span-full shadow rounded-box p-4`;

export const sidebar_active = `absolute bg-base-300 top-full drop-shadow right-0 min-h-screen w-64 flex flex-col justify-start items-end p-3 transition-all visible`;
export const sidebar_inactive = `bg-base-300 drop-shadow absolute top-full right-0 min-h-screen w-64 flex flex-col justify-start items-end p-3 transition-all invisible`;

export const detail_sub_heading = `font-bold text-base-content text-opacity-60`;
