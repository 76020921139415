export const PRODUCT_ACTION_TYPES = {
    SET_PRODUCT: 'product/SET_PRODUCT',
};

export const PRODUCT_TYPES = {
    PROFIT_ROCKET_BASE: 'base',
    PROFIT_ROCKET_DISCOVER: 'discover',
    PROFIT_ROCKET_RETAIL: 'retail',
};

export const PRODUCT_PATHS = {
    DEFAULT: '/',
    PROFIT_ROCKET_BASE: '/base/dashboard',
    PROFIT_ROCKET_DISCOVER: '/discover/dashboard',
    PROFIT_ROCKET_RETAIL: '/contact-us',
};