import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { selectRangingFilters } from '../../../../store/base/filters/ranging/ranging.selectors';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';

import { rangingService } from '../../../../store/base/filters/ranging/ranging.services';
import { stockCoverService } from '../../../../store/base/filters/stock-cover/stock-cover.services';
import { apiService } from '../../../../utils/engine/api.service';

import RangingFilters from '../../../../components/base/filters/ranging-filters/ranging-filters.components';
import FiltersContainer from '../../../../components/base/filters/filters-container.component';
import SkuDetailsComponent from '../../../../components/sku-details-component/sku-details.component';
import ShareViewComponent, {
    onShareViewClick,
} from '../../../../components/base/share-view-component/share-view.component';
import GenerateReportComponent from '../../../../components/base/generate-report-component/generate-report.component';
import ListingSkuTable from '../../../../components/base/ranging/listing-sku-table.component';
import LineChartComponent from '../../../../components/charts/line-chart-component/line-chart.component';

import NoData from '../../../../components/no-data/no-data.component';
import PageLoadingComponent from '../../../../components/loading-component/page-loading-component';
import {
    base_grid,
    base_page,
    base_subnavigation_bar,
    base_widget_full,
    base_widget_half,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const RangingPage = () => {
    // UI Specific
    const [isLoading, setIsLoading] = useState(false);
    const [graphVisible, setGraphVisible] = useState(true);
    // Weekly Trend Graph Data
    const [weeklyTrendData, setWeeklyTrendData] = useState(null);
    // Focus Sku Graph Data
    const [focusSkuGraph, setFocusSkuGraph] = useState(null);
    const [focusSkuGraphPlottable, setFocusSkuGraphPlottable] = useState(null);
    const [focusSku, setFocusSku] = useState(null);

    // Sku Table Data
    const [perSku, setPerSku] = useState(null);
    // Current period data
    const [periodReference, setPeriodReference] = useState(null);

    const [rangingFiltersVisible, setRangingFiltersVisible] = useState(false);

    const daisyTheme = useSelector(selectDaisyTheme);

    const userToken = useSelector(selectUserToken);
    const selectedFilters = useSelector(selectFiltersSelected);
    const rangingFilters = useSelector(selectRangingFilters);

    const onShare = async (recipientsSelected, additionalMessage) => {
        setIsLoading(true);
        try {
            await onShareViewClick(
                userToken,
                selectedFilters,
                null,
                recipientsSelected,
                additionalMessage
            );
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchRangingDataHelper = async () => {
        setIsLoading(true);
        if (
            !Object.keys(selectedFilters).includes('supplskus') &&
            !Object.keys(selectedFilters).includes('supplbrands') &&
            !Object.keys(selectedFilters).includes('supplcategories')
        ) {
            setIsLoading(false);
            return;
        }
        try {
            const rangingData = await rangingService.fetchRangingData(
                selectedFilters,
                rangingFilters.identifyOption,
                rangingFilters.rangingStrategy,
                focusSku
            );

            if ('perSku' in rangingData && 'periodRef' in rangingData) {
                setPerSku(
                    rangingData['perSku'].map((perSku) => {
                        return {
                            supplSkuDescr: perSku['supplSkuDescr'],
                            totalStores: rangingData['totalStores'],
                            ...perSku,
                        };
                    })
                );
                setPeriodReference(rangingData['periodRef']);

                if (focusSku) {
                    setFocusSkuGraph(rangingData['focusSkuGraph']);
                    setFocusSkuGraphPlottable(
                        rangingService.convertFocusSkuGraphToPlottable(
                            rangingData['focusSkuGraph'],
                            daisyTheme
                        )
                    );
                } else {
                    setWeeklyTrendData(
                        stockCoverService.convertFocusDataToPlottable(
                            rangingData['weeklyTrend'],
                            daisyTheme
                        )
                    );
                }
            } else {
                setFocusSkuGraph(null);
                setFocusSkuGraphPlottable(null);
                setWeeklyTrendData(null);
                setPerSku(null);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const setFocusSkuHelperByText = (skuDescription) => {
        if (skuDescription) {
            const skuObject = perSku.filter((sku) => {
                return sku.supplSkuDescr === skuDescription;
            });
            setFocusSkuHelper(skuObject[0]);
        } else {
            clearFocusSku();
        }
    };

    const setFocusSkuHelper = (sku) => {
        setFocusSku(sku);
    };
    const clearFocusSku = () => {
        setFocusSku(null);
    };

    const onGenerateReportClick = async (reportParams) => {
        setIsLoading(true);
        try {
            const rangingData = await rangingService.fetchRangingData(
                selectedFilters,
                rangingFilters.identifyOption,
                rangingFilters.rangingStrategy,
                focusSku,
                reportParams
            );
            if (
                rangingData.message &&
                rangingData.message === 'Processing Request'
            )
                createNotification(
                    'Report request sent',
                    NOTIFICATION_TYPES.SUCCESS
                );
            if (reportParams['cronText'] != null) {
                await apiService.scheduleRangingProcess(
                    selectedFilters,
                    rangingFilters.identifyOption,
                    rangingFilters.rangingStrategy,
                    reportParams,
                    reportParams['cronText']
                );
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedFilters['dists']) {
            fetchRangingDataHelper();
        }
    }, [selectedFilters, rangingFilters, focusSku]);

    useEffect(() => {
        if (focusSku) {
            if (focusSkuGraph !== null) {
                setFocusSkuGraphPlottable(
                    rangingService.convertFocusSkuGraphToPlottable(
                        focusSkuGraph,
                        daisyTheme
                    )
                );
            }
        }
    }, [daisyTheme]);

    return (
        <>
            <div className={base_subnavigation_bar}>
                <div className="flex w-full h-full justify-between items-center max-w-8xl">
                    <span className={'flex items-center gap-1'}>
                        <a
                            className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                            href={'/base/ranging'}
                        >
                            Ranging
                        </a>

                        <ShareViewComponent onClick={onShare} />
                        <GenerateReportComponent
                            onGenerate={onGenerateReportClick}
                            dateRange={false}
                            includeSoh={false}
                            frequency={false}
                        />
                    </span>

                    <span className="flex justify-between items-center">
                        <RangingFilters
                            visible={rangingFiltersVisible}
                            toggleOpen={() => {
                                setRangingFiltersVisible(
                                    !rangingFiltersVisible
                                );
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <FiltersContainer className={'col-span-full'} />

                    {selectedFilters['dists'] ? (
                        <>
                            {isLoading ? (
                                <PageLoadingComponent />
                            ) : !selectedFilters['supplbrands'] &&
                              !selectedFilters['supplcategories'] &&
                              !selectedFilters['supplskus'] ? (
                                <div
                                    className={`${base_widget_full} ${muted_border}`}
                                >
                                    <div
                                        className={`w-full flex items-center justify-center gap-2 p-6`}
                                    >
                                        <ExclamationCircleIcon
                                            className={'w-5 h-5 text-warning'}
                                        />

                                        <h2 className="text-warning text-sm font-bold">
                                            Please filter by skus, brands or
                                            categories to use this tool.
                                        </h2>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {periodReference && (
                                        <div
                                            className={`${
                                                focusSku
                                                    ? base_widget_half
                                                    : base_widget_full
                                            } ${muted_border}`}
                                        >
                                            <span
                                                className={`w-full h-full text-center py-4 text-xl font-light rounded-box bg-base-300 shadow flex flex-col justify-center items-center`}
                                            >
                                                <span className="text-primary text-base font-bold">
                                                    Current Period
                                                </span>
                                                <span className="text-base-content font-bold text-opacity-80 text-lg">
                                                    {periodReference}
                                                </span>
                                            </span>
                                        </div>
                                    )}

                                    {focusSku && (
                                        <div
                                            className={`${base_widget_half} ${muted_border}`}
                                        >
                                            <SkuDetailsComponent
                                                skuDetails={focusSku}
                                                onClear={clearFocusSku}
                                                title={
                                                    focusSku['supplSkuDescr']
                                                }
                                                isSupplierDetails={true}
                                            />
                                        </div>
                                    )}

                                    {graphVisible &&
                                        (focusSku ? (
                                            focusSkuGraphPlottable === null ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className={`${base_widget_full} ${muted_border}`}
                                                >
                                                    <LineChartComponent
                                                        title={
                                                            focusSku.supplSkuDescr
                                                        }
                                                        data={
                                                            focusSkuGraphPlottable
                                                        }
                                                        interactionMode="nearest"
                                                        onClickHideOthers={
                                                            false
                                                        }
                                                    />
                                                </div>
                                            )
                                        ) : weeklyTrendData === null ? (
                                            <></>
                                        ) : (
                                            <div
                                                className={`${base_widget_full} ${muted_border}`}
                                            >
                                                <LineChartComponent
                                                    title="Percentage Ranged"
                                                    data={weeklyTrendData}
                                                    interactionMode="nearest"
                                                    onClickHideOthers={true}
                                                    handleSkuClick={
                                                        setFocusSkuHelperByText
                                                    }
                                                />
                                            </div>
                                        ))}

                                    {perSku === null ? (
                                        <NoData className="col-span-full" />
                                    ) : (
                                        <div
                                            className={`${base_widget_full} ${muted_border}`}
                                        >
                                            <div className="flex items-start justify-center w-full py-4">
                                                <ListingSkuTable
                                                    skuData={perSku}
                                                    handleSkuClick={
                                                        setFocusSkuHelper
                                                    }
                                                    focusSku={focusSku}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <div className={`${base_widget_full} ${muted_border}`}>
                            <div
                                className={`w-full flex items-center justify-center gap-2 p-6`}
                            >
                                <ExclamationCircleIcon
                                    className={'w-5 h-5 text-warning'}
                                />

                                <h2 className="text-warning text-sm font-bold">
                                    Please select a retailer to use this tab.
                                </h2>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default RangingPage;