import { engineService } from '../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';

export const discoverSearchService = {
    loadDiscoverSearchData,
};

async function loadDiscoverSearchData() {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DISCOVER_SEARCH_DATA,
        {}
    );
    return response;
}
