import { createAction } from '../utils/reducer.utils';
import { NAVIGATION_ACTION_TYPES } from './navigation.types';

export const setThemeMenuIsActive = (boolean) => {
    return createAction(NAVIGATION_ACTION_TYPES.SET_THEME_MENU_ACTIVE, boolean);
};

export const setSidebarIsActive = (boolean) => {
    return createAction(NAVIGATION_ACTION_TYPES.SET_SIDEBAR_ACTIVE, boolean);
};

export const setSidebarMode = (boolean) => {
    return createAction(NAVIGATION_ACTION_TYPES.SET_SIDEBAR_MODE, boolean);
};
