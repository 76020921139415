import { Fragment } from 'react';
import WidgetSubAggregates from '../widget/widget-sub-aggregates.component';

import {
    addOpacity,
    DAISY_THEME_MAP,
} from '../../../utils/styles/global-styles';

import TinyBarChartComponent from '../../charts/mini-bar/tiny-bar-chart.component';
import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { muted_border } from '../../../utils/styles/tailwind-styles';
import NoData from '../../no-data/no-data.component';
import { navigationService } from '../../../utils/navigation.util';

const convertOpportunityDifferenceToPlottable = (
    local,
    global,
    daisyTheme,
    color
) => {
    const labels = [`Local % Change`, `Global % Change`];
    return {
        labels,
        datasets: [
            {
                label: 'Sales',
                data: [local, global],
                borderColor: DAISY_THEME_MAP[daisyTheme][color],
                backgroundColor: addOpacity(
                    DAISY_THEME_MAP[daisyTheme][color],
                    0.25
                ),
                xAxisID: 'x',
                yAxisID: 'y',
            },
        ],
    };
};

const convertChangeToPlottable = (previous, current, daisyTheme, color) => {
    const labels = [`Previous Period`, `Current Period`];
    return {
        labels,
        datasets: [
            {
                label: 'Sales',
                data: [previous, current],
                borderColor: DAISY_THEME_MAP[daisyTheme][color],
                backgroundColor: addOpacity(
                    DAISY_THEME_MAP[daisyTheme][color],
                    0.25
                ),
                xAxisID: 'x',
                yAxisID: 'y',
            },
        ],
    };
};

const OpportunityRank = ({
    rawData,
    perspective,
    selectedDate,
    onDateChange,
    aggregationMetric,
}) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    return (
        <>
            {!rawData[selectedDate] ? (
                <>
                    <NoData />
                </>
            ) : (
                <>
                    <div className="col-span-5 w-full">
                        <div className="grid grid-cols-6 gap-3 py-2 px-0 place-items-start w-full">
                            {Object.keys(rawData[selectedDate]).map(
                                (code, index) => {
                                    let data = rawData[selectedDate][code];

                                    return (
                                        <Fragment key={index}>
                                            <span className="col-span-6 flex items-center justify-self-start">
                                                <span className="font-mono text-xs font-bold text-base-content text-opacity-80">
                                                    {index + 1}.
                                                </span>
                                                <a
                                                    className="btn btn-ghost btn-sm normal-case hover:underline"
                                                    target={'_blank'}
                                                    href={navigationService.buildUrlByAggMetric(
                                                        aggregationMetric,
                                                        { code: code }
                                                    )}
                                                >
                                                    {data.descr
                                                        ? data.descr
                                                              .replace('-', ' ')
                                                              .slice(0, 48)
                                                        : code}
                                                    {data.descr &&
                                                        data.descr.length >
                                                            48 &&
                                                        '..'}
                                                </a>
                                            </span>

                                            <div className="flex flex-col items-start justify-start gap-2 col-span-3 p-2 w-full">
                                                <div className="w-full text-start text-base font-bold text-primary capitalize">
                                                    Opportunity
                                                </div>

                                                <div
                                                    className="text-primary text-base font-mono w-full tooltip justify-self-start text-start"
                                                    data-tip={'Opportunity'}
                                                >
                                                    <span className="w-28 font-bold text-xs mr-2">
                                                        Difference
                                                    </span>
                                                    <span className=" w-full font-bold text-base font-mono text-end">
                                                        {data.opportunity.perc.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </span>
                                                </div>

                                                <div
                                                    className="text-primary text-base font-mono w-full tooltip justify-self-start text-start"
                                                    data-tip={'Opportunity'}
                                                >
                                                    <span className="w-28 font-bold text-xs">
                                                        Value
                                                    </span>

                                                    <span className="font-bold text-base font-mono text-end w-full">
                                                        {data.opportunity.value.toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </span>
                                                </div>

                                                <div className="max-w-full shadow-inner">
                                                    <TinyBarChartComponent
                                                        data={convertOpportunityDifferenceToPlottable(
                                                            data.local.change
                                                                .perc,
                                                            data.global.change
                                                                .perc,
                                                            daisyTheme,
                                                            'primary'
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className={`flex flex-col gap-1 items-start justify-start col-span-3 p-3 rounded-box shadow-inner bg-base-200`}
                                            >
                                                <div className="w-full text-start text-sm font-bold text-secondary capitalize">
                                                    {perspective.type} Sales
                                                </div>

                                                <div
                                                    className="text-secondary text-base font-mono w-full tooltip justify-self-start text-start"
                                                    data-tip={`Value: ${data.local.change.value.toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}`}
                                                >
                                                    <span className="w-28 font-bold text-xs mr-2">
                                                        Change
                                                    </span>
                                                    <span className=" w-full font-bold text-base font-mono text-end">
                                                        {data.local.change.perc.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </span>
                                                </div>

                                                <span className="w-36 shadow-inner">
                                                    <TinyBarChartComponent
                                                        data={convertChangeToPlottable(
                                                            data.local.prev,
                                                            data.local.curr,
                                                            daisyTheme,
                                                            'secondary'
                                                        )}
                                                    />
                                                </span>

                                                <div className="w-full text-start text-sm font-bold text-accent capitalize">
                                                    Global Sales
                                                </div>

                                                <div
                                                    className="text-accent text-base font-mono w-full tooltip justify-self-start text-start"
                                                    data-tip={`Value: ${data.global.change.value.toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}`}
                                                >
                                                    <span className="w-28 font-bold text-xs mr-2">
                                                        Change
                                                    </span>
                                                    <span className=" w-full font-bold text-base font-mono text-end">
                                                        {data.global.change.perc.toFixed(
                                                            2
                                                        )}
                                                        %
                                                    </span>
                                                </div>

                                                <span className="w-36 shadow-inner">
                                                    <TinyBarChartComponent
                                                        data={convertChangeToPlottable(
                                                            data.global.prev,
                                                            data.global.curr,
                                                            daisyTheme,
                                                            'accent'
                                                        )}
                                                    />
                                                </span>
                                            </div>

                                            <div className="col-span-6 my-2"></div>
                                        </Fragment>
                                    );
                                }
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col justify-start items-center w-full h-full col-span-5">
                        {rawData && rawData['subAggregates'] && (
                            <div className="col-span-full">
                                <div className="divider my-1" />

                                <WidgetSubAggregates
                                    subAggregates={rawData['subAggregates']}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default OpportunityRank;
