import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';
import FooterComponent from '../../../components/footer-component/footer.component';
import StripeCheckoutReturn from '../../../components/stripe/stripe-checkout-return.component';

const SubscribeOutcomePage = () => {
    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
                <div
                    className={`card flex flex-col py-8 px-12 shadow-lg bg-base-300 bg-opacity-95 w-full max-w-xl justify-center items-center min-h-64 z-10 gap-3`}
                >
                    <StripeCheckoutReturn />
                </div>

                <PublicBackdrop />
            </div>
            <FooterComponent useDaisy={true} />
        </>
    );
};

export default SubscribeOutcomePage;