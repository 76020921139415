import { createAction } from '../../../utils/reducer.utils';
import { TREND_FILTER_ACTION_TYPES } from './trend.types';

export const setTrendFilterFrequency = (frequency) => {
    return createAction(
        TREND_FILTER_ACTION_TYPES.SET_TREND_FILTER_FREQUENCY,
        frequency
    );
};

export const setTrendFilterCompare = (compare) => {
    return createAction(
        TREND_FILTER_ACTION_TYPES.SET_TREND_FILTER_COMPARE,
        compare
    );
};

export const resetTrendFilters = () => {
    return createAction(TREND_FILTER_ACTION_TYPES.RESET_TREND_FILTERS);
};
