import { XMarkIcon } from '@heroicons/react/24/solid';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { formula_box, help_box } from '../../../utils/styles/tailwind-styles';

const ChaosHelp = () => {
    return (
        <>
            <p className="text-base-content text-opacity-80 text-sm">
                Performance scores are an aggregated measure of one of a
                Product, a Customer or a Sales Rep's performance. Initially, we
                calculate performance in each of <strong>5</strong> metrics,
                provided we have sufficient data to do so.
            </p>

            <div className="grid grid-cols-1 justify-items-start items-center text-sm my-3">
                <div className="flex w-full items-center flex-wrap min-h-8">
                    <span className="text-primary w-28 font-bold">Sales</span>
                    <p>
                        Sum of total sales value for 12 weeks preceding the
                        score date.
                    </p>
                </div>

                <div className="flex w-full items-center flex-wrap min-h-8">
                    <span className="text-primary w-28 font-bold">Returns</span>
                    <p>
                        Sum of total value returned for 12 weeks preceding the
                        score date.
                    </p>
                </div>

                <div className="flex w-full items-center flex-wrap min-h-8">
                    <span className="text-primary w-28 font-bold">
                        Discount
                    </span>
                    <p>
                        The total weighted discounted value on all sales for 12
                        weeks preceding the score date.
                    </p>
                    <span className={`${formula_box} text-base`}>
                        {
                            '\\(\\frac{\\sum_{n=1}^{x}{discount_n*value_n}}{\\sum_{n=1}^{x}value_n}\\)'
                        }
                    </span>
                </div>

                <div className="flex w-full items-center flex-wrap min-h-8">
                    <span className="text-primary w-28 font-bold">Profit</span>
                    <p>
                        The total profit value on all sales for 12 weeks
                        preceding the score date. Profit is calculated based on
                        average selling price and cost price.
                    </p>
                    <span className={`${formula_box} text-base`}>
                        {
                            '\\(\\frac{\\sum_{n=1}^{x}{profit_n*value_n}}{\\sum_{n=1}^{x}value_n}\\)'
                        }
                    </span>
                </div>

                <div className="flex w-full items-center flex-wrap min-h-8">
                    <span className="text-primary w-28 font-bold">
                        Unused Credit
                    </span>
                    <p>
                        Unused credit is available as a metric when viewing from
                        the perspective of a customer. By deducting a customer's
                        total sales from their allocated credit limit, we find
                        how much potential is lost due to under utilization.
                    </p>
                    <span className={`${formula_box} text-xs`}>
                        {'\\(limit_n - \\sum_{n=1}^{x}sales_n\\)'}
                    </span>
                </div>
            </div>

            <p className="text-base-content text-opacity-80 text-sm mb-3">
                Once these metrics have been calculated for each aggregation
                group, we then compare and rank the items to place them into
                their decile of performance. The decile makes up the score for
                that pillar. We then sum the pillars and divide by the number of
                pillars to find an overall performance score.
            </p>

            <span className={`${formula_box} text-base`}>
                {'\\(c= \\frac{\\sum_{n=1}^{p}{pillar_n}}{n}\\)'}
            </span>
        </>
    );
};

export default ChaosHelp;
