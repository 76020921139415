import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { formula_box } from '../../../utils/styles/tailwind-styles';
import React from 'react';

const DiscountsHelp = ({ onClose }) => {
    return (
        <>
            <div className="flex flex-col gap-2 text-sm">
                <span className="badge badge-lg badge-primary">Trend</span>
                <p className="px-2">
                    The <strong>Discounts Trend</strong> widget provides a
                    trending view of the absolute discounted value
                    <span className="badge-md badge-primary rounded-full mx-2 bg-opacity-60" />{' '}
                    and discount vs gross sales value
                    <span className="badge-md badge-secondary rounded-full mx-2 bg-opacity-60" />
                    for the current period. <br />
                    Gross sales are the sales excl tax before discounts are
                    deducted.
                </p>

                <div className={formula_box}>
                    {'\\(discount_x = \\sum_{k=1}^{N}{value_k}\\)'}
                    <br />
                    <br />
                    {
                        '\\(discountvssales = \\frac{discount_x}{grosssales_x} \\)'
                    }
                </div>

                <p className="px-2">
                    where <strong>k</strong> represents a discount bucket and{' '}
                    <strong>x</strong> represents a time interval within the
                    current period. <br />
                    <br />
                </p>
            </div>
        </>
    );
};

export default DiscountsHelp;