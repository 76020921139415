import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserToken } from '../../../../store/auth/authentication.selectors';

import { apiService } from '../../../../utils/engine/api.service';

import { formatBytes } from '../../../../components/base/shared-files/shared-file.component';
import PageSelector from '../../../../components/page-selector/page-selector.component';
import {
    discover_grid,
    discover_page,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';
import LoadingComponent from '../../../../components/loading-component/loading-component';
import { OPS_HOSTNAME } from '../../../../utils/engine/socket-config.service';
import { formatDate } from '../../../../utils/auth.util';

const LIMIT = 10;

const ExtractsPage = () => {
    const userToken = useSelector(selectUserToken);
    const [isLoading, setIsLoading] = useState(false);

    const [sharedFiles, setSharedFiles] = useState([]);
    const [sharedFilesCount, setSharedFilesCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);

    const fetchSharedFiles = async () => {
        try {
            setIsLoading(true);
            const sharedFilesObject = await apiService.fetchSharedFiles(
                LIMIT,
                selectedPage
            );
            setSharedFiles(sharedFilesObject);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const countSharedFiles = async () => {
        try {
            setIsLoading(true);
            const count = await apiService.countSharedFiles();
            setSharedFilesCount(count);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const onFileClick = async (sharedFile) => {
        let url =
            OPS_HOSTNAME +
            `/api/prweb/shared-files/${sharedFile['uuidStr']}/download?token=${userToken}`;
        await window.open(url).then((response) => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.redirected === true) {
                return Promise.reject('Failed to download file.');
            } else {
                return response.json();
            }
        });
    };

    const handlePageChange = (page) => {
        setSelectedPage(page);
    };

    useEffect(() => {
        countSharedFiles();
    }, []);

    useEffect(() => {
        fetchSharedFiles();
    }, [selectedPage]);

    useEffect(() => {}, [sharedFiles]);

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <div
                    className={`card gap-2 w-full p-6 h-full col-span-full ${muted_border} bg-base-300`}
                >
                    <span className="text-sm text-base-content text-opacity-80 font-bold">
                        Data Extracts
                    </span>

                    <div className="divider my-0" />

                    {isLoading ? (
                        <LoadingComponent />
                    ) : (
                        <>
                            <table
                                className={`table table-compact table-zebra w-full rounded-box text-sm tracking-normal`}
                            >
                                <thead>
                                    <tr>
                                        <th className="normal-case tracking-normal font-normal text-base-content text-opacity-80 text-sm ">
                                            Date Generated
                                        </th>
                                        <th className="normal-case tracking-normal font-normal text-base-content text-opacity-80 text-sm ">
                                            Shared By
                                        </th>
                                        <th className="normal-case tracking-normal font-normal text-base-content text-opacity-80 text-sm ">
                                            File Size
                                        </th>
                                        <th className="normal-case tracking-normal font-normal"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sharedFiles &&
                                        sharedFiles.map((sharedFile) => (
                                            <tr key={sharedFile.uuid}>
                                                <td>
                                                    <div
                                                        className={'font-mono'}
                                                    >
                                                        <div
                                                            className={`badge badge-outline flex flex-wrap h-fit justify-start text-start items-center break-all gap-2 max-w-2xs w-full text-xs overflow-x-auto px-2 py-4 shadow z-10 badge-accent tooltip`}
                                                            data-tip={`Last Logged In`}
                                                        >
                                                            <p className="absolute bottom-0 right-2 text-2xs capitalize text-base-content text-opacity-60">
                                                                Date Generated
                                                            </p>
                                                            <div className="font-bold monospace font-mono">
                                                                {sharedFile[
                                                                    'dateCreated'
                                                                ] !== null
                                                                    ? formatDate(
                                                                          sharedFile[
                                                                              'dateCreated'
                                                                          ]
                                                                      )
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {sharedFile.sharedBy}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="font-mono">
                                                        {formatBytes(
                                                            sharedFile[
                                                                'fileSize'
                                                            ]
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        className={
                                                            'btn btn-primary btn-ghost text-primary underline tooltip tooltip-left flex w-full justify-start items-center'
                                                        }
                                                        data-tip={
                                                            'Download File'
                                                        }
                                                        onClick={() =>
                                                            onFileClick(
                                                                sharedFile
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                'flex w-full justify-start'
                                                            }
                                                        >
                                                            {
                                                                sharedFile[
                                                                    'fileName'
                                                                ]
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            {sharedFiles && sharedFiles.length === 0 && (
                                <div className="flex w-full flex-1 justify-center m-5 text-base-content text-opacity-80 text-sm ">
                                    <span>No results found.</span>
                                </div>
                            )}

                            <div className="w-full flex justify-between items-center px-2 mt-2 text-sm col-span-full">
                                <span className="flex justify-start items-center w-32">
                                    <strong className="mr-3">Page Size:</strong>
                                    <p>{sharedFiles.length}</p>
                                </span>

                                <PageSelector
                                    pageSize={LIMIT}
                                    totalResults={sharedFilesCount}
                                    selectedIndex={selectedPage}
                                    onPageChange={handlePageChange}
                                />

                                <span className="flex justify-end items-center w-32">
                                    <strong className="mr-3">Total:</strong>
                                    <p>{sharedFilesCount}</p>
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ExtractsPage;
