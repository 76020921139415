import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import {
    selectThemeColor,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import { TableDataCell } from '../stock-cover/sku-table.styles';
import {
    addOpacity,
    GLOBAL_COLOURS,
} from '../../../utils/styles/global-styles';

function darkModeColorMap(backendColor) {
    const map = {
        '#c1f0cc': GLOBAL_COLOURS['accents']['emerald-dark'],
        '#ffecb3': GLOBAL_COLOURS['accents']['amber-dark'],
        '#ffd9b3': GLOBAL_COLOURS['accents']['slate-dark'],
        '#f4bec3': GLOBAL_COLOURS['accents']['rose-dark'],
    };
    return addOpacity(map[backendColor], 0.7);
}

function mapHeadingToLabel(heading) {
    const map = {
        supplSkuDescr: 'SKU',
        avgStock: 'Average Stock',
        sellingPerc: 'Selling %',

        totalStores: 'Total Stores',
        rangedStores: 'Targeted Stores',
        rangedPerc: 'Ranged %',
        stockedPerc: 'Stocked %',

        avgStockvs12wm: null,
        hasMappings: null,

        rangedvs12wm: null,
        sellingvs12wm: null,

        stockedvs12wm: null,
        storesSelling: null,
        storesStocked: null,
        supplSku: null,
    };
    return map[heading];
}

const ListingSkuTable = ({ skuData, handleSkuClick, focusSku }) => {
    const themeMode = useSelector(selectThemeMode);

    const tableHeadings = Object.keys(skuData[0]);

    const [filter, setFilter] = useState('');
    const [filteredSkus, setFilteredSkus] = useState(skuData);

    const handleFilterChange = (event) => {
        setFilter(event.currentTarget.value);
        filterSkus(event.currentTarget.value);
    };

    useEffect(() => {
        filterSkus(filter);
    }, [focusSku]);

    const filterSkus = (filter) => {
        const initiallyFiltered = skuData.filter((sku) => {
            return sku.supplSkuDescr
                .toLowerCase()
                .includes(filter.toLowerCase());
        });

        if (focusSku) {
            let shifted = [];

            initiallyFiltered.forEach((sku) => {
                if (sku.supplSku === focusSku.supplSku) shifted.unshift(sku);
                else shifted.push(sku);
            });
            setFilteredSkus(shifted);
        } else {
            setFilteredSkus(initiallyFiltered);
        }
    };

    return (
        <div className="w-full block max-h-screen overflow-y-scroll">
            <div className="w-full min-w-full flex justify-center items-center mb-2 mt-2">
                <MagnifyingGlassIcon className="w-5 h-5 text-primary mr-2" />
                <input
                    className="w-full input input-bordered input-sm max-w-3xl"
                    onChange={handleFilterChange}
                    name="filter"
                    id="filter"
                    type="text"
                />
            </div>

            <table className="table table-compact normal-case w-full">
                <thead>
                    <tr>
                        {tableHeadings.map((heading, index) => {
                            const label = mapHeadingToLabel(heading);
                            if (label) {
                                return (
                                    <th
                                        key={heading}
                                        className={`normal-case ${
                                            label === 'SKU'
                                                ? 'text-left'
                                                : 'text-right'
                                        }`}
                                    >
                                        {label}
                                    </th>
                                );
                            }
                        })}
                    </tr>
                </thead>

                <tbody>
                    {filteredSkus.map((skuObject, index) => {
                        let border;

                        if (
                            focusSku &&
                            focusSku['supplSku'] === skuObject['supplSku']
                        ) {
                            border = 'border-l-4 border-r-4 border-primary';
                        } else {
                            border =
                                'border-l-4 border-r-4 hover:border-primary';
                        }

                        return (
                            <tr
                                key={skuObject['supplSku']}
                                onClick={() => handleSkuClick(skuObject)}
                                className={`cursor-pointer ${border}`}
                            >
                                {Object.keys(skuObject).map((key, index) => {
                                    const label = mapHeadingToLabel(key);
                                    if (label) {
                                        if (
                                            key === 'daysCover' ||
                                            key === 'daysSinceSale'
                                        ) {
                                            return (
                                                <td
                                                    key={index}
                                                    className={
                                                        isNaN(skuObject[key])
                                                            ? 'text-left'
                                                            : 'text-right'
                                                    }
                                                >
                                                    {skuObject[key]}
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td
                                                    key={index}
                                                    className={
                                                        isNaN(skuObject[key])
                                                            ? 'text-left'
                                                            : 'text-right'
                                                    }
                                                >
                                                    {skuObject[key]}
                                                </td>
                                            );
                                        }
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ListingSkuTable;