export const RANGING_ACTION_TYPES = {
    SET_RANGING_FILTER_STRATEGY:
        'base/filters/ranging/SET_RANGING_FILTER_STRATEGY',
    SET_RANGING_FILTER_SORT: 'base/filters/ranging/SET_RANGING_FILTER_SORT',

    RESET_RANGING_FILTER: 'base/filters/ranging/RESET_RANGING_FILTER',
};

export const RANGING_TYPES = {
    rangingStrategy: {
        heading: 'Targeted Stores',
        options: [
            { value: 'all', label: 'All Stores' },
            { value: 'brand', label: 'Stores that stock brand' },
            { value: 'skus', label: 'Stores that stock skus' },
        ],
        default: { value: 'all', label: 'All Stores' },
    },
    identifyOption: {
        heading: 'Sort By',
        options: [
            { value: 'leastranged', label: 'Least Ranged' },
            { value: 'rangedvsstocked', label: 'Ranged vs Stock Disparity' },
            { value: 'stockedvsselling', label: 'Stock vs Selling Disparity' },
        ],
        default: { value: 'leastranged', label: 'Least Ranged' },
    },
};
