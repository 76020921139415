import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { apiService } from '../../utils/engine/api.service';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUser,
    selectUserToken,
} from '../../store/auth/authentication.selectors';
import { CheckIcon } from '@heroicons/react/24/solid';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../loading-component/loading-component';
import { sleep } from '../../utils/dev-data/dev-data';
import { authenticationService } from '../../store/auth/authentication.services';
import { PRODUCT_TYPES } from '../../store/product/product.types';
import { createAction } from '../../store/utils/reducer.utils';
import { AUTHENTICATION_ACTION_TYPES } from '../../store/auth/authentication.types';

const StripeCheckoutReturn = () => {
    const userToken = useSelector(selectUserToken);

    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [redirectCountdown, setRedirectCountdown] = useState(10);

    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        setPaymentLoading(true);
        setUserLoading(true);
        apiService
            .checkStripeSessionStatus(userToken, sessionId)
            .then((data) => {
                data = JSON.parse(data);
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
                handleSuccess();
            });
    }, []);

    const handleSuccess = () => {
        setUserLoading(true);
        sleep(500).then(() => {
            setPaymentLoading(false);

            authenticationService
                .fetchUserMasterdata(user.email, user.token)
                .then((response) => {
                    setUserLoading(true);
                    const userProducts = response['products'].map((product) => {
                        switch (product) {
                            case 'base':
                                return PRODUCT_TYPES.PROFIT_ROCKET_BASE;
                            case 'discover':
                                return PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER;
                            case 'retail':
                                return PRODUCT_TYPES.PROFIT_ROCKET_RETAIL;
                            default:
                                return '';
                        }
                    });

                    const userObject = {
                        ...user,
                        email: user.email,
                        products: userProducts,
                    };

                    dispatch(
                        createAction(
                            AUTHENTICATION_ACTION_TYPES.SET_CURRENT_USER,
                            userObject
                        )
                    );
                    sleep(750).then(async () => {
                        setUserLoading(false);
                        await countdown();
                    });
                });
        });
    };

    const countdown = async () => {
        for (let countdownStart = 10; countdownStart >= 0; countdownStart--) {
            await sleep(1000);
            setRedirectCountdown(countdownStart);
        }
        navigate('/product-select');
    };

    if (status === 'open') {
        return <Navigate to="/subscribe" />;
    }

    if (status === 'complete') {
        return (
            <section id="success">
                <div className="flex items-center gap-2">
                    <span className="rounded-full bg-success text-success-content p-2">
                        <CheckIcon className={'w-6 h-6'} />
                    </span>
                    <h3 className="text-lg text-base-content text-opacity-70 font-bold">
                        Subscription Successful
                    </h3>
                </div>

                <div className="text-sm text-base-content text-opacity-80 my-2">
                    Thank you for subscribing to Profit Rocket Discover. An
                    email confirmation will be sent to {customerEmail}. You may
                    manage your subscription at any time by navigating to the{' '}
                    <a
                        href={`/discover/billing`}
                        className="text-primary hover:bg-base-100 px-1 cursor-pointer"
                    >
                        manage billing
                    </a>{' '}
                    page.
                </div>

                <div className="divider my-0" />

                <ul className="steps steps-vertical w-full">
                    <li
                        className={`step transition-colors duration-500 ${
                            paymentLoading ? 'step-neutral' : 'step-primary'
                        }`}
                    >
                        <div className="flex items-center gap-4">
                            <span>Verifying Payment</span>

                            {paymentLoading && (
                                <LoadingComponent
                                    type={LOADING_COMPONENT_TYPES.TAILCHASE}
                                    size={40}
                                />
                            )}
                        </div>
                    </li>
                    <li
                        className={`step transition-colors duration-500 ${
                            userLoading ? 'step-neutral' : 'step-primary'
                        }`}
                    >
                        <div className="flex items-center gap-4">
                            <span>Finalizing User</span>

                            {!paymentLoading && userLoading && (
                                <LoadingComponent
                                    type={LOADING_COMPONENT_TYPES.TAILCHASE}
                                    size={40}
                                />
                            )}
                        </div>
                    </li>
                </ul>

                <div className="divider my-2" />

                <button
                    className="btn btn-primary font-bold w-full"
                    onClick={() => navigate('/product-select')}
                >
                    Go to Dashboard
                </button>

                {!userLoading && !paymentLoading && (
                    <span className="text-sm text-base-content text-opacity-80 w-full text-end justify-end">
                        or you will be redirected in{' '}
                        <strong>{redirectCountdown}</strong>
                    </span>
                )}
            </section>
        );
    }

    return <LoadingComponent type={LOADING_COMPONENT_TYPES.HELIX} size={200} />;
};

export default StripeCheckoutReturn;