import LimitedDateRangeSelectorComponent from '../../limited-date-range-selector/limited-date-range-selector.component';
import { REACT_TOUR_CLASS_NAMES } from '../../../utils/react-tour.util';
import { DISCOVER_WIDGET_DATA_VISUALIZATIONS } from '../../../store/discover/widgets/widgets.types';
import { useLocation } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import {
    clearCategoryWidgets,
    clearCustomerWidgets,
    clearDashboardWidgets,
    clearHiddenWidgets,
    clearhiddenWidgets,
    clearProductWidgets,
    clearRepWidgets,
    clearWatchlistWidgets,
    clearWidgets,
    setHiddenWidgets,
} from '../../../store/discover/widgets/widgets.actions';
import { EyeIcon, EyeSlashIcon, PlusIcon } from '@heroicons/react/16/solid';

import {
    selectDiscoverCategoryPageWidgets,
    selectDiscoverCustomerPageWidgets,
    selectDiscoverDashboardPageWidgets,
    selectDiscoverHiddenWidgets,
    selectDiscoverRepPageWidgets,
    selectDiscoverSkuPageWidgets,
    selectDiscoverWatchlistPageWidgets,
} from '../../../store/discover/widgets/widgets.selectors';
import { widgetService } from '../../../store/discover/widgets/widgets.services';
import AnimatedBorder from '../../animated-border/animated-border.component';
import { objectService } from '../../../utils/object.util';

const DiscoverSubNavigation = ({
    className,
    title,
    onDateChange,
    selectedDate,
    widgets,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const hiddenWidgets = useSelector(selectDiscoverHiddenWidgets);

    const dashboardWidgets = useSelector(selectDiscoverDashboardPageWidgets);
    const watchlistWidgets = useSelector(selectDiscoverWatchlistPageWidgets);
    const customerWidgets = useSelector(selectDiscoverCustomerPageWidgets);
    const repWidgets = useSelector(selectDiscoverRepPageWidgets);
    const productWidgets = useSelector(selectDiscoverSkuPageWidgets);
    const categoryWidgets = useSelector(selectDiscoverCategoryPageWidgets);

    const handleHidePageWidgets = () => {
        let pageWidgets;

        if (location.pathname.includes('dashboard')) {
            pageWidgets = { ...dashboardWidgets };
        } else if (location.pathname.includes('watchlist')) {
            pageWidgets = { ...watchlistWidgets };
        } else if (location.pathname.includes('sku')) {
            pageWidgets = { ...productWidgets };
        } else if (location.pathname.includes('customer')) {
            pageWidgets = { ...customerWidgets };
        } else if (location.pathname.includes('product')) {
            pageWidgets = { ...productWidgets };
        } else if (location.pathname.includes('rep')) {
            pageWidgets = { ...repWidgets };
        } else if (location.pathname.includes('category')) {
            pageWidgets = { ...categoryWidgets };
        }

        let newHiddenWidgets = { ...hiddenWidgets };

        Object.keys(pageWidgets).forEach((widgetId) => {
            const widgetData = pageWidgets[widgetId];

            if (!Object.keys(hiddenWidgets).includes(widgetId)) {
                newHiddenWidgets[widgetId] = widgetData;
            }
        });

        dispatch(setHiddenWidgets(newHiddenWidgets));
    };

    return (
        <div
            className={`group card laptop:flex laptop:flex-col hidden justify-start items-start w-full p-4 bg-base-300 shadow-md gap-2 ${className} ${REACT_TOUR_CLASS_NAMES.SUB_NAV} max-h-screen overflow-y-auto`}
        >
            <AnimatedBorder />

            <h3 className="font-bold text-base-content text-base text-opacity-80">
                {title}
            </h3>
            <div className="divider my-1" />

            {objectService.checkObjectPopulated(widgets) &&
                Object.keys(widgets).map((key, index) => {
                    return (
                        <a
                            key={key}
                            className={`flex flex-wrap w-full justify-start items-center text-base-content text-xs text-opacity-80 break-all capitalize gap-0`}
                            href={`#${key}`}
                        >
                            {index > 0 ? (
                                `${widgets[key].metric} ${widgets[key].visualization}` !==
                                    `${
                                        widgets[Object.keys(widgets)[index - 1]]
                                            .metric
                                    } ${
                                        widgets[Object.keys(widgets)[index - 1]]
                                            .visualization
                                    }` && (
                                    <p
                                        className={
                                            'font-bold w-full mb-1 hover:underline'
                                        }
                                    >
                                        {widgets[key].metric.includes('_')
                                            ? widgets[key].metric.replace(
                                                  '_',
                                                  ' '
                                              )
                                            : widgets[key].metric}{' '}
                                        {widgets[key].visualization}
                                    </p>
                                )
                            ) : (
                                <p
                                    className={
                                        'font-bold w-full mb-1 hover:underline'
                                    }
                                >
                                    {widgets[key].metric.includes('_')
                                        ? widgets[key].metric.replace('_', ' ')
                                        : widgets[key].metric}{' '}
                                    {widgets[key].visualization}
                                </p>
                            )}

                            <span
                                className={
                                    'flex flex-wrap justify-start items-center gap-1 hover:underline pl-2'
                                }
                            >
                                {widgets[key].aggregationMetric && (
                                    <span className="text-base-content">
                                        {widgets[key].aggregationMetric}
                                    </span>
                                )}
                                {widgets[key].visualization !==
                                    DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND &&
                                    widgets[key].sortDirection && (
                                        <span className="badge badge-outline badge-primary badge-xs">
                                            {widgets[key].sortDirection}
                                        </span>
                                    )}
                            </span>
                        </a>
                    );
                })}

            <div className="divider my-1" />

            <div className={`${REACT_TOUR_CLASS_NAMES.DATE_SELECTOR} w-full`}>
                <h3 className="font-bold text-base-content text-xs text-opacity-70">
                    Time Period
                </h3>

                <LimitedDateRangeSelectorComponent
                    selectedDate={selectedDate}
                    onChange={onDateChange}
                    className={`w-full`}
                    compact={true}
                />
            </div>

            <div className="divider my-1" />

            <h3 className="font-bold text-base-content text-xs text-opacity-70">
                Widgets
            </h3>

            <div
                className={`flex items-center w-full justify-evenly ${REACT_TOUR_CLASS_NAMES.WIDGET_RESET}`}
            >
                <button
                    className={'btn btn-sm btn-ghost tooltip w-1/4'}
                    data-tip={'Reset'}
                    onClick={() => {
                        if (
                            window.confirm(
                                "Are you sure you want to reset this page's widgets?"
                            )
                        ) {
                            if (location.pathname.includes('dashboard')) {
                                dispatch(clearDashboardWidgets());
                                createNotification(
                                    'Dashboard widgets reset to default',
                                    NOTIFICATION_TYPES.INFO
                                );
                            } else if (location.pathname.includes('customer')) {
                                dispatch(clearCustomerWidgets());
                                createNotification(
                                    'Customer page widgets reset to default',
                                    NOTIFICATION_TYPES.INFO
                                );
                            } else if (location.pathname.includes('rep')) {
                                dispatch(clearRepWidgets());
                                createNotification(
                                    'Rep page widgets reset to default',
                                    NOTIFICATION_TYPES.INFO
                                );
                            } else if (
                                location.pathname.includes('sku') ||
                                location.pathname.includes('product')
                            ) {
                                dispatch(clearProductWidgets());
                                createNotification(
                                    'Product page widgets reset to default',
                                    NOTIFICATION_TYPES.INFO
                                );
                            } else if (
                                location.pathname.includes('watchlist')
                            ) {
                                dispatch(clearWatchlistWidgets());
                                createNotification(
                                    'Watchlist items removed',
                                    NOTIFICATION_TYPES.INFO
                                );
                            } else if (location.pathname.includes('category')) {
                                dispatch(clearCategoryWidgets());
                                createNotification(
                                    'Category widgets reset to default',
                                    NOTIFICATION_TYPES.INFO
                                );
                            }
                        }
                    }}
                >
                    <ArrowPathIcon className={'w-4 h-4'} />
                </button>

                <button
                    className={'btn btn-sm btn-ghost tooltip w-1/4'}
                    data-tip={'Expand'}
                    onClick={() => {
                        dispatch(clearHiddenWidgets());
                    }}
                >
                    <EyeIcon
                        className={'w-4 h-4 text-base-content text-opacity-60'}
                    />
                </button>

                <button
                    className={'btn btn-sm btn-ghost tooltip w-1/4'}
                    data-tip={'Collapse'}
                    onClick={handleHidePageWidgets}
                >
                    <EyeSlashIcon
                        className={'w-4 h-4 text-base-content text-opacity-60'}
                    />
                </button>

                <a
                    className={'btn btn-sm btn-ghost tooltip w-1/4'}
                    data-tip={'Add'}
                    href={'#add-widget'}
                >
                    <PlusIcon
                        className={'w-4 h-4 text-base-content text-opacity-80'}
                    />
                </a>
            </div>
        </div>
    );
};

export default DiscoverSubNavigation;
