export const selectUser = (state) => state.authentication.user;
export const selectIsLoggedIn = (state) => {
    return state.authentication.user !== null;
};

export const selectUserToken = (state) => state.authentication.user.token;
export const selectUserEmail = (state) => state.authentication.user.email;
export const selectUserProducts = (state) => state.authentication.user.products;
export const selectUserPreferences = (state) =>
    state.authentication.user.preferences;
