import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';

import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import {
    addOpacity,
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
} from '../../../utils/styles/global-styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const FONT_SIZE = 8;

const barChartOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 0.5,
            hoverBorderWidth: 1,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 5,
                pointStyle: 'rect',
                usePointStyle: true,
                font: {
                    size: FONT_SIZE,
                },
            },
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
        },
    },
    interaction: {
        intersect: false,
        axis: 'y',
        mode: 'index',
    },
};

const TinyBarChartComponent = ({ data, interactionMode, className }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    let options = { ...barChartOptions };
    options = {
        ...options,
        plugins: {
            ...options['plugins'],
            legend: {
                ...options['plugins']['legend'],
                labels: {
                    ...options['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
                display: false,
            },
        },
        scales: {
            ...options['scales'],
            x: {
                grid: {
                    ...options['scales']['grid'],
                    color: addOpacity(
                        DAISY_THEME_MAP[daisyTheme]['base-100'],
                        1
                    ),
                },
                ticks: {
                    display: false,
                },
            },
            y: {
                grid: {
                    ...options['scales']['grid'],
                    color: addOpacity(
                        DAISY_THEME_MAP[daisyTheme]['base-100'],
                        1
                    ),
                },
                ticks: {
                    display: false,
                },
            },
        },
    };

    return (
        <Bar className={className} id="chart" data={data} options={options} />
    );
};

export default TinyBarChartComponent;