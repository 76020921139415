import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GLOBAL_STYLES } from '../../utils/styles/global-styles';
import {
    ArrowDownTrayIcon,
    DocumentCheckIcon,
    XMarkIcon,
} from '@heroicons/react/16/solid';
import LimitedDateRangeSelectorComponent, {
    limitedDateTextMap,
} from '../limited-date-range-selector/limited-date-range-selector.component';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../utils/notification.util';
import {
    DISCOVER_GRAPH_TYPES,
    discoverHomeService,
} from '../../store/discover/home/discover-home.services';
import { selectUser } from '../../store/auth/authentication.selectors';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';

const DownloadExtract = ({
    metric,
    perspective,
    selectedDate,
    onDateChange,
}) => {
    const user = useSelector(selectUser);

    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && ref.current === e.target) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    const handleCompleteRequest = async () => {
        try {
            const response = await discoverHomeService.submitDataExtractRequest(
                metric,
                perspective,
                selectedDate,
                user
            );
            createNotification(response.message, NOTIFICATION_TYPES.SUCCESS);
        } catch (err) {}
    };

    return (
        <>
            <label
                className="btn btn-circle btn-ghost btn-xs flex justify-center items-center tooltip tooltip-left normal-case"
                htmlFor={`generate-report-modal-${metric}-${
                    perspective && perspective['type']
                }-${perspective && perspective['code']}`}
                data-tip={'Download Extract'}
            >
                <ArrowDownTrayIcon className="w-4 h-4 text-base-content text-opacity-90" />
            </label>

            <input
                type="checkbox"
                id={`generate-report-modal-${metric}-${
                    perspective && perspective['type']
                }-${perspective && perspective['code']}`}
                className="modal-toggle"
            />

            <div className="modal">
                <div
                    className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl`}
                    style={{
                        maxHeight: '80vh',
                    }}
                >
                    <span className="w-full flex flex-col items-center justify-start mt-2">
                        <div className="flex justify-between items-center w-full gap-2">
                            <h3 className="text-sm font-bold w-fit">
                                Data Extract
                            </h3>

                            <div className="flex flex-wrap grow gap-1 justify-start items-center">
                                <span className="badge badge-primary font-bold capitalize">
                                    {metric.replace('_', ' ')}
                                </span>
                                {perspective && (
                                    <span className="badge badge-secondary font-bold capitalize">
                                        {perspective['type']}
                                        {perspective['code'] &&
                                            ' - ' + perspective['code']}
                                    </span>
                                )}

                                {selectedDate && (
                                    <span className="badge badge-accent font-bold capitalize">
                                        {selectedDate}
                                    </span>
                                )}
                            </div>

                            <div className="flex items-center gap-1">
                                <label
                                    htmlFor={`generate-report-modal-${metric}-${
                                        perspective && perspective['type']
                                    }-${perspective && perspective['code']}`}
                                    className="btn btn-ghost btn-circle btn-sm"
                                >
                                    <XMarkIcon
                                        className="w-4 h-4"
                                        onClick={toggleIsOpen}
                                    />
                                </label>
                            </div>
                        </div>
                    </span>

                    <div className="divider my-2" />

                    <div className="flex flex-wrap gap-2 w-full">
                        <p className="text-base-content text-opacity-80 text-sm my-2">
                            We'll generate a report and email you a secure
                            download link.
                            {selectedDate && (
                                <>
                                    The report will contain{' '}
                                    <strong>
                                        {limitedDateTextMap(selectedDate)}
                                    </strong>{' '}
                                    worth of data.
                                </>
                            )}
                        </p>

                        {/*<p className="text-base-content text-opacity-80 text-xs my-2">*/}
                        {/*    You've selected{' '}*/}
                        {/*    <strong>{metric.replace('_', ' ')}</strong>, which*/}
                        {/*    means our report will include*/}
                        {/*}*/}
                        {/*</p>*/}
                    </div>

                    {!metric.includes('_master') && (
                        <div className="w-full flex items-center justify-end gap-2">
                            <p className="text-sm text-base-content text-opacity-60">
                                Change date period?
                            </p>
                            <LimitedDateRangeSelectorComponent
                                selectedDate={selectedDate}
                                onChange={onDateChange}
                                compact={true}
                            />
                        </div>
                    )}

                    <div className="divider my-2" />

                    <div className="w-full flex justify-end items-center">
                        <label
                            onClick={handleCompleteRequest}
                            htmlFor={`generate-report-modal-${metric}-${
                                perspective && perspective['type']
                            }-${perspective && perspective['code']}`}
                            className="btn btn-primary btn-sm gap-1"
                        >
                            <PaperAirplaneIcon className="w-4 h-4" />
                            Submit Request
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DownloadExtract;