import FooterComponent from '../../../components/footer-component/footer.component';
import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';
import { useEffect, useState } from 'react';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { authenticationService } from '../../../store/auth/authentication.services';

const PasswordResetPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const [email, setEmail] = useState('');

    const onResetHandler = async (event) => {
        event.preventDefault();

        try {
            setIsLoading(true);
            const response = await authenticationService.requestResetPassword(
                email
            );

            if (response.status >= 200 && response.status < 300) {
                createNotification(
                    'You should receive an email with a temporary link to reset your password.',
                    NOTIFICATION_TYPES.SUCCESS
                );
            }

            setIsLoading(false);
            setIsCompleted(true);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
                <div
                    className={`card flex flex-col py-8 px-12 shadow-lg bg-base-300 bg-opacity-95 w-full max-w-xl justify-center items-center min-h-64 z-10`}
                >
                    {!isCompleted ? (
                        <form id="resetForm" className="w-full">
                            <p className="text-base-content text-opacity-80 text-sm">
                                Please enter your email and hit the{' '}
                                <strong>Reset</strong> button. We will email you
                                a secure link which will allow you to reset your
                                password.
                            </p>

                            <div className="divider my-2" />

                            <div className="w-full h-full flex justify-evenly items-start flex-col">
                                <label
                                    className="text-start mb-2 font-bold text-base-content text-opacity-80 text-sm"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    className={`input input-bordered input w-full`}
                                    name="email"
                                    id="email"
                                    type="email"
                                    disabled={isLoading}
                                    value={email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                    required={true}
                                />
                                <div className="divider" />
                                <div className="w-full flex justify-end items-center">
                                    <button
                                        className={`btn btn-outline btn-primary normal-case ${
                                            isLoading &&
                                            'loading disabled btn-disabled'
                                        }`}
                                        onClick={onResetHandler}
                                        type={'button'}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <>
                            <div className="flex flex-wrap break-anywhere items-center gap-1 text-base-content text-opacity-80 text-base">
                                We've sent an email to
                                <span className="badge badge-primary">
                                    {email}
                                </span>{' '}
                                with a link to reset your password.
                            </div>
                        </>
                    )}

                    {/*<p className="text-base-content text-opacity-80">*/}
                    {/*    We're working on some authentication{' '}*/}
                    {/*    <strong>upgrades</strong>.*/}
                    {/*    <span className="divider my-2" />*/}
                    {/*    In the meantime, please contact{' '}*/}
                    {/*    <a*/}
                    {/*        className="btn btn-link text-md font-bold p-1 m-0 btn-sm"*/}
                    {/*        href={`mailto:support@m2north.com?subject=${encodeURIComponent(*/}
                    {/*            'Password Reset Request'*/}
                    {/*        )}&body=${encodeURIComponent(*/}
                    {/*            'Greetings,\n\nCould you please assist with resetting my password to access the Profit Rocket Dashboard?\n\nKind regards, '*/}
                    {/*        )}`}*/}
                    {/*        target={'_blank'}*/}
                    {/*    >*/}
                    {/*        support@m2north.com*/}
                    {/*    </a>*/}
                    {/*    to assist you with resetting your password.*/}
                    {/*</p>*/}
                </div>

                <PublicBackdrop />
            </div>

            <FooterComponent />
        </>
    );
};

export default PasswordResetPage;