import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectDashboardTopStores } from '../../../store/base/dashboard/dashboard.selectors';
import {
    selectDaisyTheme,
    selectThemeColor,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../store/base/filters/base-filters.selectors';
import { setDashboardTopStores } from '../../../store/base/dashboard/dashboard.actions';
import { dashboardService } from '../../../store/base/dashboard/dashboard.services';

import MessageComponent, {
    ALERT_TYPES,
} from '../../message-component/message.component';
import BarChartComponent from '../../../components/charts/bar-chart-component/bar-chart.component';

import { handleError } from '../../../utils/engine/engine.service';
import NoData from '../../no-data/no-data.component';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import {
    base_widget_full,
    base_widget_half,
    muted_border,
} from '../../../utils/styles/tailwind-styles';

const DashboardTopStoresComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [insufficientData, setInsufficientData] = useState(false);

    const dispatch = useDispatch();
    const daisyTheme = useSelector(selectDaisyTheme);

    const dashboardTopStores = useSelector(selectDashboardTopStores);
    const [dashboardTopStoresPlottable, setDashboardTopStoresPlottable] =
        useState(null);

    const selectedFilters = useSelector(selectFiltersSelected);

    const fetchTopStoresHelper = async () => {
        try {
            setIsLoading(true);
            const topStoresData =
                await dashboardService.fetchDashboardTopStores(selectedFilters);
            if (topStoresData !== null && !('action' in topStoresData)) {
                dispatch(setDashboardTopStores(topStoresData));
                setDashboardTopStoresPlottable(
                    dashboardService.convertTopSkuDataToPlottable(
                        topStoresData,
                        daisyTheme
                    )
                );
                setInsufficientData(
                    topStoresData['insufficientComparisonData']
                );
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    /*
    Re-fetch data when user updates selected filters (and on initial component render)
     */
    useEffect(() => {
        fetchTopStoresHelper();
    }, [selectedFilters]);

    /*
    Update graph colours when user alters theme color
     */
    useEffect(() => {
        if (dashboardTopStores !== null && !('action' in dashboardTopStores)) {
            setDashboardTopStoresPlottable(
                dashboardService.convertTopSkuDataToPlottable(
                    dashboardTopStores,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    return (
        <>
            {!isLoading && insufficientData && (
                <div className="col-span-full flex justify-center">
                    <MessageComponent
                        type={ALERT_TYPES.ERROR}
                        message="Insufficient Data to Compare Year on Year"
                        title="Note"
                    />
                </div>
            )}

            {isLoading ? (
                <PageLoadingComponent />
            ) : dashboardTopStoresPlottable === null ||
              dashboardTopStoresPlottable === undefined ? (
                <NoData className="col-span-full" />
            ) : (
                <>
                    <div className={`${base_widget_half} ${muted_border}`}>
                        <BarChartComponent
                            data={
                                dashboardTopStoresPlottable['byValueGraphData']
                            }
                            title="Top Stores"
                            type={'By Value'}
                        />
                    </div>

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <BarChartComponent
                            data={
                                dashboardTopStoresPlottable['byVolumeGraphData']
                            }
                            title="Top Stores"
                            type={'By Volume'}
                        />
                    </div>

                    {!insufficientData ? (
                        <>
                            <div
                                className={`${base_widget_half} ${muted_border}`}
                            >
                                <BarChartComponent
                                    data={
                                        dashboardTopStoresPlottable[
                                            'biggestDropsGraphData'
                                        ]
                                    }
                                    title="Biggest Drops"
                                />
                            </div>

                            <div
                                className={`${base_widget_half} ${muted_border}`}
                            >
                                <BarChartComponent
                                    data={
                                        dashboardTopStoresPlottable[
                                            'highestGainsGraphData'
                                        ]
                                    }
                                    title="Highest Gains"
                                />
                            </div>

                            <div
                                className={`${base_widget_full} ${muted_border}`}
                            >
                                <BarChartComponent
                                    data={
                                        dashboardTopStoresPlottable[
                                            'mostProfitGraphData'
                                        ]
                                    }
                                    title="Most Profit"
                                />
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </>
            )}
        </>
    );
};

export default DashboardTopStoresComponent;
