import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    selectUser,
    selectUserToken,
} from '../../../store/auth/authentication.selectors';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { DISCOVER_WIDGET_AGGREGATION_METRICS } from '../../../store/discover/widgets/widgets.types';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { discoverHomeService } from '../../../store/discover/home/discover-home.services';
import { CheckIcon } from '@heroicons/react/16/solid';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import { glowing_border } from '../../../utils/styles/tailwind-styles';
import { getYearMonthFromDate } from '../../../utils/date.util';

function isNumeric(str) {
    if (typeof str != 'string') return false; // we only process strings!
    return !isNaN(str) && !isNaN(parseFloat(str));
}

const TargetEdit = ({
    metric,
    perspective,
    targetValue,
    targetDate,
    cancelEdit,
    onFinish,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [newTarget, setNewTarget] = useState(
        targetValue === 0 ? '' : targetValue
    );

    useEffect(() => {}, [targetValue]);

    const handleSaveTarget = async () => {
        setIsLoading(true);
        discoverHomeService
            .setTarget(metric, perspective, targetDate, newTarget)
            .then((response) => {
                if ('hash' in response) {
                    createNotification(
                        `${
                            perspective['type'] ===
                            DISCOVER_WIDGET_AGGREGATION_METRICS.SKU
                                ? 'Product'
                                : perspective.type[0].toUpperCase() +
                                  perspective.type.substring(1)
                        } ${
                            perspective['type'] !== 'global'
                                ? ` [${perspective['code']}]`
                                : ''
                        } target updated to ${newTarget.toLocaleString()} for month [${targetDate}]`,
                        NOTIFICATION_TYPES.SUCCESS
                    );
                }
                setIsLoading(false);
                onFinish(newTarget);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading ? (
                <div className={'flex items-center w-full'}>
                    <div
                        className={`input input-sm input-primary bg-base-300 text-xs focus:shadow-none focus:outline-none focus:outline-offset-0 w-full flex items-center justify-center`}
                    >
                        <LoadingComponent
                            type={LOADING_COMPONENT_TYPES.BOUNCY}
                            size={25}
                            speed={1.5}
                        />
                    </div>
                    <button className={`btn btn-xs h-full btn-disabled`}>
                        <CheckIcon className={'w-4 h-4'} />
                    </button>
                </div>
            ) : (
                <div className={'flex items-center w-full'}>
                    <input
                        className={`input input-sm input-primary bg-base-300 text-xs w-full`}
                        placeholder={`Add target (${targetDate})`}
                        inputMode={'numeric'}
                        type={'text'}
                        value={newTarget}
                        onChange={(e) => {
                            if (
                                e.target.value === '' ||
                                isNumeric(e.target.value)
                            )
                                setNewTarget(e.target.value);
                        }}
                    />
                    {cancelEdit && (
                        <button
                            className={`btn btn-xs btn-ghost h-full tooltip`}
                            data-tip={'Cancel Edit'}
                            onClick={cancelEdit}
                        >
                            <XMarkIcon className={'w-4 h-4 text-error'} />
                        </button>
                    )}

                    <button
                        className={`btn btn-xs h-full ${
                            newTarget === '' ||
                            Number(newTarget) === Number(targetValue)
                                ? 'btn-disabled'
                                : 'btn-success tooltip'
                        }`}
                        data-tip={'Save Target'}
                        onClick={handleSaveTarget}
                    >
                        <CheckIcon className={'w-4 h-4'} />
                    </button>
                </div>
            )}
        </>
    );
};

export default TargetEdit;