import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { apiService } from '../../utils/engine/api.service';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../utils/notification.util';
import { useSelector } from 'react-redux';
import { selectUserToken } from '../../store/auth/authentication.selectors';
import {
    LOADING_COMPONENT_TYPES,
    LoadingComponent,
} from '../loading-component/loading-component';

const stripePromise = loadStripe('pk_test_GLDE4wNKx81Wy7HtTzxQVxxB');

const StripeCheckoutForm = () => {
    const [clientSecret, setClientSecret] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        apiService
            .createStripeCheckoutSession()
            .then((response) => {
                setIsLoading(false);
                return JSON.parse(response);
            })
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    return (
        <div id="checkout" className={'w-full col-span-full'}>
            {isLoading ? (
                <div className="w-fullm  flex items center justify-center">
                    <LoadingComponent
                        type={LOADING_COMPONENT_TYPES.HELIX}
                        size={200}
                    />
                </div>
            ) : (
                clientSecret && (
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        options={{ clientSecret }}
                    >
                        <EmbeddedCheckout className={'bg-opacity-0'} />
                    </EmbeddedCheckoutProvider>
                )
            )}
        </div>
    );
};

export default StripeCheckoutForm;