import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    selectThemeColor,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../store/base/filters/base-filters.selectors';
import { setBaseFilterOptions } from '../../../store/base/filters/options/base-filter-options.actions';
import { resetBaseFiltersSelected } from '../../../store/base/filters/base-filters.actions';
import { filterService } from '../../../store/base/filters/base-filters.services';

import CompactFilterContainer from './compact-filter-container/compact-filter-container.component';
import RetailerFilters from './retailer-filters/retailer-filters.component';
import SkuFilters from './sku-filters/sku-filters.component';

import { GLOBAL_STYLES } from '../../../utils/styles/global-styles';
import { handleError } from '../../../utils/engine/engine.service';

import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    TrashIcon,
} from '@heroicons/react/24/solid';
import { muted_border } from '../../../utils/styles/tailwind-styles';
import AnimatedBorder from '../../animated-border/animated-border.component';

const FiltersContainer = ({ compactOnly, overrideSelected, className }) => {
    const dispatch = useDispatch();

    const themeMode = useSelector(selectThemeMode);
    const themeColor = useSelector(selectThemeColor);

    const filterState = useSelector(selectFiltersSelected);

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [headingVisible, setHeadingVisible] = useState(true);

    const initializeFilterOptions = async () => {
        const response = await filterService.loadInitialFilterOptions(
            filterState
        );
        const options = filterService.removeUnwantedKeys(response);
        dispatch(setBaseFilterOptions(options));
    };

    useEffect(() => {
        setIsLoading(true);

        initializeFilterOptions();

        setIsLoading(false);
    }, []);

    const setLoading = (value) => {
        setIsLoading(value);
    };

    const toggleIsOpen = () => {
        if (compactOnly) {
            setIsOpen(false);
        } else {
            setIsOpen(!isOpen);
        }
    };

    const resetFilters = () => {
        dispatch(resetBaseFiltersSelected());
        updateFilterOptions();
    };

    const updateFilterOptions = async () => {
        try {
            setLoading(true);
            const response = await filterService.loadInitialFilterOptions();
            const options = filterService.removeUnwantedKeys(response);

            dispatch(setBaseFilterOptions(options));
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    function handleResize() {
        if (window.innerWidth >= GLOBAL_STYLES['screen-md'].replace('px', '')) {
            setHeadingVisible(true);
        } else if (
            window.innerWidth < GLOBAL_STYLES['screen-md'].replace('px', '')
        ) {
            setHeadingVisible(false);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {!isOpen ? (
                <div
                    className={`relative rounded-box bg-base-300 hover:bg-base-200 min-h-8 w-full max-w-8xl p-2 flex justify-between items-center ${className} ${muted_border} group`}
                >
                    <AnimatedBorder withGlow={false} />

                    <span
                        className={`w-full h-full flex justify-between items-center cursor${
                            compactOnly ? '' : '-pointer'
                        }`}
                        onClick={toggleIsOpen}
                    >
                        {headingVisible && (
                            <h3 className="badge badge-outline badge-primary text-xs mr-4 w-auto font-bold">
                                Filters
                            </h3>
                        )}

                        {isLoading ? (
                            <LoadingComponent
                                className="w-6 h-6 border-t-2 border-primary my-2"
                                size={20}
                                type={LOADING_COMPONENT_TYPES.TAILCHASE}
                            />
                        ) : (
                            <CompactFilterContainer
                                themeMode={themeMode}
                                themeColor={themeColor}
                                overrideSelected={overrideSelected}
                            />
                        )}
                    </span>

                    {overrideSelected ? (
                        ''
                    ) : (
                        <span className="btn btn-circle btn-ghost btn-sm">
                            <TrashIcon
                                className="w-5 h-5 text-error "
                                onClick={resetFilters}
                            />
                        </span>
                    )}

                    {compactOnly ? (
                        ''
                    ) : (
                        <span className="btn btn-circle btn-ghost btn-sm">
                            <ChevronDownIcon
                                className="w-5 h-5"
                                onClick={toggleIsOpen}
                            />
                        </span>
                    )}
                </div>
            ) : (
                <div
                    className={`rounded-box bg-base-300 min-h-8 p-2 flex justify-between z-20 shadow ${className} ${muted_border} group relative`}
                >
                    <AnimatedBorder withGlow={false} />
                    <div className="flex justify-evenly items-center w-full flex-col sm:flex-row gap-1">
                        <RetailerFilters
                            isLoading={isLoading}
                            setLoading={setLoading}
                        />

                        <SkuFilters
                            isLoading={isLoading}
                            setLoading={setLoading}
                        />
                    </div>

                    <span className="flex flex-col min-h-full justify-between">
                        <span className="btn btn-circle btn-ghost btn-sm">
                            <ChevronUpIcon
                                className="w-5 h-5"
                                onClick={toggleIsOpen}
                            />
                        </span>
                    </span>
                </div>
            )}
        </>
    );
};

export default FiltersContainer;