import {
    discover_page,
    glowing_border,
} from '../../../../utils/styles/tailwind-styles';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';

import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../../../components/loading-component/loading-component';

const DiscoverTabPage = () => {
    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div
                className={`w-full h-full max-w-7xl grid grid-cols-12 items-start justify-start gap-2`}
            >
                <div className="h-full w-full flex items-center justify-center col-span-full">
                    <div className="flex flex-col justify-center items-center gap-2 my-2">
                        <span
                            className={`text-base-content text-opacity-70 text-lg p-4 ${glowing_border} border-primary`}
                        >
                            Coming Soon
                        </span>
                        <LoadingComponent
                            type={LOADING_COMPONENT_TYPES.HELIX}
                            size={200}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscoverTabPage;
