import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';

import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { filterService } from '../../../../store/base/filters/base-filters.services';
import { setBaseFilterOptions } from '../../../../store/base/filters/options/base-filter-options.actions';
import {
    clearBaseFilterSelected,
    setBaseFiltersSelected,
} from '../../../../store/base/filters/base-filters.actions';

import { generateSelectStylesDaisy } from '../../../../utils/styles/global-styles';
import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const BasicFilter = (props) => {
    const dispatch = useDispatch();
    const daisyTheme = useSelector(selectDaisyTheme);

    const [windowSize, setWindowSize] = useState(getWindowSize());

    const {
        id,
        options,
        placeholder,
        isMulti,
        isClearable,
        className,
        isLoading,
        setLoading,
    } = props;

    const filterState = useSelector(selectFiltersSelected);
    const [localFilter, setLocalFilter] = useState(filterState[id]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const customStyles = generateSelectStylesDaisy(daisyTheme, windowSize);

    const onFilterChange = (event) => {
        if (isMulti) {
            if (event.length === 0) {
                setLocalFilter(null);
            } else {
                setLocalFilter(event);
            }
        } else {
            setLocalFilter(event);
        }
    };

    const updateFilterOptions = async () => {
        setLoading(true);
        let conjoinedFilter = { ...filterState };

        if (localFilter != null) {
            conjoinedFilter[id] = localFilter;
        } else {
            delete conjoinedFilter[id];
        }

        const response = await filterService.loadFilterOptions(
            conjoinedFilter,
            id
        );

        const options = filterService.removeUnwantedKeys(response);
        dispatch(setBaseFilterOptions(options));
        setLoading(false);
    };

    const onFilterClose = () => {
        if (
            localFilter === null ||
            localFilter === undefined ||
            ('length' in localFilter && localFilter.length === 0)
        ) {
            dispatch(clearBaseFilterSelected(id));
            setLocalFilter(null);
        } else {
            if (JSON.stringify(localFilter) !== JSON.stringify(filterState[id])) {
                dispatch(setBaseFiltersSelected(id, localFilter));
            }
        }

        if (
            localFilter !== filterState[id] &&
            (localFilter !== null || filterState[id] !== undefined)
        ) {
            updateFilterOptions();
        }
    };

    return (
        <Select
            key={id}
            styles={customStyles}
            onChange={onFilterChange}
            onMenuClose={onFilterClose}
            value={localFilter}
            isLoading={isLoading}
            isDisabled={isLoading}
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            isClearable={isClearable}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            menuPlacement={'bottom'}
            className={`${className} relative`}
        />
    );
};

export default BasicFilter;
