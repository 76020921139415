import { useSelector } from 'react-redux';
import { selectDashboardActiveComponent } from '../../../../store/base/dashboard/dashboard.selectors';

import FiltersContainer from '../../../../components/base/filters/filters-container.component';

import DashboardNavigation, {
    DASHBOARD_NAVIGATION_COMPONENT_TABS,
} from '../../../../components/base/dashboard/dashboard-navigation.component';
import DashboardTopSkusComponent from '../../../../components/base/dashboard/dashboard-top-skus.component';
import DashboardTopStoresComponent from '../../../../components/base/dashboard/dashboard-top-stores.component';
import DashboardDataStatusComponent from '../../../../components/base/dashboard/dashboard-data-status.component';
import DashboardSalesPerformanceComponent from '../../../../components/base/dashboard/dashboard-sales-performance.component';
import { base_grid, base_page } from '../../../../utils/styles/tailwind-styles';

function renderComponent(activeComponent) {
    switch (activeComponent) {
        case DASHBOARD_NAVIGATION_COMPONENT_TABS.TOP_SKU_CODES:
            return <DashboardTopSkusComponent />;
        case DASHBOARD_NAVIGATION_COMPONENT_TABS.TOP_STORES:
            return <DashboardTopStoresComponent />;
        case DASHBOARD_NAVIGATION_COMPONENT_TABS.DATA_STATUS:
            return <DashboardDataStatusComponent />;
        case DASHBOARD_NAVIGATION_COMPONENT_TABS.SALES_PERFORMANCE:
            return <DashboardSalesPerformanceComponent />;
        case DASHBOARD_NAVIGATION_COMPONENT_TABS.ACTIVITY:
            return '';
        default:
            return '';
    }
}

const DashboardPage = () => {
    const activeComponent = useSelector(selectDashboardActiveComponent);

    return (
        <>
            <DashboardNavigation />

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <FiltersContainer className={'col-span-full'} />

                    {renderComponent(activeComponent)}
                </div>
            </div>
        </>
    );
};

export default DashboardPage;