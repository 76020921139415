import { Route, Routes } from 'react-router-dom';

import DashboardPage from './dashboard-page/dashboard.page';
import AlertsPage from './alerts-page/alerts.page';
import DistributionPage from './distribution-page/distribution.page';
import ForecastingPage from './forecasting-page/forecasting.page';
import RangingPage from './ranging-page/ranging.page';
import SalesPage from './sales-page/sales.page';
import ManageReportsPage from './manage-reports-page/manage-reports.page';
import TeamPage from '../team-page/team.page';
import StockCoverPage from './stock-cover-page/stock-cover.page';
import SOHTrendPage from './soh-trend-page/soh-trend.page';
import FooterComponent from '../../../components/footer-component/footer.component';
import PageNotFound from '../../public/errors/page-not-found';

import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/auth/authentication.selectors';
import {
    isM2Support,
    isCAdmin,
    isM2Admin,
    isCOwner,
} from '../../../utils/auth.util';

const BasePage = () => {
    const user = useSelector(selectUser);

    return (
        <>
            <Routes>
                <Route
                    index={true}
                    path="dashboard"
                    element={<DashboardPage />}
                />

                <Route path="ranging" element={<RangingPage />} />

                <Route path="stock-trend" element={<SOHTrendPage />} />

                <Route path="stock-cover" element={<StockCoverPage />} />

                <Route path="distribution" element={<DistributionPage />} />

                <Route path="sales" element={<SalesPage />} />

                <Route path="forecasting" element={<ForecastingPage />} />

                <Route path="alerts" element={<AlertsPage />} />

                <Route path="manage-reports" element={<ManageReportsPage />} />

                {(isM2Admin(user) ||
                    isM2Support(user) ||
                    isCOwner(user) ||
                    isCAdmin(user)) && (
                    <Route
                        path="manage-users"
                        exact={true}
                        element={<TeamPage />}
                    />
                )}

                <Route path="*" exact={true} element={<PageNotFound />} />
            </Routes>

            <FooterComponent className="mt-2" />
        </>
    );
};

export default BasePage;