export const THEMES_ACTION_TYPES = {
    SET_THEME_COLOR: 'themes/SET_THEME_COLOR',
    SET_THEME_MODE: 'themes/SET_THEME_MODE',

    SET_DAISY_THEME: 'themes/SET_DAISY_THEME',
};

export const THEME_MODES = {
    DARK: 'dark',
    LIGHT: 'light',
};

export const THEME_COLORS = {
    indigo: 'indigo',
    // emerald: 'emerald',

    fuchsia: 'fuchsia',
    lime: 'lime',

    // rose: 'rose',
    amber: 'amber',

    cyan: 'cyan',
    slate: 'slate',
};

export const DAISY_THEMES = {
    CORPORATE: 'corporate',
    BUSINESS: 'business',

    LIGHT: 'light',
    DARK: 'dark',

    COFFEE: 'coffee',
};