import {
    addOpacity,
    DAISY_THEME_MAP,
    GRAPH_OPACITY,
    GRAPH_OPACITY_SUBDUED,
    graphColourSelector,
    lineGraphStyles,
} from '../../../utils/styles/global-styles';

export const discoverGraphService = {
    convertSalesTrendDataToLineCharts,
    convertSalesQtyDataToLineCharts,
    convertSalesPerformersToPlottable,
    convertReturnsTrendDataToLineCharts,
    convertDiscountsTrendDataToLineCharts,
    convertUnusedCreditTrendDatatoLineCharts,
    convertProfitTrendDataToLineCharts,
    convertChaosTrendDataToLineCharts,
};

function convertSalesTrendDataToLineCharts(trendData, daisyTheme) {
    const positiveColours = graphColourSelector(0, daisyTheme);
    const negativeColours = graphColourSelector(1, daisyTheme);

    let returnData = {};
    delete trendData['hash'];

    Object.keys(trendData).forEach((dateKey) => {
        if (dateKey !== 'hash' && dateKey !== 'subAggregates') {
            const labels = Object.keys(trendData[dateKey]['trend']);

            returnData[dateKey] = {
                labels: labels,
                datasets: [
                    {
                        label: 'Net Sales Value',
                        data: Object.keys(trendData[dateKey]['trend']).map(
                            (item) => {
                                return trendData[dateKey]['trend'][item][
                                    'curr'
                                ];
                            }
                        ),
                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                positiveColours.backgroundColor,
                                GRAPH_OPACITY
                            ),
                        },

                        borderColor: positiveColours.borderColor,

                        backgroundColor: addOpacity(
                            positiveColours.backgroundColor,
                            GRAPH_OPACITY
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                    {
                        label: 'Target Value',
                        data: Object.keys(trendData[dateKey]['trend']).map(
                            (item) => {
                                return trendData[dateKey]['trend'][item][
                                    'target'
                                ];
                            }
                        ),

                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                negativeColours.backgroundColor,
                                GRAPH_OPACITY_SUBDUED
                            ),
                        },

                        borderColor: negativeColours.borderColor,
                        backgroundColor: addOpacity(
                            negativeColours.backgroundColor,
                            GRAPH_OPACITY_SUBDUED
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                ],
            };
        }
    });
    return returnData;
}

function convertSalesQtyDataToLineCharts(trendData, daisyTheme) {
    const positiveColours = graphColourSelector(0, daisyTheme);
    const negativeColours = graphColourSelector(1, daisyTheme);

    let returnData = {};
    delete trendData['hash'];

    Object.keys(trendData).forEach((dateKey) => {
        if (dateKey !== 'hash' && dateKey !== 'subAggregates') {
            const labels = Object.keys(trendData[dateKey]['trend']);

            returnData[dateKey] = {
                labels: labels,
                datasets: [
                    {
                        label: 'Net Sales Qty',
                        data: Object.keys(trendData[dateKey]['trend']).map(
                            (item) => {
                                return trendData[dateKey]['trend'][item]['qty'];
                            }
                        ),

                        borderColor: positiveColours.borderColor,
                        backgroundColor: addOpacity(
                            positiveColours.backgroundColor,
                            GRAPH_OPACITY_SUBDUED
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                    {
                        label: 'Mean Selling Price',
                        data: Object.keys(trendData[dateKey]['trend']).map(
                            (item) => {
                                return trendData[dateKey]['trend'][item]['msp'];
                            }
                        ),

                        borderColor: negativeColours.borderColor,

                        backgroundColor: addOpacity(
                            negativeColours.backgroundColor,
                            GRAPH_OPACITY_SUBDUED
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y6',
                    },
                ],
            };
        }
    });
    return returnData;
}

function convertSalesPerformersToPlottable(
    unformattedData,
    top,
    daisyTheme,
    returns = false
) {
    delete unformattedData['hash'];

    let newObject = {};
    Object.keys(unformattedData).forEach((time) => {
        if (time !== 'hash' && time !== 'subAggregates') {
            const byTime = unformattedData[time];
            if (unformattedData[time]) {
                if (!(time in newObject)) newObject[time] = {};

                const labels = byTime.map((el) => el.descr);

                newObject[time] = {
                    labels,
                    datasets: [
                        {
                            label: 'Value',
                            data: byTime.map((el) => el.value),
                            borderColor: returns
                                ? DAISY_THEME_MAP[daisyTheme]['primary']
                                : DAISY_THEME_MAP[daisyTheme]['primary'],
                            backgroundColor: addOpacity(
                                returns
                                    ? DAISY_THEME_MAP[daisyTheme]['primary']
                                    : DAISY_THEME_MAP[daisyTheme]['primary'],
                                GRAPH_OPACITY
                            ),
                            xAxisID: 'x',
                        },
                        {
                            label: '% of Total',
                            data: byTime.map((el) => el.perc),
                            borderColor:
                                DAISY_THEME_MAP[daisyTheme]['secondary'],
                            backgroundColor: addOpacity(
                                DAISY_THEME_MAP[daisyTheme]['secondary'],
                                GRAPH_OPACITY
                            ),
                            xAxisID: 'x2',
                        },
                    ],
                };
            }
        }
    });

    return newObject;
}

function convertReturnsTrendDataToLineCharts(returnsTrendData, daisyTheme) {
    const positiveColours = graphColourSelector(0, daisyTheme);
    const negativeColours = graphColourSelector(1, daisyTheme);

    let returnData = {};

    delete returnsTrendData['hash'];

    Object.keys(returnsTrendData).forEach((dateKey) => {
        if (
            dateKey !== 'hash' &&
            dateKey !== 'subAggregates' &&
            !Array.isArray(returnsTrendData[dateKey])
        ) {
            const labels = Object.keys(returnsTrendData[dateKey]['trend']);

            returnData[dateKey] = {
                labels: labels,
                datasets: [
                    {
                        label: 'Returns Value',
                        data: Object.keys(
                            returnsTrendData[dateKey]['trend']
                        ).map((item) => {
                            return returnsTrendData[dateKey]['trend'][item][
                                'returns'
                            ];
                        }),
                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                positiveColours.backgroundColor,
                                GRAPH_OPACITY
                            ),
                        },

                        borderColor: positiveColours.borderColor,
                        backgroundColor: addOpacity(
                            positiveColours.backgroundColor,
                            GRAPH_OPACITY
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                    {
                        label: '% of Returns vs Gross Sales',
                        data: Object.keys(
                            returnsTrendData[dateKey]['trend']
                        ).map((item) => {
                            return returnsTrendData[dateKey]['trend'][item][
                                'perc'
                            ];
                        }),

                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                negativeColours.backgroundColor,
                                GRAPH_OPACITY_SUBDUED
                            ),
                        },

                        borderColor: negativeColours.borderColor,
                        backgroundColor: addOpacity(
                            negativeColours.backgroundColor,
                            GRAPH_OPACITY_SUBDUED
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y3',
                    },
                ],
            };
        }
    });
    return returnData;
}

function convertDiscountsTrendDataToLineCharts(discountsTrendData, daisyTheme) {
    const positiveColours = graphColourSelector(0, daisyTheme);
    const negativeColours = graphColourSelector(1, daisyTheme);

    let returnData = {};
    delete discountsTrendData['hash'];

    Object.keys(discountsTrendData).forEach((dateKey) => {
        if (
            dateKey !== 'hash' &&
            dateKey !== 'subAggregates' &&
            !Array.isArray(discountsTrendData[dateKey])
        ) {
            const labels = Object.keys(discountsTrendData[dateKey]['trend']);

            returnData[dateKey] = {
                labels: labels,
                datasets: [
                    {
                        label: 'Discount vs Gross Sales %',
                        data: Object.keys(
                            discountsTrendData[dateKey]['trend']
                        ).map((item) => {
                            return discountsTrendData[dateKey]['trend'][item][
                                'discountedPerc'
                            ];
                        }),
                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                negativeColours.backgroundColor,
                                GRAPH_OPACITY_SUBDUED
                            ),
                        },

                        borderColor: negativeColours.borderColor,
                        backgroundColor: addOpacity(
                            negativeColours.backgroundColor,
                            GRAPH_OPACITY_SUBDUED
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y3',
                    },
                    {
                        label: 'Discounted Value',
                        data: Object.keys(
                            discountsTrendData[dateKey]['trend']
                        ).map((item) => {
                            return discountsTrendData[dateKey]['trend'][item][
                                'discountedValue'
                            ];
                        }),

                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                positiveColours.backgroundColor,
                                GRAPH_OPACITY
                            ),
                        },

                        borderColor: positiveColours.borderColor,
                        backgroundColor: addOpacity(
                            positiveColours.backgroundColor,
                            GRAPH_OPACITY
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                ],
            };
        }
    });
    return returnData;
}

function convertUnusedCreditTrendDatatoLineCharts(
    unusedCreditTrendData,
    daisyTheme
) {
    const positiveColours = graphColourSelector(0, daisyTheme);
    const negativeColours = graphColourSelector(1, daisyTheme);

    let returnData = {};
    delete unusedCreditTrendData['hash'];

    Object.keys(unusedCreditTrendData).forEach((dateKey) => {
        if (
            dateKey !== 'hash' &&
            dateKey !== 'subAggregates' &&
            !Array.isArray(unusedCreditTrendData[dateKey])
        ) {
            const labels = Object.keys(unusedCreditTrendData[dateKey]['trend']);

            returnData[dateKey] = {
                labels: labels,
                datasets: [
                    {
                        label: 'Sales Value',
                        data: Object.keys(
                            unusedCreditTrendData[dateKey]['trend']
                        ).map((item) => {
                            return unusedCreditTrendData[dateKey]['trend'][
                                item
                            ]['sales'];
                        }),
                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                positiveColours.backgroundColor,
                                GRAPH_OPACITY
                            ),
                        },

                        borderColor: positiveColours.borderColor,
                        backgroundColor: addOpacity(
                            positiveColours.backgroundColor,
                            GRAPH_OPACITY
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                    {
                        label: 'Remaining Credit',
                        data: Object.keys(
                            unusedCreditTrendData[dateKey]['trend']
                        ).map((item) => {
                            return unusedCreditTrendData[dateKey]['trend'][
                                item
                            ]['credit'];
                        }),

                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                negativeColours.backgroundColor,
                                GRAPH_OPACITY_SUBDUED
                            ),
                        },

                        borderColor: negativeColours.borderColor,
                        backgroundColor: addOpacity(
                            negativeColours.backgroundColor,
                            GRAPH_OPACITY_SUBDUED
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                ],
            };
        }
    });
    return returnData;
}

function convertChaosTrendDataToLineCharts(chaosTrendsData, daisyTheme) {
    const overallColour = {
        borderColor: DAISY_THEME_MAP[daisyTheme].primary,
        backgroundColor: DAISY_THEME_MAP[daisyTheme].primary,
    };

    const salesColour = {
        borderColor: DAISY_THEME_MAP[daisyTheme].accent,
        backgroundColor: DAISY_THEME_MAP[daisyTheme].accent,
    };

    const returnsColour = {
        borderColor: DAISY_THEME_MAP[daisyTheme].secondary,
        backgroundColor: DAISY_THEME_MAP[daisyTheme].secondary,
    };

    const discountColour = graphColourSelector(2, daisyTheme);
    const profitColour = graphColourSelector(3, daisyTheme);

    const creditColour = {
        borderColor: DAISY_THEME_MAP[daisyTheme].accent,
        backgroundColor: DAISY_THEME_MAP[daisyTheme].accent,
    };

    if (!chaosTrendsData) return {};

    let chaosData = {};

    const labels = Object.keys(chaosTrendsData);
    const pillars = Object.keys(
        chaosTrendsData[Object.keys(chaosTrendsData)[0]]
    );

    chaosData = {
        labels: labels,
        datasets: [
            {
                label: 'Performance Score',
                data: Object.keys(chaosTrendsData).map(
                    (el) => chaosTrendsData[el].overall
                ),
                borderColor: overallColour.borderColor,
                backgroundColor: overallColour.backgroundColor,
                borderWidth: 4,

                tension: 0.25,

                pointRadius: 1,
                pointHoverRadius: 4,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 2,

                yAxisID: 'y5',
            },
            {
                label: 'Sales Pillar',
                data: Object.keys(chaosTrendsData).map(
                    (el) => chaosTrendsData[el].sales
                ),
                borderColor: salesColour.borderColor,
                backgroundColor: salesColour.backgroundColor,
                borderWidth: 1,

                tension: 0.25,

                pointRadius: 0.5,
                pointHoverRadius: 2,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 2,
                yAxisID: 'y5',
            },
            {
                label: 'Returns Pillar',
                data: Object.keys(chaosTrendsData).map(
                    (el) => chaosTrendsData[el].returns
                ),

                borderColor: returnsColour.borderColor,
                backgroundColor: returnsColour.backgroundColor,
                borderWidth: 1,

                tension: 0.25,

                pointRadius: 0.5,
                pointHoverRadius: 2,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 2,
                yAxisID: 'y5',
            },
        ],
    };

    if (pillars.includes('discount')) {
        chaosData['datasets'].push({
            label: 'Discount Pillar',
            data: Object.keys(chaosTrendsData).map(
                (el) => chaosTrendsData[el].discount
            ),

            borderColor: discountColour.borderColor,
            backgroundColor: discountColour.backgroundColor,
            borderWidth: 1,

            tension: 0.25,

            pointRadius: 0.5,
            pointHoverRadius: 2,
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            yAxisID: 'y5',
        });
    }

    if (pillars.includes('profit')) {
        chaosData['datasets'].push({
            label: 'Profit Pillar',
            data: Object.keys(chaosTrendsData).map(
                (el) => chaosTrendsData[el].profit
            ),

            borderColor: profitColour.borderColor,
            backgroundColor: profitColour.backgroundColor,
            borderWidth: 1,

            tension: 0.25,

            pointRadius: 0.5,
            pointHoverRadius: 2,
            pointBorderWidth: 1,
            pointHoverBorderWidth: 2,
            yAxisID: 'y5',
        });
    }

    return chaosData;
}

function convertProfitTrendDataToLineCharts(trendData, daisyTheme) {
    const positiveColours = graphColourSelector(0, daisyTheme);
    const negativeColours = graphColourSelector(1, daisyTheme);

    let returnData = {};
    delete trendData['hash'];

    Object.keys(trendData).forEach((dateKey) => {
        if (dateKey !== 'hash' && dateKey !== 'subAggregates') {
            const labels = Object.keys(trendData[dateKey]['trend']);

            returnData[dateKey] = {
                labels: labels,
                datasets: [
                    {
                        label: 'Profit Value',
                        data: Object.keys(trendData[dateKey]['trend']).map(
                            (item) => {
                                return trendData[dateKey]['trend'][item][
                                    'value'
                                ];
                            }
                        ),
                        fill: {
                            target: 'origin',
                            above: addOpacity(
                                positiveColours.backgroundColor,
                                GRAPH_OPACITY
                            ),
                        },

                        borderColor: positiveColours.borderColor,
                        backgroundColor: addOpacity(
                            positiveColours.backgroundColor,
                            GRAPH_OPACITY
                        ),

                        ...lineGraphStyles,

                        yAxisID: 'y1',
                    },
                ],
            };
        }
    });
    return returnData;
}
