import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    BanknotesIcon,
    MegaphoneIcon,
    DocumentChartBarIcon,
    UserGroupIcon,
    TruckIcon,
    TagIcon,
} from '@heroicons/react/24/solid';

import {
    selectSidebarActive,
    selectSidebarMode,
} from '../../../store/navigation/navigation.selectors';
import { setSidebarIsActive } from '../../../store/navigation/navigation.actions';

import Dropdown from '../../dropdown-component/dropdown.component';
import LogoutMenu from '../../logout-menu-component/logout-menu.component';

import '../../navigation-bar-component/navigation-bar.styles.css';
import { selectUser } from '../../../store/auth/authentication.selectors';

const PRBaseNavigation = () => {
    const dispatch = useDispatch();

    const sidebarMode = useSelector(selectSidebarMode);
    const sidebarActive = useSelector(selectSidebarActive);

    const user = useSelector(selectUser);

    const location = useLocation();

    const setSidebarInactive = () => {
        dispatch(setSidebarIsActive(false));
    };
    /*
    Rerender component when sidebar mode changes, which is dictated by redux and screen width
     */
    useEffect(() => {}, [sidebarMode]);

    return (
        <div
            className={
                !sidebarMode
                    ? 'flex justify-evenly items-center text-center'
                    : sidebarActive
                    ? 'bg-base-100 drop-shadow absolute top-full right-0 min-h-screen w-64 flex flex-col justify-start items-end p-3 transition-all visible'
                    : 'bg-base-100 drop-shadow absolute top-full right-0 min-h-screen w-64 flex flex-col justify-start items-end p-3 transition-all invisible'
            }
        >
            <Link
                to="base/ranging"
                className={`btn btn-ghost normal-case text-xs hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/base/ranging'
                                  ? 'font-bold'
                                  : 'font-normal'
                          }`
                        : `flex justify-between items-center px-4 ${
                              location.pathname === '/base/ranging'
                                  ? 'font-bold'
                                  : 'font-normal'
                          }`
                }`}
                onClick={setSidebarInactive}
            >
                <TagIcon className="w-5 h-5" />
                <p className="ml-2">Ranging</p>
            </Link>

            <Dropdown
                title="Stock"
                options={[
                    {
                        name: 'Trend',
                        path: 'base/stock-trend',
                    },
                    {
                        name: 'Cover',
                        path: 'base/stock-cover',
                    },
                ]}
                className={`btn btn-ghost normal-case text-xs hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/base/stock-trend' ||
                              location.pathname === '/base/stock-cover'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                        : `flex justify-between items-center ${
                              location.pathname === '/base/stock-trend' ||
                              location.pathname === '/base/stock-cover'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                }`}
                onClick={setSidebarInactive}
            />

            <Link
                to="base/distribution"
                className={`btn btn-ghost normal-case text-xs hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/base/distribution'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                        : `flex justify-between items-center px-4 ${
                              location.pathname === '/base/distribution'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                }`}
                onClick={setSidebarInactive}
            >
                <TruckIcon className="w-5 h-5" />
                <p className="ml-2">Distribution</p>
            </Link>

            <Link
                to="base/sales"
                className={`btn btn-ghost normal-case text-xs hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/base/sales'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                        : `flex justify-between items-center px-4 ${
                              location.pathname === '/base/sales'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                }`}
                onClick={setSidebarInactive}
            >
                <BanknotesIcon className="w-5 h-5" />
                <p className="ml-2">Sales</p>
            </Link>

            <Link
                className={`btn btn-ghost normal-case text-xs hover:text-primary ${
                    sidebarMode
                        ? `flex items-center justify-start py-3 w-full ${
                              location.pathname === '/base/alerts'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                        : `flex justify-between items-center px-4 ${
                              location.pathname === '/base/alerts'
                                  ? 'font-bold'
                                  : 'font-normal '
                          }`
                }`}
                to="base/alerts"
                onClick={setSidebarInactive}
            >
                <MegaphoneIcon className="w-5 h-5" />
                <p className="ml-2">Alerts</p>
            </Link>

            <LogoutMenu />
        </div>
    );
};

export default PRBaseNavigation;