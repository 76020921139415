import toast from 'react-hot-toast';
import {
    BellIcon,
    CheckIcon,
    DocumentArrowDownIcon,
    ExclamationTriangleIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import { glowing_border } from './styles/tailwind-styles';

export const NOTIFICATION_TYPES = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    PROMISE: 'promise',

    FILE: 'file',
};

export const createNotification = (
    message,
    type = NOTIFICATION_TYPES.INFO,
    duration = 8000,
    onClick = null,
    title = ''
) => {
    let borderColor = 'border-primary';
    if (type === NOTIFICATION_TYPES.SUCCESS)
        borderColor = 'border-success shadow-success';
    if (type === NOTIFICATION_TYPES.FILE)
        borderColor = 'border-primary shadow-primary';
    if (type === NOTIFICATION_TYPES.WARNING)
        borderColor = 'border-warning shadow-warning';
    if (type === NOTIFICATION_TYPES.ERROR)
        borderColor = 'border-error shadow-error';

    toast(
        (t) => {
            return (
                <span
                    className={`rounded-box w-full h-full flex items-center justify-between bg-base-300 bg-opacity-95 text-base-content p-4 cursor-pointer text-sm ${glowing_border} ${borderColor}`}
                    onClick={
                        onClick === null ? () => toast.dismiss(t.id) : onClick
                    }
                >
                    {mapNotificationTypeToIcon(type)}

                    <span className="px-4 py-2 w-full bg-base-300 bg-opacity-80">
                        {title !== '' && (
                            <span className="font-bold pr-2 text-opacity-80">
                                {title}
                            </span>
                        )}

                        {message}
                    </span>
                </span>
            );
        },
        {
            style: {
                borderRadius: '2px',

                padding: '0px 0px',
                margin: '0px 0px',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                boxShadow: '0 0 0 0',
                border: 'none',
            },
            duration: duration,
        }
    );
};

function mapNotificationTypeToIcon(notificationType) {
    switch (notificationType) {
        case NOTIFICATION_TYPES.SUCCESS:
            return (
                <span className="rounded-full p-1 border border-success">
                    <CheckIcon className="w-5 h-5 text-success" />
                </span>
            );
        case NOTIFICATION_TYPES.FILE:
            return (
                <span className="rounded-full p-1 border border-primary">
                    <DocumentArrowDownIcon className="w-5 h-5 text-primary" />
                </span>
            );
        case NOTIFICATION_TYPES.ERROR:
            return (
                <span className="rounded-full p-1 border border-error">
                    <XMarkIcon className="w-5 h-5 text-error" />
                </span>
            );

        case NOTIFICATION_TYPES.INFO:
            return (
                <span className="rounded-full p-1 border border-primary">
                    <BellIcon className="w-5 h-5 text-primary" />
                </span>
            );
        case NOTIFICATION_TYPES.WARNING:
            return (
                <span className="rounded-full p-1 border border-warning">
                    <ExclamationTriangleIcon className="w-5 h-5 text-warning" />
                </span>
            );
        default:
            return (
                <span className="rounded-full p-1 border border-primary">
                    <BellIcon className="w-5 h-5 text-primary" />
                </span>
            );
    }
}