import MiniLineChartComponent from '../../charts/mini-line/mini-line-chart.component';
import { glowing_border } from '../../../utils/styles/tailwind-styles';
import { objectService } from '../../../utils/object.util';

const ChaosTrend = ({ data, trend, className }) => {
    return (
        <div
            className={`p-4 rounded-box bg-base-300 bg-opacity-80 w-full shadow-inner ${className}`}
        >
            <span className="text-base-content text-opacity-80 text-lg font-bold">
                Performance Trend
            </span>

            {objectService.checkObjectPopulated(trend) ? (
                <div className="min-w-full">
                    <MiniLineChartComponent
                        data={trend}
                        className={'w-full max-h-32'}
                    />
                </div>
            ) : (
                <></>
            )}

            {objectService.checkObjectPopulated(trend) &&
            objectService.checkObjectPopulated(data) ? (
                <>
                    <div className="divider my-1" />

                    <div className="flex items-center gap-2 text-xs text-base-content text-opacity-80 col-span-3">
                        from
                        <span className="font-mono font-bold">
                            {data['fromDate']}
                        </span>
                        to
                        <span className="font-mono font-bold">
                            {data['toDate']}
                        </span>
                    </div>
                </>
            ) : (
                <>
                    <div className="divider divider-vertical my-1" />
                    <span className="text-xs text-base-content text-opacity-80">
                        No trend data available
                    </span>
                </>
            )}
        </div>
    );
};

export default ChaosTrend;
