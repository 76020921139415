import { useSelector } from 'react-redux';
import {
    selectUser,
    selectUserToken,
} from '../../../store/auth/authentication.selectors';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

import { useEffect, useRef, useState } from 'react';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { engineService } from '../../../utils/engine/engine.service';
import { apiService } from '../../../utils/engine/api.service';
import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';
import { selectProductType } from '../../../store/product/product.selectors';
import {
    generateTemporaryPassword,
    isCAdmin,
    isCOwner,
    isM2Admin,
    isM2Support,
    USER_ROLES,
} from '../../../utils/auth.util';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid';
import { PencilIcon, QueueListIcon } from '@heroicons/react/16/solid';

const UserEditComponent = ({ initialUser, onSuccess }) => {
    const ref = useRef();

    const user = useSelector(selectUser);
    const product = useSelector(selectProductType);

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState(initialUser.name);
    const [email, setEmail] = useState(initialUser.email);
    const [role, setRole] = useState(initialUser.role);

    const [roleOptions, setRoleOptions] = useState([]);

    useEffect(() => {
        if (isM2Admin(user)) setRoleOptions(Object.values(USER_ROLES));

        if (isM2Support(user))
            setRoleOptions(
                Object.values(USER_ROLES).filter(
                    (roleOption) => roleOption !== USER_ROLES.M2_ADMIN
                )
            );

        if (isCOwner(user) || isCAdmin(user)) {
            setRoleOptions(
                Object.values(USER_ROLES).filter(
                    (roleOption) =>
                        ![
                            USER_ROLES.M2_ADMIN,
                            USER_ROLES.M2_SUPPORT,
                            USER_ROLES.C_OWNER,
                        ].includes(roleOption)
                )
            );
        }
    }, [user]);

    const onUpdateUser = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        try {
            const response = await apiService.updateUser(name, email, role);

            if (response.status >= 200 && response.status < 400) {
                createNotification(
                    `[${email}] successfully updated.`,
                    NOTIFICATION_TYPES.SUCCESS
                );
            }

            setName('');
            setEmail('');
            setRole(USER_ROLES.USER);

            onSuccess({
                name,
                email,
                role,
            });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <label
                className={`btn btn-secondary btn-outline btn-xs flex justify-center items-center tooltip normal-case gap-1`}
                htmlFor={`edit-user-modal-${initialUser.email}`}
                data-tip={'Edit User'}
            >
                <PencilIcon className={'w-4 h-4'} />
            </label>

            <input
                type="checkbox"
                id={`edit-user-modal-${initialUser.email}`}
                ref={ref}
                className="modal-toggle"
            />

            <div className="modal">
                <div className="modal-box relative py-3">
                    {isLoading ? (
                        <span className="w-full flex justify-center items-center min-h-16 py-28">
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.TAILCHASE}
                            />
                        </span>
                    ) : (
                        <>
                            <span className="w-full flex justify-between items-center gap-2">
                                <h3 className="text-lg font-bold">Edit User</h3>
                                <span className="badge badge-lg badge-primary text-center justify-center font-bold">
                                    {user?.custaccountName}
                                </span>
                            </span>

                            <div className="divider my-1" />

                            <form
                                className="w-full h-full m-0 px-2"
                                autoComplete={'off'}
                            >
                                <div className="w-full h-full grid grid-cols-2 place-items-center gap-2">
                                    <div className="col-span-2 w-full flex flex-col">
                                        <label
                                            className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                            htmlFor="name"
                                        >
                                            Name
                                        </label>

                                        <input
                                            className="input input-bordered input-sm w-full"
                                            name="name"
                                            type="text"
                                            value={name}
                                            onChange={(event) =>
                                                setName(
                                                    event.currentTarget.value
                                                )
                                            }
                                            required={true}
                                            autoComplete={'false'}
                                        />
                                    </div>

                                    <div className="col-span-2 w-full mb-2 flex flex-col">
                                        <label
                                            className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                            htmlFor="email"
                                        >
                                            Email
                                        </label>
                                        <input
                                            className="input input-bordered input-sm w-full input-disabled disabled"
                                            disabled={true}
                                            name="user-email"
                                            id="user-email"
                                            type="email"
                                            value={email}
                                            onChange={
                                                (event) => {}
                                                // setEmail(
                                                //     event.currentTarget.value
                                                // )
                                            }
                                            required={true}
                                            autoComplete={'new-email'}
                                        />
                                    </div>
                                </div>

                                <div className="w-full h-full grid grid-cols-2 place-items-center gap-2">
                                    <div className="col-span-2 md:col-span-1 w-full">
                                        <span className="w-full h-full flex justify-between items-center">
                                            <label
                                                className="text-sm text-start p-2 text-base-content font-bold text-opacity-70"
                                                htmlFor="role"
                                            >
                                                Role
                                            </label>
                                        </span>

                                        <select
                                            onChange={(e) =>
                                                setRole(e.target.value)
                                            }
                                            value={role}
                                            id={'role'}
                                            className="select select-bordered select-sm min-w-full"
                                        >
                                            {roleOptions.map((roleOption) => {
                                                return (
                                                    <option key={roleOption}>
                                                        {roleOption}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="divider" />

                                <div className="w-full flex justify-end gap-2 items-center mt-5">
                                    <label
                                        className="btn btn-outline btn-sm normal-case"
                                        htmlFor={`edit-user-modal-${initialUser.email}`}
                                    >
                                        Close
                                    </label>

                                    <label
                                        className={`btn btn-primary btn-outline btn-sm normal-case`}
                                        onClick={onUpdateUser}
                                        htmlFor={`edit-user-modal-${initialUser.email}`}
                                    >
                                        Update
                                    </label>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default UserEditComponent;