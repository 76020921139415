import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';

import { selectUserToken } from '../../../../store/auth/authentication.selectors';

import { apiService } from '../../../../utils/engine/api.service';

import ScheduledProcessComponent from '../../../../components/base/shared-files/scheduled-process.component';
import { formatBytes } from '../../../../components/base/shared-files/shared-file.component';
import PageSelector from '../../../../components/page-selector/page-selector.component';
import {
    base_grid,
    base_page,
    base_subnavigation_bar,
    base_widget_full,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';
import LoadingComponent from '../../../../components/loading-component/loading-component';
import { formatDate } from '../../../../utils/auth.util';
import { OPS_HOSTNAME } from '../../../../utils/engine/socket-config.service';

const LIMIT = 10;

const ManageReportsPage = () => {
    const userToken = useSelector(selectUserToken);

    const [isLoading, setIsLoading] = useState(false);

    const [scheduledReports, setScheduledReports] = useState({});
    const [selectedScheduledPage, setSelectedScheduledPage] = useState(0);

    const [sharedFiles, setSharedFiles] = useState([]);
    const [sharedFilesCount, setSharedFilesCount] = useState(0);
    const [selectedFilesPage, setSelectedFilesPage] = useState(0);

    const fetchScheduledReports = async () => {
        try {
            setIsLoading(true);
            const scheduledReportsObject =
                await apiService.fetchScheduledProcesses();

            setScheduledReports(scheduledReportsObject);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchSharedFiles = async () => {
        try {
            setIsLoading(true);
            const sharedFilesObject = await apiService.fetchSharedFiles(
                LIMIT,
                selectedFilesPage
            );
            setSharedFiles(sharedFilesObject);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const countSharedFiles = async () => {
        try {
            setIsLoading(true);
            const count = await apiService.countSharedFiles();
            setSharedFilesCount(count);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const removeProcessFlow = async (control) => {
        if (
            window.confirm(
                'Are you sure you want to delete this scheduled report?'
            )
        ) {
            setIsLoading(true);
            try {
                await apiService.removeProcessFlowSchedule(control);
                createNotification(
                    'Removed scheduled report',
                    NOTIFICATION_TYPES.SUCCESS
                );

                await fetchScheduledReports();
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        }
    };

    const onFileClick = async (sharedFile) => {
        let url =
            OPS_HOSTNAME +
            `/api/prweb/shared-files/${sharedFile['uuidStr']}/download?token=${userToken}`;
        await window.open(url).then((response) => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            if (response.redirected === true) {
                return Promise.reject('Failed to download file.');
            } else {
                return response.json();
            }
        });
    };

    useEffect(() => {
        countSharedFiles();
    }, []);

    useEffect(() => {
        fetchSharedFiles();
    }, [selectedFilesPage]);

    useEffect(() => {
        fetchScheduledReports();
    }, [selectedScheduledPage]);

    useEffect(() => {}, [scheduledReports, sharedFiles]);

    return (
        <>
            <div className={base_subnavigation_bar}>
                <div className="flex w-full h-full justify-between items-center max-w-8xl">
                    <span className={'flex items-center gap-1'}>
                        <a
                            className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                            href={'/base/manage-reports'}
                        >
                            Manage Reports
                        </a>
                    </span>
                </div>
            </div>

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <div className={`${base_widget_full} ${muted_border}`}>
                        <div className="w-full h-full p-8">
                            <h2
                                className={
                                    'text-base text-primary font-bold p-2'
                                }
                            >
                                Shared Files History
                            </h2>
                            <div className="divider my-2" />

                            {isLoading ? (
                                <LoadingComponent />
                            ) : (
                                <>
                                    <table
                                        className={`table table-compact table-zebra w-full rounded-box text-sm tracking-normal`}
                                    >
                                        <thead>
                                            <tr>
                                                <th className="normal-case tracking-normal font-normal text-base-content text-opacity-80 text-sm ">
                                                    Date Generated
                                                </th>
                                                <th className="normal-case tracking-normal font-normal text-base-content text-opacity-80 text-sm ">
                                                    Shared By
                                                </th>
                                                <th className="normal-case tracking-normal font-normal text-base-content text-opacity-80 text-sm ">
                                                    File Size
                                                </th>
                                                <th className="normal-case tracking-normal font-normal"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sharedFiles &&
                                                sharedFiles.map(
                                                    (sharedFile) => (
                                                        <tr
                                                            key={
                                                                sharedFile.uuid
                                                            }
                                                        >
                                                            <td>
                                                                <div
                                                                    className={
                                                                        'font-mono'
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`badge badge-outline flex flex-wrap h-fit justify-start text-start items-center break-all gap-2 max-w-2xs w-full text-xs overflow-x-auto px-2 py-4 shadow z-10 badge-accent tooltip`}
                                                                        data-tip={`Last Logged In`}
                                                                    >
                                                                        <p className="absolute bottom-0 right-2 text-2xs capitalize text-base-content text-opacity-60">
                                                                            Date
                                                                            Generated
                                                                        </p>
                                                                        <div className="font-bold monospace font-mono">
                                                                            {sharedFile[
                                                                                'dateCreated'
                                                                            ] !==
                                                                            null
                                                                                ? formatDate(
                                                                                      sharedFile[
                                                                                          'dateCreated'
                                                                                      ]
                                                                                  )
                                                                                : '-'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        sharedFile.sharedBy
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="font-mono">
                                                                    {formatBytes(
                                                                        sharedFile[
                                                                            'fileSize'
                                                                        ]
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className={
                                                                        'btn btn-primary btn-ghost text-primary underline tooltip tooltip-left flex w-full justify-start items-center'
                                                                    }
                                                                    data-tip={
                                                                        'Download File'
                                                                    }
                                                                    onClick={() =>
                                                                        onFileClick(
                                                                            sharedFile
                                                                        )
                                                                    }
                                                                >
                                                                    <span
                                                                        className={
                                                                            'flex w-full justify-start'
                                                                        }
                                                                    >
                                                                        {
                                                                            sharedFile[
                                                                                'fileName'
                                                                            ]
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </table>

                                    {sharedFiles &&
                                        sharedFiles.length === 0 && (
                                            <div className="flex w-full flex-1 justify-center m-5">
                                                <span
                                                    className={
                                                        ' text-base-content text-opacity-60 text-sm '
                                                    }
                                                >
                                                    No results found.
                                                </span>
                                            </div>
                                        )}

                                    <div className="w-full flex justify-between items-center px-2 mt-2 text-sm col-span-full">
                                        <span className="flex justify-start items-center w-32">
                                            <strong className="mr-3">
                                                Page Size:
                                            </strong>
                                            <p>{sharedFiles.length}</p>
                                        </span>

                                        <PageSelector
                                            pageSize={LIMIT}
                                            totalResults={sharedFilesCount}
                                            selectedIndex={selectedFilesPage}
                                            onPageChange={(page) =>
                                                setSelectedFilesPage(page)
                                            }
                                        />

                                        <span className="flex justify-end items-center w-32">
                                            <strong className="mr-3">
                                                Total:
                                            </strong>
                                            <p>{sharedFilesCount}</p>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={`${base_widget_full} ${muted_border}`}>
                        <div className="w-full h-full p-8">
                            <h2
                                className={
                                    'text-base text-primary font-bold p-2'
                                }
                            >
                                Scheduled Reports
                            </h2>
                            <div className="divider my-2" />
                            {!isLoading &&
                                scheduledReports &&
                                Object.keys(scheduledReports).length > 0 &&
                                Object.keys(scheduledReports).includes(
                                    'content'
                                ) &&
                                scheduledReports.content.map(
                                    (processFlowScheduleView, key) => {
                                        return (
                                            <ScheduledProcessComponent
                                                className="my-2"
                                                key={key}
                                                processFlowScheduleView={
                                                    processFlowScheduleView
                                                }
                                                onDelete={removeProcessFlow}
                                            />
                                        );
                                    }
                                )}

                            {!isLoading &&
                            scheduledReports &&
                            Object.keys(scheduledReports).length > 0 &&
                            Object.keys(scheduledReports).includes('content') &&
                            scheduledReports.content.length === 0 ? (
                                <div
                                    className={`w-full flex justify-center items-center py-3 font-light`}
                                >
                                    <p className="text-sm">
                                        No scheduled reports found so far.
                                        Schedule a report using the{' '}
                                        <strong>Generate Report</strong>{' '}
                                        component on any of the major tabs.
                                    </p>
                                </div>
                            ) : (
                                isLoading && <LoadingComponent />
                            )}

                            {scheduledReports &&
                                Object.keys(scheduledReports).length > 0 &&
                                Object.keys(scheduledReports).includes(
                                    'content'
                                ) && (
                                    <div className="w-full flex justify-between items-center px-2 mt-2 text-sm col-span-full">
                                        <span className="flex justify-start items-center w-32">
                                            <strong className="mr-3">
                                                Page Size:
                                            </strong>
                                            <p>
                                                {
                                                    scheduledReports.content
                                                        .length
                                                }
                                            </p>
                                        </span>

                                        <PageSelector
                                            pageSize={LIMIT}
                                            totalResults={
                                                scheduledReports.numberOfElements
                                            }
                                            selectedIndex={
                                                selectedScheduledPage
                                            }
                                            onPageChange={(page) =>
                                                setSelectedScheduledPage(page)
                                            }
                                        />

                                        <span className="flex justify-end items-center w-32">
                                            <strong className="mr-3">
                                                Total:
                                            </strong>
                                            <p>
                                                {scheduledReports.totalElements}
                                            </p>
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageReportsPage;
