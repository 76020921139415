import { useSelector } from 'react-redux';
import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../store/themes/themes.selectors';

import { M2NORTH_DETAILS } from './footer-details';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';
import { DAISY_THEMES } from '../../store/themes/themes.types';
import { checkLightTheme } from '../../utils/styles/global-styles';

const date = new Date();
const FooterComponent = () => {
    const daisyTheme = useSelector(selectDaisyTheme);

    return (
        <div className="flex flex-col justify-center items-center w-full min-h-72 bg-base-300 py-8 font-light shadow-inner">
            <div className="text-sm flex flex-col items-center justify-center w-full px-1 gap-4">
                <a
                    className="w-40 rounded px-4 py-2 bg-base-300 shadow-inner"
                    href={'https://www.m2north.com/profitrocket'}
                    target={'_blank'}
                >
                    <img
                        src={`${
                            !checkLightTheme(daisyTheme)
                                ? '/images/profitrocket-logo-white.png'
                                : '/images/profitrocket-logo-black.png'
                        } `}
                        alt="image"
                        className={'w-full h-full'}
                    />
                </a>

                <span className="text-xs font-bold text-opacity-80">
                    powered by
                </span>

                <a
                    className={`${
                        !checkLightTheme(daisyTheme)
                            ? 'w-40 p-4 bg-base-200 rounded shadow-inner'
                            : 'w-40 p-4 bg-base-content bg-opacity-80 rounded shadow'
                    }`}
                    href={'https://www.m2north.com/'}
                    target={'_blank'}
                >
                    <img
                        src="/images/m2north.png"
                        alt="image"
                        className={'w-full'}
                    />
                </a>
            </div>

            <div className="w-full flex justify-center items-center">
                <div className="divider my-4 w-8/12 max-w-3xl" />
            </div>

            {M2NORTH_DETAILS['addressList'].map((address) => {
                return (
                    <div
                        key={address}
                        className="text-sm flex items-center justify-center w-full text-center px-4"
                    >
                        {address}
                    </div>
                );
            })}

            <div className="w-full flex justify-center items-center">
                <div className="divider my-4 w-4/12 max-w-xl" />
            </div>

            <div className="text-sm flex md:flex-row flex-col px-4 items-center justify-center w-full text-center">
                <strong className="font-bold mr-2">
                    COPYRIGHT © {date.getFullYear()}
                </strong>{' '}
                IRJ.io limited. All rights reserved.
            </div>
        </div>
    );
};

export default FooterComponent;
