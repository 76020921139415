import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectDiscoverSearch } from '../../../../store/discover/search/discover-search.selectors';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import DiscoverSubNavigation from '../../../../components/discover/discover-sub-navigation/discover-sub-navigation.component';
import {
    discover_grid,
    discover_page,
    muted_border,
    widget_full,
    widget_half,
} from '../../../../utils/styles/tailwind-styles';

import Widget from '../../../../components/discover/widget/widget.component';

import Admin from '../../../../components/discover/admin/admin.component';
import { REACT_TOUR_CLASS_NAMES } from '../../../../utils/react-tour.util';
import {
    setDashboardPageWidgets,
    setSkuPageWidgets,
} from '../../../../store/discover/widgets/widgets.actions';
import { selectDiscoverSkuPageWidgets } from '../../../../store/discover/widgets/widgets.selectors';
import AddWidget from '../../../../components/discover/widget/add-widget.component';
import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
} from '../../../../store/discover/widgets/widgets.types';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';
import NotFound from '../../../../components/not-found/not-found.component';
import { widgetService } from '../../../../store/discover/widgets/widgets.services';
import { objectService } from '../../../../utils/object.util';

const ProductPage = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const discoverSearchMaster = useSelector(selectDiscoverSearch);
    const productPageWidgets = useSelector(selectDiscoverSkuPageWidgets);
    const daisyTheme = useSelector(selectDaisyTheme);

    const [refresher, setRefresher] = useState(0);

    const [productInfo, setProductInfo] = useState({});
    const [perspective, setPerspective] = useState({});

    const [selectedDate, setSelectedDate] = useState(
        localStorage.getItem('selectedDate') ?? 'MTD'
    );

    const handleDateChange = (date) => {
        localStorage.setItem('selectedDate', date);
        setSelectedDate(date);
    };

    const handleAddWidget = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric,
        size
    ) => {
        const widgetData = {
            metric,
            visualization,
            sortDirection:
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND
                    ? null
                    : sortDirection,
            aggregationMetric,
            size,
        };

        const widgetId = widgetService.createWidgetRuleset(
            widgetData.metric,
            widgetData.visualization,
            widgetData.sortDirection,
            perspective,
            widgetData.aggregationMetric
        );

        let validWidget = true;

        if (Object.keys(productPageWidgets).includes(widgetId)) {
            createNotification(
                'A widget exists on this page which matches those parameters.',
                NOTIFICATION_TYPES.ERROR
            );
            document
                .getElementById(widgetId)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });

            validWidget = false;
        }

        if (validWidget) {
            let newProductPageWidgets = {
                ...productPageWidgets,
            };
            newProductPageWidgets[widgetId] = widgetData;
            dispatch(setSkuPageWidgets(newProductPageWidgets));
        }
    };

    const handleRemoveWidget = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric
    ) => {
        const widgetId = widgetService.createWidgetRuleset(
            metric,
            visualization,
            sortDirection,
            perspective,
            aggregationMetric
        );
        let newProductPageWidgets = { ...productPageWidgets };
        if (Object.keys(productPageWidgets).includes(widgetId)) {
            delete newProductPageWidgets[widgetId];
            createNotification('Widget removed', NOTIFICATION_TYPES.SUCCESS);
        }

        dispatch(setSkuPageWidgets(newProductPageWidgets));
    };

    const handleMoveWidgetUp = (
        metric,
        visualization,
        sortDirection,
        aggregationMetric
    ) => {
        const widgetId = widgetService.createWidgetRuleset(
            metric,
            visualization,
            sortDirection,
            perspective,
            aggregationMetric
        );

        let newProductPageWidgets = objectService.moveObjectItemUp(
            widgetId,
            productPageWidgets
        );

        if (
            !objectService.checkObjectKeysEqual(
                newProductPageWidgets,
                productPageWidgets
            )
        ) {
            dispatch(setSkuPageWidgets(newProductPageWidgets));
        }
    };

    useEffect(() => {
        if (discoverSearchMaster['sku'][params.id]) {
            setProductInfo(discoverSearchMaster['sku'][params.id]);
        }
    }, [discoverSearchMaster, params.id]);

    useEffect(() => {
        if (Object.keys(productInfo).includes('code')) {
            setPerspective({
                code: productInfo['code'],
                descr: productInfo['descr'],
                type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
            });
        }
    }, [productInfo]);

    useEffect(() => {
        // dispatch(clearWidgets());
    }, [productPageWidgets]);

    return (
        <div
            className={`${discover_page} ${REACT_TOUR_CLASS_NAMES.DISCOVER_PAGE}`}
        >
            <div className={`${discover_grid}`}>
                <DiscoverSubNavigation
                    title="Product View"
                    className={`col-span-2 row-span-max sticky ${muted_border}`}
                    onDateChange={handleDateChange}
                    selectedDate={selectedDate}
                    widgets={productPageWidgets}
                />

                {objectService.checkObjectPopulated(productInfo) ? (
                    objectService.checkObjectPopulated(perspective) && (
                        <>
                            <Admin
                                type={DISCOVER_WIDGET_AGGREGATION_METRICS.SKU}
                                data={productInfo}
                                className={widget_full}
                                handleRefresh={() =>
                                    setRefresher(refresher + 1)
                                }
                            />

                            {objectService.checkObjectPopulated(
                                productPageWidgets
                            ) &&
                                Object.keys(productPageWidgets).map(
                                    (widgetId, index) => {
                                        const widget =
                                            productPageWidgets[widgetId];

                                        return (
                                            <Widget
                                                id={widgetService.createWidgetRuleset(
                                                    widget.metric,
                                                    widget.visualization,
                                                    widget.sortDirection,
                                                    perspective,
                                                    widget.aggregationMetric
                                                )}
                                                key={widgetService.createWidgetRuleset(
                                                    widget.metric,
                                                    widget.visualization,
                                                    widget.sortDirection,
                                                    perspective,
                                                    widget.aggregationMetric
                                                )}
                                                perspective={perspective}
                                                metric={widget.metric}
                                                visualization={
                                                    widget.visualization
                                                }
                                                sortDirection={
                                                    widget.sortDirection
                                                }
                                                aggregationMetric={
                                                    widget.aggregationMetric
                                                }
                                                selectedDate={selectedDate}
                                                onDateChange={handleDateChange}
                                                size={widget.size}
                                                className={`${
                                                    widget.size === 'full'
                                                        ? widget_full
                                                        : widget_half
                                                } ${
                                                    index === 0 &&
                                                    REACT_TOUR_CLASS_NAMES.WIDGET_1
                                                }`}
                                                onRemoveWidget={
                                                    handleRemoveWidget
                                                }
                                                onMoveUp={handleMoveWidgetUp}
                                                daisyTheme={daisyTheme}
                                                refresher={refresher}
                                            />
                                        );
                                    }
                                )}
                        </>
                    )
                ) : (
                    <NotFound type={'Product'} descr={params.id} />
                )}

                <AddWidget
                    perspective={perspective}
                    onAddWidget={handleAddWidget}
                />
            </div>
        </div>
    );
};

export default ProductPage;