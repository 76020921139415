// Third Party
import FullStory from 'react-fullstory';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { selectUser } from '../store/auth/authentication.selectors';
import { PRODUCT_TYPES } from '../store/product/product.types';
import {
    OPS_HOSTNAME,
    OPS_WS,
    TOPICS,
} from '../utils/engine/socket-config.service';
import { ORG_ID } from '../utils/engine/fullstory-config.service';

// Utilities
import { ProtectedRoute } from '../utils/protected-route';
import { ProductProtectedRoute } from '../utils/product-protected-route';

// Components
import NavigationBar from '../components/navigation-bar-component/navigation-bar.component';
import LoginPage from '../pages/public/login-page/login.page';
import ProductSelectPage from '../pages/authenticated/product-select-page/product-select.page';
import PageNotFound from '../pages/public/errors/page-not-found';
import UrlRequest from '../pages/public/urlrequest/urlrequest.page';

// Pages
import BasePage from '../pages/authenticated/base/base-page';
import DiscoverPage from '../pages/authenticated/discover/discover-page';
import SubscribePage from '../pages/authenticated/subscribe-page/subscribe.page';
import PasswordResetPage from '../pages/public/password-reset/password-reset.page';
import PasswordUpdatePage from '../pages/authenticated/password-update/password-update.page';
import FileShare from '../pages/public/fileshare/fileshare.page';

import { selectDaisyTheme } from '../store/themes/themes.selectors';
import { useEffect } from 'react';
import { DAISY_THEMES, THEME_MODES } from '../store/themes/themes.types';
import { setDaisyTheme, setThemeMode } from '../store/themes/themes.actions';
import { StompSessionProvider } from 'react-stomp-hooks';
import { SocketSubscriptionComponent } from '../components/socket-subscription.component';

// Styles
import './app.css';
import SubscribeOutcomePage from '../pages/authenticated/subscribe-page/subscribe-outcome.page';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../utils/notification.util';
import ToasterComponent from '../components/toaster.component';
import PasswordResetRequestPage from '../pages/public/password-reset/password-reset-request.page';

function App() {
    const daisyTheme = useSelector(selectDaisyTheme);
    const dispatch = useDispatch();

    const user = useSelector(selectUser);

    useEffect(() => {
        if (!daisyTheme) {
            dispatch(setDaisyTheme(DAISY_THEMES.CORPORATE));
            dispatch(setThemeMode(THEME_MODES.LIGHT));
        }
    }, []);

    return (
        <div data-theme={daisyTheme}>
            <FullStory org={ORG_ID} />

            {user && user.token && (
                <StompSessionProvider url={`${OPS_WS}?token=${user.token}`}>
                    <SocketSubscriptionComponent
                        callback={(message) => {
                            let jsonMessage = JSON.parse(message);

                            createNotification(
                                `${jsonMessage.payLoad.message}`,
                                jsonMessage.payLoad.title ===
                                    'Targets Import Started'
                                    ? NOTIFICATION_TYPES.SUCCESS
                                    : NOTIFICATION_TYPES.FILE,
                                10000,
                                () => {
                                    if (jsonMessage.payLoad.fileShareId) {
                                        let url =
                                            OPS_HOSTNAME +
                                            `/api/prweb/shared-files/${jsonMessage.payLoad.fileShareId}/download?token=${user.token}`;

                                        window.open(url);
                                    }
                                },
                                `${jsonMessage.payLoad.title}`
                            );
                        }}
                        path={'user/topic'}
                        topic={TOPICS.TOAST}
                    />
                </StompSessionProvider>
            )}

            <ToasterComponent position="top-left" />

            <Routes>
                <Route exact={true} path="/" element={<NavigationBar />}>
                    <Route index={true} element={<LoginPage />} />

                    <Route path="login" element={<LoginPage />} />

                    <Route
                        path="password-reset-request"
                        element={<PasswordResetRequestPage />}
                    />

                    <Route
                        path="password-reset"
                        element={<PasswordResetPage />}
                    />

                    <Route path="urlrequest" element={<UrlRequest />} />

                    <Route path="fileshare" element={<FileShare />} />

                    <Route
                        path="password-update"
                        element={
                            <ProtectedRoute>
                                <PasswordUpdatePage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="product-select"
                        element={
                            <ProtectedRoute>
                                <ProductSelectPage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="subscribe"
                        element={
                            <ProtectedRoute>
                                <SubscribePage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="subscribe/return"
                        element={
                            <ProtectedRoute>
                                <SubscribeOutcomePage />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="base/*"
                        element={
                            <ProtectedRoute>
                                <ProductProtectedRoute
                                    product={PRODUCT_TYPES.PROFIT_ROCKET_BASE}
                                >
                                    <BasePage />
                                </ProductProtectedRoute>
                            </ProtectedRoute>
                        }
                    />

                    {/*Profit Rocket Discover*/}
                    <Route
                        path="discover/*"
                        element={
                            <ProtectedRoute>
                                <ProductProtectedRoute
                                    product={
                                        PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER
                                    }
                                >
                                    <DiscoverPage />
                                </ProductProtectedRoute>
                            </ProtectedRoute>
                        }
                    />

                    <Route path="*" exact={true} element={<PageNotFound />} />
                </Route>
            </Routes>
        </div>
    );
}

export default App;