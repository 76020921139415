import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_METRICS,
} from '../../../store/discover/widgets/widgets.types';
import { badge_overflow } from '../../../utils/styles/tailwind-styles';

const selectBorderColourByElement = (element) => {
    switch (element.toLowerCase()) {
        case 'overall':
            return 'bg-primary text-primary-content border-primary font-bold';
        case DISCOVER_WIDGET_DATA_METRICS.SALES:
            return 'border-accent text-base-content';
        case DISCOVER_WIDGET_DATA_METRICS.RETURNS:
            return 'border-secondary text-base-content';

        case DISCOVER_WIDGET_DATA_METRICS.PROFIT:
            return `border-[#10b981] text-base-content`;

        case DISCOVER_WIDGET_DATA_METRICS.DISCOUNT:
            return `border-[#eba80b] text-base-content`;

        default:
            return `border-base-100 text-base-content`;
    }
};

const ChaosElement = ({ element, value, ranking, overall = false }) => {
    return (
        <span className="flex items-center justify-start gap-2 text-sm text-base-content text-opacity-80">
            <p
                className={`${
                    overall ? 'font-bold' : 'text-opacity-70'
                } w-24 capitalize`}
            >
                {element === DISCOVER_WIDGET_DATA_METRICS.RETURNS
                    ? 'non returns'
                    : element}
            </p>
            <span
                className={`flex items-center justify-center font-mono font-bold w-12 ${badge_overflow} border rounded-box ${selectBorderColourByElement(
                    element
                )} ${overall && ''}`}
            >
                {value}
            </span>
        </span>
    );
};

export default ChaosElement;
