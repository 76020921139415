import { XMarkIcon } from '@heroicons/react/24/solid';

function mapKeyToLabel(key) {
    const map = {
        distSku: null,
        sohDate: 'Last Updated',
        supplSku: null,
        supplSkuDescr: null,
        soh: 'Stock on Hand',
        '6weekAvg': '6 Week Average',
        '12weekAvg': '12 Week Average',
        daysCover: 'Days Cover',
        daysCoverColor: null,
        daysSinceSale: 'Days Since Sale',
        daysSinceSaleColor: null,
        distSkuDescr: 'Retailer Description',
        distBarcode: 'Barcode',
        onOrderQty: 'On Order Quantity',
        onRange: 'On Range',
        lastSoldDate: 'Last Sold Date',
        lastOrderedDate: 'Last Ordered Date',
        lastReceivedDate: 'Last Received Date',
    };
    return map[key];
}

function supplierMapKeyToLabel(key) {
    const map = {
        supplSku: 'Supplier Sku Code',
        supplSkuDescr: 'Supplier Description',
        soh: 'Stock on Hand',
        rangedPerc: 'Ranged Percentage',
        sellingPerc: 'Selling Percentage',
        stockedPerc: 'Stocked Percentage',
        avgStock: 'Average Stock',
    };
    return map[key];
}

function distMapKeyToLabel(key) {
    const map = {
        supplSkuDescr: 'Supplier Description',
        category: 'Category',
        quadrant: 'Quadrant',
        strategy: 'Strategy',
        ros: 'Rate of Sale',
        avgDistribution: 'Average Distribution',
    };
    return map[key];
}

const SkuDetailsComponent = ({
    title,
    skuDetails,
    className,
    onClear,
    isSupplierDetails,
    isDistPage,
}) => {
    const mappingFunction = isSupplierDetails
        ? supplierMapKeyToLabel
        : isDistPage
        ? distMapKeyToLabel
        : mapKeyToLabel;

    return (
        <div
            className={`bg-base-300 shadow w-full rounded-box p-4 grid grid-cols-6 justify-start items-center text-end ${className} `}
        >
            <div className="col-span-6 flex justify-between items-center px-2 font-bold">
                <h3 className="text-base capitalize rounded-box text-primary">
                    {title}
                </h3>

                <button
                    className="btn btn-ghost btn-circle btn-sm"
                    onClick={onClear}
                >
                    <XMarkIcon className="w-5 h-5" />
                </button>
            </div>

            <div className="divider col-span-6 my-2" />

            {Object.keys(skuDetails).map((key, index) => {
                if (mappingFunction(key) && key !== 'supplSkuDescr') {
                    return (
                        <div
                            className={`flex justify-between w-full items-center py-2 px-4 text-sm ${
                                isSupplierDetails
                                    ? 'desktop:laptop:col-span-3 tablet:col-span-3 col-span-6'
                                    : 'col-span-6'
                            }`}
                            key={key}
                        >
                            <span className="mr-6 text-start font-bold tracking-wider">
                                {mappingFunction(key)}
                            </span>

                            <span className="font-light">
                                {skuDetails[key] ? skuDetails[key] : '-'}
                            </span>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default SkuDetailsComponent;