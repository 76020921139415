import React from 'react';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

import { selectDaisyTheme } from '../../../store/themes/themes.selectors';

import {
    addOpacity,
    DAISY_THEME_MAP,
} from '../../../utils/styles/global-styles';
import {
    GRAPH_FONT_FAMILY,
    GRAPH_FONT_SIZE,
} from '../mini-bar/mini-bar-chart.component';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const radarChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 30,
                pointStyle: 'circle',
                usePointStyle: true,
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
            display: false,
        },
        title: {
            display: false,
        },
    },
    interaction: {
        intersect: false,
        axis: 'xy',
        mode: 'nearest',
    },
    scales: {
        r: {
            beginAtZero: true,
            max: 100,
            // max: 10,
        },
    },
};

const MiniRadarChartComponent = ({ data, className }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    let options = {
        ...radarChartOptions,
        plugins: {
            ...radarChartOptions['plugins'],
            legend: {
                ...radarChartOptions['plugins']['legend'],
                labels: {
                    ...radarChartOptions['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
        scales: {
            ...radarChartOptions['scales'],
            r: {
                ...radarChartOptions['scales']['r'],
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                    backdropColor: DAISY_THEME_MAP[daisyTheme]['base-300'],
                    display: false,
                },
                grid: {
                    ...radarChartOptions['scales']['grid'],
                    color: addOpacity(
                        DAISY_THEME_MAP[daisyTheme]['base-content'],
                        0.2
                    ),
                },
                pointLabels: {
                    font: {
                        size: GRAPH_FONT_SIZE + 1,
                        family: GRAPH_FONT_FAMILY,
                        weight: 'bold',
                    },
                    color: addOpacity(
                        DAISY_THEME_MAP[daisyTheme]['base-content'],
                        0.7
                    ),
                    display: true,
                },
            },
        },
    };

    return (
        <Radar
            className={`h-full w-full ${className}`}
            data={data}
            options={options}
        />
    );
};

export default MiniRadarChartComponent;
