import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { formula_box } from '../../../utils/styles/tailwind-styles';

const SalesHelp = () => {
    return (
        <>
            <div className="flex flex-col gap-2  text-sm">
                <span className="badge badge-lg badge-primary">Trend</span>
                <p className="px-2">
                    The <strong>Sales Trend</strong> widget provides a trending
                    view of net sales for current period{' '}
                    <span className="badge-md badge-primary rounded-full mx-2 bg-opacity-60" />{' '}
                    and the sales target for the current period{' '}
                    <span className="badge-md badge-secondary rounded-full mx-2 bg-opacity-60" />
                </p>

                <span className={`${formula_box}`}>
                    {'\\(sales = \\sum_{n=1}^{x}{invoices_n - returns_n}\\)'}
                </span>

                <p className="px-2">
                    where <strong>n</strong> represents the current time
                    interval and <strong>x</strong> represents the number of
                    time intervals within the current period. <br />
                    <br />
                    The <strong>dashboard page</strong> will show total sales
                    across the business. <br />
                    Pages within a <strong>perspective</strong> (i.e. Customer,
                    Rep or Product) will only pull sales data from that given
                    perspective.
                </p>

                <span className="badge badge-lg badge-accent mt-2">
                    Ranking
                </span>
                <p className="px-2">
                    The <strong>Sales Ranking</strong> widget provides a ranked
                    view of sales for the current period in terms of absolute
                    sales value
                    <span className="badge-md badge-primary mx-2 bg-opacity-60" />{' '}
                    and a % contribution of the given aggregation metric to the
                    total sales within that perspective
                    <span className="badge-md badge-secondary mx-2 bg-opacity-60" />
                    .
                </p>

                <span className={formula_box}>
                    {'\\(sales_k = \\sum{invoices_k - returns_k}\\)'}
                </span>

                <p className="px-2">
                    where <strong>k</strong> represents an item of{' '}
                    <strong>N</strong> total items.
                    <br />
                </p>

                <span className={formula_box}>
                    {
                        '\\(contribution_x = \\frac{sales_x}{\\sum_k^N{invoices_k - returns_k}}\\)'
                    }
                </span>
            </div>
        </>
    );
};

export default SalesHelp;