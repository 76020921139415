import {
    DAISY_THEMES,
    THEME_COLORS,
    THEME_MODES,
    THEMES_ACTION_TYPES,
} from './themes.types';

const INITIAL_STATE = {
    color: THEME_COLORS.indigo,
    mode: THEME_MODES.DARK,

    daisy: DAISY_THEMES.BUSINESS,
};

export const themesReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case THEMES_ACTION_TYPES.SET_THEME_COLOR:
            return { ...state, color: payload };
        case THEMES_ACTION_TYPES.SET_THEME_MODE:
            return { ...state, mode: payload };

        case THEMES_ACTION_TYPES.SET_DAISY_THEME:
            return { ...state, daisy: payload };

        default:
            return state;
    }
};
