import LoadingComponent from './loading-component';

export const PageLoadingComponent = () => {
    return (
        <div className="absolute top-0 left-0 z-50 min-h-screen bg-base-100 bg-opacity-95 w-full flex justify-center items-center">
            <LoadingComponent />
        </div>
    );
};

export default PageLoadingComponent;
