export const selectDiscoverDashboardPageWidgets = (state) =>
    state.discoverWidgets.dashboard;

export const selectDiscoverSkuPageWidgets = (state) =>
    state.discoverWidgets.sku;

export const selectDiscoverRepPageWidgets = (state) =>
    state.discoverWidgets.rep;

export const selectDiscoverCustomerPageWidgets = (state) =>
    state.discoverWidgets.customer;

export const selectDiscoverCategoryPageWidgets = (state) =>
    state.discoverWidgets.category;

export const selectDiscoverWatchlistPageWidgets = (state) =>
    state.discoverWidgets.watchlist;

export const selectDiscoverHiddenWidgets = (state) =>
    state.discoverWidgets.hidden;