import { createNotification, NOTIFICATION_TYPES } from './notification.util';

export const navigationService = {
    buildUrlByAggMetric,
    copyToClipboard,
};

function buildUrlByAggMetric(aggregationMetric, unit) {
    return `/discover/${
        aggregationMetric.endsWith('ies')
            ? aggregationMetric.slice(0, -3) + 'y'
            : aggregationMetric.slice(0, -1)
    }/${encodeURIComponent(unit.code)}`;
}

function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
        function () {
            createNotification(
                `Copied to clipboard`,
                NOTIFICATION_TYPES.SUCCESS
            );
        },
        function (err) {
            createNotification(`Could not copy data`, NOTIFICATION_TYPES.ERROR);
        }
    );
}
