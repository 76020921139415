import AdminSkeleton from './admin-skeleton.component';
import { objectService } from '../../../utils/object.util';
import {
    detail_sub_heading,
    muted_border,
} from '../../../utils/styles/tailwind-styles';
import { navigationService } from '../../../utils/navigation.util';

class Customer {
    code;
    descr;

    active;

    creditLimit;
    currency;
    class;
    taxNumber;
    parentCode;

    typeCode;
    salesRep;

    onHold;
    paymentsAllowed;
    salesAllowed;
    crNotesAllowed;

    shipToAddress;
    soldToAddress;

    contact;
    telephone;
    email;

    subAggregates;

    constructor(detail) {
        if (objectService.checkObjectPopulated(detail)) {
            this.code = detail.code;
            this.descr = detail.code;

            if ('active' in detail) this.active = detail.active;
            if ('creditLimit' in detail) this.creditLimit = detail.creditLimit;
            if ('currency' in detail) this.currency = detail.currency;

            if ('parentCode' in detail) this.parentCode = detail.parentCode;
            if ('typeCode' in detail) this.typeCode = detail.typeCode;

            if ('salesRep' in detail) this.salesRep = detail.salesRep;
            if ('contact' in detail) this.contact = detail.contact;
            if ('email' in detail) this.email = detail.email;
            if ('telephone' in detail) this.telephone = detail.telephone;

            if ('taxNumber' in detail) this.taxNumber = detail.taxNumber;
            if ('class' in detail) this.class = detail.class;

            if ('shipToAddress' in detail)
                this.shipToAddress = detail.shipToAddress;
            if ('soldToAddress' in detail)
                this.soldToAddress = detail.soldToAddress;

            if ('onHold' in detail) this.onHold = detail.onHold;
            if ('paymentsAllowed' in detail)
                this.paymentsAllowed = detail.paymentsAllowed;
            if ('salesAllowed' in detail)
                this.salesAllowed = detail.salesAllowed;
            if ('crNotesAllowed' in detail)
                this.crNotesAllowed = detail.crNotesAllowed;

            if ('subAggregates' in detail)
                this.subAggregates = detail.subAggregates;
        }
    }
}

const CustomerDetail = ({ detail }) => {
    const customer = new Customer(detail);

    return (
        <>
            {objectService.checkObjectPopulated(detail) ? (
                <div
                    className={`grid grid-cols-2 gap-x-2 gap-y-6 text-sm items-start`}
                >
                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Credit Limit
                        </span>

                        <span className="font-mono">
                            {customer.creditLimit.toLocaleString() ?? 0}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Currency
                        </span>

                        <span className="badge badge-sm badge-primary">
                            {customer.currency ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>Class</span>

                        <span className="badge badge-sm badge-primary">
                            {customer.class >> '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Tax Number
                        </span>

                        <span
                            className="text-center text-xs cursor-copy font-mono"
                            onClick={() =>
                                navigationService.copyToClipboard(
                                    customer.email
                                )
                            }
                        >
                            {customer.taxNumber ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Sales Rep
                        </span>

                        <span className="badge badge-primary badge-sm">
                            {customer.salesRep ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>Email</span>

                        <span
                            className="text-center text-xs cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(
                                    customer.email
                                )
                            }
                        >
                            {customer.email ?? '-'}
                        </span>
                    </div>

                    <div
                        className={`flex flex-col justify-center items-center gap-1 col-span-1`}
                    >
                        <span className={`${detail_sub_heading}`}>
                            Customer Type
                        </span>

                        <span className="badge badge-primary badge-sm">
                            {customer.typeCode ?? '-'}
                        </span>
                    </div>

                    {customer.parentCode ? (
                        <>
                            <div className="flex flex-col items-center justify-center text-sm col-span-1 gap-1">
                                <span className={`${detail_sub_heading}`}>
                                    Parent Code
                                </span>
                                <a
                                    href={
                                        '/discover/customer/' +
                                        customer.parentCode
                                    }
                                    target={'_blank'}
                                    className={
                                        'badge badge-outline badge-primary font-mono cursor-pointer'
                                    }
                                >
                                    {customer.parentCode ?? '-'}
                                </a>
                            </div>

                            <div className="flex flex-col justify-center items-center gap-1 col-span-2">
                                <span className={`${detail_sub_heading}`}>
                                    Address
                                </span>

                                <span className="text-center text-xs">
                                    {customer.shipToAddress
                                        ? customer.shipToAddress.length > 52
                                            ? customer.shipToAddress.slice(
                                                  0,
                                                  52
                                              ) + '..'
                                            : customer.shipToAddress
                                        : '-'}
                                </span>
                            </div>
                        </>
                    ) : (
                        customer.subAggregates && (
                            <>
                                <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                                    <span className={`${detail_sub_heading}`}>
                                        Address
                                    </span>

                                    <span className="text-center text-xs">
                                        {customer.shipToAddress
                                            ? customer.shipToAddress.length > 52
                                                ? customer.shipToAddress.slice(
                                                      0,
                                                      52
                                                  ) + '..'
                                                : customer.shipToAddress
                                            : '-'}
                                    </span>
                                </div>

                                <div
                                    className={`flex flex-col items-center justify-start text-sm col-span-full gap-1 max-h-48 overflow-y-auto overflow-x-clip p-3 rounded-box mb-2 no-scrollbar shadow-inner bg-base-200 hover:border-secondary hover:border-opacity-80 border border-base-200`}
                                >
                                    <div className={`${detail_sub_heading}`}>
                                        Sub Customers
                                        <span className="badge badge-xs badge-secondary mx-2">
                                            {customer.subAggregates.length}
                                        </span>
                                    </div>

                                    <div className="flex flex-wrap items-center justify-center gap-2">
                                        {customer.subAggregates
                                            .sort()
                                            .map((cust) => {
                                                return (
                                                    <a
                                                        key={cust['code']}
                                                        href={
                                                            '/discover/customer/' +
                                                            cust['code']
                                                        }
                                                        target={'_blank'}
                                                        className={
                                                            'badge badge-sm badge-outline badge-secondary font-mono cursor-pointer tooltip flex items-center justify-center'
                                                        }
                                                        data-tip={`View Customer [${cust['descr']}]`}
                                                    >
                                                        {cust['descr']
                                                            ? cust['descr']
                                                                  .length > 20
                                                                ? cust[
                                                                      'descr'
                                                                  ].slice(
                                                                      0,
                                                                      20
                                                                  ) + '..'
                                                                : cust['descr']
                                                            : cust['code']}
                                                    </a>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )
                    )}
                </div>
            ) : (
                <AdminSkeleton rows={5} />
            )}
        </>
    );
};

export default CustomerDetail;
