import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';

import { format, parseISO } from 'date-fns';
import {
    CalendarDaysIcon,
    CheckIcon,
    TrashIcon,
    XMarkIcon,
} from '@heroicons/react/24/solid';

import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { setBaseFiltersSelected } from '../../../../store/base/filters/base-filters.actions';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';

import './date-range.styles.css';
import { muted_border } from '../../../../utils/styles/tailwind-styles';

const DateRangeComponent = ({ compact }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const selectedDateRange = useSelector(selectFiltersSelected);

    const [range, setRange] = useState(null);

    useEffect(() => {
        if (
            typeof selectedDateRange.fromDate === 'string' &&
            typeof selectedDateRange.toDate === 'string'
        ) {
            setRange({
                from: new Date(selectedDateRange.fromDate),
                to: new Date(selectedDateRange.toDate),
            });
        } else {
            setRange({
                from: selectedDateRange.fromDate,
                to: selectedDateRange.toDate,
            });
        }
    }, [selectedDateRange]);

    let footer = (
        <p
            className={`tracking-wide font-light text-center text-xs ${
                isOpen ? 'mt-5' : ''
            }`}
        >
            Please pick a date range
        </p>
    );

    if (range?.from) {
        if (!range.to) {
            footer = (
                <p
                    className={`text-center text-xs font-bold ${
                        isOpen ? 'mt-5' : ''
                    }`}
                >
                    {format(parseISO(range.from.toISOString()), 'PPP')}
                </p>
            );
        } else if (range.to) {
            footer = (
                <p
                    className={`text-center text-xs font-bold ${
                        isOpen ? 'mt-5' : ''
                    }`}
                >
                    {format(parseISO(range.from.toISOString()), 'PPP')}
                    <span className="font-bold px-3">to</span>
                    {format(parseISO(range.to.toISOString()), 'PPP')}
                </p>
            );
        }
    }

    const setDateRange = (event) => {
        if (!range?.from) {
            setRange({ from: event });
        } else if (range.from && !range?.to) {
            if (event > range.from) {
                setRange({ ...range, to: event });
            } else {
                setRange({ from: event, to: range.from });
            }
        } else {
            if (event < range.from) {
                setRange({ ...range, from: event });
            } else {
                setRange({ ...range, to: event });
            }
        }
    };

    const confirmDateRange = () => {
        if (range === null || !range.to) {
            createNotification(
                'Please select a date range before trying to apply',
                NOTIFICATION_TYPES.WARNING
            );
        } else {
            setIsOpen(false);
            dispatch(setBaseFiltersSelected('fromDate', range.from));
            dispatch(setBaseFiltersSelected('toDate', range.to));
        }
    };

    const clearDateRange = () => {
        setRange(null);
    };

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);
    };

    if (!compact) {
        return (
            <div
                className={`relative flex justify-center items-center py-0 m-0 px-0 rounded shadow-md normal-case font-normal bg-base-200 text-base-content`}
                style={{
                    minWidth: '20rem',
                }}
            >
                <span
                    className=" w-full h-full normal-case btn btn-ghost py-2  font-normal flex items-center justify-center text-xs"
                    onClick={toggleIsOpen}
                >
                    <CalendarDaysIcon className="h-5 w-5 mr-2" />

                    <span className="w-8/12 text-center">{footer}</span>
                </span>

                {isOpen && (
                    <div
                        className={`card ${muted_border} card-body bg-base-300 text-base-content py-2 px-0 absolute top-0 left-0 w-full shadow-md z-1000`}
                    >
                        <div className="w-full flex justify-between px-2 py-0">
                            <button
                                onClick={clearDateRange}
                                className="btn btn-sm btn-error btn-outline text-sm normal-case gap-1 tooltip tooltip-left"
                                data-tip={'Clear'}
                            >
                                <TrashIcon className="w-5 h-5" />
                                Clear Dates
                            </button>

                            <button
                                onClick={toggleIsOpen}
                                className={
                                    'btn btn-sm btn-outline text-sm normal-case gap-1 tooltip tooltip-right'
                                }
                                data-tip={'Close'}
                            >
                                <XMarkIcon className="w-5 h-5" />
                                Close
                            </button>
                        </div>

                        <div className="divider my-0" />
                        <div className="flex w-full h-full justify-center items-center">
                            <DayPicker
                                mode="range"
                                className="text-sm"
                                min={1}
                                selected={range}
                                onDayClick={setDateRange}
                                footer={footer}
                                fromYear={2010}
                                toYear={new Date().getFullYear()}
                                captionLayout="dropdown"
                            />
                        </div>
                        <div className="divider my-0" />

                        <div className="w-full flex justify-center px-2 py-0">
                            <button
                                onClick={confirmDateRange}
                                data-tip={'Save'}
                                className="btn btn-sm btn-success btn-outline text-sm normal-case gap-1 tooltip tooltip-bottom"
                            >
                                <CheckIcon className="w-5 h-5" />
                                Apply Range
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return (
            <div className="flex justify-between items-center text-start mx-2 max-w-full">
                <h4 className="font-bold mr-4 text-xs">Timeline</h4>

                <div
                    className="flex normal-case font-normal justify-center items-center p-2 rounded-box bg-base-100 text-base-content text-xs"
                    style={{
                        minWidth: '19rem',
                    }}
                >
                    {footer}
                </div>
            </div>
        );
    }
};

export default DateRangeComponent;