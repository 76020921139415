import {
    ArrowRightStartOnRectangleIcon,
    BookmarkSquareIcon,
    CheckIcon,
    ChevronUpIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    DocumentIcon,
    PlusIcon,
    RectangleGroupIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid';
import {
    badge_overflow,
    glowing_border,
    widget_full,
} from '../../../utils/styles/tailwind-styles';
import { useEffect, useState } from 'react';
import {
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_DATA_SORTING,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
} from '../../../store/discover/widgets/widgets.types';
import { REACT_TOUR_CLASS_NAMES } from '../../../utils/react-tour.util';
import { DISCOVER_WIDGET_AGGREGATION_METRICS } from '../../../store/discover/widgets/widgets.types';

import MiniLineChartComponent from '../../charts/mini-line/mini-line-chart.component';
import {
    generateStubBarChartData,
    generateStubLineChartData,
} from '../../../utils/dev-data/dev-data';
import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import MiniBarChartComponent from '../../charts/mini-bar/mini-bar-chart.component';
import { useLocation } from 'react-router-dom';
import AnimatedBorder from '../../animated-border/animated-border.component';
import { checkLightTheme } from '../../../utils/styles/global-styles';
import { objectService } from '../../../utils/object.util';

const getValidMetricByLocation = (location) => {
    let validMetrics = { ...DISCOVER_WIDGET_DATA_METRICS };

    if (!location.pathname.includes('customer')) {
        delete validMetrics.UNUSED_CREDIT;
    }

    if (!location.pathname.includes('dashboard')) {
        delete validMetrics.CHAOS;
    }

    if (
        !location.pathname.includes('customer') &&
        !location.pathname.includes('rep')
    ) {
        delete validMetrics.OPPORTUNITY;
    }

    return Object.keys(validMetrics);
};

const getValidVisualizationByMetricAndLocation = (dataMetric, location) => {
    let validVisualizations = { ...DISCOVER_WIDGET_DATA_VISUALIZATIONS };
    delete validVisualizations.SINGULAR;

    if (
        [
            DISCOVER_WIDGET_DATA_METRICS.DISCOUNT,
            DISCOVER_WIDGET_DATA_METRICS.UNUSED_CREDIT,
        ].includes(dataMetric)
    ) {
        delete validVisualizations.RANKING;
    }

    if (
        [
            // DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY,
        ].includes(dataMetric)
    ) {
        delete validVisualizations.TREND;
    }

    if ([DISCOVER_WIDGET_DATA_METRICS.CHAOS].includes(dataMetric)) {
        delete validVisualizations.TREND;
        delete validVisualizations.SINGULAR;

        if (!location.pathname.includes('dashboard')) {
            delete validVisualizations.RANKING;
        }
    }

    return validVisualizations;
};

const getValidAggMetricsByMetricAndVisualisation = (
    dataMetric,
    dataVisualisation,
    perspective
) => {
    let validAggMetrics = { ...DISCOVER_WIDGET_AGGREGATION_METRICS };

    if (
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.SKU ||
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS
    ) {
        delete validAggMetrics.SKUS;
        delete validAggMetrics.SKU;
    }

    if (
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER ||
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS
    ) {
        delete validAggMetrics.CUSTOMERS;
        delete validAggMetrics.CUSTOMER;
    }

    if (
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.REP ||
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.REPS ||
        dataMetric === DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY
    ) {
        delete validAggMetrics.REPS;
        delete validAggMetrics.REP;
    }

    if (
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.CATEGORY ||
        perspective.type === DISCOVER_WIDGET_AGGREGATION_METRICS.CATEGORIES
    ) {
        delete validAggMetrics.CATEGORY;
        delete validAggMetrics.CATEGORIES;
    }

    if (
        dataVisualisation === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND ||
        dataVisualisation === DISCOVER_WIDGET_DATA_VISUALIZATIONS.SINGULAR
    ) {
        return {};
    } else if (
        dataVisualisation === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
    ) {
        delete validAggMetrics.SKU;
        delete validAggMetrics.CUSTOMER;
        delete validAggMetrics.REP;
        delete validAggMetrics.GLOBAL;
        delete validAggMetrics.CATEGORY;

        if (
            dataMetric !== DISCOVER_WIDGET_DATA_METRICS.SALES &&
            dataMetric !== DISCOVER_WIDGET_DATA_METRICS.RETURNS
        ) {
            delete validAggMetrics.CATEGORIES;
        }

        return validAggMetrics;
    } else {
        return {};
    }
};

export const getStubGraphBasedOnVisualisation = (visualization, data) => {
    if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
        if (!objectService.checkObjectPopulated(data))
            return (
                <Cog6ToothIcon className="w-8 h-8 text-primary animate-spin" />
            );
        else
            return (
                <MiniLineChartComponent data={data} className={'max-h-80'} />
            );
    } else if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING) {
        if (!objectService.checkObjectPopulated(data))
            return (
                <Cog6ToothIcon className="w-8 h-8 text-primary animate-spin" />
            );
        else
            return <MiniBarChartComponent data={data} className={'max-h-80'} />;
    } else {
        return (
            <div className="flex items-center justify-center">
                <div className="stats w-full bg-base-300">
                    <div className="stat">
                        <a
                            className="btn btn-ghost hover:underline stat-title normal-case text-start justify-start font-normal text-lg text-base-content text-opacity-70"
                            href={`#`}
                        >
                            Stub
                        </a>
                        <div className="stat-value text-primary font-mono text-2xl">
                            R{(Math.random() * 10000 + 1).toLocaleString()}
                            <span
                                className={
                                    'font-light text-base-content text-opacity-80 text-xs ml-2'
                                }
                            >
                                [time period]
                            </span>
                        </div>
                        <div className="stat-desc">
                            This distinction makes up
                            <span className="font-mono text-primary mr-1">
                                {(Math.random() * 100 + 1).toLocaleString()}%
                            </span>{' '}
                            of the total
                        </div>

                        <div className="divider my-2" />

                        <p className="text-base-content text-opacity-60 text-sm">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Aut distinctio eaque harum illum libero
                            maiores, nesciunt nisi optio quis quo repudiandae
                            sit sunt tenetur unde vel! Asperiores expedita
                            facilis quis?
                        </p>
                    </div>
                </div>
            </div>
        );
    }
};

const AddWidget = ({ perspective, onAddWidget }) => {
    const daisyTheme = useSelector(selectDaisyTheme);
    const location = useLocation();

    const [isOpen, setIsOpen] = useState(false);

    const [metric, setMetric] = useState(DISCOVER_WIDGET_DATA_METRICS.SALES);

    const [validVisualizations, setValidVisualizations] = useState(
        getValidVisualizationByMetricAndLocation(metric, location)
    );

    const [visualization, setVisualization] = useState(
        DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND
    );

    const [sort, setSort] = useState('');

    const [validAggregationMetrics, setValidAggregationMetrics] = useState(
        getValidAggMetricsByMetricAndVisualisation(
            metric,
            visualization,
            perspective
        )
    );
    const [aggregateBy, setAggregateBy] = useState('');

    const [fullWidget, setFullWidget] = useState(true);

    const [stubData, setStubData] = useState({});

    useEffect(() => {
        if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND) {
            setStubData(generateStubLineChartData(daisyTheme));
        } else if (
            visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
        ) {
            setStubData(generateStubBarChartData(daisyTheme));
        } else {
            setStubData({});
        }
    }, [visualization, aggregateBy, sort, daisyTheme]);

    useEffect(() => {
        let newValidAggMetrics = getValidAggMetricsByMetricAndVisualisation(
            metric,
            visualization,
            perspective
        );
        setValidAggregationMetrics(newValidAggMetrics);
        // console.lo;

        if (!objectService.checkObjectPopulated(newValidAggMetrics)) {
            setAggregateBy(null);
        } else if (!Object.values(newValidAggMetrics).includes(aggregateBy)) {
            setAggregateBy(
                newValidAggMetrics[Object.keys(newValidAggMetrics)[0]]
            );
        }

        if (
            visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND ||
            visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.SINGULAR
        ) {
            setSort('');
        } else if (sort === '') {
            setSort(DISCOVER_WIDGET_DATA_SORTING.TOP);
        }
    }, [metric, visualization]);

    useEffect(() => {
        if (visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND)
            setFullWidget(true);
        else setFullWidget(false);
    }, [visualization]);

    useEffect(() => {
        let newValidVisualizations = getValidVisualizationByMetricAndLocation(
            metric,
            location
        );
        setValidVisualizations(
            getValidVisualizationByMetricAndLocation(metric, location)
        );

        if (!Object.values(newValidVisualizations).includes(visualization)) {
            setVisualization(
                newValidVisualizations[Object.keys(newValidVisualizations)[0]]
            );
        }
    }, [metric]);

    return (
        <div
            className={`${
                REACT_TOUR_CLASS_NAMES.ADD_WIDGET_EXPANDED
            } rounded-box relative ${
                isOpen
                    ? fullWidget
                        ? 'laptop:col-span-10 col-span-12'
                        : 'col-span-6 laptop:col-span-5'
                    : 'col-span-12 laptop:col-span-10'
            }`}
            id={'add-widget'}
        >
            <div className="flex items-center justify-center w-full">
                <span
                    className={`${
                        isOpen
                            ? ' h-0 w-0 p-0 m-0 '
                            : ` bg-base-300 btn btn-outline btn-primary tooltip tooltip-left flex items-center justify-center cursor-pointer duration-200 ${
                                  !checkLightTheme(daisyTheme) && glowing_border
                              } border-primary border-opacity-90 shadow-primary`
                    } ${REACT_TOUR_CLASS_NAMES.ADD_WIDGET}`}
                    onClick={() => setIsOpen(!isOpen)}
                    data-tip={isOpen ? 'Cancel' : 'Add Widget'}
                    id={REACT_TOUR_CLASS_NAMES.ADD_WIDGET}
                >
                    {!isOpen ? (
                        <PlusIcon className={'w-6 h-6 font-bold'} />
                    ) : (
                        <></>
                    )}
                </span>
            </div>

            <div
                className={`w-full h-full grid grid-cols-5 gap-4 p-6 ${
                    isOpen
                        ? `translate-x-0 group`
                        : 'translate-x-0 opacity-0 bg-base-100 bg-opacity-0 h-0 w-0 shadow-none overflow-y-clip ease-linear duration-100'
                } transition-all bg-base-300 rounded-box`}
            >
                <AnimatedBorder />

                <div className="flex w-full justify-between items-center col-span-5">
                    <div
                        className={`flex w-full gap-1 ${
                            fullWidget
                                ? 'flex-row justify-start items-center'
                                : 'flex-col items-start justify-center'
                        }`}
                    >
                        <div className="flex items-center gap-1">
                            <span className="min-h-full text-primary capitalize font-bold min-w-fit text-sm">
                                Metric
                            </span>

                            <select
                                onChange={(e) => {
                                    setMetric(e.target.value);
                                }}
                                value={metric}
                                id={REACT_TOUR_CLASS_NAMES.ADD_WIDGET_METRIC}
                                className={`select select-bordered select-primary mx-2 ${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_METRIC}`}
                            >
                                {getValidMetricByLocation(location).map(
                                    (metric) => {
                                        return (
                                            <option
                                                key={
                                                    DISCOVER_WIDGET_DATA_METRICS[
                                                        metric
                                                    ]
                                                }
                                            >
                                                {
                                                    DISCOVER_WIDGET_DATA_METRICS[
                                                        metric
                                                    ]
                                                }
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>

                        <div className="divider divider-horizontal mx-0 my-0" />

                        <div className="flex items-center gap-1">
                            <span className="min-h-full text-secondary capitalize font-bold min-w-fit text-sm">
                                Perspective
                            </span>

                            {perspective && perspective.type && (
                                <div
                                    className={`mx-2 bg-secondary min-h-full text-secondary-content text-base ${badge_overflow} w-fit ${REACT_TOUR_CLASS_NAMES.WIDGET_3} text-start justify-start gap-1`}
                                >
                                    {perspective.type === 'global' && (
                                        <span className="font-bold">
                                            {perspective.type}
                                        </span>
                                    )}
                                    {Object.keys(perspective).includes(
                                        'descr'
                                    ) &&
                                        perspective.descr && (
                                            <span
                                                className={`tooltip bg-secondary min-h-full text-secondary-content ${badge_overflow} w-fit ${REACT_TOUR_CLASS_NAMES.WIDGET_3} text-start justify-start gap-1`}
                                                data-tip={
                                                    perspective.descr &&
                                                    perspective.descr.replace(
                                                        '-',
                                                        ' '
                                                    )
                                                }
                                            >
                                                {perspective.descr
                                                    .replace('-', ' ')
                                                    .slice(0, 20)}
                                                {perspective.descr.length >
                                                    20 && '..'}
                                            </span>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center justify-end gap-1">
                        {/*<button*/}
                        {/*    className={`btn btn-primary btn-outline btn-sm tooltip tooltip-bottom flex justify-center items-center normal-case font-light text-xs ${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_SIZE}`}*/}
                        {/*    id={REACT_TOUR_CLASS_NAMES.ADD_WIDGET_SIZE}*/}
                        {/*    data-tip={'Change Widget Size'}*/}
                        {/*    onClick={() => setFullWidget(!fullWidget)}*/}
                        {/*>*/}
                        {/*    {fullWidget ? (*/}
                        {/*        <RectangleGroupIcon className={'w-5 h-5'} />*/}
                        {/*    ) : (*/}
                        {/*        <RectangleGroupIcon className={'w-5 h-5'} />*/}
                        {/*    )}*/}
                        {/*</button>*/}

                        {/*<div className="divider divider-horizontal mx-1" />*/}
                        <div className={`flex items-center justify-end gap-2`}>
                            <button
                                className={`btn btn-error btn-xs btn-outline tooltip tooltip-bottom flex justify-center items-center normal-case font-light text-xs ${REACT_TOUR_CLASS_NAMES.WATCHLIST}`}
                                data-tip={'Cancel'}
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <XMarkIcon className={'w-4 h-4'} />
                            </button>

                            <button
                                className={`btn btn-success btn-outline btn-xs tooltip tooltip-bottom flex justify-center items-center normal-case font-light text-xs ${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_SAVE}`}
                                id={REACT_TOUR_CLASS_NAMES.ADD_WIDGET_SAVE}
                                data-tip={'Save Widget'}
                                onClick={() => {
                                    onAddWidget(
                                        metric,
                                        visualization,
                                        sort,
                                        aggregateBy,
                                        fullWidget ? 'full' : 'half'
                                    );
                                    setIsOpen(!isOpen);
                                }}
                            >
                                <BookmarkSquareIcon className={'w-4 h-4'} />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-start col-span-2 w-full">
                    <span className="text-primary capitalize font-bold min-w-fit text-sm">
                        Data Visualisation
                    </span>

                    <select
                        onChange={(e) => {
                            setVisualization(e.target.value);
                        }}
                        value={visualization}
                        className={`select select-bordered select-primary mx-2 ${REACT_TOUR_CLASS_NAMES.ADD_WIDGET_VISUALIZATION}`}
                        id={REACT_TOUR_CLASS_NAMES.ADD_WIDGET_VISUALIZATION}
                    >
                        {Object.keys(validVisualizations).length > 0 &&
                            Object.keys(validVisualizations).map(
                                (visualization) => {
                                    return (
                                        <option
                                            key={
                                                validVisualizations[
                                                    visualization
                                                ]
                                            }
                                        >
                                            {validVisualizations[visualization]}
                                        </option>
                                    );
                                }
                            )}
                    </select>
                </div>

                {Object.keys(validAggregationMetrics).length > 0 && (
                    <>
                        <div className="divider col-span-5 my-0" />

                        <div className="flex items-center justify-start gap-1 col-span-5">
                            <span className="w-28 text-accent capitalize font-bold min-w-fit text-sm">
                                Aggregate By
                            </span>
                            <select
                                onChange={(e) => {
                                    setAggregateBy(e.target.value);
                                }}
                                value={aggregateBy}
                                className="select select-bordered select-sm select-accent mx-2"
                            >
                                {Object.keys(validAggregationMetrics).map(
                                    (aggregateBy) => {
                                        return (
                                            <option
                                                key={
                                                    DISCOVER_WIDGET_AGGREGATION_METRICS[
                                                        aggregateBy
                                                    ]
                                                }
                                            >
                                                {
                                                    DISCOVER_WIDGET_AGGREGATION_METRICS[
                                                        aggregateBy
                                                    ]
                                                }
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>

                        <div className="flex items-center justify-start gap-1 col-span-5">
                            <span className="w-28 text-accent capitalize font-bold min-w-fit text-sm">
                                Sort By
                            </span>
                            <select
                                onChange={(e) => {
                                    setSort(e.target.value);
                                }}
                                value={sort}
                                className="select select-bordered select-sm select-accent mx-2"
                            >
                                {Object.keys(DISCOVER_WIDGET_DATA_SORTING).map(
                                    (aggregateBy) => {
                                        return (
                                            <option
                                                key={
                                                    DISCOVER_WIDGET_DATA_SORTING[
                                                        aggregateBy
                                                    ]
                                                }
                                            >
                                                {
                                                    DISCOVER_WIDGET_DATA_SORTING[
                                                        aggregateBy
                                                    ]
                                                }
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>
                    </>
                )}

                <div className="divider col-span-5 my-0" />

                <div className="flex items-center justify-center col-span-5 w-full">
                    <div className="w-full h-full col-span-3 shadow rounded-box p-4 flex flex-col gap-2 bg-base-300">
                        {getStubGraphBasedOnVisualisation(
                            visualization,
                            stubData
                        )}

                        <span className="text-base-content text-opacity-80 text-xs w-full text-end font-bold capitalize">
                            Stub {visualization} Data
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddWidget;