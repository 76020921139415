import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import Select from 'react-select';

import { RANGING_TYPES } from '../../../../store/base/filters/ranging/ranging.types';
import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import {
    setRangingFilterSort,
    setRangingFilterStrategy,
} from '../../../../store/base/filters/ranging/ranging.actions';

import CompactFilterGroup from '../compact-filter-container/compact-filter-group.component';

import { generateSelectStylesDaisy } from '../../../../utils/styles/global-styles';

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const RangingFilters = ({ visible, toggleOpen }) => {
    const dispatch = useDispatch();

    const daisyTheme = useSelector(selectDaisyTheme);

    const [rangingStrategy, setRangingStrategy] = useState(
        RANGING_TYPES.rangingStrategy.default
    );
    const [sortBy, setSortBy] = useState(RANGING_TYPES.identifyOption.default);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [dropdownMode, setDropdownMode] = useState(
        windowSize['innerWidth'] <= 1200
    );
    const [dropdownActive, setDropdownActive] = useState(false);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());

            if (getWindowSize()['innerWidth'] < 1200) {
                setDropdownMode(true);
            } else {
                setDropdownMode(false);
            }
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleIdenfityOptionChange = (event) => {
        setSortBy(event);
        dispatch(setRangingFilterSort(event.value));
    };

    const handleRangingStrategyChange = (event) => {
        setRangingStrategy(event);
        dispatch(setRangingFilterStrategy(event.value));
    };

    const customStyles = generateSelectStylesDaisy(daisyTheme, windowSize);

    const toggleDropdownActive = () => {
        setDropdownActive(!dropdownActive);
    };

    return (
        <>
            {!visible && !dropdownMode ? (
                <div
                    className="flex justify-evenly items-center hover:cursor-pointer"
                    onClick={toggleOpen}
                >
                    <CompactFilterGroup
                        title="Targeted Stores"
                        activeFilters={[rangingStrategy]}
                    />
                    <CompactFilterGroup
                        title="Sort By"
                        activeFilters={[sortBy]}
                    />

                    <span className="btn btn-circle btn-ghost btn-sm">
                        <ChevronDownIcon
                            className="w-5 h-5"
                            onClick={toggleOpen}
                        />
                    </span>
                </div>
            ) : (
                <>
                    <div
                        className={
                            !dropdownMode
                                ? 'flex justify-evenly h-full w-full'
                                : dropdownActive
                                ? 'bg-base-200 drop-shadow absolute top-full right-0 min-h-screen w-80 flex flex-col justify-start items-end p-3 transition-all visible'
                                : 'bg-base-200 drop-shadow absolute top-full right-0 min-h-screen w-80 flex flex-col justify-start items-end p-3 transition-all invisible'
                        }
                    >
                        <div className="flex flex-col items-center justify-evenly m-2 text-xs">
                            <span className="mb-2 font-light">
                                {RANGING_TYPES.rangingStrategy.heading}
                            </span>

                            <Select
                                styles={customStyles}
                                options={RANGING_TYPES.rangingStrategy.options}
                                className="w-40"
                                value={rangingStrategy}
                                placeholder=""
                                onChange={handleRangingStrategyChange}
                                isSearchable={false}
                                isClearable={false}
                                isMulti={false}
                            />
                        </div>

                        <div className="flex flex-col items-center justify-evenly m-2 text-xs">
                            <span className="mb-2 font-light">
                                {RANGING_TYPES.identifyOption.heading}
                            </span>
                            <Select
                                styles={customStyles}
                                options={RANGING_TYPES.identifyOption.options}
                                className="w-40"
                                value={sortBy}
                                placeholder=""
                                onChange={handleIdenfityOptionChange}
                                isSearchable={false}
                                isClearable={false}
                                isMulti={false}
                            />
                        </div>
                    </div>

                    {dropdownMode ? (
                        dropdownActive ? (
                            <div
                                className="btn btn-ghost text-xs font-normal normal-case"
                                onClick={toggleDropdownActive}
                            >
                                <p>Ranging Filters</p>
                                <ChevronUpIcon className="w-5 h-5" />
                            </div>
                        ) : (
                            <div
                                className="btn btn-ghost text-xs font-normal normal-case"
                                onClick={toggleDropdownActive}
                            >
                                <p>Ranging Filters</p>

                                <ChevronDownIcon
                                    className="w-5 h-5"
                                    onClick={toggleDropdownActive}
                                />
                            </div>
                        )
                    ) : (
                        <span className="btn btn-circle btn-ghost btn-sm">
                            <ChevronUpIcon
                                className="w-5 h-5"
                                onClick={toggleOpen}
                            />
                        </span>
                    )}
                </>
            )}
        </>
    );
};

export default RangingFilters;