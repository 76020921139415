import { Navigate } from 'react-router-dom';
import { selectUser } from '../store/auth/authentication.selectors';
import { useSelector } from 'react-redux';

import { createNotification, NOTIFICATION_TYPES } from './notification.util';

export const ProductProtectedRoute = ({ product, children }) => {
    const userProducts = useSelector(selectUser).products;

    if (!userProducts || !userProducts.includes(product)) {
        // user is not authenticated
        createNotification(
            'You do not have access to that product',
            NOTIFICATION_TYPES.ERROR
        );

        return <Navigate to="/" replace={true} />;
    }
    return children;
};