import { AUTHENTICATION_ACTION_TYPES } from './authentication.types';

const INITIAL_STATE = {
    // Object retrieved from backend
    user: null,
};

export const authenticationReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case AUTHENTICATION_ACTION_TYPES.SET_CURRENT_USER:
            return { ...state, user: payload };
        default:
            return state;
    }
};
