import { useSelector } from 'react-redux';
import { selectFilterOptions } from '../../../../store/base/filters/options/base-filter-options.selectors';
import {
    BASE_FILTER_PLACEHOLDER_MAP,
    BASE_FILTER_TYPES,
} from '../../../../store/base/filters/base-filters.types';

import BasicFilter from '../basic-filter/basic-filter.component';

const RetailerFilters = ({ isLoading, setLoading }) => {
    const { dists, storelists, storetypes, distregions, distpoints } =
        useSelector(selectFilterOptions);

    return (
        <div className={`w-full max-w-2xl h-full`}>
            <span className="badge badge-outline badge-primary badge-sm font-bold">
                Retailer Filters
            </span>

            <BasicFilter
                id={BASE_FILTER_TYPES.dists}
                options={dists}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.dists]
                }
                isMulti={false}
                isClearable={true}
                closeMenuOnSelect={false}
                className="my-4 max-w-2xl"
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={storelists}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.storelists]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.storelists}
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={storetypes}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.storetypes]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.storetypes}
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={distregions}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.distregions]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.distregions}
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={distpoints}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.distpoints]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.distpoints}
                isLoading={isLoading}
                setLoading={setLoading}
            />
        </div>
    );
};

export default RetailerFilters;