import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDashboardDataStatus } from '../../../store/base/dashboard/dashboard.actions';
import { dashboardService } from '../../../store/base/dashboard/dashboard.services';
import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { selectFiltersSelected } from '../../../store/base/filters/base-filters.selectors';
import { selectUserToken } from '../../../store/auth/authentication.selectors';
import { selectDashboardDataStatus } from '../../../store/base/dashboard/dashboard.selectors';

import LineChartComponent from '../../../components/charts/line-chart-component/line-chart.component';
import NoData from '../../no-data/no-data.component';

import { handleError } from '../../../utils/engine/engine.service';
import PageLoadingComponent from '../../loading-component/page-loading-component';
import {
    base_widget_half,
    muted_border,
} from '../../../utils/styles/tailwind-styles';

const DashboardDataStatusComponent = () => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const daisyTheme = useSelector(selectDaisyTheme);

    /*
    dashboardDataStatus             - stores actual data, which is updated and stored in redux when filters change
    dashboardDataStatusPlottable    - stores graph data, which is updated at the same time as dashboardDateStatus and when themeColor/themeMode changes
     */
    const dashboardDataStatus = useSelector(selectDashboardDataStatus);
    const [dashboardDataStatusPlottable, setDashboardDataStatusPlottable] =
        useState(null);

    const selectedFilters = useSelector(selectFiltersSelected);

    const fetchDataStatusHelper = async () => {
        setIsLoading(true);

        try {
            const dataStatusData =
                await dashboardService.fetchDashboardDataStatus(
                    selectedFilters
                );
            if (dataStatusData !== null && !('action' in dataStatusData)) {
                dispatch(setDashboardDataStatus(dataStatusData));
                setDashboardDataStatusPlottable(
                    dashboardService.convertDataStatusToPlottable(
                        dataStatusData,
                        daisyTheme
                    )
                );
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    /*
    Re-fetch data when user updates selected filters (and on initial component render)
     */
    useEffect(() => {
        fetchDataStatusHelper();
    }, [selectedFilters]);

    /*
    Update graph colours when user alters theme color
     */
    useEffect(() => {
        if (
            dashboardDataStatus !== null &&
            !('action' in dashboardDataStatus)
        ) {
            setDashboardDataStatusPlottable(
                dashboardService.convertDataStatusToPlottable(
                    dashboardDataStatus,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    return (
        <>
            {isLoading ? (
                <PageLoadingComponent />
            ) : dashboardDataStatusPlottable === null ||
              dashboardDataStatusPlottable === undefined ? (
                <NoData className="col-span-full" />
            ) : (
                <>
                    <div className={`${base_widget_half} ${muted_border}`}>
                        <LineChartComponent
                            data={
                                dashboardDataStatusPlottable[
                                    'salesPerDistValue'
                                ]
                            }
                            title="Sales per Dist"
                            type={'Value'}
                        />
                    </div>

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <LineChartComponent
                            data={
                                dashboardDataStatusPlottable[
                                    'salesPerDistStoreCount'
                                ]
                            }
                            title="Sales Per Dist"
                            type={'Store Count'}
                        />
                    </div>

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <LineChartComponent
                            data={
                                dashboardDataStatusPlottable['invPerDistVolume']
                            }
                            title="Inv Per Dist"
                            type={'Volume'}
                        />
                    </div>

                    <div className={`${base_widget_half} ${muted_border}`}>
                        <LineChartComponent
                            data={
                                dashboardDataStatusPlottable[
                                    'invPerDistStoreCount'
                                ]
                            }
                            title="Inv Per Dist"
                            type={'Store Count'}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default DashboardDataStatusComponent;
