import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import { TableDataCell } from './sku-table.styles';
import {
    addOpacity,
    GLOBAL_COLOURS,
} from '../../../utils/styles/global-styles';

function darkModeColorMap(backendColor) {
    const map = {
        '#c1f0cc': GLOBAL_COLOURS['accents']['emerald-dark'],
        '#ffecb3': GLOBAL_COLOURS['accents']['amber-dark'],
        '#ffd9b3': GLOBAL_COLOURS['accents']['slate-dark'],
        '#f4bec3': GLOBAL_COLOURS['accents']['rose-dark'],
    };
    return addOpacity(map[backendColor], 0.7);
}

function mapHeadingToLabel(heading) {
    const map = {
        distSku: null,
        sohDate: 'Last Updated',
        supplSku: null,
        supplSkuDescr: 'SKU',
        soh: 'Average Stock on Hand',
        '6weekAvg': null,
        '12weekAvg': null,
        daysCover: 'Average Days Cover',
        daysCoverColor: null,
        daysSinceSale: 'Average Days Since Sale',
        daysSinceSaleColor: null,
    };
    return map[heading];
}

const SkuTable = ({ skuData, handleSkuClick, focusSku }) => {
    const tableHeadings = Object.keys(skuData[0]);

    const [filter, setFilter] = useState('');
    const [filteredSkus, setFilteredSkus] = useState(skuData);

    const handleFilterChange = (event) => {
        setFilter(event.currentTarget.value);
        filterSkus(event.currentTarget.value);
    };

    useEffect(() => {
        filterSkus(filter);
    }, [focusSku]);

    const filterSkus = (filter) => {
        const initiallyFiltered = skuData.filter((sku) => {
            return sku.supplSkuDescr
                .toLowerCase()
                .includes(filter.toLowerCase());
        });

        if (focusSku) {
            let shifted = [];

            initiallyFiltered.forEach((sku) => {
                if (sku.supplSku === focusSku.supplSku) shifted.unshift(sku);
                else shifted.push(sku);
            });
            setFilteredSkus(shifted);
        } else {
            setFilteredSkus(initiallyFiltered);
        }
    };

    return (
        <div className="w-full block max-h-screen overflow-y-scroll">
            <div className="w-full min-w-full flex justify-center items-center mb-2 gap-2 text-sm">
                <MagnifyingGlassIcon className="w-5 h-5 text-primary" />
                <input
                    className="w-full input input-bordered max-w-3xl input-sm"
                    onChange={handleFilterChange}
                    name="filter"
                    id="filter"
                    type="text"
                />
            </div>

            <table className="table table-zebra normal-case w-full text-xs">
                <thead>
                    <tr>
                        {tableHeadings.map((heading, index) => {
                            const label = mapHeadingToLabel(heading);
                            if (label) {
                                return (
                                    <th
                                        key={heading}
                                        className={`normal-case text-sm ${
                                            index === 0
                                                ? 'text-left'
                                                : 'text-right'
                                        }`}
                                    >
                                        {label}
                                    </th>
                                );
                            }
                        })}
                    </tr>
                </thead>

                <tbody>
                    {filteredSkus.map((skuObject, index) => {
                        let border;

                        if (
                            focusSku &&
                            focusSku['supplSku'] === skuObject['supplSku']
                        ) {
                            border = 'border-l-4 border-r-4 border-primary';
                        } else {
                            border =
                                'border-l-4 border-r-4 hover:border-primary';
                        }

                        return (
                            <tr
                                key={skuObject['supplSku']}
                                onClick={() => handleSkuClick(skuObject)}
                                className={`cursor-pointer ${border}`}
                            >
                                {Object.keys(skuObject).map((key) => {
                                    const label = mapHeadingToLabel(key);
                                    if (label) {
                                        if (
                                            key === 'daysCover' ||
                                            key === 'daysSinceSale'
                                        ) {
                                            return (
                                                <TableDataCell
                                                    key={uuidv4()}
                                                    style={{
                                                        backgroundColor:
                                                            darkModeColorMap(
                                                                skuObject[
                                                                    `${key}Color`
                                                                ]
                                                            ),
                                                    }}
                                                    className={
                                                        isNaN(skuObject[key])
                                                            ? 'text-left'
                                                            : 'text-right'
                                                    }
                                                >
                                                    {skuObject[key]}
                                                </TableDataCell>
                                            );
                                        } else {
                                            return (
                                                <TableDataCell
                                                    key={uuidv4()}
                                                    className={
                                                        isNaN(skuObject[key])
                                                            ? 'text-left'
                                                            : 'text-right'
                                                    }
                                                >
                                                    {skuObject[key]}
                                                </TableDataCell>
                                            );
                                        }
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default SkuTable;