export const TREND_FILTER_ACTION_TYPES = {
    SET_TREND_FILTER_FREQUENCY: 'base/filters/trend/SET_TREND_FILTER_FREQUENCY',
    SET_TREND_FILTER_COMPARE: 'base/filters/trend/SET_TREND_FILTER_COMPARE',

    RESET_TREND_FILTERS: 'base/filters/trend/RESET_TREND_FILTERS',
};

export const TREND_FILTER_TYPES = {
    frequency: {
        heading: 'Frequency',
        options: [
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
        ],
        default: { value: 'daily', label: 'Daily' },
    },
    compare: {
        heading: 'Compare',
        options: [
            { value: 'retailers', label: 'Retailers' },
            { value: 'storetypes', label: 'Store Types' },
            { value: 'storeregions', label: 'Store Regions' },
            { value: 'stores', label: 'Stores' },
            { value: 'categories', label: 'Categories' },
            { value: 'subcategories', label: 'Sub-Categories' },
            { value: 'brands', label: 'Brands' },
            { value: 'skus', label: "SKU's" },
        ],
        default: { value: 'retailers', label: 'Retailers' },
    },
    view: {
        heading: 'Graph Type',
        options: [
            { value: 'lines', label: 'Lines' },
            { value: 'stacked', label: 'Stacked Areas' },
        ],
        default: { value: 'lines', label: 'Lines' },
    },
};
