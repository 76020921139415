import { useState, useEffect } from 'react';

import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/solid';

const PageSelector = ({
    totalResults,
    pageSize,
    selectedIndex,
    onPageChange,
}) => {
    const [numPages, setNumPages] = useState(0);

    const calculateNumPages = () => {
        setNumPages(Math.ceil(totalResults / pageSize));
    };

    useEffect(() => {
        calculateNumPages();
    }, [totalResults, pageSize]);

    return (
        <>
            <div className="w-full h-full mt-2 flex flex-wrap justify-center items-center gap-1">
                <button
                    className={`btn btn-circle btn-xs btn-ghost ${
                        selectedIndex === 0 ? 'btn-disabled' : ''
                    }`}
                    onClick={() => onPageChange(0)}
                >
                    <ChevronDoubleLeftIcon className={`w-4 h-4`} />
                </button>

                <button
                    className={`btn btn-circle btn-xs btn-ghost ${
                        selectedIndex === 0 ? 'btn-disabled' : ''
                    }`}
                    onClick={() =>
                        onPageChange(
                            selectedIndex - 1 !== -1
                                ? selectedIndex - 1
                                : selectedIndex
                        )
                    }
                >
                    <ChevronLeftIcon className={`w-4 h-4`} />
                </button>

                {[...Array(numPages > 8 ? 8 : numPages).keys()].map((num) => {
                    if (selectedIndex >= 8) num += selectedIndex - 8 + 1;

                    return (
                        <div
                            key={num}
                            className={`text-xs btn btn-xs btn-circle btn-ghost ${
                                num === selectedIndex && 'text-primary'
                            }`}
                            onClick={() => onPageChange(num)}
                        >
                            {num + 1}
                        </div>
                    );
                })}
                <button
                    className={`btn btn-circle btn-xs btn-ghost ${
                        selectedIndex < numPages - 1 ? '' : 'btn-disabled'
                    }`}
                    onClick={() =>
                        onPageChange(
                            selectedIndex + 1 !== numPages
                                ? selectedIndex + 1
                                : selectedIndex
                        )
                    }
                >
                    <ChevronRightIcon className="w-4 h-4" />
                </button>
                <button
                    className={`btn btn-circle btn-xs btn-ghost ${
                        selectedIndex < numPages - 1 ? '' : 'btn-disabled'
                    }`}
                    onClick={() => onPageChange(numPages)}
                >
                    <ChevronDoubleRightIcon className="w-4 h-4" />
                </button>

                <div className="w-full flex justify-center items-center">
                    [
                    <span className={'text-primary px-1'}>
                        {selectedIndex + 1}
                    </span>
                    ]
                </div>
            </div>
        </>
    );
};

export default PageSelector;