import { engineService } from '../../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';
import {
    addOpacity,
    GLOBAL_COLOURS,
    graphColourSelector,
} from '../../../../utils/styles/global-styles';

export const distributionService = {
    loadDistributionROSData,
    convertDistributionROSDataToPlottable,
    convertRangingFocusSkuGraphDataToPlottable,
};

function darkModeColorMap(backendColor) {
    const map = {
        '#b5dead': GLOBAL_COLOURS['accents']['emerald-dark'],
        '#fafcb1': GLOBAL_COLOURS['accents']['amber-dark'],
        '#fcd060': GLOBAL_COLOURS['accents']['slate-dark'],
        '#ffc9c9': GLOBAL_COLOURS['accents']['rose-dark'],
    };
    return map[backendColor];
}

async function loadDistributionROSData(
    selectedFilters,
    focusSku,
    reportParams
) {
    const fetchParam = { selectedFilters };
    if (focusSku) {
        fetchParam['focusSku'] = focusSku.supplSku;
    }

    let param;
    if (reportParams) {
        param = {
            uiRequest: {
                action: 'generateReport',
                state: fetchParam,
                reportParams,
            },
        };
    } else {
        param = fetchParam;
    }

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DIST_ROS,
        param
    );
    return response;
}

function convertDistributionROSDataToPlottable(distROSData) {
    if (distROSData['graphData'].length === 0) {
        createNotification('No analysis data found', NOTIFICATION_TYPES.ERROR);

        return null;
    }

    const datasetKeys = Object.keys(distROSData).filter((key) => {
        if (key.includes('graphData') && key.includes('Label')) {
            return distROSData[key];
        }
    });
    const dataSets = datasetKeys.map((datasetKey) => distROSData[datasetKey]);

    const datasetColorKeys = Object.keys(distROSData).filter((key) => {
        if (key.includes('graphData') && key.includes('Color')) {
            return distROSData[key];
        }
    });
    const dataColors = datasetColorKeys.map((datasetKey) =>
        darkModeColorMap(distROSData[datasetKey])
    );

    const dataMap = {};
    dataSets.forEach((dataSet) => {
        dataMap[dataSet] = distROSData['graphData'].filter(
            (graphData) => graphData['note'] === dataSet
        );
    });

    return {
        labels: dataSets,
        datasets: Object.keys(dataMap).map((dataset, index) => {
            return {
                label: dataset,
                data: dataMap[dataset],

                borderColor: dataColors[index],
                backgroundColor: addOpacity(dataColors[index], 0.7),

                pointRadius: 6,
                pointHoverRadius: 9,

                pointBorderWidth: 2,
                pointHoverBorderWidth: 1,
            };
        }),
    };
}

function convertRangingFocusSkuGraphDataToPlottable(focusSkuData, daisyTheme) {
    if (focusSkuData['rows'].length === 0) {
        createNotification('No analysis data found', NOTIFICATION_TYPES.ERROR);
        return null;
    }

    const dataSets = focusSkuData['channels'].map((dataKey) => dataKey.dataKey);
    const labels = focusSkuData['rows'].map((row) => row.name);

    return {
        labels,
        datasets: dataSets.map((datasetLabel, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: datasetLabel,
                data: focusSkuData['rows'].map((row) => row[datasetLabel]),

                fill: false,
                borderColor,
                backgroundColor: addOpacity(backgroundColor, 0.5),
                tension: 0.125,

                pointRadius: 4,
                pointHoverRadius: 8,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 0.5,
                pointBorderColor: borderColor,
                pointBackgroundColor: addOpacity(backgroundColor, 0.5),

                yAxisID: `y${index + 1}`,
            };
        }),
    };
}
