import { WEB_PROCESS_FLOWS } from './pf-config.service';
import http from '../request.util';
import { removeDateRangeFromFilters } from './engine.service';
import { USER_ROLES } from '../auth.util';

export const apiService = {
    fetchTeam,

    fetchUsers,
    deleteUser,
    addUser,
    updateUser,

    updatePassword,

    fetchSharedFiles,
    countSharedFiles,
    getFileShareData,

    fetchScheduledProcesses,
    removeProcessFlowSchedule,
    updateProcessFlowSchedule,

    scheduleTrendProcess,
    scheduleDistributionProcess,
    scheduleRangingProcess,
    scheduleAlertsProcess,

    downloadSharedFile,

    createFiltersAlias,
    getFiltersFromAlias,
    publishShareViewLink,

    createStripeCheckoutSession,
    createStripeBillingSession,
    checkStripeSessionStatus,
};

async function fetchTeam() {
    const response = await http.post('/api/prweb/user/team', {});
    return response.data;
}

async function fetchUsers(resultPage, resultsPerPage, searchText) {
    const response = await http.get(
        `/api/prweb/user?resultPage=${
            resultPage + 1
        }&resultsPerPage=${resultsPerPage}${
            searchText ? `&searchText=${searchText}` : ''
        }`
    );
    return response.data;
}

async function addUser(name, email, plainTextPassword, role = USER_ROLES.USER) {
    const response = await http.post(
        '/api/prweb/user',
        JSON.stringify({ name, email, plainTextPassword, role })
    );
    return response;
}

async function updateUser(name, email, role = USER_ROLES.USER) {
    const response = await http.put(
        '/api/prweb/user',
        JSON.stringify({ name, email, role })
    );
    return response;
}

async function deleteUser(userControl) {
    const response = await http.delete(
        `/api/prweb/user?userControl=${userControl}`
    );
    return response.data;
}

async function updatePassword(password) {
    const response = await http.put(
        `/api/prweb/user/password`,
        JSON.stringify({ password })
    );
    return response.data;
}

async function countSharedFiles() {
    const response = await http.get(`/api/prweb/shared-files/total`);
    return response.data;
}

async function fetchSharedFiles(limit = 10, pageIndex = 0) {
    const response = await http.post(
        `/api/prweb/shared-files`,
        JSON.stringify({
            limit,
            page: pageIndex + 1,
        })
    );
    return response.data;
}

async function downloadSharedFile(fileShareId) {
    const response = await http.get(
        `/api/prweb/shared-files/${fileShareId}/download`
    );
    return response.data;
}

async function fetchScheduledProcesses(limit = 10, pageIndex = 0) {
    const response = await http.get(
        `/api/prweb/process/schedule?page=${pageIndex}&pageSize=${limit}`
    );
    return response.data;
}

async function scheduleTrendProcess(
    pfSystemCode,
    selectedFilters,
    trendFilters,
    reportParams,
    cronText
) {
    let param = {
        uiRequest: {
            action: 'generateReport',
            state: {
                selectedFilters: selectedFilters,
                compare: trendFilters['compare'],
                frequency: trendFilters['frequency'],
            },
            reportParams,
        },
        cronText,
    };

    const response = await http.post(
        `/api/prweb/process/schedule`,
        JSON.stringify({
            pfSystemCode,
            param,
        })
    );
    return response.data;
}

async function scheduleDistributionProcess(
    selectedFilters,
    reportParams,
    cronText
) {
    const fetchParam = { selectedFilters };

    let param = {
        uiRequest: {
            action: 'generateReport',
            state: fetchParam,
            reportParams,
        },
        cronText,
    };

    const response = await http.post(
        `/api/prweb/process/schedule`,
        JSON.stringify({
            pfSystemCode: WEB_PROCESS_FLOWS.DIST_ROS,
            param,
        })
    );
    return response.data;
}

async function scheduleRangingProcess(
    filters,
    identifyOption,
    rangingStrategy,
    reportParams,
    cronText
) {
    const fetchParam = {
        selectedFilters: removeDateRangeFromFilters(filters),
        identifyOption,
        rangingStrategy,
    };

    let param = {
        uiRequest: {
            action: 'generateReport',
            state: fetchParam,
            reportParams,
        },
        cronText,
    };

    const response = await http.post(
        `/api/prweb/process/schedule`,
        JSON.stringify({
            pfSystemCode: WEB_PROCESS_FLOWS.RANGING_DIST,
            param,
        })
    );
    return response.data;
}

async function scheduleAlertsProcess(
    selectedFilters,
    trendFilters,
    reportParams,
    cronText
) {
    const fetchParam = {
        selectedFilters: selectedFilters,
        compare: trendFilters['compare'],
        frequency: trendFilters['frequency'],
    };

    let param = {
        uiRequest: {
            action: 'generateReport',
            state: fetchParam,
            reportParams,
        },
        cronText,
    };

    const response = await http.post(
        `/api/prweb/process/schedule`,
        JSON.stringify({
            pfSystemCode: WEB_PROCESS_FLOWS.ALERT_OVERVIEW,
            param,
        })
    );
    return response.data;
}

async function removeProcessFlowSchedule(processFlowScheduleControl) {
    let param = {
        control: processFlowScheduleControl,
    };

    const response = await http.delete(
        `/api/prweb/process/schedule`,
        JSON.stringify({
            param,
        })
    );
    return response.data;
}

async function updateProcessFlowSchedule(
    control,
    cronText,
    updatedJopParamJson
) {
    const response = await http.put(
        `/api/prweb/process/schedule`,
        JSON.stringify({
            control: control,
            cronText: cronText,
            jobParamJson: updatedJopParamJson,
        })
    );
    return response.data;
}

async function createFiltersAlias(selectedFilters) {
    const response = await http.post(
        `/api/prweb/urlrequest/create`,
        JSON.stringify({
            payLoad: JSON.stringify(selectedFilters),
        })
    );
    return response.data;
}

async function getFiltersFromAlias(alias) {
    const response = await http.get(`/api/prweb/urlrequest/${alias}`);
    return response.data;
}

async function publishShareViewLink(body) {
    const response = await http.post(
        `/api/prweb/urlrequest/send`,
        JSON.stringify(body)
    );
    return response.data;
}

async function getFileShareData(fileShareId) {
    const response = await http.get(`/api/fileshare/${fileShareId}`);
    return response.data;
}

async function createStripeCheckoutSession() {
    const response = await http.post(
        `/api/prweb/stripe/create-checkout-session`,
        JSON.stringify({})
    );
    return response.data;
}

async function checkStripeSessionStatus(sessionId) {
    const response = await http.get(
        `/api/prweb/stripe/session-status?sessionId=${sessionId}`
    );
    return response.data;
}

async function createStripeBillingSession() {
    const response = await http.post(
        `/api/prweb/stripe/create-billing-session`,
        JSON.stringify({})
    );
    return response.data;
}