import LimitedDateRangeSelectorComponent, {
    limitedDateTextMap,
} from '../limited-date-range-selector/limited-date-range-selector.component';
import { help_box } from '../../utils/styles/tailwind-styles';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { widgetService } from '../../store/discover/widgets/widgets.services';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const Help = ({ metric, visualization, onClose }) => {
    return (
        <div className={help_box}>
            <div className="w-full flex justify-between items-center">
                <span className="text-lg text-base-content text-opacity-80 font-bold capitalize">
                    {metric} Overview
                </span>

                <span
                    className="btn btn-ghost btn-circle btn-sm justify-self-end"
                    onClick={onClose}
                >
                    <XMarkIcon className={'w-5 h-5'} />
                </span>
            </div>

            <div className="divider my-1" />

            <MathJaxContext>
                <MathJax>
                    {widgetService.getHelpByMetricAndVisualiztion(
                        metric,
                        visualization
                    )}
                </MathJax>
            </MathJaxContext>
        </div>
    );
};

export default Help;