import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../store/themes/themes.selectors';
import { checkLightTheme } from '../../utils/styles/global-styles';

const AnimatedBorder = ({ withGlow = true }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    return (
        <>
            <span
                className={`ease-in-out absolute bottom-0 left-0 h-0 w-0 border-l border-primary border-opacity-60 transition-all duration-1000 delay-200 group-hover:h-full ${
                    withGlow
                        ? checkLightTheme(daisyTheme)
                            ? ''
                            : 'shadow-[0px_0px_10px_0.25px_rgba(0,0,0,1)] shadow-primary'
                        : ''
                }`}
            ></span>
            <span
                className={`ease-in-out absolute left-0 top-0 h-0 w-0 border-t border-primary border-opacity-60 transition-all duration-1000 delay-200 group-hover:w-full  
                ${
                    withGlow
                        ? checkLightTheme(daisyTheme)
                            ? ''
                            : 'shadow-[0px_0px_10px_0.25px_rgba(0,0,0,1)] shadow-primary'
                        : ''
                }`}
            ></span>
            <span
                className={`ease-in-out absolute right-0 top-0 h-0 w-0 border-r border-primary border-opacity-60 transition-all duration-1000 delay-200 group-hover:h-full ${
                    withGlow
                        ? checkLightTheme(daisyTheme)
                            ? ''
                            : 'shadow-[0px_0px_10px_0.25px_rgba(0,0,0,1)] shadow-primary'
                        : ''
                }`}
            ></span>
            <span
                className={`ease-in-out absolute bottom-0 right-0 h-0 w-0 border-b border-primary border-opacity-60 transition-all duration-1000 delay-200 group-hover:w-full ${
                    withGlow
                        ? checkLightTheme(daisyTheme)
                            ? ''
                            : 'shadow-[0px_0px_10px_0.25px_rgba(0,0,0,1)] shadow-primary'
                        : ''
                }`}
            ></span>
        </>
    );
};

export default AnimatedBorder;
