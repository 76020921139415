import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import { getWindowSize } from '../../base/filters/trend-filters/trend-filters.component';

import {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';

import {
    backdrop,
    enlarged_chart,
    regular_chart,
} from '../../../utils/styles/tailwind-styles';

ChartJS.register(
    Filler,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const scatterChartOptions = {
    indexAxis: 'x',
    type: 'scatter',
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 30,
                pointStyle: 'circle',
                usePointStyle: true,
            },
        },
        title: {
            display: false,
        },
        tooltip: {
            callbacks: {
                title: function (ctx) {
                    const data = ctx[0].raw;
                    return data.descr;
                },
                label: function (ctx) {
                    const data = ctx.dataset.data[ctx.dataIndex];

                    return `[x: ${data.x} | y: ${data.y}]`;
                },
                footer: function (ctx) {
                    const data = ctx[0].raw;
                    return data.note;
                },
            },
        },
    },
    scales: {
        x: {
            grid: {
                display: true,
                drawBorder: false,
                color: GLOBAL_COLOURS['dark']['bg-page'],
            },
        },
        y: {
            grid: {
                display: true,
                drawBorder: false,
                color: GLOBAL_COLOURS['dark']['bg-page'],
            },
        },
    },
    interaction: {
        intersect: false,
        axis: 'xy',
        mode: 'nearest',
    },
};

const ScatterChartComponent = ({ data, title, handleSkuClick }) => {
    const [isEnlarged, setIsEnlarged] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const themeMode = useSelector(selectThemeMode);
    const daisyTheme = useSelector(selectDaisyTheme);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const toggleEnlarged = () => {
        setIsEnlarged(!isEnlarged);
        if (!isEnlarged) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    if (handleSkuClick) {
        scatterChartOptions['onClick'] = function (e, dataset) {
            let ci = e.chart,
                metaSets = [];
            for (let i = 0; i < ci.data.datasets.length; i++) {
                metaSets.push(ci.getDatasetMeta(i));
            }

            const skuDescr =
                metaSets[dataset[0]['datasetIndex']]['data'][
                    dataset[0]['index']
                ]['$context']['raw']['descr'];
            handleSkuClick(skuDescr);
        };
    }

    let options = { ...scatterChartOptions };
    if (windowSize['innerWidth'] < 1000) {
        options = {
            ...scatterChartOptions,
            plugins: {
                ...scatterChartOptions['plugins'],
                legend: {
                    display: false,
                },
            },
            scales: {
                y: {
                    ticks: {
                        font: {
                            size: 8,
                        },
                    },
                },
                x: {
                    ticks: {
                        font: {
                            size: 8,
                        },
                    },
                },
            },
        };
    }
    options = {
        ...options,
        plugins: {
            ...options['plugins'],
            legend: {
                ...options['plugins']['legend'],
                labels: {
                    ...options['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
        scales: {
            ...options['scales'],
            x: {
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y: {
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
    };

    return (
        <>
            {isEnlarged ? (
                <div className={backdrop}>
                    <div className={enlarged_chart}>
                        <div className="flex justify-between items-center w-full pb-8 px-12">
                            <h3 className="text-base text-base-content text-opacity-80">
                                {title}
                            </h3>
                            <span className="btn btn-sm btn-ghost btn-circle">
                                <ArrowsPointingInIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </span>
                        </div>

                        <Scatter
                            style={{
                                maxHeight: '85%',
                            }}
                            data={data}
                            options={options}
                        />
                    </div>
                </div>
            ) : (
                <div className={regular_chart}>
                    <div className="flex justify-between items-center w-full pb-8 px-12">
                        <h3 className="text-base text-base-content text-opacity-80">
                            {title}
                        </h3>
                        {windowSize['innerWidth'] > 1366 ? (
                            <span className="btn btn-sm btn-ghost btn-circle">
                                <ArrowsPointingOutIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </span>
                        ) : (
                            ''
                        )}
                    </div>

                    <Scatter
                        className="w-fit h-fit"
                        data={data}
                        options={options}
                    />
                </div>
            )}
        </>
    );
};

export default ScatterChartComponent;