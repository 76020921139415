import FooterComponent from '../../../components/footer-component/footer.component';
import PublicBackdrop from '../../../components/public-backdrop/public-backdrop.component';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { authenticationService } from '../../../store/auth/authentication.services';

const PasswordResetPage = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const [newPasswordVisible, setNewPasswordVisible] = useState(false);

    const [newPasswordValid, setNewPasswordValid] = useState(false);

    const handlePasswordChange = (event) => {
        setNewPassword(event.currentTarget.value);
        if (event.currentTarget.value.length >= 8) {
            setNewPasswordValid(true);
        } else {
            setNewPasswordValid(false);
        }
    };

    const onResetHandler = async (event) => {
        event.preventDefault();
        const token = searchParams.get('token');

        try {
            setIsLoading(true);
            const response = await authenticationService.resetPassword(
                token,
                newPassword
            );

            setIsLoading(false);

            if (response.status >= 200 && response.status < 300) {
                createNotification(
                    'Password successfully reset.',
                    NOTIFICATION_TYPES.SUCCESS
                );

                navigate(`../login`, { replace: true });
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar relative">
                <div
                    className={`card flex flex-col py-8 px-12 shadow-lg bg-base-300 bg-opacity-95 w-full max-w-xl justify-center items-center min-h-64 z-10 gap-2`}
                >
                    <div className="w-full h-full flex justify-evenly items-start flex-col">
                        <span className="w-full h-full flex justify-between items-center">
                            <label
                                className="text-sm text-start p-2 text-base-content text-opacity-80"
                                htmlFor="new-password"
                            >
                                New Password
                            </label>
                            {newPasswordVisible ? (
                                <span
                                    className="btn btn-circle btn-ghost btn-sm"
                                    onClick={() =>
                                        setNewPasswordVisible(
                                            !newPasswordVisible
                                        )
                                    }
                                >
                                    <EyeSlashIcon className="w-4 h-4" />
                                </span>
                            ) : (
                                <span
                                    className="btn btn-circle btn-ghost btn-sm"
                                    onClick={() =>
                                        setNewPasswordVisible(
                                            !newPasswordVisible
                                        )
                                    }
                                >
                                    <EyeIcon className="w-4 h-4" />
                                </span>
                            )}
                        </span>

                        <input
                            className={`input input-bordered w-full ${
                                newPasswordValid && newPassword !== ''
                                    ? 'input-success'
                                    : 'input-error'
                            }`}
                            name="password"
                            id="password"
                            type={newPasswordVisible ? 'text' : 'password'}
                            required={true}
                            value={newPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>

                    <div className="divider my-2" />

                    <div className="w-full flex justify-end items-center">
                        <button
                            className={`btn btn-outline normal-case ${
                                isLoading && 'loading btn-disabled'
                            }`}
                            type="submit"
                            disabled={isLoading}
                            onClick={onResetHandler}
                        >
                            Reset
                        </button>
                    </div>
                </div>

                <PublicBackdrop />
            </div>

            <FooterComponent />
        </>
    );
};

export default PasswordResetPage;