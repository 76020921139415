import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ArrowRightIcon,
    BanknotesIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    FolderArrowDownIcon,
    KeyIcon,
    QuestionMarkCircleIcon,
    UserGroupIcon,
    NoSymbolIcon
} from '@heroicons/react/24/solid';

import { selectSidebarMode } from '../../store/navigation/navigation.selectors';
import { selectUser } from '../../store/auth/authentication.selectors';
import { clearProductType } from '../../store/product/product.actions';

import { authenticationService } from '../../store/auth/authentication.services';
import {
    isM2Admin,
    isCAdmin,
    isBillingUser,
    isM2Support,
    isCOwner,
} from '../../utils/auth.util';
import { clearDiscoverSearchData } from '../../store/discover/search/discover-search.actions';
import { Link, useNavigate } from 'react-router-dom';
import ThemeComponent from '../theme-component/theme.component';
import { useTour } from '@reactour/tour';
import { REACT_TOUR_CLASS_NAMES } from '../../utils/react-tour.util';
import { selectProductType } from '../../store/product/product.selectors';
import { PRODUCT_TYPES } from '../../store/product/product.types';
import { apiService } from '../../utils/engine/api.service';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../utils/notification.util';

const LogoutMenu = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setIsOpen } = useTour();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const user = useSelector(selectUser);
    const productType = useSelector(selectProductType);
    const sidebarMode = useSelector(selectSidebarMode);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (
                isDropdownOpen &&
                ref.current &&
                !ref.current.contains(e.target)
            ) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isDropdownOpen]);

    const toggleIsOpen = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const logOutUserHelper = () => {
        dispatch(clearProductType());
        dispatch(clearDiscoverSearchData());
        dispatch(authenticationService.logout());
    };

    return (
        <div
            ref={ref}
            className={`wrapper relative ${
                sidebarMode && 'w-full justify-start'
            }`}
        >
            <div
                className={`flex justify-start items-center cursor-pointer text-base btn btn-ghost normal-case hover:text-primary ${
                    !sidebarMode && 'px-4'
                } ${REACT_TOUR_CLASS_NAMES.THEME_1}`}
                id={REACT_TOUR_CLASS_NAMES.THEME_1}
                onClick={toggleIsOpen}
            >
                {isDropdownOpen ? (
                    sidebarMode ? (
                        <ChevronRightIcon className="dropdown-icon" />
                    ) : (
                        <ChevronUpIcon className="dropdown-icon" />
                    )
                ) : sidebarMode ? (
                    <ChevronLeftIcon className="dropdown-icon" />
                ) : (
                    <ChevronDownIcon className="dropdown-icon" />
                )}

                <span
                    className={`${
                        !sidebarMode && 'ml-2'
                    } flex flex-wrap justify-between items-center gap-2`}
                >
                    <span className="font-normal">{user.name}</span>
                    <p className="text-primary font-bold">
                        {user.custaccountName}
                    </p>
                </span>
            </div>

            {isDropdownOpen ? (
                <div
                    className={`z-50 drop-shadow menu bg-base-300 rounded-box absolute w-full p-2 top-full right-0`}
                    style={
                        sidebarMode
                            ? {
                                  top: 0,
                                  right: `100%`,
                                  minWidth: '12rem',
                              }
                            : {
                                  top: '100%',
                                  right: 0,
                                  minWidth: '12rem',
                              }
                    }
                >
                    <div className="normal-case flex w-full items-center">
                        <ThemeComponent />
                    </div>

                    <Link
                        className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                            sidebarMode
                                ? 'flex justify-start items-center w-full h-full'
                                : 'w-full h-full'
                        }`}
                        to={
                            productType === PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER
                                ? `/discover/extracts`
                                : '/base/manage-reports'
                        }
                    >
                        <FolderArrowDownIcon className="h-5 w-5" />
                        <span className="ml-2">
                            {productType ===
                            PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER
                                ? 'Data Extracts'
                                : 'Manage Reports'}
                        </span>
                    </Link>

                    {user &&
                        (isM2Admin(user) ||
                            isM2Support(user) ||
                            isCOwner(user) ||
                            isCAdmin(user)) && (
                            <Link
                                className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                                    sidebarMode
                                        ? 'flex justify-start items-center w-full h-full'
                                        : 'w-full h-full'
                                }`}
                                to={
                                    productType ===
                                    PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER
                                        ? `/discover/team`
                                        : '/base/manage-users'
                                }
                            >
                                <UserGroupIcon className="h-5 w-5" />
                                <span className="ml-2">Manage Team</span>
                            </Link>
                        )}

                    {productType === PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER && (
                        <span
                            className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                                sidebarMode
                                    ? 'flex justify-start items-center w-full h-full'
                                    : 'w-full h-full'
                            }`}
                            onClick={() => {
                                // const cookies = new Cookies(null, { path: '/' });
                                setIsDropdownOpen(false);
                                navigate('/discover/dashboard');
                                setIsOpen(true);
                            }}
                        >
                            <QuestionMarkCircleIcon className="h-5 w-5" />
                            <span className="ml-2">Help</span>
                        </span>
                    )}

                    {user && 
                        (isM2Admin(user) ||
                            isM2Support(user) ||
                            isCOwner(user) ||
                            isCAdmin(user)) && productType === PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER && (
                        <Link
                            className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                                sidebarMode
                                    ? 'flex justify-start items-center w-full h-full'
                                    : 'w-full h-full'
                            }`}
                            to={`/discover/exclusions`}
                        >
                            <NoSymbolIcon className="w-5 h-5"/>   
                            <span className="ml-2">Exclusions</span>
                        </Link>
                    )}

                    <div className="divider my-2" />

                    <Link
                        className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                            sidebarMode
                                ? 'flex justify-start items-center w-full h-full'
                                : 'w-full h-full'
                        }`}
                        to="password-update"
                    >
                        <KeyIcon className="h-5 w-5" />
                        <span className="ml-2">Update Password</span>
                    </Link>

                    {user &&
                        isBillingUser(user) &&
                        productType ===
                            PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER && (
                            <>
                                <Link
                                    className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                                        sidebarMode
                                            ? 'flex justify-start items-center w-full h-full'
                                            : 'w-full h-full'
                                    }`}
                                    to={`discover/billing`}
                                >
                                    <BanknotesIcon className="h-5 w-5" />
                                    <span className="ml-2">Manage Billing</span>
                                </Link>
                            </>
                        )}

                    <Link
                        className={`btn btn-ghost text-xs normal-case font-normal hover:text-primary justify-start ${
                            sidebarMode
                                ? 'flex justify-start items-center w-full h-full'
                                : 'w-full h-full'
                        }`}
                        onClick={logOutUserHelper}
                        to="/"
                    >
                        <ArrowRightIcon className="h-5 w-5" />
                        <span className="ml-2">Logout</span>
                    </Link>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default LogoutMenu;