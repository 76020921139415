import WidgetFooter from './widget-footer.component';
import WidgetHeader from './widget-header.component';

import {
    DISCOVER_GRAPH_TYPES,
    discoverHomeService,
} from '../../../store/discover/home/discover-home.services';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { muted_border } from '../../../utils/styles/tailwind-styles';

import { createNotification } from '../../../utils/notification.util';

import {
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
    DISCOVER_WIDGET_SORT_TYPES,
} from '../../../store/discover/widgets/widgets.types';
import {
    selectDiscoverHiddenWidgets,
    selectDiscoverWatchlistPageWidgets,
} from '../../../store/discover/widgets/widgets.selectors';
import {
    setHiddenWidgets,
    setWatchlistPageWidgets,
} from '../../../store/discover/widgets/widgets.actions';
import { widgetService } from '../../../store/discover/widgets/widgets.services';

import Help from '../help.component';
import AnimatedBorder from '../../animated-border/animated-border.component';
import { selectDiscoverSearch } from '../../../store/discover/search/discover-search.selectors';
import { objectService } from '../../../utils/object.util';

const Widget = ({
    metric,
    visualization,
    sortDirection,

    perspective,
    aggregationMetric,

    selectedDate,
    onDateChange,

    onRemoveWidget,
    onMoveUp,

    size,

    id,

    daisyTheme,
    className,
    excludeFooter = false,
    excludeHeader = false,
    refresher = 0,
}) => {
    const discoverSearch = useSelector(selectDiscoverSearch);
    const watchlistWidgets = useSelector(selectDiscoverWatchlistPageWidgets);
    const hiddenWidgets = useSelector(selectDiscoverHiddenWidgets);

    const dispatch = useDispatch();

    const [data, setData] = useState({});

    const [favourite, setFavourite] = useState(
        Object.keys(watchlistWidgets).includes(
            widgetService.createWatchlistWidgetRuleset(
                metric,
                visualization,
                sortDirection,
                perspective,
                aggregationMetric
            )
        )
    );

    const [hidden, setHidden] = useState(
        Object.keys(hiddenWidgets).includes(
            widgetService.createWidgetRuleset(
                metric,
                visualization,
                sortDirection,
                perspective,
                aggregationMetric
            )
        )
    );
    const [limit, setLimit] = useState(5);
    const [sortType, setSortType] = useState(DISCOVER_WIDGET_SORT_TYPES.VALUE);

    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        setData({});
        if (
            objectService.checkObjectPopulated(discoverSearch) &&
            objectService.checkObjectPopulated(discoverSearch['customer'])
        ) {
            /*
            NB: This is behind a check to ensure we are not making calls to every web discover process until commonly called fucntions are pre-cached by the
            web_retrieve_supplier_search_data process.
             */
            fetchData();
        }
    }, [limit, sortType, discoverSearch, refresher]);

    useEffect(() => {
        setFavourite(
            Object.keys(watchlistWidgets).includes(
                widgetService.createWatchlistWidgetRuleset(
                    metric,
                    visualization,
                    sortDirection,
                    perspective,
                    aggregationMetric
                )
            )
        );
    }, [watchlistWidgets]);

    useEffect(() => {
        setHidden(
            Object.keys(hiddenWidgets).includes(
                widgetService.createWidgetRuleset(
                    metric,
                    visualization,
                    sortDirection,
                    perspective,
                    aggregationMetric
                )
            )
        );
    }, [hiddenWidgets]);

    const handleToggleWatchlist = () => {
        const widgetData = {
            metric,
            visualization,
            sortDirection:
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND
                    ? null
                    : sortDirection,
            aggregationMetric,
            size: size,
            perspective,
        };

        const widgetId = widgetService.createWatchlistWidgetRuleset(
            widgetData.metric,
            widgetData.visualization,
            widgetData.sortDirection,
            perspective,
            widgetData.aggregationMetric
        );

        let newWatchlistWidgets = {
            ...watchlistWidgets,
        };

        if (Object.keys(watchlistWidgets).includes(widgetId)) {
            delete newWatchlistWidgets[widgetId];
            dispatch(setWatchlistPageWidgets(newWatchlistWidgets));
            createNotification('Removed from Watchlist.');
        } else {
            newWatchlistWidgets[widgetId] = widgetData;
            dispatch(setWatchlistPageWidgets(newWatchlistWidgets));
            createNotification('Added to watchlist');
        }
    };

    const handleToggleHidden = () => {
        const widgetData = {
            metric,
            visualization,
            sortDirection:
                visualization === DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND
                    ? null
                    : sortDirection,
            aggregationMetric,
            size: 'full',
        };

        const widgetId = widgetService.createWidgetRuleset(
            widgetData.metric,
            widgetData.visualization,
            widgetData.sortDirection,
            perspective,
            widgetData.aggregationMetric
        );

        let newHiddenWidgets = {
            ...hiddenWidgets,
        };

        if (Object.keys(hiddenWidgets).includes(widgetId)) {
            delete newHiddenWidgets[widgetId];
            dispatch(setHiddenWidgets(newHiddenWidgets));
        } else {
            newHiddenWidgets[widgetId] = widgetData;
            dispatch(setHiddenWidgets(newHiddenWidgets));
        }
    };

    const handleChangeSortType = () => {
        // Hacky until we find a reason to clean it up...
        if (sortType === DISCOVER_WIDGET_SORT_TYPES.VALUE)
            setSortType(DISCOVER_WIDGET_SORT_TYPES.MARGIN);
        else setSortType(DISCOVER_WIDGET_SORT_TYPES.VALUE);
    };

    const fetchData = async () => {
        switch (metric) {
            case DISCOVER_WIDGET_DATA_METRICS.SALES:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND:
                        const salesTrend =
                            await discoverHomeService.getDiscoverSalesTrend(
                                DISCOVER_GRAPH_TYPES.TREND,
                                perspective,
                                aggregationMetric
                            );

                        setData(salesTrend);
                        break;

                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING:
                        const salesRanking =
                            await discoverHomeService.getDiscoverSalesRanking(
                                DISCOVER_GRAPH_TYPES.RANKING,
                                perspective,
                                sortDirection,
                                aggregationMetric,
                                limit
                            );

                        setData(salesRanking);
                        break;

                    default:
                        break;
                }
                break;

            case DISCOVER_WIDGET_DATA_METRICS.RETURNS:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND:
                        const returnsTrend =
                            await discoverHomeService.getDiscoverReturnsTrend(
                                DISCOVER_GRAPH_TYPES.TREND,
                                perspective,
                                aggregationMetric
                            );

                        setData(returnsTrend);
                        break;

                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING:
                        const returnsRanking =
                            await discoverHomeService.getDiscoverReturnsRanking(
                                DISCOVER_GRAPH_TYPES.RANKING,
                                perspective,
                                sortDirection,
                                aggregationMetric,
                                limit
                            );

                        setData(returnsRanking);
                        break;

                    default:
                        break;
                }
                break;

            case DISCOVER_WIDGET_DATA_METRICS.DISCOUNT:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND:
                        const discountsTrend =
                            await discoverHomeService.getDiscoverDiscountsTrend(
                                DISCOVER_GRAPH_TYPES.TREND,
                                perspective,
                                aggregationMetric
                            );
                        setData(discountsTrend);
                        break;

                    default:
                        break;
                }
                break;

            case DISCOVER_WIDGET_DATA_METRICS.PROFIT:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND:
                        const profitTrend =
                            await discoverHomeService.getDiscoverProfitTrend(
                                DISCOVER_GRAPH_TYPES.TREND,
                                perspective,
                                aggregationMetric
                            );

                        setData(profitTrend);
                        break;

                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING:
                        const profitRanking =
                            await discoverHomeService.getDiscoverProfitRanking(
                                DISCOVER_GRAPH_TYPES.RANKING,
                                perspective,
                                sortDirection,
                                aggregationMetric,
                                limit,
                                sortType
                            );
                        setData(profitRanking);
                        break;

                    default:
                        break;
                }
                break;

            case DISCOVER_WIDGET_DATA_METRICS.UNUSED_CREDIT:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND:
                        const unusedCreditTrend =
                            await discoverHomeService.getDiscoverUnusedCreditTrend(
                                DISCOVER_GRAPH_TYPES.TREND,
                                perspective,
                                sortDirection,
                                aggregationMetric
                            );
                        setData(unusedCreditTrend);
                        break;

                    default:
                        break;
                }
                break;

            case DISCOVER_WIDGET_DATA_METRICS.CHAOS:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.SINGULAR:
                        const chaosSingularData =
                            await discoverHomeService.getDiscoverChaos(
                                DISCOVER_GRAPH_TYPES.SINGULAR,
                                perspective
                            );
                        setData(chaosSingularData);
                        break;

                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING:
                        const chaosRankingData =
                            await discoverHomeService.getDiscoverChaosRanking(
                                DISCOVER_GRAPH_TYPES.RANKING,
                                aggregationMetric,
                                sortDirection,
                                limit
                            );
                        setData(chaosRankingData);
                        break;
                    default:
                        break;
                }
                break;

            case DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY:
                switch (visualization) {
                    case DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING:
                        const opportunityRanking =
                            await discoverHomeService.getDiscoverOpportunityRanking(
                                DISCOVER_GRAPH_TYPES.RANKING,
                                perspective,
                                sortDirection,
                                aggregationMetric,
                                limit
                            );

                        setData(opportunityRanking);
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    };

    return (
        <div
            className={`${muted_border} ${className} ${
                hidden ? 'h-fit py-2 px-4' : 'no-scrollbar'
            } group`}
            id={id}
        >
            <AnimatedBorder />

            {!excludeHeader && (
                <WidgetHeader
                    metric={metric}
                    visualization={visualization}
                    sortDirection={sortDirection}
                    perspective={perspective}
                    aggregationMetric={aggregationMetric}
                    onToggleHidden={handleToggleHidden}
                    hidden={hidden}
                    onFavourite={handleToggleWatchlist}
                    favourite={favourite}
                    onRemoveWidget={() =>
                        onRemoveWidget(
                            metric,
                            visualization,
                            sortDirection,
                            aggregationMetric
                        )
                    }
                    toggleShowHelp={() => setShowHelp(!showHelp)}
                    id={id}
                    className="col-span-full"
                    limit={limit}
                    onLimitChange={(newLimit) => setLimit(newLimit)}
                    shouldShowLimit={
                        metric !== DISCOVER_WIDGET_DATA_METRICS.CHAOS
                    }
                    sortType={
                        metric === DISCOVER_WIDGET_DATA_METRICS.PROFIT &&
                        visualization ===
                            DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
                            ? sortType
                            : null
                    }
                    onChangeSortType={
                        metric === DISCOVER_WIDGET_DATA_METRICS.PROFIT &&
                        visualization ===
                            DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING
                            ? handleChangeSortType
                            : null
                    }
                />
            )}

            {!hidden &&
                metric &&
                visualization &&
                widgetService.getComponentByMetricAndVisualization(
                    metric,
                    visualization,
                    sortDirection,

                    perspective,
                    aggregationMetric,

                    data,
                    selectedDate,
                    onDateChange,
                    daisyTheme,

                    async () => {
                        setData({});
                        await fetchData();
                    }
                )}

            <WidgetFooter
                metric={metric}
                perspective={perspective}
                selectedDate={selectedDate}
                onDateChange={onDateChange}
                toggleShowHelp={() => setShowHelp(!showHelp)}
                onRemoveWidget={() =>
                    onRemoveWidget(
                        metric,
                        visualization,
                        sortDirection,
                        aggregationMetric
                    )
                }
                onMoveUp={() =>
                    onMoveUp(
                        metric,
                        visualization,
                        sortDirection,
                        aggregationMetric
                    )
                }
                id={id}
                className={'col-span-full h-full'}
                hidden={hidden}
            />

            {showHelp && (
                <Help
                    metric={metric}
                    visualization={visualization}
                    onClose={() => setShowHelp(false)}
                />
            )}
        </div>
    );
};

export default Widget;