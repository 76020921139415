import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';

import { selectUserToken } from '../../../../store/auth/authentication.selectors';
import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';

import FiltersContainer from '../../../../components/base/filters/filters-container.component';
import StockCoverFilters from '../../../../components/base/filters/stock-cover-filters/stock-cover-filters.component';
import PieChartComponent from '../../../../components/charts/pie-chart-component/pie-chart.component';
import LineChartComponent from '../../../../components/charts/line-chart-component/line-chart.component';
import SkuTable from '../../../../components/base/stock-cover/sku-table.component';
import SkuDetailsComponent from '../../../../components/sku-details-component/sku-details.component';
import ShareViewComponent, {
    onShareViewClick,
} from '../../../../components/base/share-view-component/share-view.component';
import NoData from '../../../../components/no-data/no-data.component';

import { stockCoverService } from '../../../../store/base/filters/stock-cover/stock-cover.services';
import { STOCK_COVER_TYPES } from '../../../../store/base/filters/stock-cover/stock-cover.types';
import { getWindowSize } from '../../../../components/base/filters/trend-filters/trend-filters.component';

import PageLoadingComponent from '../../../../components/loading-component/page-loading-component';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/20/solid';

import {
    base_grid,
    base_page,
    base_subnavigation_bar,
    base_widget_full,
    base_widget_half,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';

const StockCoverPage = () => {
    const daisyTheme = useSelector(selectDaisyTheme);

    const userToken = useSelector(selectUserToken);
    const selectedFilters = useSelector(selectFiltersSelected);

    const [identifyOption, setIdentifyOption] = useState(
        STOCK_COVER_TYPES.HIGHEST_DAYS_COVER.value
    );
    const [isLoading, setIsLoading] = useState(false);

    const [daysCover, setDaysCover] = useState(null);
    const [daysSinceSale, setDaysSinceSale] = useState(null);
    const [focusSkuGraph, setFocusSkuGraph] = useState(null);
    const [focusSkuGraphPlottable, setFocusSkuGraphPlottable] = useState(null);

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [dropdownMode, setDropdownMode] = useState(
        windowSize['innerWidth'] <= 1200
    );

    const [perSku, setPerSku] = useState(null);

    const [focusSku, setFocusSku] = useState(null);
    const [focusSkuVerbose, setFocusSkuVerbose] = useState(null);

    const [stockFiltersVisible, setStockFiltersVisible] = useState(false);

    const onStockCoverFilterChange = (event) => {
        setIdentifyOption(event.value);
    };

    const handleSkuClick = (event) => {
        clearFocusSku();
        setFocusSku(event);
    };

    const clearFocusSku = () => {
        setFocusSku(null);
        setFocusSkuVerbose(null);
        setFocusSkuGraph(null);
    };

    const clearData = () => {
        clearFocusSku();
        setDaysCover(null);
        setDaysSinceSale(null);
        setPerSku(null);
    };

    const onShare = async (recipientsSelected, additionalMessage) => {
        setIsLoading(true);
        try {
            await onShareViewClick(
                userToken,
                selectedFilters,
                null,
                recipientsSelected,
                additionalMessage
            );
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchStockCoverDataHelper = async () => {
        setIsLoading(true);
        try {
            const stockCoverData = await stockCoverService.loadStockCoverData(
                selectedFilters,
                identifyOption,
                focusSku ? focusSku['supplSku'] : null
            );
            if ('focusSkuData' in stockCoverData) {
                setFocusSkuVerbose(stockCoverData['focusSkuData']);
                setFocusSkuGraph(stockCoverData['focusSkuGraph']);
                setFocusSkuGraphPlottable(
                    stockCoverService.convertFocusDataToPlottable(
                        stockCoverData['focusSkuGraph'],
                        daisyTheme
                    )
                );
            } else {
                setDaysCover(
                    stockCoverService.convertCoverDataToPlottable(
                        stockCoverData['daysCoverHistogram'],
                        daisyTheme
                    )
                );
                setDaysSinceSale(
                    stockCoverService.convertCoverDataToPlottable(
                        stockCoverData['daysSinceSaleHistogram'],
                        daisyTheme
                    )
                );
            }
            setPerSku(stockCoverData['perSku']);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!selectedFilters['dists']) {
            clearData();
        } else if (
            !selectedFilters['distpoints'] &&
            !selectedFilters['storelists'] &&
            !selectedFilters['storetypes']
        ) {
            clearData();
        } else {
            fetchStockCoverDataHelper();
        }
    }, [selectedFilters, identifyOption, focusSku]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());

            if (getWindowSize()['innerWidth'] < 1200) {
                setDropdownMode(true);
            } else {
                setDropdownMode(false);
            }
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (focusSkuGraph !== null) {
            setFocusSkuGraphPlottable(
                stockCoverService.convertFocusDataToPlottable(
                    focusSkuGraph,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);
    return (
        <>
            <div className={base_subnavigation_bar}>
                <div className="flex w-full h-full justify-between items-center max-w-8xl">
                    <span className={'flex items-center gap-1'}>
                        <a
                            className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                            href={'/base/stock-cover'}
                        >
                            Stock Cover
                        </a>

                        <ShareViewComponent onClick={onShare} />
                    </span>

                    <span className="flex justify-between items-center">
                        <StockCoverFilters
                            onChange={onStockCoverFilterChange}
                            visible={stockFiltersVisible}
                            toggleOpen={() => {
                                setStockFiltersVisible(!stockFiltersVisible);
                            }}
                        />
                        {stockFiltersVisible ? (
                            !dropdownMode ? (
                                <span className="btn btn-circle btn-ghost btn-sm">
                                    <ChevronUpIcon
                                        className="w-5 h-5"
                                        onClick={() => {
                                            setStockFiltersVisible(false);
                                        }}
                                    />
                                </span>
                            ) : (
                                <></>
                            )
                        ) : !dropdownMode ? (
                            <span className="btn btn-circle btn-ghost btn-sm">
                                <ChevronDownIcon
                                    className="w-5 h-5"
                                    onClick={() => {
                                        setStockFiltersVisible(true);
                                    }}
                                />
                            </span>
                        ) : (
                            <></>
                        )}
                    </span>
                </div>
            </div>

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <FiltersContainer className={'col-span-2'} />

                    {selectedFilters['dists'] ? (
                        isLoading === true ? (
                            <PageLoadingComponent />
                        ) : !selectedFilters['distpoints'] &&
                          !selectedFilters['storelists'] &&
                          !selectedFilters['storetypes'] ? (
                            <div
                                className={`${base_widget_full} ${muted_border}`}
                            >
                                <div
                                    className={`w-full flex items-center justify-center gap-2 p-6`}
                                >
                                    <ExclamationCircleIcon
                                        className={'w-5 h-5 text-warning'}
                                    />

                                    <h2 className="text-warning text-sm font-bold">
                                        Please filter by at least one{' '}
                                        <strong>store</strong>, or select one of
                                        your pre-populated{' '}
                                        <strong>store lists</strong>.
                                    </h2>
                                </div>
                            </div>
                        ) : (
                            <>
                                {focusSkuVerbose &&
                                focusSkuGraphPlottable !== null ? (
                                    <div
                                        className={`${base_widget_half} ${muted_border}`}
                                    >
                                        <LineChartComponent
                                            title={focusSku['supplSkuDescr']}
                                            data={focusSkuGraphPlottable}
                                        />
                                    </div>
                                ) : (
                                    daysCover &&
                                    daysSinceSale && (
                                        <>
                                            <div
                                                className={`${base_widget_half} ${muted_border}`}
                                            >
                                                <PieChartComponent
                                                    title="Days Cover"
                                                    data={daysCover}
                                                />
                                            </div>

                                            <div
                                                className={`${base_widget_half} ${muted_border}`}
                                            >
                                                <PieChartComponent
                                                    title="Days Since Sale"
                                                    data={daysSinceSale}
                                                />
                                            </div>
                                        </>
                                    )
                                )}

                                {focusSkuVerbose && (
                                    <div
                                        className={`${base_widget_half} ${muted_border}`}
                                    >
                                        <SkuDetailsComponent
                                            title={'Details'}
                                            skuDetails={focusSkuVerbose}
                                            className="w-full"
                                            onClear={clearFocusSku}
                                        />
                                    </div>
                                )}

                                {perSku === null ? (
                                    <NoData className="col-span-full" />
                                ) : (
                                    <div
                                        className={`${base_widget_full} ${muted_border}`}
                                    >
                                        <div className="flex items-start justify-center w-full py-4">
                                            <SkuTable
                                                skuData={perSku}
                                                handleSkuClick={handleSkuClick}
                                                focusSku={focusSku}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    ) : (
                        <div className={`${base_widget_full} ${muted_border}`}>
                            <div
                                className={`w-full flex items-center justify-center gap-2 p-6`}
                            >
                                <ExclamationCircleIcon
                                    className={'w-5 h-5 text-warning'}
                                />

                                <h2 className="text-warning text-sm font-bold">
                                    Please select a retailer to use this tab.
                                </h2>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default StockCoverPage;