let opsHost;

const hostname = window && window.location && window.location.hostname;
if (
    hostname === 'app.profitrocket.io' ||
    hostname === 'www.profitrocket.io' ||
    hostname === 'profitrocket.io'
) {
    opsHost = 'https://ops-za.profitrocket.io';
} else if (hostname === 'qa.profitrocket.io') {
    opsHost = 'https://dev.profitrocket.io';
} else {
    // opsHost = 'https://ops-za.profitrocket.io';
    opsHost = 'http://localhost:8080';
}

export const OPS_WS = `${opsHost}/prweb-ws`;
export const OPS_HOSTNAME = opsHost;

export const TOPICS = {
    ENGINE_INIT: 'engineInit',

    ENGINE_STATUS: 'engineStatus',
    ACTIVE_JOBS: 'activeJobs',
    QUEUES: 'queues',
    REPO_STATUS: 'repoStatus',
    LOAD_SUMMARY: 'loadSummary',
    STORAGE_VOLUMES: 'storageVolumes',
    SETTINGS: 'settings',

    MSG_SUCCESS: 'msg-success',
    MSG_ERROR: 'msg-error',
    BACKLOG_LIST: 'backlog-list',

    FAILED_JOBS: 'failedJobs',

    FILE_SHARE: 'file-share',
    TOAST: 'toast',
};