import AdminSkeleton from './admin-skeleton.component';
import { objectService } from '../../../utils/object.util';
import { navigationService } from '../../../utils/navigation.util';
import { detail_sub_heading } from '../../../utils/styles/tailwind-styles';

class Product {
    code;
    descr;

    active;

    category;
    categoryDescr;

    fundamentalUom;
    uom;

    materialCost = 0;
    labourCost = 0;
    fixedOverhead = 0;
    variableCost = 0;

    taxCode;

    warehouseToUse;
    supplier;

    constructor(detail) {
        if (objectService.checkObjectPopulated(detail)) {
            this.code = detail.code;
            this.descr = detail.code;

            if ('active' in detail) this.active = detail.active;

            if ('category' in detail) this.category = detail.category;
            if ('categoryDescr' in detail)
                this.categoryDescr = detail.categoryDescr;

            if ('fundamentalUom' in detail)
                this.fundamentalUom = detail.fundamentalUom;
            if ('uom' in detail) this.uom = detail.uom;

            if ('materialCost' in detail)
                this.materialCost = detail.materialCost;
            if ('labourCost' in detail) this.labourCost = detail.labourCost;
            if ('fixedOverhead' in detail)
                this.fixedOverhead = detail.fixedOverhead;
            if ('variableCost' in detail)
                this.variableCost = detail.variableCost;

            if ('taxCode' in detail) this.taxCode = detail.taxCode;
            if ('supplier' in detail) this.supplier = detail.supplier;
            if ('warehouseToUse' in detail)
                this.warehouseToUse = detail.warehouseToUse;
        }
    }
}

const ProductDetail = ({ detail }) => {
    const product = new Product(detail);

    return (
        <>
            {objectService.checkObjectPopulated(detail) ? (
                <div
                    className={`grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-6 text-sm items-start mb-2`}
                >
                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Category
                        </span>

                        <span className="flex flex-wrap items-center gap-1">
                            <span
                                className="badge badge-sm badge-primary cursor-copy"
                                onClick={() =>
                                    navigationService.copyToClipboard(
                                        product.category
                                    )
                                }
                            >
                                {product.category ?? '-'}
                            </span>
                            <div className="divider divider-horizontal mx-0" />
                            {product.categoryDescr ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Unit of Measure
                        </span>

                        <span className="font-mono">{product.uom ?? '-'}</span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Fundamental Unit
                        </span>

                        <span className="font-mono">
                            {product.fundamentalUom ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Tax Code
                        </span>

                        <span className="badge badge-primary badge-sm">
                            {product.taxCode ?? '-'}
                        </span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Supplier
                        </span>

                        <span
                            className="badge badge-xs cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(
                                    product.supplier
                                )
                            }
                        >
                            {product.supplier ?? '-'}
                        </span>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-1 col-span-1">
                        <span className={`${detail_sub_heading}`}>
                            Product Cost
                        </span>

                        <div className="flex flex-wrap gap-1 w-full h-full items-center justify-center">
                            <span
                                data-tip={'Material Cost'}
                                className={'tooltip text-xs'}
                            >
                                {product.materialCost}
                            </span>
                            <p>+</p>
                            <span
                                data-tip={'Labour Cost'}
                                className={'tooltip text-xs'}
                            >
                                {product.labourCost}
                            </span>
                            <p>+</p>
                            <span
                                data-tip={'Fixed Overhead'}
                                className={'tooltip text-xs'}
                            >
                                {product.fixedOverhead}
                            </span>
                            <p>+</p>
                            <span
                                data-tip={'Variable Cost'}
                                className={'tooltip text-xs'}
                            >
                                {product.variableCost}
                            </span>
                            <p>=</p>
                            <span
                                className={
                                    'font-mono font-bold badge badge-primary tooltip'
                                }
                                data-tip={'Total Cost'}
                            >
                                {Number(product.materialCost) +
                                    Number(product.labourCost) +
                                    Number(product.fixedOverhead) +
                                    Number(product.variableCost)}
                            </span>
                        </div>
                    </div>
                </div>
            ) : (
                <AdminSkeleton rows={5} />
            )}
        </>
    );
};

export default ProductDetail;
