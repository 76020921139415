import { useSelector } from 'react-redux';
import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { BASE_FILTER_PLACEHOLDER_MAP } from '../../../../store/base/filters/base-filters.types';

import CompactFilterGroup from './compact-filter-group.component';

const CompactFilterContainer = ({
    themeMode,
    themeColor,
    overrideSelected,
}) => {
    let selectedFilters = useSelector(selectFiltersSelected);
    if (overrideSelected) selectedFilters = overrideSelected;

    return (
        <div className="relative w-full h-full flex flex-wrap items-center justify-start gap-2 mx-2 my-1">
            {Object.keys(selectedFilters).map((filterType) => {
                if (
                    selectedFilters[filterType] !== null &&
                    filterType !== 'fromDate' &&
                    filterType !== 'toDate'
                ) {
                    const activeFiltersPerType = selectedFilters[filterType];

                    return (
                        <CompactFilterGroup
                            key={filterType}
                            title={BASE_FILTER_PLACEHOLDER_MAP[filterType]}
                            activeFilters={activeFiltersPerType}
                            themeMode={themeMode}
                            themeColor={themeColor}
                        />
                    );
                }
            })}
        </div>
    );
};

export default CompactFilterContainer;
