export const objectService = {
    checkObjectPopulated,
    checkObjectKeysEqual,

    moveObjectItemUp,
};

function checkObjectPopulated(object) {
    if (!object) return false;
    if (typeof object !== 'object') return false;
    if (Object.keys(object).length === 0) return false;

    return true;
}

function checkObjectKeysEqual(objectOne, objectTwo) {
    if (Object.keys(objectOne) === Object.keys(objectTwo)) return true;

    return false;
}

function moveObjectItemUp(key, object) {
    const objectKeys = Object.keys(object);
    const oldPosition = objectKeys.indexOf(key);
    if (oldPosition === 0) return object;

    const targetPosition = oldPosition - 1;

    let newObject = {};
    for (let i = 0; i < objectKeys.length; i++) {
        if (i === targetPosition) {
            newObject[key] = object[key];
            newObject[objectKeys[i]] = object[objectKeys[i]];
            i += 1;
        } else {
            newObject[objectKeys[i]] = object[objectKeys[i]];
        }
    }
    return newObject;
}