import { BASE_FILTER_OPTIONS_ACTION_TYPES } from './base-filter-options.types';

const INITIAL_STATE = {
    skulists: [],
    supplcategories: [],
    supplsubcategories: [],
    supplbrands: [],
    supplskus: [],

    dists: [],
    storelists: [],
    storetypes: [],
    distregions: [],
    distpoints: [],
};

export const baseFilterOptionsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case BASE_FILTER_OPTIONS_ACTION_TYPES.SET_FILTERS_OPTIONS:
            return { ...state, ...payload };

        case BASE_FILTER_OPTIONS_ACTION_TYPES.CLEAR_FILTERS_OPTIONS:
            return { ...INITIAL_STATE };

        default:
            return state;
    }
};
