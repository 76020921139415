import React, { useState, useEffect } from 'react';
import {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';
import { getWindowSize } from '../../base/filters/trend-filters/trend-filters.component';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';

import {
    backdrop,
    enlarged_chart,
    regular_chart,
} from '../../../utils/styles/tailwind-styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const barChartOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 0.5,
            hoverBorderWidth: 1,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 30,
                pointStyle: 'rect',
                usePointStyle: true,
            },
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: true,
                drawBorder: true,
            },
        },
        x2: {
            type: 'linear',
            position: 'top',
            grid: {
                drawOnChartArea: false,
            },
        },
        y: {
            grid: {
                display: true,
                drawBorder: true,
            },
        },
    },
    interaction: {
        intersect: false,
        axis: 'y',
        mode: 'index',
    },
};

const BarChartComponent = ({ data, title, interactionMode, type }) => {
    const [isEnlarged, setIsEnlarged] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const daisyTheme = useSelector(selectDaisyTheme);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    let options;

    if (!interactionMode) options = { ...barChartOptions };
    else
        options = {
            ...barChartOptions,
            interaction: {
                ...barChartOptions['interaction'],
                mode: interactionMode,
            },
        };

    options = {
        ...options,
        plugins: {
            ...options['plugins'],
            legend: {
                ...options['plugins']['legend'],
                labels: {
                    ...options['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
        scales: {
            ...options['scales'],
            x: {
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            x2: {
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                    drawOnChartArea: false,
                },
                position: 'top',
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y: {
                grid: {
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
    };

    if (windowSize['innerWidth'] < 1000) {
        options = {
            ...options,
            plugins: {
                ...options['plugins'],
                legend: {
                    display: false,
                },
            },
            scales: {
                y: {
                    display: false,
                },
                x: {
                    ticks: {
                        font: {
                            size: 8,
                        },
                    },
                },
            },
        };
    }

    const toggleEnlarged = () => {
        setIsEnlarged(!isEnlarged);
        if (!isEnlarged) {
            // window.scrollTo({
            //     top: GLOBAL_STYLES['enlarged-scroll'],
            //     left: 0,
            //     behavior: 'smooth',
            // });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <>
            {isEnlarged ? (
                <div className={backdrop}>
                    <div className={enlarged_chart} id={'enlarged-chart'}>
                        <div className="flex justify-between items-center w-full px-2">
                            <div className="flex items-center">
                                <h3 className="text-primary font-bold text-base">
                                    {title}
                                </h3>

                                {type && (
                                    <>
                                        <div className="divider divider-horizontal mx-0" />
                                        <span className="badge badge-primary font-bold">
                                            {type}
                                        </span>
                                    </>
                                )}
                            </div>

                            <span className="btn btn-sm btn-ghost btn-circle">
                                <ArrowsPointingInIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </span>
                        </div>

                        <div className="divider my-3" />
                        <Bar
                            style={{
                                maxHeight: '85%',
                            }}
                            id="chart"
                            data={data}
                            options={options}
                        />
                    </div>
                </div>
            ) : (
                <div className={regular_chart}>
                    <div className="flex desktop:justify-between tablet:justify-between justify-evenly items-center w-full px-2">
                        <div className="flex items-center">
                            <h3 className="text-primary font-bold text-base">
                                {title}
                            </h3>

                            {type && (
                                <>
                                    <div className="divider divider-horizontal mx-0" />
                                    <span className="badge badge-primary font-bold">
                                        {type}
                                    </span>
                                </>
                            )}
                        </div>

                        {windowSize['innerWidth'] > 1366 ? (
                            <a
                                className="btn btn-sm btn-ghost btn-circle"
                                href={'#enlarged-chart'}
                            >
                                <ArrowsPointingOutIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </a>
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="divider my-3" />
                    <Bar id="chart" data={data} options={options} />
                </div>
            )}
        </>
    );
};

export default BarChartComponent;