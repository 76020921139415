import { Navigate, useParams } from 'react-router-dom';
import { selectUser } from '../store/auth/authentication.selectors';
import { useSelector } from 'react-redux';

import { createNotification, NOTIFICATION_TYPES } from './notification.util';

/*
Users can be linked to one or more reps.

If a user is linked to no reps, they can view all data.
If a user is linked to at least one rep, they can only view rep pages for the
reps to which they are linked.
 */
export const PermissionProtectedRoute = ({ children }) => {
    const params = useParams();

    const user = useSelector(selectUser);

    let validPermission = false;

    if (user.reps && user.reps.length > 0) {
        user.reps.forEach((repCode) => {
            if (`${params.id}` === `${repCode}`) validPermission = true;
        });
    } else {
        validPermission = true;
    }

    if (!validPermission) {
        // createNotification(
        //     'You do not have access to that data',
        //     NOTIFICATION_TYPES.INFO
        // );

        return (
            <Navigate
                to={
                    user.reps && user.reps.length > 0
                        ? `/discover/rep/${user.reps[0]}`
                        : '/'
                }
                replace={true}
            />
        );
    }
    // if (!userRole || !roles.includes(userRole)) {

    // }

    return children;
};