import moment from 'moment-timezone';

export const USER_ROLES = {
    M2_ADMIN: 'm2-admin',
    M2_SUPPORT: 'm2-support',

    C_OWNER: 'c-owner',
    C_ADMIN: 'c-admin',

    USER: 'user',
};

export function isM2Admin(user) {
    return user.role === USER_ROLES.M2_ADMIN;
}

export function isM2Support(user) {
    return user.role === USER_ROLES.M2_SUPPORT;
}

export function isCOwner(user) {
    return user.role === USER_ROLES.C_OWNER;
}

export function isCAdmin(user) {
    return user.role === USER_ROLES.C_ADMIN;
}

export function isBillingUser(user) {
    return user.email === user.billingEmail;
}

export function formatDate(timestamp) {
    return moment
        .tz(new Date(timestamp).toISOString().toString(), 'Africa/Johannesburg')
        .format()
        .replace('T', ' ');
}

export function generateTemporaryPassword(length = 8) {
    let result = '';
    const validCharacters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#+';
    const charactersLength = validCharacters.length;
    let counter = 0;
    while (counter < length) {
        result += validCharacters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
    }
    return result;
}