import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../store/themes/themes.selectors';
import { setDaisyTheme } from '../../store/themes/themes.actions';
import {
    MoonIcon,
    BuildingOfficeIcon,
    PaintBrushIcon,
    SunIcon,
    BeakerIcon,
    BriefcaseIcon,
} from '@heroicons/react/24/solid';

import { DAISY_THEMES } from '../../store/themes/themes.types';
import { REACT_TOUR_CLASS_NAMES } from '../../utils/react-tour.util';

const selectDaisyIcon = (daisyTheme, className) => {
    switch (daisyTheme) {
        case DAISY_THEMES.LIGHT:
            return <SunIcon className={className} />;
        case DAISY_THEMES.CORPORATE:
            return <BuildingOfficeIcon className={className} />;
        case DAISY_THEMES.BUSINESS:
            return <BriefcaseIcon className={className} />;
        case DAISY_THEMES.DARK:
            return <MoonIcon className={className} />;
        case DAISY_THEMES.COFFEE:
            return <BeakerIcon className={className} />;
        default:
            return <PaintBrushIcon className={className} />;
    }
};

const ThemeComponent = ({ className }) => {
    const dispatch = useDispatch();
    const daisyTheme = useSelector(selectDaisyTheme);
    const ref = useRef(null);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!daisyTheme) dispatch(setDaisyTheme(DAISY_THEMES.LIGHT));
    }, []);

    const toggleOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [open]);

    return (
        <div ref={ref} className={`dropdown w-full ${className}`}>
            <label
                className={`btn btn-ghost justify-start capitalize font-normal w-full text-xs hover:text-primary gap-1 ${REACT_TOUR_CLASS_NAMES.THEME_2}`}
                id={REACT_TOUR_CLASS_NAMES.THEME_2}
                onClick={toggleOpen}
            >
                {selectDaisyIcon(daisyTheme, 'w-5 h-5 text-base-content')}
                <p className="text-xs ml-1 capitalize">Theme</p>
            </label>
            {open && (
                <ul
                    className={
                        'menu absolute z-50 left-0 p-2 shadow bg-base-300 rounded-box w-full'
                    }
                    style={{
                        top: '100%',
                    }}
                >
                    {Object.values(DAISY_THEMES).map((theme) => {
                        return (
                            <li key={theme}>
                                <a
                                    className={`flex items-center justify-start ${REACT_TOUR_CLASS_NAMES.THEME_3}-${theme}`}
                                    id={`${REACT_TOUR_CLASS_NAMES.THEME_3}-${theme}`}
                                    onClick={() => {
                                        dispatch(setDaisyTheme(theme));
                                    }}
                                >
                                    {selectDaisyIcon(
                                        theme,
                                        'w-4 h-4 text-base-content'
                                    )}
                                    <span className="text-xs capitalize hover:text-primary ml-1">
                                        {theme}
                                    </span>
                                </a>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default ThemeComponent;