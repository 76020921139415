import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';

import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
} from '../../../utils/styles/global-styles';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const GRAPH_FONT_SIZE = 10;
export const GRAPH_FONT_FAMILY = 'Plus Jakarta Sans';

const barChartOptions = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 0.5,
            hoverBorderWidth: 1,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 5,
                pointStyle: 'rect',
                usePointStyle: true,
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: true,
                drawBorder: true,
                // color: GLOBAL_COLOURS['dark']['bg-page']
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
        },
        x2: {
            type: 'linear',
            position: 'top',
            grid: {
                drawOnChartArea: false,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
        },
        x3: {
            type: 'linear',
            position: 'bottom',
            grid: {
                drawOnChartArea: false,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
            display: false,
        },
        x4: {
            type: 'linear',
            position: 'bottom',
            grid: {
                drawOnChartArea: false,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
                display: false,
            },
            display: false,
        },
        y: {
            grid: {
                display: true,
                drawBorder: true,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
        },
        y1: {
            display: false,
            grid: {
                display: true,
                drawBorder: true,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
        },
        y2: {
            display: false,
            grid: {
                display: true,
                drawBorder: true,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
            },
        },
        y3: {
            display: false,
            grid: {
                display: true,
                drawBorder: true,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
                display: false,
            },
        },
        y4: {
            display: false,
            grid: {
                display: true,
                drawBorder: true,
            },
            ticks: {
                font: {
                    size: GRAPH_FONT_SIZE,
                    family: GRAPH_FONT_FAMILY,
                },
                display: false,
            },
        },
    },
    interaction: {
        intersect: false,
        axis: 'y',
        mode: 'index',
    },
};

const MiniBarChartComponent = ({ data, interactionMode, className }) => {
    const daisyTheme = useSelector(selectDaisyTheme);

    let options;

    if (!interactionMode) options = { ...barChartOptions };
    else
        options = {
            ...barChartOptions,
            interaction: {
                ...barChartOptions['interaction'],
                mode: interactionMode,
            },
        };

    options = {
        ...options,
        plugins: {
            ...options['plugins'],
            legend: {
                ...options['plugins']['legend'],
                labels: {
                    ...options['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
        scales: {
            ...options['scales'],
            x: {
                display: false,
                grid: {
                    ...options['scales']['x']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
                ticks: {
                    ...options['scales']['x']['ticks'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            x2: {
                display: false,
                grid: {
                    ...options['scales']['x2']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                    drawOnChartArea: false,
                },
                position: 'top',
                ticks: {
                    ...options['scales']['x2']['ticks'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y: {
                grid: {
                    ...options['scales']['y']['ticks'],
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
                ticks: {
                    ...options['scales']['y']['ticks'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y2: {
                display: false,
                grid: {
                    ...options['scales']['y']['ticks'],
                    ...options['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-100'],
                },
                ticks: {
                    ...options['scales']['y']['ticks'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
    };

    return (
        <Bar className={className} id="chart" data={data} options={options} />
    );
};

export default MiniBarChartComponent;
