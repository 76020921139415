import { DISCOVER_SEARCH_ACTION_TYPES } from './discover-search.types';

const INITIAL_STATE = {
    customer: {},
    rep: {},
    sku: {},
    category: {},
};

export const discoverSearchReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case DISCOVER_SEARCH_ACTION_TYPES.SET_CUSTOMERS:
            return { ...state, customer: payload };
        case DISCOVER_SEARCH_ACTION_TYPES.SET_SKUS:
            return { ...state, sku: payload };
        case DISCOVER_SEARCH_ACTION_TYPES.SET_REPS:
            return { ...state, rep: payload };
        case DISCOVER_SEARCH_ACTION_TYPES.SET_CATEGORIES:
            return { ...state, category: payload };

        case DISCOVER_SEARCH_ACTION_TYPES.CLEAR:
            return { ...INITIAL_STATE };

        default:
            return state;
    }
};
