import { engineService } from '../../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';
import {
    addOpacity,
    graphColourSelector,
} from '../../../../utils/styles/global-styles';

export const alertsService = {
    loadAlertsData,
    convertAlertDataToTrendGraph,
    convertAlertDataToDoughnuts,
};

async function loadAlertsData(selectedFilters, trendFilters, reportParams) {
    const fetchParam = {
        selectedFilters: selectedFilters,
        compare: trendFilters['compare'],
        frequency: trendFilters['frequency'],
    };

    let param;
    if (reportParams) {
        param = {
            uiRequest: {
                action: 'generateReport',
                state: fetchParam,
                reportParams,
            },
        };
    } else {
        param = fetchParam;
    }

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.ALERT_OVERVIEW,
        param
    );
    return response;
}

function convertAlertDataToTrendGraph(alertData, daisyTheme) {
    const trendData = alertData['freqTrendFutureValue'];

    const unformattedDatasets = trendData['channels'].map(
        (dataset) => dataset.dataKey
    );
    const labels = trendData['rows'].map((row) => row.name);

    return {
        labels,
        datasets: unformattedDatasets.map((dataset, index) => {
            const { borderColor, backgroundColor } = graphColourSelector(
                index,
                daisyTheme
            );
            return {
                label: dataset,
                data: trendData['rows'].map((row) => row[dataset]),
                fill: false,
                borderColor,
                backgroundColor: addOpacity(backgroundColor, 0.5),
                tension: 0.125,

                pointRadius: 4,
                pointHoverRadius: 8,
                pointBorderWidth: 1,
                pointHoverBorderWidth: 0.5,
                pointBorderColor: borderColor,
                pointBackgroundColor: addOpacity(backgroundColor, 0.5),
            };
        }),
    };
}

function convertAlertDataToDoughnuts(alertData, themeColor, themeMode) {
    if ('resultHeadings' in alertData && 'results' in alertData) {
        let graphHeadings = alertData['resultHeadings'].reduce(
            (result, heading, index) => {
                if (index !== 0) {
                    result.push({
                        index,
                        graphType: heading,
                    });
                }
                return result;
            },
            []
        );

        let graphData = graphHeadings.map((heading) => {
            // console.log(heading);
        });
        // let dataLabel;
        // let data = [];
        // let dataKeys = [];
        // for (const key in coverData['rows'][0]) {
        //     if (key === "name") dataLabel = key;
        //     else {
        //         dataKeys.push(key);
        //         data.push(coverData['rows'][0][key]);
        //     }
        // }

        // const borderColors = data.map((item, index)=>graphColourSelector(index, themeColor).borderColor);
        // const backgroundColors = data.map((item, index)=> addOpacity(graphColourSelector(index, themeColor).backgroundColor, 0.75))
        //
        // return {
        //     labels: dataKeys,
        //     datasets: [{
        //         label: dataLabel,
        //         data: data,
        //         borderWidth: 3,
        //         hoverBorderWidth: 6,
        //
        //         backgroundColor: backgroundColors,
        //         borderColor: borderColors,
        //
        //         hoverBackgroundColor: data.map((item, index)=>addOpacity(graphColourSelector(index, themeColor).backgroundColor)),
        //     }]
        // }
    }

    return null;
}
