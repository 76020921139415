import { useEffect } from 'react';
import DownloadExtract from '../download-extract.component';
import LimitedDateRangeSelectorComponent from '../../limited-date-range-selector/limited-date-range-selector.component';
import { REACT_TOUR_CLASS_NAMES } from '../../../utils/react-tour.util';
import {
    BarsArrowDownIcon,
    BarsArrowUpIcon,
    TrashIcon,
} from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';
import { DISCOVER_WIDGET_DATA_METRICS } from '../../../store/discover/widgets/widgets.types';

const WidgetFooter = ({
    metric,
    perspective,
    selectedDate,
    onDateChange,
    className,
    id,
    onRemoveWidget,
    onMoveUp,
    onMoveDown,
    hidden = false,
}) => {
    const location = useLocation();

    useEffect(() => {}, [metric, perspective, selectedDate]);

    return (
        <div
            className={`${className} ${
                hidden ? 'py-0 hidden opacity-0' : 'opacity-100'
            } transition-opacity ease-in duration-150 mt-3 border-t-2 border-base-content border-opacity-5`}
        >
            <div className="flex w-full justify-end items-center gap-1 mt-2">
                <div className="w-full">
                    <div className="max-w-lg">
                        {metric !== DISCOVER_WIDGET_DATA_METRICS.CHAOS && (
                            <LimitedDateRangeSelectorComponent
                                defaultDate={localStorage.getItem(
                                    'selectedDate'
                                )}
                                onChange={onDateChange}
                                selectedDate={selectedDate}
                            />
                        )}
                    </div>
                </div>

                <div className="flex items-center gap-1">
                    <span
                        className={
                            'btn btn-xs btn-ghost btn-circle tooltip text-base-content text-opacity-60 items-center justify-center'
                        }
                        data-tip={'Move Widget Up'}
                        onClick={onMoveUp}
                    >
                        <BarsArrowUpIcon className="w-4 h-4" />
                    </span>
                </div>

                <div className="divider divider-horizontal mx-1" />

                <div className="flex items-center gap-1">
                    {metric !== DISCOVER_WIDGET_DATA_METRICS.CHAOS && (
                        <DownloadExtract
                            metric={metric}
                            perspective={perspective}
                            selectedDate={selectedDate}
                            onDateChange={onDateChange}
                        />
                    )}

                    {!location.pathname.includes('watchlist') && (
                        <button
                            className={`btn btn-ghost btn-circle btn-xs tooltip tooltip-left flex justify-center items-center normal-case font-light text-xs ${REACT_TOUR_CLASS_NAMES.DELETE}`}
                            data-tip={'Delete Widget'}
                            onClick={onRemoveWidget}
                        >
                            <TrashIcon
                                className={'w-4 h-4 text-error text-opacity-90'}
                            />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default WidgetFooter;