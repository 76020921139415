import { useEffect, useState } from 'react';
import LoadingComponent from '../../loading-component/loading-component';
import { limitedDateTextMap } from '../../limited-date-range-selector/limited-date-range-selector.component';
import TargetTemplateDownload from '../target/target-template-download.component';
import { muted_border } from '../../../utils/styles/tailwind-styles';

import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/auth/authentication.selectors';
import { DISCOVER_WIDGET_DATA_METRICS } from '../../../store/discover/widgets/widgets.types';

import { PencilSquareIcon } from '@heroicons/react/24/solid';
import TargetBulkEdit from '../target/target-bulk-edit.component';
import TargetEdit from '../target/target-edit-component.component';
import { getYearMonthFromDate } from '../../../utils/date.util';
import { widgetService } from '../../../store/discover/widgets/widgets.services';
import TargetTemplateUploadModal from '../target/target-template-upload-modal.component';
import {
    isM2Admin,
    isM2Support,
    isCAdmin,
    isCOwner,
} from '../../../utils/auth.util';

function isNumeric(str) {
    if (typeof str != 'string') return false; // we only process strings!
    return !isNaN(str) && !isNaN(parseFloat(str));
}

const currentDate = new Date();

const SalesTarget = ({
    rawData,
    selectedDate,
    perspective,
    onTargetChange,
}) => {
    const user = useSelector(selectUser);

    const [editTarget, setEditTarget] = useState(false);

    useEffect(() => {}, [rawData, selectedDate]);

    return (
        <>
            <div
                className={`text-sm text-base-content text-opacity-80 py-4 px-4 shadow-inner w-full relative ${muted_border}`}
            >
                {widgetService.checkWidgetHasData(
                    rawData,
                    selectedDate,
                    'targetSet'
                ) &&
                widgetService.checkWidgetHasData(
                    rawData,
                    selectedDate,
                    'targetValue'
                ) ? (
                    !rawData[selectedDate]['targetSet'] ? (
                        <div className="flex items-center w-full justify-between gap-2">
                            <TargetEdit
                                metric={DISCOVER_WIDGET_DATA_METRICS.SALES}
                                perspective={perspective}
                                targetValue={
                                    rawData[selectedDate]['targetValue']
                                }
                                targetDate={getYearMonthFromDate(currentDate)}
                                onFinish={onTargetChange}
                            />

                            <TargetBulkEdit
                                metric={'sales'}
                                perspective={perspective}
                                onFinish={onTargetChange}
                            />
                        </div>
                    ) : (
                        <div
                            className={
                                'w-full flex flex-col justify-start items-start gap-2'
                            }
                        >
                            <div className="flex items-center w-full justify-between gap-2">
                                <span className="w-16 mr-2 font-bold text-xs text-base-content text-opacity-70">
                                    Target
                                </span>

                                {editTarget &&
                                (isM2Admin(user) ||
                                    isM2Support(user) ||
                                    isCOwner(user) ||
                                    isCAdmin(user)) ? (
                                    <TargetEdit
                                        metric={
                                            DISCOVER_WIDGET_DATA_METRICS.SALES
                                        }
                                        perspective={perspective}
                                        targetValue={
                                            rawData[selectedDate][
                                                'targetValue'
                                            ] ?? 0
                                        }
                                        targetDate={getYearMonthFromDate(
                                            currentDate
                                        )}
                                        cancelEdit={() =>
                                            setEditTarget(!editTarget)
                                        }
                                        onFinish={onTargetChange}
                                    />
                                ) : (
                                    <div className="flex items-center gap-1 w-full justify-start">
                                        <span className="font-bold font-mono">
                                            R
                                            {rawData[selectedDate][
                                                'targetValue'
                                            ].toLocaleString()}
                                        </span>

                                        <div className="divider divider-horizontal mx-0" />
                                        {(isM2Admin(user) ||
                                            isM2Support(user) ||
                                            isCOwner(user) ||
                                            isCAdmin(user)) && (
                                            <span
                                                className="btn btn-xs btn-circle btn-ghost tooltip tooltip-bottom"
                                                onClick={() =>
                                                    setEditTarget(!editTarget)
                                                }
                                                data-tip={`Edit target (${
                                                    currentDate.getFullYear() +
                                                    '-' +
                                                    String(
                                                        Number(
                                                            currentDate.getMonth() +
                                                                1
                                                        )
                                                    ).padStart(2, '0')
                                                })`}
                                            >
                                                <PencilSquareIcon
                                                    className={'w-4 h-4'}
                                                />
                                            </span>
                                        )}
                                    </div>
                                )}

                                {(isM2Admin(user) ||
                                    isM2Support(user) ||
                                    isCOwner(user) ||
                                    isCAdmin(user)) && (
                                    <TargetBulkEdit
                                        metric={'sales'}
                                        perspective={perspective}
                                        onFinish={onTargetChange}
                                    />
                                )}
                            </div>

                            <div className="flex items-center">
                                <span className="w-16 mr-2 font-bold text-xs text-base-content text-opacity-70">
                                    Progress
                                </span>
                                <span
                                    className={`font-bold font-mono ${
                                        rawData[selectedDate]['targetMet']
                                            ? 'text-success'
                                            : 'text-warning'
                                    }`}
                                >
                                    {(
                                        Math.round(
                                            100 *
                                                100 *
                                                Math.abs(
                                                    rawData[selectedDate][
                                                        'total'
                                                    ] /
                                                        rawData[selectedDate][
                                                            'targetValue'
                                                        ]
                                                )
                                        ) / 100
                                    ).toLocaleString()}
                                    %
                                </span>
                            </div>

                            {rawData[selectedDate]['checkFullPeriod'] && (
                                <span className="flex flex-wrap whitespace-pre-wrap justify-start items-center w-100 my-1 text-sm">
                                    <div className="divider my-1 w-full" />
                                    <span
                                        className={`mr-1 font-bold font-mono text-primary`}
                                    >
                                        {rawData[selectedDate] &&
                                            rawData[selectedDate][
                                                'targetProgress'
                                            ] &&
                                            Math.round(
                                                rawData[selectedDate][
                                                    'targetProgress'
                                                ] * 100
                                            ).toLocaleString()}
                                        %
                                    </span>
                                    of the total trading days in{' '}
                                    <strong className="mr-1">
                                        {limitedDateTextMap(selectedDate)}
                                    </strong>{' '}
                                    have elapsed.
                                </span>
                            )}
                        </div>
                    )
                ) : (
                    <LoadingComponent />
                )}

                {(isM2Admin(user) ||
                    isM2Support(user) ||
                    isCOwner(user) ||
                    isCAdmin(user)) && (
                    <div className="w-full flex items-center justify-end gap-1 mt-2">
                        <TargetTemplateDownload
                            metric={'sales'}
                            perspective={{
                                type: 'global',
                                code: null,
                            }}
                        />

                        <TargetTemplateUploadModal
                            metric={'sales'}
                            onFinish={onTargetChange}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default SalesTarget;