import { engineService } from '../../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../../utils/engine/pf-config.service';

import { BASE_FILTER_TYPES } from './base-filters.types';

export const filterService = {
    loadFilterOptions,
    loadInitialFilterOptions,
    removeUnwantedKeys,
};

async function loadFilterOptions(selectedFilters, updatedField) {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DEFAULT_FILTERS,
        {
            selectedFilters: selectedFilters,
            updatedField: updatedField,
        }
    );
    return response;
}

async function loadInitialFilterOptions(selectedFilters) {
    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.DEFAULT_FILTERS,
        {
            selectedFilters,
        }
    );
    return response;
}

function removeUnwantedKeys(responseObject) {
    let newObject = {};
    for (const key in responseObject) {
        if (key in BASE_FILTER_TYPES) {
            newObject[key] = responseObject[key];
        }
    }
    return newObject;
}
