import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/auth/authentication.selectors';
import { GLOBAL_STYLES } from '../../../utils/styles/global-styles';

import {
    XMarkIcon,
    PaperAirplaneIcon,
    DocumentArrowDownIcon,
    ArrowDownOnSquareIcon,
} from '@heroicons/react/20/solid';

import { DISCOVER_WIDGET_AGGREGATION_METRICS } from '../../../store/discover/widgets/widgets.types';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { discoverHomeService } from '../../../store/discover/home/discover-home.services';
import { isCAdmin, isM2Support } from '../../../utils/auth.util';

export const FROM_DATE_OPTIONS = {
    TWO_YEARS_AGO: '-2 years',
    ONE_YEAR_AGO: '-1 year',
    SIX_MONTHS_AGO: '-6 months',
    NOW: 'now',
};

export const TO_DATE_OPTIONS = {
    NOW: 'now',
    SIX_MONTHS_FROM_NOW: '+6 months',
    ONE_YEAR_FROM_NOW: '+1 year',
    TWO_YEARS_FROM_NOW: '+2 years',
};

const TargetTemplateDownload = ({ metric, className }) => {
    const user = useSelector(selectUser);

    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);

    const [from, setFrom] = useState(FROM_DATE_OPTIONS.ONE_YEAR_AGO);
    const [to, setTo] = useState(TO_DATE_OPTIONS.ONE_YEAR_FROM_NOW);

    const [global, setGlobal] = useState(true);
    const [sku, setSku] = useState(true);
    const [customer, setCustomer] = useState(true);
    const [rep, setRep] = useState(true);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isOpen && ref.current && ref.current === e.target) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const toggleIsOpen = () => {
        setIsOpen(!isOpen);

        if (!isOpen) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    const handleCompleteRequest = async () => {
        if (!global && !customer && !sku && !rep) {
            createNotification(
                'You must select at least on perspective [global, products, customers or reps].',
                NOTIFICATION_TYPES.WARNING
            );
            return;
        }

        let perspectives = [];
        if (global) perspectives.push('global');
        if (sku) perspectives.push(DISCOVER_WIDGET_AGGREGATION_METRICS.SKU);
        if (customer)
            perspectives.push(DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER);
        if (rep) perspectives.push(DISCOVER_WIDGET_AGGREGATION_METRICS.REP);

        try {
            const response =
                await discoverHomeService.submitTargetTemplateRequest(
                    metric,
                    perspectives,
                    {
                        fromDate: from,
                        toDate: to,
                    },
                    user
                );
            createNotification(response.message, NOTIFICATION_TYPES.SUCCESS);
        } catch (err) {}
    };

    return (
        <>
            <label
                className={`btn btn-outline btn-accent btn-xs flex justify-center items-center tooltip tooltip-left normal-case ${className}`}
                htmlFor={`target-template-download-modal-${metric}`}
                data-tip={'Download Template'}
            >
                <ArrowDownOnSquareIcon className="w-4 h-4" />
            </label>

            <input
                type="checkbox"
                id={`target-template-download-modal-${metric}`}
                className="modal-toggle"
            />

            <div className="modal">
                <div
                    className={`modal-box bg-base-100 bg-opacity-95 relative w-full h-auto max-w-xl`}
                    style={{
                        maxHeight: '80vh',
                    }}
                >
                    <span className="w-full flex flex-col items-center justify-start mt-2">
                        <div className="flex justify-between items-center w-full gap-2">
                            <h3 className="text-sm font-bold w-fit">
                                Targets Template
                            </h3>

                            <div className="flex flex-wrap grow gap-1 justify-start items-center">
                                <span className="badge badge-primary font-bold capitalize">
                                    {metric.replace('_', ' ')}
                                </span>
                            </div>

                            <div className="flex items-center gap-1">
                                <label
                                    htmlFor={`target-template-download-modal-${metric}`}
                                    className="btn btn-ghost btn-circle btn-sm"
                                >
                                    <XMarkIcon
                                        className="w-4 h-4"
                                        onClick={toggleIsOpen}
                                    />
                                </label>
                            </div>
                        </div>
                    </span>

                    <div className="divider my-2" />

                    <p className="text-sm text-base-content text-opacity-70 mb-2">
                        When would you like to define targets from/to?
                    </p>

                    <div className="w-full flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <span className="w-20 font-bold text-base-content">
                                From
                            </span>

                            <select
                                onChange={(e) => {
                                    if (e.target.value === to)
                                        createNotification(
                                            'You must select different dates for [from] and [to].',
                                            NOTIFICATION_TYPES.WARNING
                                        );
                                    else setFrom(e.target.value);
                                }}
                                value={from}
                                className="select select-bordered select-sm select-accent"
                            >
                                {Object.keys(FROM_DATE_OPTIONS).map(
                                    (targetDate) => {
                                        return (
                                            <option
                                                key={
                                                    FROM_DATE_OPTIONS[
                                                        targetDate
                                                    ]
                                                }
                                            >
                                                {FROM_DATE_OPTIONS[targetDate]}
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>

                        <div className="flex items-center gap-2">
                            <span className="w-20 font-bold text-base-content">
                                To
                            </span>

                            <select
                                onChange={(e) => {
                                    if (e.target.value === from)
                                        createNotification(
                                            'You must select different dates for [from] and [to].',
                                            NOTIFICATION_TYPES.WARNING
                                        );
                                    else setTo(e.target.value);
                                }}
                                value={to}
                                className="select select-bordered select-sm select-accent"
                            >
                                {Object.keys(TO_DATE_OPTIONS).map(
                                    (targetDate) => {
                                        return (
                                            <option
                                                key={
                                                    TO_DATE_OPTIONS[targetDate]
                                                }
                                            >
                                                {TO_DATE_OPTIONS[targetDate]}
                                            </option>
                                        );
                                    }
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="divider my-2" />
                    <p className="text-sm text-base-content text-opacity-70 my-2">
                        Which targets would you like to set?
                    </p>
                    <div className="grid w-full grid-cols-2 place-items-center justify-items-start">
                        <label className="label cursor-pointer">
                            <span className="w-20 font-bold text-base-content mr-2">
                                Global
                            </span>
                            <input
                                type="checkbox"
                                checked={global}
                                className="checkbox checkbox-accent"
                                onChange={(event) => {
                                    setGlobal(!global);
                                }}
                            />
                        </label>
                        <label className="label cursor-pointer">
                            <span className="w-20 font-bold text-base-content mr-2">
                                Products
                            </span>
                            <input
                                type="checkbox"
                                checked={sku}
                                className="checkbox checkbox-accent"
                                onChange={(event) => {
                                    setSku(!sku);
                                }}
                            />
                        </label>
                        <label className="label cursor-pointer">
                            <span className="w-20 font-bold text-base-content mr-2">
                                Customers
                            </span>
                            <input
                                type="checkbox"
                                checked={customer}
                                className="checkbox checkbox-accent"
                                onChange={(event) => {
                                    setCustomer(!customer);
                                }}
                            />
                        </label>
                        <label className="label cursor-pointer">
                            <span className="w-20 font-bold text-base-content mr-2">
                                Reps
                            </span>
                            <input
                                type="checkbox"
                                checked={rep}
                                className="checkbox checkbox-accent"
                                onChange={(event) => {
                                    setRep(!rep);
                                }}
                            />
                        </label>
                    </div>

                    <div className="divider my-2" />

                    <div className="flex flex-wrap gap-2 w-full">
                        <p className="text-base-content text-opacity-80 text-sm my-2">
                            We'll email you a link to download a template file.
                            Once you've filled it out, you must email the file
                            to <strong>...</strong> and we'll import the targets
                            automatically.
                        </p>
                    </div>

                    <div className="divider my-2" />

                    <div className="w-full flex justify-end items-center">
                        <label
                            onClick={handleCompleteRequest}
                            htmlFor={`target-template-download-modal-${metric}`}
                            className="btn btn-primary btn-sm gap-1"
                        >
                            <PaperAirplaneIcon className="w-4 h-4" />
                            Submit Request
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TargetTemplateDownload;