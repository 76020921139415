export const DASHBOARD_ACTION_TYPES = {
    SET_DASHBOARD_ACTIVE_COMPONENT:
        'base/dashboard/SET_DASHBOARD_ACTIVE_COMPONENT',
    CLEAR_DASHBOARD_DATA: 'base/dashboard/CLEAR_DASHBOARD_DATA',

    SET_DASHBOARD_TOP_SKUS: 'base/dashboard/SET_DASHBOARD_TOP_SKUS',
    SET_DASHBOARD_TOP_STORES: 'base/dashboard/SET_DASHBOARD_TOP_STORES',
    SET_DASHBOARD_SALES_PERFORMANCE:
        'base/dashboard/SET_DASHBOARD_SALES_PERFORMANCE',
    SET_DASHBOARD_DATA_STATUS: 'base/dashboard/SET_DASHBOARD_DATA_STATUS',
    SET_DASHBOARD_ACTIVITY: 'base/dashboard/SET_DASHBOARD_ACTIVITY',

    SET_DASHBOARD_SIDEBAR_MODE: 'dashboard/SET_DASHBOARD_SIDEBAR_MODE',
    SET_DASHBOARD_SIDEBAR_ACTIVE: 'dashboard/SET_DASHBOARD_SIDEBAR_ACTIVE',
};
