import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
} from '@heroicons/react/24/solid';
import { getWindowSize } from '../../base/filters/trend-filters/trend-filters.component';

import {
    selectDaisyTheme,
    selectThemeMode,
} from '../../../store/themes/themes.selectors';

import {
    DAISY_THEME_MAP,
    GLOBAL_COLOURS,
    GLOBAL_STYLES,
} from '../../../utils/styles/global-styles';

import {
    backdrop,
    enlarged,
    enlarged_chart,
    regular_chart,
} from '../../../utils/styles/tailwind-styles';

ChartJS.register(
    Filler,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const multiAxisLineChartOptions = {
    indexAxis: 'x',
    type: 'line',
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            onHover: function (e) {
                e.native.target.style.cursor = 'pointer';
            },
            onLeave(e) {
                e.native.target.style.cursor = 'default';
            },
            labels: {
                padding: 30,
                pointStyle: 'circle',
                usePointStyle: true,
            },
        },
        title: {
            display: false,
        },
    },
    interaction: {
        intersect: false,
        axis: 'x',
        mode: 'index',
    },
    scales: {
        x: {
            grid: {
                display: true,
                drawBorder: false,
                color: GLOBAL_COLOURS['dark']['bg-page'],
            },
        },
        y1: {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
                display: true,
                drawBorder: false,
                color: GLOBAL_COLOURS['dark']['bg-page'],
            },
        },
        y2: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
                drawOnChartArea: false,
            },
        },
    },
    options: {
        events: ['click'],
        stacked: false,
    },
};

const MultiAxisLineChartComponent = ({ data, title }) => {
    const [isEnlarged, setIsEnlarged] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const daisyTheme = useSelector(selectDaisyTheme);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const toggleEnlarged = () => {
        setIsEnlarged(!isEnlarged);
        if (!isEnlarged) {
            window.scrollTo({
                top: GLOBAL_STYLES['enlarged-scroll'],
                left: 0,
                behavior: 'smooth',
            });
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    let options = {
        ...multiAxisLineChartOptions,
        plugins: {
            ...multiAxisLineChartOptions['plugins'],
            legend: {
                ...multiAxisLineChartOptions['plugins']['legend'],
                labels: {
                    ...multiAxisLineChartOptions['plugins']['legend']['labels'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
        scales: {
            ...multiAxisLineChartOptions['scales'],
            x: {
                grid: {
                    ...multiAxisLineChartOptions['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y1: {
                ...multiAxisLineChartOptions['scales']['y1'],
                grid: {
                    ...multiAxisLineChartOptions['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
            y2: {
                ...multiAxisLineChartOptions['scales']['y2'],
                grid: {
                    ...multiAxisLineChartOptions['scales']['grid'],
                    color: DAISY_THEME_MAP[daisyTheme]['base-300'],
                },
                ticks: {
                    color: DAISY_THEME_MAP[daisyTheme]['base-content'],
                },
            },
        },
    };

    return (
        <>
            {isEnlarged ? (
                <div className={backdrop}>
                    <div className={enlarged_chart} id={'enlarged-chart'}>
                        <div className="flex justify-between items-center w-full px-2">
                            <h3 className="text-primary font-bold text-base">
                                {title}
                            </h3>
                            <span className="btn btn-sm btn-ghost btn-circle">
                                <ArrowsPointingInIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </span>
                        </div>

                        <div className="divider my-3" />

                        <Line
                            style={{
                                maxHeight: '85%',
                            }}
                            data={data}
                            options={options}
                        />
                    </div>
                </div>
            ) : (
                <div className={regular_chart}>
                    <div className="flex desktop:justify-between tablet:justify-between justify-evenly items-center w-full px-2">
                        <h3 className="text-primary font-bold text-base">
                            {title}
                        </h3>
                        {windowSize['innerWidth'] > 1366 && (
                            <a
                                className="btn btn-sm btn-ghost btn-circle"
                                href={'#enlarged-chart'}
                            >
                                <ArrowsPointingOutIcon
                                    className="w-5 h-5"
                                    onClick={toggleEnlarged}
                                />
                            </a>
                        )}
                    </div>

                    <div className="divider my-3" />
                    <Line data={data} options={options} />
                </div>
            )}
        </>
    );
};

export default MultiAxisLineChartComponent;