import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_DATA_SORTING,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
    DISCOVER_WIDGETS_ACTION_TYPES,
} from './widgets.types';

const INITIAL_STATE = {
    dashboard: {
        'sales-trend-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-sales-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'customers-sales-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'returns-trend-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-returns-bottom-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'customers-returns-bottom-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'profit-trend-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-profit-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'skus-profit-bottom-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'discount-trend-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.DISCOUNT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-performance-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.CHAOS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'skus-performance-bottom-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.CHAOS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'customers-performance-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.CHAOS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'reps-performance-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.CHAOS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'categories-sales-top-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CATEGORIES,
            size: 'half',
        },
        'categories-sales-bottom-ranking-[global]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: 'bottom',
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CATEGORIES,
            size: 'half',
        },
    },
    sku: {
        'sales-trend-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-sales-top-ranking-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'reps-sales-top-ranking-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'returns-trend-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-returns-bottom-ranking-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'reps-returns-bottom-ranking-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'profit-trend-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-profit-top-ranking-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'reps-profit-top-ranking-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'discount-trend-[sku]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.DISCOUNT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
    },
    rep: {
        'sales-trend-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-sales-top-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'skus-sales-top-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'returns-trend-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-returns-bottom-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'skus-returns-bottom-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'skus-opportunity-top-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'skus-opportunity-bottom-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'profit-trend-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-profit-top-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'skus-profit-top-ranking-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'discount-trend-[rep]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.DISCOUNT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
    },
    customer: {
        'sales-trend-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-sales-top-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'reps-sales-top-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'returns-trend-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-returns-bottom-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'reps-returns-bottom-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'skus-opportunity-top-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'skus-opportunity-bottom-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.OPPORTUNITY,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'profit-trend-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'skus-profit-top-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.SKUS,
            size: 'half',
        },
        'reps-profit-top-ranking-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.PROFIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'discount-trend-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.DISCOUNT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'unused-credit-trend-[customer]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.UNUSED_CREDIT,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
    },
    category: {
        'sales-trend-[category]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-sales-top-ranking-[category]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'reps-sales-top-ranking-[category]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.SALES,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.TOP,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
        'returns-trend-[category]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.TREND,
            sortDirection: null,
            aggregationMetric: null,
            size: 'full',
        },
        'customers-returns-bottom-ranking-[category]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMERS,
            size: 'half',
        },
        'reps-returns-bottom-ranking-[category]': {
            metric: DISCOVER_WIDGET_DATA_METRICS.RETURNS,
            visualization: DISCOVER_WIDGET_DATA_VISUALIZATIONS.RANKING,
            sortDirection: DISCOVER_WIDGET_DATA_SORTING.BOTTOM,
            aggregationMetric: DISCOVER_WIDGET_AGGREGATION_METRICS.REPS,
            size: 'half',
        },
    },
    watchlist: {},
    hidden: {},
};

export const discoverWidgetsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case DISCOVER_WIDGETS_ACTION_TYPES.SET_DASHBOARD_PAGE_WIDGETS:
            return { ...state, dashboard: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.SET_SKU_PAGE_WIDGETS:
            return { ...state, sku: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.SET_REP_PAGE_WIDGETS:
            return { ...state, rep: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.SET_CUSTOMER_PAGE_WIDGETS:
            return { ...state, customer: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.SET_CATEGORY_PAGE_WIDGETS:
            return { ...state, category: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.SET_WATCHLIST_PAGE_WIDGETS:
            return { ...state, watchlist: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.SET_HIDDEN_WIDGETS:
            return { ...state, hidden: { ...payload } };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_HIDDEN_WIDGETS:
            return { ...state, hidden: {} };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_DASHBOARD_WIDGETS:
            return { ...state, dashboard: { ...INITIAL_STATE.dashboard } };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_WATCHLIST_WIDGETS:
            return { ...state, watchlist: { ...INITIAL_STATE.watchlist } };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_PRODUCT_WIDGETS:
            return { ...state, sku: { ...INITIAL_STATE.sku } };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_CATEGORY_WIDGETS:
            return { ...state, category: { ...INITIAL_STATE.category } };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_REP_WIDGETS:
            return { ...state, rep: { ...INITIAL_STATE.rep } };

        case DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_CUSTOMER_WIDGETS:
            return { ...state, customer: { ...INITIAL_STATE.customer } };

        default:
            return state;
    }
};
