import LoadingComponent, {
    LOADING_COMPONENT_TYPES,
} from '../../loading-component/loading-component';

import {
    DISCOVER_WIDGET_AGGREGATION_METRICS,
    DISCOVER_WIDGET_DATA_METRICS,
    DISCOVER_WIDGET_DATA_VISUALIZATIONS,
} from '../../../store/discover/widgets/widgets.types';

import {
    CheckIcon,
    XMarkIcon,
    InformationCircleIcon,
} from '@heroicons/react/16/solid';

import {
    muted_border,
    widget_full,
} from '../../../utils/styles/tailwind-styles';
import { useSelector } from 'react-redux';
import { selectDaisyTheme } from '../../../store/themes/themes.selectors';
import { useEffect, useState } from 'react';
import {
    DISCOVER_GRAPH_TYPES,
    discoverHomeService,
} from '../../../store/discover/home/discover-home.services';
import {
    convertChaosScoresToPlottable,
    sleep,
} from '../../../utils/dev-data/dev-data';
import ChaosRadar from '../chaos/chaos-radar.component';
import ChaosTrend from '../chaos/chaos-trend.component';

import { isObject } from 'chart.js/helpers';
import DownloadExtract from '../download-extract.component';
import Help from '../help.component';
import { navigationService } from '../../../utils/navigation.util';
import CustomerDetail from './customer-detail.component';
import RepDetail from './rep-detail.component';
import ProductDetail from './product-detail.component';
import { NoSymbolIcon } from '@heroicons/react/20/solid';
import { createNotification } from '../../../utils/notification.util';
import AnimatedBorder from '../../animated-border/animated-border.component';
import CategoryDetail from './category-detail.component';
import { objectService } from '../../../utils/object.util';
import { discoverGraphService } from '../../../store/discover/graph/discover-graph.services';
import { selectUser } from '../../../store/auth/authentication.selectors';
import { USER_ROLES } from '../../../utils/auth.util';

const Admin = ({ type, data, className, handleRefresh = () => {} }) => {
    const daisyTheme = useSelector(selectDaisyTheme);
    const userRole = useSelector(selectUser).role;

    const canExcludeData = [
        USER_ROLES.M2_ADMIN,
        USER_ROLES.M2_SUPPORT,
        USER_ROLES.C_OWNER,
        USER_ROLES.C_ADMIN,
    ].includes(userRole);

    const [chaosData, setChaosData] = useState({});
    const [radar, setRadar] = useState({});

    const [chaosTrend, setChaosTrend] = useState({});
    const [trendVisible, setTrendVisible] = useState(false);

    const [detail, setDetail] = useState({});

    const [excludedItem, setExcludedItem] = useState(null);
    const [excludedLoading, setExcludedLoading] = useState(false);

    const [chaosLoading, setChaosLoading] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false);

    const [showHelp, setShowHelp] = useState(false);

    const fetchChaosData = async () => {
        setChaosLoading(true);

        let chaosSingularData;
        if (type === DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER) {
            chaosSingularData = await discoverHomeService.getDiscoverChaos(
                DISCOVER_GRAPH_TYPES.SINGULAR,
                {
                    type: DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER,
                    code: data['code'],
                    descr: data['descr'],
                }
            );
        } else if (type === DISCOVER_WIDGET_AGGREGATION_METRICS.REP) {
            chaosSingularData = await discoverHomeService.getDiscoverChaos(
                DISCOVER_GRAPH_TYPES.SINGULAR,
                {
                    type: DISCOVER_WIDGET_AGGREGATION_METRICS.REP,
                    code: data['code'],
                    descr: data['name'],
                }
            );
        } else if (type === DISCOVER_WIDGET_AGGREGATION_METRICS.SKU) {
            chaosSingularData = await discoverHomeService.getDiscoverChaos(
                DISCOVER_GRAPH_TYPES.SINGULAR,
                {
                    type: DISCOVER_WIDGET_AGGREGATION_METRICS.SKU,
                    code: data['code'],
                    descr: data['descr'],
                }
            );
        }

        setChaosData(chaosSingularData);
        setChaosLoading(false);
    };

    const fetchDetailData = async () => {
        setDetailLoading(true);

        let detailData = await discoverHomeService.getDetailData({
            type: type,
            code: data['code'],
        });

        if (Object.keys(detailData).includes(data['code']))
            setDetail(detailData[data['code']]);

        setDetailLoading(false);
    };

    const fetchExcluded = async () => {
        setExcludedLoading(true);

        let excluded = await discoverHomeService.getExclusion(
            type,
            data['code']
        );
        if (excluded && Object.keys(excluded).length === 2)
            setExcludedItem(true);
        else setExcludedItem(false);

        setExcludedLoading(false);
    };

    const handleReinclude = async () => {
        setExcludedLoading(true);

        if (!canExcludeData) {
            createNotification('Your role is not able to remove exclusions.');
            return;
        }

        const message = await discoverHomeService.removeExclusion(
            type,
            data['code']
        );
        createNotification(message.message);
        if (message.message === 'Exclusion removed.') setExcludedItem(false);

        setExcludedItem(false);
        setExcludedLoading(false);

        await sleep(750);
        handleRefresh();
    };

    const handleExclude = async () => {
        setExcludedLoading(true);

        if (!canExcludeData) {
            createNotification('Your role is not able to add exclusions.');
            return;
        }

        const message = await discoverHomeService.addExclusion(
            type,
            data['code']
        );

        createNotification(message.message);
        if (message.message === 'Exclusion added.') setExcludedItem(true);
        setExcludedLoading(false);

        await sleep(750);
        handleRefresh();
    };

    useEffect(() => {
        setChaosData({});
        fetchChaosData();

        setDetail({});
        fetchDetailData();

        setExcludedItem(null);
        fetchExcluded();
    }, []);

    useEffect(() => {
        if (
            objectService.checkObjectPopulated(chaosData) &&
            Object.keys(chaosData).includes('latest') &&
            Object.keys(chaosData).includes('trend')
        ) {
            try {
                const radarScores = convertChaosScoresToPlottable(
                    chaosData['latest']['chaos_pillars'],
                    daisyTheme
                );
                setRadar(radarScores);
            } catch (e) {
                console.log(e);
            }

            if (
                chaosData['trend'] &&
                isObject(chaosData['trend']) &&
                Object.keys(chaosData['trend']).length > 0
            ) {
                try {
                    const chaosTrendPlottable =
                        discoverGraphService.convertChaosTrendDataToLineCharts(
                            chaosData['trend'],
                            daisyTheme
                        );
                    setChaosTrend(chaosTrendPlottable);
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [daisyTheme, chaosData]);

    return (
        <div
            className={`${className} ${widget_full} ${muted_border} gap-x-6 w-full group`}
            id={'admin'}
        >
            <AnimatedBorder />

            {type === DISCOVER_WIDGET_AGGREGATION_METRICS.CUSTOMER ? (
                <div className="col-span-2 flex flex-col gap-2 h-full w-full justify-center">
                    <span className="text-base-content text-opacity-80 font-bold text-2xl text-center w-full">
                        {data['descr']}
                    </span>

                    <div className={`flex items-center justify-evenly w-full`}>
                        <span
                            data-tip={'Customer Code'}
                            className="badge badge-primary badge-outline badge-lg tooltip cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(data['code'])
                            }
                        >
                            {data['code']}
                        </span>

                        {objectService.checkObjectPopulated(detail) ? (
                            <span
                                className={`rounded-full p-1 ${
                                    detail['active'] ? 'bg-success' : 'bg-error'
                                } tooltip`}
                                data-tip={
                                    detail['active'] ? 'Active' : 'Inactive'
                                }
                            >
                                {detail['active'] ? (
                                    <CheckIcon
                                        className={
                                            'w-4 h-4 text-success-content'
                                        }
                                    />
                                ) : (
                                    <XMarkIcon
                                        className={'w-4 h-4 text-error-content'}
                                    />
                                )}
                            </span>
                        ) : (
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.TAILCHASE}
                                size={16}
                            />
                        )}
                    </div>

                    <div className="divider my-2" />

                    <CustomerDetail detail={detail} />
                </div>
            ) : type === DISCOVER_WIDGET_AGGREGATION_METRICS.REP ? (
                <div className="col-span-2 flex flex-col gap-2 h-full w-full justify-center">
                    <span className="text-base-content text-opacity-80 font-bold text-2xl text-center w-full">
                        {data['name'] ?? '-'}
                    </span>

                    <div className={`flex items-center justify-evenly w-full`}>
                        <span
                            className="badge badge-primary badge-outline badge-lg tooltip cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(data['code'])
                            }
                            data-tip={'Rep Code'}
                        >
                            {data['code'] ?? '-'}
                        </span>

                        <span
                            className={`rounded-full p-1 bg-success tooltip`}
                            data-tip={'Active'}
                        >
                            <CheckIcon
                                className={'w-4 h-4 text-success-content'}
                            />
                        </span>
                    </div>

                    <div className="divider my-2" />

                    <RepDetail detail={detail} />
                </div>
            ) : type === DISCOVER_WIDGET_AGGREGATION_METRICS.SKU ? (
                <div className="col-span-2 flex flex-col gap-2 h-full w-full justify-center">
                    <span className="text-base-content text-opacity-80 font-bold text-2xl text-center w-full">
                        {data['descr']}
                    </span>

                    <div className={`flex items-center justify-evenly w-full`}>
                        <span
                            className="badge badge-primary badge-outline badge-lg tooltip cursor-copy"
                            onClick={() =>
                                navigationService.copyToClipboard(data['code'])
                            }
                            data-tip={'Product Code'}
                        >
                            {data['code']}
                        </span>

                        {Object.keys(detail).length > 0 ? (
                            <span
                                className={`rounded-full p-1 ${
                                    detail['active'] ? 'bg-success' : 'bg-error'
                                } tooltip`}
                                data-tip={
                                    detail['active'] ? 'Active' : 'Inactive'
                                }
                            >
                                {detail['active'] ? (
                                    <CheckIcon
                                        className={
                                            'w-4 h-4 text-success-content'
                                        }
                                    />
                                ) : (
                                    <XMarkIcon
                                        className={'w-4 h-4 text-error-content'}
                                    />
                                )}
                            </span>
                        ) : (
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.SUPERBALLS}
                                size={24}
                            />
                        )}
                    </div>

                    <div className="divider my-2" />

                    <ProductDetail detail={detail} />
                </div>
            ) : type === DISCOVER_WIDGET_AGGREGATION_METRICS.CATEGORY ? (
                <>
                    <div className="col-span-2 flex flex-col gap-2 h-full w-full justify-center">
                        <span className="text-base-content text-opacity-80 font-bold text-2xl text-center w-full">
                            {data['descr']}
                        </span>

                        <div
                            className={`flex items-center justify-evenly w-full`}
                        >
                            <span
                                className="badge badge-primary badge-outline badge-lg tooltip cursor-copy"
                                onClick={() =>
                                    navigationService.copyToClipboard(
                                        data['code']
                                    )
                                }
                                data-tip={'Category Code'}
                            >
                                {data['code']}
                            </span>
                        </div>

                        <div className="divider my-2" />

                        {<CategoryDetail detail={data} />}
                    </div>
                </>
            ) : (
                <></>
            )}

            <div className="col-span-3">
                {chaosLoading ? (
                    <div
                        className={`flex items-center justify-center w-full stats bg-base-300 bg-opacity-80 py-16 px-2 rounded-box col-span-5 border-l-2 border-base-content border-opacity-10`}
                    >
                        <LoadingComponent
                            type={LOADING_COMPONENT_TYPES.HELIX}
                            size={230}
                            speed={1.7}
                        />
                    </div>
                ) : chaosData ? (
                    <ChaosRadar
                        data={chaosData['latest']}
                        radar={radar}
                        trendVisible={trendVisible}
                        onToggleTrend={() => setTrendVisible(!trendVisible)}
                        className={null}
                    />
                ) : (
                    <div className={`${className} ${muted_border}`}>
                        <div className="flex flex-wrap justify-center items-center py-3 col-span-full h-full">
                            <div className="mx-2" />
                            <p className="col-span-3 text-sm text-base-content text-opacity-70 font-bold">
                                Chaos scores are not calculated for{' '}
                                <span className="text-primary">
                                    {type.slice(0, 1).toUpperCase() +
                                        type.slice(1, type.length)}
                                </span>
                                .
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <div className="col-span-5">
                {chaosData && (
                    <ChaosTrend
                        data={chaosData['latest']}
                        trend={chaosTrend}
                        className={`${
                            trendVisible
                                ? 'h-fit opacity-90'
                                : 'h-0 opacity-0 p-0'
                        } transition-all ease-linear duration-300 z-0`}
                    />
                )}
            </div>

            <div className="flex w-full justify-end items-center gap-1 col-span-full">
                <div className="flex items-center gap-1 w-fit">
                    {excludedLoading ? (
                        <span className="mx-1 flex items-center">
                            <LoadingComponent
                                type={LOADING_COMPONENT_TYPES.TAILCHASE}
                                size={16}
                            />
                        </span>
                    ) : excludedItem ? (
                        <span
                            className={`btn ${
                                canExcludeData
                                    ? 'btn-error '
                                    : 'disabled btn-disabled'
                            } btn-outline btn-xs gap-1 cursor-pointer tooltip tooltip-left font-bold`}
                            onClick={handleReinclude}
                            data-tip={'Re-include in Data?'}
                        >
                            <NoSymbolIcon className="w-4 h-4" />
                            Excluded <span className="capitalize">{type}</span>
                        </span>
                    ) : (
                        <span
                            className={`tooltip tooltip-left btn ${
                                canExcludeData
                                    ? 'btn-ghost'
                                    : 'disabled btn-disabled'
                            } btn-circle btn-xs capitalize`}
                            onClick={handleExclude}
                            data-tip={`Exclude from data`}
                        >
                            <NoSymbolIcon
                                className={`w-4 h-4 ${
                                    canExcludeData ? 'text-error' : ''
                                }`}
                            />
                        </span>
                    )}

                    <span
                        className={`tooltip tooltip-left btn btn-ghost btn-circle btn-xs capitalize cursor-help`}
                        onClick={() => {
                            setTrendVisible(true);
                            setShowHelp(!showHelp);
                        }}
                        data-tip={`What is Performance?`}
                    >
                        <InformationCircleIcon
                            className={'w-4 h-4 text-primary'}
                        />
                    </span>

                    <DownloadExtract
                        metric={`${type}_master`}
                        perspective={null}
                        selectedDate={null}
                        onDateChange={null}
                    />
                    <span
                        className={
                            'text-xs text-base-content text-opacity-50 capitalize w-full'
                        }
                    >
                        {type} Master
                    </span>
                </div>
            </div>

            {showHelp && (
                <Help
                    metric={DISCOVER_WIDGET_DATA_METRICS.CHAOS}
                    visualization={DISCOVER_WIDGET_DATA_VISUALIZATIONS.SINGULAR}
                    onClose={() => setShowHelp(false)}
                />
            )}
        </div>
    );
};

export default Admin;