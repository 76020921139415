import { useSelector } from 'react-redux';
import { selectFilterOptions } from '../../../../store/base/filters/options/base-filter-options.selectors';
import {
    BASE_FILTER_PLACEHOLDER_MAP,
    BASE_FILTER_TYPES,
} from '../../../../store/base/filters/base-filters.types';

import BasicFilter from '../basic-filter/basic-filter.component';

const SkuFilters = ({ isLoading, setLoading }) => {
    const {
        skulists,
        supplcategories,
        supplsubcategories,
        supplbrands,
        supplskus,
    } = useSelector(selectFilterOptions);

    return (
        <div className={`w-full max-w-2xl h-full`}>
            <span className="badge badge-outline badge-primary badge-sm font-bold">
                Sku Filters
            </span>

            <BasicFilter
                options={skulists}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.skulists]
                }
                isMulti={true}
                isClearable={true}
                id={BASE_FILTER_TYPES.skulists}
                isLoading={isLoading}
                setLoading={setLoading}
                className="my-4 max-w-2xl"
            />

            <BasicFilter
                options={supplcategories}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[
                        BASE_FILTER_TYPES.supplcategories
                    ]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.supplcategories}
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={supplsubcategories}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[
                        BASE_FILTER_TYPES.supplsubcategories
                    ]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.supplsubcategories}
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={supplbrands}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.supplbrands]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.supplbrands}
                isLoading={isLoading}
                setLoading={setLoading}
            />

            <BasicFilter
                options={supplskus}
                placeholder={
                    BASE_FILTER_PLACEHOLDER_MAP[BASE_FILTER_TYPES.supplskus]
                }
                isMulti={true}
                isClearable={true}
                className="my-4 max-w-2xl"
                closeMenuOnSelect={false}
                id={BASE_FILTER_TYPES.supplskus}
                isLoading={isLoading}
                setLoading={setLoading}
            />
        </div>
    );
};

export default SkuFilters;