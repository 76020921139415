import { createAction } from '../../utils/reducer.utils';
import { DISCOVER_WIDGETS_ACTION_TYPES } from './widgets.types';

export const setDashboardPageWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_DASHBOARD_PAGE_WIDGETS,
        widgets
    );
};

export const setSkuPageWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_SKU_PAGE_WIDGETS,
        widgets
    );
};

export const setRepPageWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_REP_PAGE_WIDGETS,
        widgets
    );
};

export const setCustomerPageWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_CUSTOMER_PAGE_WIDGETS,
        widgets
    );
};

export const setCategoryPageWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_CATEGORY_PAGE_WIDGETS,
        widgets
    );
};

export const setWatchlistPageWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_WATCHLIST_PAGE_WIDGETS,
        widgets
    );
};

export const setHiddenWidgets = (widgets) => {
    return createAction(
        DISCOVER_WIDGETS_ACTION_TYPES.SET_HIDDEN_WIDGETS,
        widgets
    );
};

export const clearWatchlistWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_WATCHLIST_WIDGETS);
};

export const clearProductWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_PRODUCT_WIDGETS);
};

export const clearCategoryWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_CATEGORY_WIDGETS);
};

export const clearCustomerWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_CUSTOMER_WIDGETS);
};

export const clearRepWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_REP_WIDGETS);
};

export const clearDashboardWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_DASHBOARD_WIDGETS);
};

export const clearWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR);
};

export const clearHiddenWidgets = () => {
    return createAction(DISCOVER_WIDGETS_ACTION_TYPES.CLEAR_HIDDEN_WIDGETS);
};
