import { RANGING_ACTION_TYPES } from './ranging.types';
import { createAction } from '../../../utils/reducer.utils';

export const setRangingFilterStrategy = (strategy) => {
    return createAction(
        RANGING_ACTION_TYPES.SET_RANGING_FILTER_STRATEGY,
        strategy
    );
};

export const setRangingFilterSort = (sort) => {
    return createAction(RANGING_ACTION_TYPES.SET_RANGING_FILTER_SORT, sort);
};

export const resetRangingFilters = () => {
    return createAction(RANGING_ACTION_TYPES.RESET_RANGING_FILTER);
};
