import { useState } from 'react';
import { useSelector } from 'react-redux';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

import FooterComponent from '../../../components/footer-component/footer.component';

import { apiService } from '../../../utils/engine/api.service';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../utils/notification.util';
import { selectProductType } from '../../../store/product/product.selectors';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_TYPES } from '../../../store/product/product.types';

import LoadingComponent from '../../../components/loading-component/loading-component';

const PasswordUpdatePage = () => {
    const navigate = useNavigate();
    const productSelected = useSelector(selectProductType);

    const [isLoading, setIsLoading] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [newPasswordValid, setNewPasswordValid] = useState(false);
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);

    const handlePasswordChange = (event) => {
        setNewPassword(event.currentTarget.value);
        if (event.currentTarget.value.length >= 8) {
            setNewPasswordValid(true);
        } else {
            setNewPasswordValid(false);
        }
    };

    const handleConfirmChange = (event) => {
        setConfirmPassword(event.currentTarget.value);

        if (event.currentTarget.value === newPassword) {
            setConfirmPasswordValid(true);
        } else {
            setConfirmPasswordValid(false);
        }
    };

    const onUpdatePassword = async (event) => {
        event.preventDefault();

        if (!newPasswordValid) {
            createNotification(
                'Your password must be at least 8 characters',
                NOTIFICATION_TYPES.ERROR
            );
        } else if (!confirmPasswordValid) {
            createNotification(
                'Passwords do not match',
                NOTIFICATION_TYPES.ERROR
            );
        } else {
            try {
                setIsLoading(true);
                const response = await apiService.updatePassword(newPassword);
                setIsLoading(false);

                createNotification(
                    'Password updated',
                    NOTIFICATION_TYPES.SUCCESS
                );

                if (productSelected === PRODUCT_TYPES.PROFIT_ROCKET_BASE) {
                    navigate('../base/dashboard', { replace: true });
                } else if (
                    productSelected === PRODUCT_TYPES.PROFIT_ROCKET_DISCOVER
                ) {
                    navigate('../discover/dashboard', { replace: true });
                } else {
                    navigate('/');
                }
            } catch (err) {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center bg-base-100 w-full h-screen-without-navbar">
                <div
                    className="card flex bg-base-200 flex-col py-8 px-12 shadow-lg w-full max-w-xl justify-center items-center"
                    style={{
                        minHeight: '14rem',
                    }}
                >
                    {isLoading ? (
                        <LoadingComponent />
                    ) : (
                        <form
                            id="updateForm"
                            className="w-full"
                            onSubmit={onUpdatePassword}
                        >
                            <div className="w-full h-full flex justify-evenly items-start flex-col">
                                <span className="w-full h-full flex justify-between items-center">
                                    <label
                                        className="text-sm text-start p-2"
                                        htmlFor="new-password"
                                    >
                                        New Password
                                    </label>
                                    {newPasswordVisible ? (
                                        <span
                                            className="btn btn-circle btn-ghost btn-sm"
                                            onClick={() =>
                                                setNewPasswordVisible(
                                                    !newPasswordVisible
                                                )
                                            }
                                        >
                                            <EyeSlashIcon className="w-5 h-6" />
                                        </span>
                                    ) : (
                                        <span
                                            className="btn btn-circle btn-ghost btn-sm"
                                            onClick={() =>
                                                setNewPasswordVisible(
                                                    !newPasswordVisible
                                                )
                                            }
                                        >
                                            <EyeIcon className="w-5 h-5" />
                                        </span>
                                    )}
                                </span>

                                <input
                                    className={`input input-bordered w-full ${
                                        newPasswordValid && newPassword !== ''
                                            ? 'border-success'
                                            : 'border-error'
                                    }`}
                                    name="password"
                                    id="password"
                                    type={
                                        newPasswordVisible ? 'text' : 'password'
                                    }
                                    required={true}
                                    value={newPassword}
                                    onChange={handlePasswordChange}
                                />
                            </div>

                            <div className="w-full h-full flex justify-evenly items-start flex-col">
                                <span className="w-full h-full flex justify-between items-center">
                                    <label
                                        className="text-sm text-start p-2"
                                        htmlFor="confirm-password"
                                    >
                                        Confirm Password
                                    </label>
                                    {confirmPasswordVisible ? (
                                        <span
                                            className="btn btn-circle btn-ghost btn-sm"
                                            onClick={() =>
                                                setConfirmPasswordVisible(
                                                    !confirmPasswordVisible
                                                )
                                            }
                                        >
                                            <EyeSlashIcon className="w-5 h-6" />
                                        </span>
                                    ) : (
                                        <span
                                            className="btn btn-circle btn-ghost btn-sm"
                                            onClick={() =>
                                                setConfirmPasswordVisible(
                                                    !confirmPasswordVisible
                                                )
                                            }
                                        >
                                            <EyeIcon className="w-5 h-5" />
                                        </span>
                                    )}
                                </span>

                                <input
                                    className={`input input-bordered w-full ${
                                        confirmPasswordValid &&
                                        confirmPassword !== ''
                                            ? 'border-success'
                                            : 'border-error'
                                    }`}
                                    name="confirm-password"
                                    id="confirm-password"
                                    type={
                                        confirmPasswordVisible
                                            ? 'text'
                                            : 'password'
                                    }
                                    required={true}
                                    value={confirmPassword}
                                    onChange={handleConfirmChange}
                                />
                            </div>

                            <div className="divider" />
                            <div className="w-full flex justify-end items-center mt-5">
                                <button
                                    className="btn btn-outline normal-case"
                                    type="Submit"
                                >
                                    Update Password
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <FooterComponent />
        </>
    );
};

export default PasswordUpdatePage;
