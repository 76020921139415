import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    createNotification,
    NOTIFICATION_TYPES,
} from '../../../../utils/notification.util';

import { selectDaisyTheme } from '../../../../store/themes/themes.selectors';
import { selectFiltersSelected } from '../../../../store/base/filters/base-filters.selectors';
import { selectTrendFilters } from '../../../../store/base/filters/trend/trend.selectors';
import { selectUserToken } from '../../../../store/auth/authentication.selectors';
import { trendService } from '../../../../store/base/filters/trend/trend.services';
import { apiService } from '../../../../utils/engine/api.service';
import { WEB_PROCESS_FLOWS } from '../../../../utils/engine/pf-config.service';
import { TREND_FILTER_TYPES } from '../../../../store/base/filters/trend/trend.types';

import TrendFiltersComponent from '../../../../components/base/filters/trend-filters/trend-filters.component';
import FiltersContainer from '../../../../components/base/filters/filters-container.component';
import LineChartComponent from '../../../../components/charts/line-chart-component/line-chart.component';
import StackedChartComponent from '../../../../components/charts/stacked-chart-component/stacked-chart.component';
import ShareViewComponent, {
    onShareViewClick,
} from '../../../../components/base/share-view-component/share-view.component';
import GenerateReportComponent from '../../../../components/base/generate-report-component/generate-report.component';
import NoData from '../../../../components/no-data/no-data.component';
import PageLoadingComponent from '../../../../components/loading-component/page-loading-component';
import {
    base_grid,
    base_page,
    base_subnavigation_bar,
    base_widget_half,
    muted_border,
} from '../../../../utils/styles/tailwind-styles';

const SOHTrendPage = () => {
    const [sumData, setSumData] = useState(null);
    const [averageData, setAverageData] = useState(null);
    const [sumDataPlottable, setSumDataPlottable] = useState(null);
    const [averageDataPlottable, setAverageDataPlottable] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [trendFiltersVisible, setTrendFiltersVisible] = useState(false);

    const [viewType, setViewType] = useState(TREND_FILTER_TYPES.view.default);

    const daisyTheme = useSelector(selectDaisyTheme);

    const selectedFilters = useSelector(selectFiltersSelected);
    const trendFilters = useSelector(selectTrendFilters);

    const userToken = useSelector(selectUserToken);

    const handleViewTypeChange = (event) => {
        setViewType(event);
    };

    const onShare = async (recipientsSelected, additionalMessage) => {
        setIsLoading(true);
        try {
            await onShareViewClick(
                userToken,
                selectedFilters,
                trendFilters,
                recipientsSelected,
                additionalMessage
            );
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchStockTrendDataHelper = async () => {
        setIsLoading(true);
        try {
            const trendData = await trendService.loadStockTrendData(
                selectedFilters,
                trendFilters
            );

            if (trendData['sumInvQty']['channels'][0]['dataKey'] !== 'Value') {
                setSumData(trendData['sumInvQty']);
                setSumDataPlottable(
                    trendService.convertTrendDataToPlottable(
                        trendData['sumInvQty'],
                        daisyTheme
                    )
                );
                setAverageData(trendData['avgInvQty']);
                setAverageDataPlottable(
                    trendService.convertTrendDataToPlottable(
                        trendData['avgInvQty'],
                        daisyTheme
                    )
                );
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const onGenerateReportClick = async (reportParams) => {
        try {
            setIsLoading(true);
            const trendData = await trendService.loadStockTrendData(
                selectedFilters,
                trendFilters,
                reportParams
            );
            if (trendData.message && trendData.message === 'Processing Request')
                createNotification(
                    'Report request sent',
                    NOTIFICATION_TYPES.SUCCESS
                );

            if (reportParams['cronText'] != null) {
                await apiService.scheduleTrendProcess(
                    WEB_PROCESS_FLOWS.INV,
                    selectedFilters,
                    trendFilters,
                    reportParams,
                    reportParams['cronText']
                );
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchStockTrendDataHelper();
    }, [selectedFilters, trendFilters]);

    useEffect(() => {
        if (sumData !== null) {
            setSumDataPlottable(
                trendService.convertTrendDataToPlottable(sumData, daisyTheme)
            );
        }
        if (averageData !== null) {
            setAverageDataPlottable(
                trendService.convertTrendDataToPlottable(
                    averageData,
                    daisyTheme
                )
            );
        }
    }, [daisyTheme]);

    return (
        <>
            <div className={base_subnavigation_bar}>
                <div className="flex w-full h-full justify-between items-center max-w-8xl">
                    <span className={'flex items-center gap-1'}>
                        <a
                            className="flex h-full items-center btn btn-ghost font-bold btn-sm md:btn-md lg:btn-lg text-base normal-case text-base-content text-opacity-80 tracking-wider"
                            href={'/base/stock-trend'}
                        >
                            Stock Trend
                        </a>

                        <ShareViewComponent onClick={onShare} />
                        <GenerateReportComponent
                            onGenerate={onGenerateReportClick}
                            dateRange={false}
                            includeSoh={false}
                            frequency={false}
                        />
                    </span>

                    <span className="flex items-center justify-between">
                        <TrendFiltersComponent
                            className="z-10"
                            onViewChange={handleViewTypeChange}
                            viewState={viewType}
                            visible={trendFiltersVisible}
                            toggleOpen={() => {
                                setTrendFiltersVisible(!trendFiltersVisible);
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className={`${base_page}`}>
                <div className={`${base_grid}`}>
                    <FiltersContainer className={'col-span-full'} />

                    {isLoading === true ? (
                        <PageLoadingComponent />
                    ) : sumDataPlottable && averageDataPlottable ? (
                        <>
                            <div
                                className={`${base_widget_half} ${muted_border}`}
                            >
                                {viewType.value === 'lines' ? (
                                    <LineChartComponent
                                        data={sumDataPlottable}
                                        title="Stock Sum"
                                        type={'Average'}
                                        interactionMode="nearest"
                                        onClickHideOthers={false}
                                    />
                                ) : (
                                    <StackedChartComponent
                                        data={sumDataPlottable}
                                        title="Stock Sum"
                                        type={'Average'}
                                        interactionMode="nearest"
                                        onClickHideOthers={false}
                                    />
                                )}
                            </div>

                            <div
                                className={`${base_widget_half} ${muted_border}`}
                            >
                                {' '}
                                {viewType.value === 'lines' ? (
                                    <LineChartComponent
                                        data={averageDataPlottable}
                                        title="Stock Count"
                                        type={'Average'}
                                        interactionMode="nearest"
                                        onClickHideOthers={false}
                                    />
                                ) : (
                                    <StackedChartComponent
                                        data={averageDataPlottable}
                                        title="Stock Count"
                                        type={'Average'}
                                        interactionMode="nearest"
                                        onClickHideOthers={false}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <NoData className="col-span-full" />
                    )}
                </div>
            </div>
        </>
    );
};

export default SOHTrendPage;
