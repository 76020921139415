import { createAction } from '../utils/reducer.utils';

import { engineService } from '../../utils/engine/engine.service';
import { WEB_PROCESS_FLOWS } from '../../utils/engine/pf-config.service';

import { AUTHENTICATION_ACTION_TYPES } from './authentication.types';

import http from '../../utils/request.util';
import { store } from '../store';

export const authenticationService = {
    login,
    fetchUserMasterdata,

    resetPassword,
    requestResetPassword,

    logout,
};

async function login(email, password) {
    const response = await http.post(
        '/app/login',
        JSON.stringify({ email, password })
    );

    const user = response.data;

    if (!user) return null;

    // Store retrieved user in redux
    store.dispatch(
        createAction(AUTHENTICATION_ACTION_TYPES.SET_CURRENT_USER, user)
    );

    // Fetch user's masterdata to check if they're setup for specific products
    const userMasterdata = await engineService.invoke(
        WEB_PROCESS_FLOWS.MASTERDATA,
        {
            action: 'retrieveUserMasterdata',
            payload: {
                email: email,
            },
        }
    );

    let authUser = store.getState().authentication.user;
    const userObject = {
        ...authUser,
        email: email,
        products: userMasterdata['products'],
        reps: userMasterdata['reps'],
    };

    return createAction(
        AUTHENTICATION_ACTION_TYPES.SET_CURRENT_USER,
        userObject
    );
}

async function requestResetPassword(email) {
    const response = await http.post(
        `/api/prweb/auth/request-password-reset`,
        JSON.stringify({ email })
    );
    return response;
}

async function resetPassword(token, plainTextPassword) {
    const response = await http.post(
        `/api/prweb/auth/password-reset`,
        JSON.stringify({ token, plainTextPassword })
    );
    return response;
}

export function logout() {
    return createAction(AUTHENTICATION_ACTION_TYPES.SET_CURRENT_USER, null);
}

async function fetchUserMasterdata(email) {
    let fetchParams = {
        action: 'retrieveUserMasterdata',
        payload: {
            email: email,
        },
    };

    const response = await engineService.invoke(
        WEB_PROCESS_FLOWS.MASTERDATA,
        fetchParams
    );
    return response.data;
}