let orgId;

const hostname = window && window.location && window.location.hostname;

if (
    hostname === 'app.profitrocket.io' ||
    hostname === 'www.profitrocket.io' ||
    hostname === 'profitrocket.io'
) {
    orgId = 'JGKV3';
}

export const ORG_ID = orgId;
