import { useState } from 'react';

const AdminSkeleton = ({ rows = 5, className = '' }) => {
    const [rowArr, setRowArr] = useState([...Array(rows).keys()]);

    return (
        <div
            className={`grid grid-cols-2 gap-x-2 gap-y-6 text-sm items-start ${className}`}
        >
            {rowArr.map((index) => {
                if (index === rowArr.length - 1) {
                    return (
                        <div
                            key={index}
                            className="flex flex-col justify-center items-center gap-1 col-span-2"
                        >
                            <span
                                className={
                                    'bg-base-content p-4 bg-opacity-15 w-48 animate-pulse'
                                }
                            ></span>

                            <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse"></span>
                        </div>
                    );
                } else {
                    return (
                        <div
                            key={index}
                            className="flex flex-col justify-center items-center gap-1 col-span-1"
                        >
                            <span
                                className={
                                    'bg-base-content p-4 bg-opacity-15 w-24 animate-pulse'
                                }
                            ></span>

                            <span className="bg-base-content p-4 bg-opacity-5 w-full animate-pulse"></span>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default AdminSkeleton;